import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import ToolItem from "./ToolItem";
import ToolUserModal from "./ToolUserModal";
import useToolModal from "./useToolModal";
import ToolFunctionModal from "./ToolFunctionModals/ToolFunctionModal";
import AddEditToolModal from "./AddEditToolModal";
import { useState } from "react";
import { getHostDomain } from "../../utils/utils";
import ConfirmationModal from "../Modals/Confirmation";
import { fetchController } from "../../utils/FetchController/fetchController";
import { toast } from "react-toastify";

export default function ToolsList({ filteredTools, getToolList }) {
  const baseUrl = getHostDomain();
  const { toolId, closeToolModal, openToolModal, isOpen } = useToolModal();
  const [selectedToolId, setSelectedToolId] = useState("");
  const [functionBaseUrl, setFuncionBaseUrl] = useState("");

  const {
    isOpen: isToolFunctionsOpen,
    onOpen: openToolFunctions,
    onClose: closeToolFunctions,
  } = useDisclosure();

  const {
    isOpen: isEditToolOpen,
    onOpen: editToolOpen,
    onClose: editToolClose,
  } = useDisclosure();

  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();

  const selectedTool = filteredTools.find((tool) => tool.id === toolId);

  const onConfirmToolDelete = async () => {
    try {
      const response = await fetchController(
        baseUrl + `/api/v1/tool/${selectedToolId}`,
        "DELETE"
      );
      toast.success(response.message || "Tool deleted successfully");
    } catch (error) {
      console.error(error);
    } finally {
      setSelectedToolId("");
      getToolList();
    }
  };

  const onCancelToolDelete = () => {
    setSelectedToolId("");
    onCloseConfirmationModal();
  };

  return (
    <Box>
      <Flex maxH={"70svh"} overflowY={"auto"} flexWrap={"wrap"} gap={4}>
        {filteredTools.map((tool, index) => (
          <ToolItem
            tool={tool}
            key={index}
            onOpenTool={openToolModal}
            editToolOpen={editToolOpen}
            selectedToolId={selectedToolId}
            setSelectedToolId={setSelectedToolId}
            onOpenConfirmationModal={onOpenConfirmationModal}
          />
        ))}
      </Flex>

      {selectedTool && isOpen && !isEditToolOpen && !isToolFunctionsOpen && (
        <ToolUserModal
          isOpen={isOpen}
          onClose={closeToolModal}
          tool={selectedTool}
        />
      )}

      {selectedToolId && isEditToolOpen && (
        <AddEditToolModal
          isOpen={isEditToolOpen}
          onClose={editToolClose}
          toolId={selectedToolId}
          refreshToolList={getToolList}
          openToolFunctions={openToolFunctions}
          setFuncionBaseUrl={setFuncionBaseUrl}
        />
      )}

      {selectedToolId && isToolFunctionsOpen && (
        <ToolFunctionModal
          isOpen={isToolFunctionsOpen}
          onClose={closeToolFunctions}
          toolId={selectedToolId}
          closeToolModal={closeToolModal}
          functionBaseUrl={functionBaseUrl}
        />
      )}

      {selectedToolId && isConfirmationModalOpen && (
        <ConfirmationModal
          table={"customTool"}
          isOpen={isConfirmationModalOpen}
          onConfirm={onConfirmToolDelete}
          onClose={onCancelToolDelete}
        />
      )}
    </Box>
  );
}
