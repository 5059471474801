import { Button, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { FaMicrophone, FaRegStopCircle } from "react-icons/fa";

const VoiceRecorder = ({
  status,
  mediaBlobUrl,
  previewStream,
  handleClickOnStartRecording,
  handleClickOnStopRecording,
}) => {
  return (
    <Stack gap={2} direction={"column"}>
      <Text>
        <b>
          Please read the following message in a silent room to build your
          digital voice:
        </b>{" "}
        <br />
        Voice cloning is the process of creating a synthetic voice using voice
        recordings of a real person. Voice cloning uses Artificial Intelligence
        techniques to extract the spectra of the voice and train a
        machine-learning voice model on real recordings to create a voice that
        sounds almost like the real voice.
      </Text>

      <Stack gap={2} direction={"column"}>
        <Stack
          spacing={4}
          border={"1px solid"}
          borderColor={"inherit"}
          borderRadius={4}
          direction={"row"}
          justifyContent={"space-between"}
          p={3}
        >
          <Text>
            {status === "idle"
              ? "Hit the record button to start creating your own voice"
              : status}
          </Text>
          <Stack spacing={4} direction={"row"}>
            {status === "idle" || status === "stopped" ? (
              <>
                <Button
                  onClick={handleClickOnStartRecording}
                >
                  {" "}
                  <FaMicrophone size={25} className="start__recordingBtn" />
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleClickOnStopRecording}
                >
                  <FaRegStopCircle size={25} className="stop__recordingBtn" />
                </Button>
              </>
            )}
          </Stack>
        </Stack>
        {mediaBlobUrl && (
          <Stack
            spacing={4}
            direction={"column"}
            border={"1px solid"}
            borderColor={"inherit"}
            borderRadius={4}
          >
            <audio controls>
              <source src={mediaBlobUrl} type="audio/wav" />
            </audio>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default VoiceRecorder;
