import { Box, Flex, Grid, Spinner, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import usePricingInAssistant from "./usePricingInAssistant";
import { IoInformationCircleOutline } from "react-icons/io5";

export default function PricingAssistantCallRealtime(pricingParams) {
  const { knownCostBreakups, costBreakupPercentages, status, pricing } =
    usePricingInAssistant(pricingParams);
  if (status === "loading")
    return (
      <Flex justifyContent={"center"} alignItems={"center"}>
        <Spinner />
      </Flex>
    );
  return (
    <Box>
      <Grid justifyItems={"center"} templateColumns={"auto 1fr"} gap={3}>
        <Flex gap={2} justifyContent={"flex-start"} alignItems={"center"}>
          <Text fontWeight={"bold"}>
            Total Cost : {pricing?.currency_symbol}
            {pricing?.total_cost?.toFixed(2)}/min
          </Text>
          <a target="_blank" href="https://insightoai.freshdesk.com/support/solutions/articles/1070000091215-pay-as-you-go-wallet">
            <IoInformationCircleOutline size={15} />
          </a>
        </Flex>
        <Flex h={"10px"} width={"100%"}>
          {costBreakupPercentages.map((costBreakup, index) => (
            <Tooltip key={index} label={costBreakup.label}>
              <Box
                bg={costBreakup.bg}
                key={index}
                w={`${costBreakup.percentage}%`}
              />
            </Tooltip>
          ))}
        </Flex>
      </Grid>
      <Flex
        gap={3}
        justifyContent={"center"}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        {Object.entries(knownCostBreakups).map(([_, breakupMeta], index) => (
          <Flex
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
            key={index}
          >
            <Box w={"20px"} h={"15px"} bg={breakupMeta.color} />
            <Text>{breakupMeta.label}</Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}
