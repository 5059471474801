import { Box, Text } from "@chakra-ui/react";
import { useContext } from "react";
import TourLayout from "../Layout/TourLayout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
export default function DashboardTour({ onCloseDashtour }) {
  const agency = useContext(AgencyContext);
  const { textColor, myDetails } = agency;
  const name = myDetails?.branding?.agency_name || "Insighto.ai";
  return (
    <TourLayout onCloseTour={onCloseDashtour}>
      <Box fontSize={"md"}>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          Welcome to {name}
        </Text>
        <Text>
          This interactive guide will walk you through the key steps required in
          setting up your custom chatbot, trained on your data. Please navigate
          to the “AI Agents” on the left menu panel to get started.
        </Text>
      </Box>
    </TourLayout>
  );
}
