import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import LLmModel from "./SelectLLmModel";
import { Select } from "chakra-react-select";

const LLMrelated = ({ editData, promptType, setPromptType }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const promptTypeOptions = [
    { label: "Prompt", value: "prompt" },
    { label: "(Beta) Conversation Flow", value: "conversation_flow" },
  ];

  useEffect(() => {
    if (watch("systemPrompt")?.length) {
      setPromptType({ label: "Prompt", value: "prompt" });
    }
    if (watch("conversation_flow_id")) {
      setPromptType({
        label: "(Beta) Conversation Flow",
        value: "conversation_flow",
      });
    }
  }, [watch("systemPrompt"), watch("conversation_flow_id")]);

  const handlePromptType = (selectedOption) => {
    setPromptType(selectedOption);
  };

  const isLLmModalVisible =
    watch("assistant_type") !== "realtime_openai"
      ? promptType?.value === "prompt"
        ? true
        : false
      : true;

  return (
    <Box px={4} py={2}>
      {watch("assistant_type") !== "realtime_openai" && (
        <Box>
          <FormControl>
            <FormLabel>Select Prompt Type</FormLabel>
            <Select
              onChange={(selectedOption) => handlePromptType(selectedOption)}
              options={promptTypeOptions}
              value={promptType}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 999 }),
              }}
            />
          </FormControl>
        </Box>
      )}
      {isLLmModalVisible && (
        <Box mt="4">
          <LLmModel
            editData={editData}
            watch={watch}
            register={register}
            setValue={setValue}
            errors={errors}
          />
        </Box>
      )}
    </Box>
  );
};

export default LLMrelated;
