import { Box, Card, CardBody, Flex, IconButton, Image, Spinner, Stack, Text, Tooltip } from "@chakra-ui/react";
import { Handle } from "@xyflow/react";
import { LiaToolsSolid } from "react-icons/lia";
import { clipText } from "../../utils/utils";
import { GrConfigure } from "react-icons/gr";
import { FiDatabase } from "react-icons/fi";
import { LuBrain } from "react-icons/lu";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { DeleteIcon } from "@chakra-ui/icons";
import { useFlowContext } from "./FlowContext";

const ToolIcon = ({ data }) => {
  const fallbackToolImg = data?.tool?.logo_url ? <Spinner size={"sm"}/> : <LiaToolsSolid size={15} />;
  return (
    <Image
      objectFit={"contain"}
      margin={"auto"}
      width={30}
      h={30}
      src={data?.tool?.logo_url}
      fallback={fallbackToolImg}
      alt={data?.tool?.name || "Tool"}
      borderRadius="lg"
    />
  )
};

const ConversationIcon = ({ data }) => {
  const conversationIconType = {
    llm: LuBrain,
    static: MdOutlineDocumentScanner,
  };
  const Icon = conversationIconType[data?.response?.type]

  return (
    <Icon size={15}/>
  )
};

export default function CustomNode({ id, data }) {
  const type = data?.node_type;
  const isStartNode = type === "start";

  const { nodes, edges, flowName, operations } = useFlowContext();

  const dataSources = data?.data_sources || []
  const toolUsers = data?.tool_users || []

  const handleNodeDelete = () => {
    console.log("id", id)
    operations.onDeleteNode(id, edges, nodes, flowName)
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        borderRadius: "3px",
        minWidth: isStartNode ? "100px" : "300px",
        maxWidth: "500px",
        backgroundColor: data?.backgroundColor || "#fff",
        color: data?.textColor || "inherit",
        fontSize: "12px",
        border: "1px solid #1a192b",
        textAlign: "center",
        position: "relative",
        cursor: isStartNode ? "grab" : "pointer",
        gap: "0.5rem",
      }}
    >
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={"row"}
      >
        <Box>
          {!isStartNode && (
            <Tooltip label={"Delete node"}>
              <IconButton
                isRound={true}
                variant="outline"
                colorScheme="red"
                aria-label="Send email"
                size={"xs"}
                fontSize={"8px"}
                icon={<DeleteIcon />}
                onClick={handleNodeDelete}
              />
            </Tooltip>
          )}
        </Box>

        <Flex alignItems={"center"} gap={1}>
          <Box as="span">
            {type === "tool_function" && <ToolIcon data={data} />}
            {type === "conversation" && <ConversationIcon data={data} />}
          </Box>
          <Box as="span" fontSize={"1rem"}>
            {clipText(data.label, 40)}
          </Box>
        </Flex>

        <Stack spacing={2} justifyContent={"flex-end"} direction={"row"}>
          {type === "conversation" && data?.response?.type === "llm" && (
            <>
              <Tooltip label={"Data sources"}>
                <Flex gap={0.5} alignItems={"center"}>
                  <FiDatabase size={10} />
                  <Text>{dataSources.length}</Text>
                </Flex>
              </Tooltip>

              <Tooltip label={"Tool users"}>
                <Flex gap={0.5} alignItems={"center"}>
                  <GrConfigure size={10} />
                  <Text>{toolUsers.length}</Text>
                </Flex>
              </Tooltip>
            </>
          )}
        </Stack>
      </Stack>

      {type === "tool_function" && data?.tool?.id && (
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box as="span">{`${data?.tool?.name}: `}</Box>
          <Box as="span">{`${data?.tool_function?.name || "N/A"}`}</Box>
        </Stack>
      )}

      {data?.response?.value && (
        <Card variant={"filled"} w={"100%"}>
          <CardBody p={2}>
            <Text fontSize={"0.5rem"} textAlign={"left"} noOfLines={5}>
              {data.response.value}
            </Text>
          </CardBody>
        </Card>
      )}

      {/* Input Handle */}
      {!isStartNode && (
        <Handle
          type="target" // This defines it as an input
          position="top" // Adjust position as needed
          style={{ background: "#555" }}
        />
      )}

      {/* Output Handle */}
      <Handle
        type="source" // This defines it as an output
        position="bottom" // Adjust position as needed
        style={{ background: "#555" }}
      />
    </Box>
  );
}
