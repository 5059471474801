import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import PasswordInput from "../Forms/PasswordInput";

export const AddKeyModal = ({ isOpen, onClose, onAdd, labelName }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const { name, key, region } = data;
    if (labelName.value === "azure_speech") {
      onAdd(name, key, region);
    } else {
      onAdd(name, key);
    }
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{labelName.label}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb="2">
              <Text>Name</Text>
              <Input {...register("name", { required: true })} />
              {errors.name && <Text color="red">Name is required</Text>}
            </Box>
            <Box mt="4" mb="2">
              <Text>Key</Text>
              <PasswordInput
                register={{ ...register("key", { required: true }) }}
              />
              {errors.key && <Text color="red">Key is required</Text>}
            </Box>
            {labelName.value === "azure_speech" && (
              <Box mt="4" mb="2">
                <Text>Region</Text>
                <Input {...register("region", { required: true })} />
                {errors.region && <Text color="red">Region is required</Text>}
              </Box>
            )}
            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Submit
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
