import { Flex, FormLabel, Tooltip } from "@chakra-ui/react";
import { IoInformationCircleOutline } from "react-icons/io5";

export default function FormLabelWithInfoTooltip({ label, tip, isRequired }) {
  return (
    <Flex>
      <FormLabel
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={2}
      >
        <span>{label}</span>
        <Tooltip aria-label="Info" hasArrow label={tip}>
          <span>
            <IoInformationCircleOutline size={15} />
          </span>
        </Tooltip>
      </FormLabel>
    </Flex>
  );
}
