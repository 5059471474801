import { Box, Text } from "@chakra-ui/react";
import { useContext } from "react";
import TourLayout from "../Layout/TourLayout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export default function CapturedFormsTour({ onCloseCapturedFormsTour }) {
  const agency = useContext(AgencyContext);
  const { textColor } = agency;
  return (
    <TourLayout onCloseTour={onCloseCapturedFormsTour}>
      <Box fontSize={"md"}>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          What is a Captured Form?
        </Text>
        <Text>
          All the forms submitted by your users can be found here. You can
          download them from here in CSV format or use our Zapier integration to
          push them to any tool such as your CRM, marketing automation, HRMS,
          etc.
        </Text>
      </Box>
    </TourLayout>
  );
}
