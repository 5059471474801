import { useSteps } from "@chakra-ui/react";
import ProviderStep from "./Steps/ProviderStep";
import WidgetStep from "./Steps/WidgetStep";
import AssistantStep from "./Steps/AssistantStep";
import InitialForm from "./ConfigForms/InitailForm";
import LeadConnectorForm from "./ConfigForms/LeadConnectorForm";
import SMSForm from "./ConfigForms/SMSForm";
import WhatsAppForm from "./ConfigForms/WhatsAppForm";
import PhoneForm from "./ConfigForms/PhoneForm";
import HTMLCallForm from "./ConfigForms/HTMLCallForm";
import PlivoForm from "./ConfigForms/PlivoForm";
import TelnyxForm from "./ConfigForms/TelnyxForm";
import FBMessengerForm from "./ConfigForms/FBMessengerForm";
import InstagramForm from "./ConfigForms/InstagramForm";
import TelegramForm from "./ConfigForms/TelegramForm";
import { toast } from "react-toastify";
import LeadConnectorCallForm from "./ConfigForms/LeadConnectorCallForm";

export default function useWidgetStepper({ widgetForm, onClose, handleSubmit, fetchWidgets, onGotoFirstPage }) {
  const { watch } = widgetForm;

  const configFormOptions = [
    {
      label: "Initial form",
      allowedWidgets: ["chat"],
      allowedAssistants: ["simple"],
      Children: InitialForm,
    },
    {
      label: "Lead connector form",
      allowedWidgets: ["leadconnector"],
      allowedAssistants: ["simple"],
      Children: LeadConnectorForm,
    },
    {
      label: "Lead connector cal form",
      allowedWidgets: ["leadconnector_call"],
      allowedAssistants: ["phone", "realtime_openai"],
      Children: LeadConnectorCallForm,
    },
    {
      label: "SMS form",
      allowedWidgets: ["sms"],
      allowedAssistants: ["simple"],
      Children: SMSForm,
    },
    {
      label: "WhatsApp form",
      allowedWidgets: ["whatsapp"],
      allowedAssistants: ["simple"],
      Children: WhatsAppForm,
    },
    {
      label: "Phone form",
      allowedWidgets: ["phone"],
      allowedAssistants: ["phone", "realtime_openai"],
      Children: PhoneForm,
    },
    {
      label: "HTML call form",
      allowedWidgets: ["html_call"],
      allowedAssistants: ["phone", "realtime_openai"],
      Children: HTMLCallForm,
    },
    {
      label: "Telnyx form",
      allowedWidgets: ["telnyx_call"],
      allowedAssistants: ["phone", "realtime_openai"],
      Children: TelnyxForm,
    },
    {
      label: "Plivo form",
      allowedWidgets: ["plivo_call"],
      allowedAssistants: ["phone", "realtime_openai"],
      Children: PlivoForm,
    },
    {
      label: "FB messenger form",
      allowedWidgets: ["fb_messenger"],
      allowedAssistants: ["simple"],
      Children: FBMessengerForm,
    },
    {
      label: "Instagram form",
      allowedWidgets: ["instagram"],
      allowedAssistants: ["simple"],
      Children: InstagramForm,
    },
    {
      label: "Telegram form",
      allowedWidgets: ["telegram"],
      allowedAssistants: ["simple"],
      Children: TelegramForm,
    },
    {
      label: "Render form",
      allowedWidgets: ["render_form"],
      allowedAssistants: [""],
      Children: InitialForm,
    }
  ];

  const widgetType = watch("widget_type")
  const findConfigByType = (configOption) =>
    configOption.allowedWidgets.includes(widgetType) &&
    configOption.allowedAssistants.includes(watch("assistant_type"));

  const selectedConfigForm = configFormOptions.find(findConfigByType);
  const ConfigForm = selectedConfigForm?.Children || <></>;

  const nullableAssistantWidgetTypes = ["render_form"]

  const handleClose = () => {
    widgetForm.reset({});
    stepper.setActiveStep(0);
    onClose();
  };

  const handleFinalSubmit = async (values) => {
    try {
      const response = await handleSubmit(values);
      toast.success(response?.message || "Widget Added Successfully");
      handleClose();
      fetchWidgets();
      onGotoFirstPage();
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
      handleClose();
    }
  };

  const steps = [
    {
      title: "Provider",
      description: "select provider",
      Children: ProviderStep,
      onSubmit: () => {
        stepper.goToNext();
      },
    },
    {
      title: "Widget",
      description: "select widget type",
      Children: WidgetStep,
      onSubmit: () => {
        stepper.goToNext();
      },
      onBack: () => {
        stepper.goToPrevious();
      },
    },
    ...(!nullableAssistantWidgetTypes.includes(widgetType)
      ? [
          {
            title: "Assistant",
            description: "select assistant",
            Children: AssistantStep,
            onSubmit: async (values) => {
              if (selectedConfigForm) {
                stepper.goToNext();
              } else {
                await handleFinalSubmit(values);
              }
            },
            onBack: () => {
              stepper.goToPrevious();
            },
          },
        ]
      : []),
    ...(selectedConfigForm
      ? [
          {
            title: "Configuration",
            description: "configure widget",
            Children: ConfigForm,
            onSubmit: async (values) => {
              await handleFinalSubmit(values);
            },
            onBack: () => {
              stepper.goToPrevious();
            },
          },
        ]
      : []),
  ];

  const stepper = useSteps({
    index: 0,
    count: steps.length,
  });

  return { stepper, steps, selectedConfigForm };
}
