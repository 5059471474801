import { FormControl, FormLabel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Select } from "chakra-react-select"
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import useToolUserRunStaticFunction from "../Tools/useToolUserRunStaticFunction";

export default function FBMessenger({ setValue, watch }) {
  const baseUrl = getHostDomain();
  const [fbMessengerOptions, setFBMessengerOptions] = useState([]);

  const setFBMessengerUseToolOptions = async () => {
    try {
      const response = await fetchController(
        baseUrl +
          "/api/v1/external_auth/provider_auth?auth_provider=fb_messenger&size=100",
        "GET"
      );

      const options = response.map((data) => ({
        label: data?.name,
        value: data?.id,
      }));

      setFBMessengerOptions(options);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setFBMessengerUseToolOptions();
  }, []);

  const handleFBMessengerSelect = ({ value }) => {
    setValue("tool_user_id", value);
  };

  const { tool_user_id } = watch();
  
  const { response } = useToolUserRunStaticFunction({
    tool_user_id,
    staticFnPayload: {
      function_name: "get_account_pages",
    },
  });

  const pageOptions = response
    ? response.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : [];

  const handleFBMessengerPageSelect = ({ label, value }) => {
    setValue("fb_page_id", value);
    setValue("fb_page_name", label);
  };

  return (
    <>
      <FormControl isRequired>
        <FormLabel>{"Select FB Messenger"}</FormLabel>
        <Select
          options={fbMessengerOptions}
          onChange={handleFBMessengerSelect}
        />
      </FormControl>
      {watch("tool_user_id") && (
        <FormControl isRequired>
          <FormLabel>{"Select Page"}</FormLabel>
          <Select
            options={pageOptions}
            onChange={handleFBMessengerPageSelect}
          />
        </FormControl>
      )}
    </>
  );
}
