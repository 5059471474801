import { SmallCloseIcon } from "@chakra-ui/icons";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { BaseEdge, EdgeLabelRenderer, getStraightPath } from "@xyflow/react";
import { useFlowContext } from "./FlowContext";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  data,
}) {
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const { nodes, edges, flowName, operations } = useFlowContext();

  const edgeType = data.type || "default"
  const edgeColorScheme = {
    variables: "blue",
    description: "green",
    default: "gray",
  }
  const borderColor = edgeColorScheme[edgeType]

  const handleEdgeRemove = () => {
    operations.onDeleteEdge(id, edges, nodes, flowName);
  };

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          stroke: borderColor,
        }}
      />
      <EdgeLabelRenderer>
        {data?.name ? (
          <div
            style={{
              position: "absolute",
              pointerEvents: "all",
              transformOrigin: "center",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              position: "absolute",
              backgroundColor: "#fff",
              border: `1px solid ${borderColor}`,
              padding: "10px",
              borderRadius: "3px",
              fontSize: "8px",
              fontWeight: "500",
            }}
            className="nodrag nopan"
          >
            {data?.name}
            <Tooltip label="Remove edge">
              <IconButton
                isRound={true}
                variant="solid"
                colorScheme="red"
                fontSize="8px"
                ml={1}
                size={"20px"}
                icon={<SmallCloseIcon />}
                onClick={handleEdgeRemove}
              />
            </Tooltip>
          </div>
        ) : (
          <div
            className="nodrag nopan"
            style={{
              position: "absolute",
              pointerEvents: "all",
              transformOrigin: "center",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            }}
          >
            <Tooltip label="Remove edge">
              <IconButton
                isRound={true}
                variant="solid"
                colorScheme="red"
                fontSize="10px"
                size={"xs"}
                icon={<SmallCloseIcon />}
                border={"5px solid white"}
                onClick={handleEdgeRemove}
              />
            </Tooltip>
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  );
}
