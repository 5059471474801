import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import { useContext } from "react";
import { LiaToolsSolid } from "react-icons/lia";
import { MdDelete } from "react-icons/md";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import CardRibbon from "./CardRibbon";

export default function ToolItem({
  tool,
  onOpenTool,
  onOpenConfirmationModal,
  editToolOpen,
  selectedToolId,
  setSelectedToolId,
}) {
  const handleSetupClick = () => {
    onOpenTool(tool);
  };

  const handleToolEdit = (tool) => {
    console.log(tool);
    setSelectedToolId(tool.id);
    editToolOpen();
  };

  const handleToolDelete = (tool) => {
    setSelectedToolId(tool.id);
    onOpenConfirmationModal();
  };

  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg } = agency;

  return (
    <>
      <Card bg={cardBg} width={"200px"}>
        <CardBody position={"relative"} overflow={"hidden"}>
          {tool.tool_type === "user" && (
            <>
              <CardRibbon text={"Custom Tool"} />
              <IconButton
                isRound={true}
                size="xs"
                icon={<EditIcon />}
                position={"absolute"}
                top={"5px"}
                right={"5px"}
                title="Edit Tool"
                onClick={() => handleToolEdit(tool)}
              />
              <IconButton
                isRound={true}
                size="xs"
                icon={<MdDelete />}
                position={"absolute"}
                top={"5px"}
                right={"35px"}
                title="Delete Tool"
                onClick={() => handleToolDelete(tool)}
              />
            </>
          )}
          <Flex
            justifyContent={"space-between"}
            flexDir={"column"}
            alignItems={"center"}
            h={"100%"}
          >
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDir={"column"}
              h={"100%"}
            >
              <Image
                objectFit={"contain"}
                margin={"auto"}
                width={100}
                h={100}
                src={tool.logo_url}
                fallback={
                  <LiaToolsSolid
                    size={70}
                    style={{
                      margin: "auto",
                    }}
                  />
                }
                alt="Bamboo"
                borderRadius="lg"
              />

              <Box marginBlock={3}>
                <Heading textAlign={"center"} size="sm">
                  {tool.name}
                </Heading>
              </Box>
            </Flex>

            <Tooltip
              label="Enable Tool to begin the Setup"
              placement="bottom"
              isDisabled={tool.enabled}
            >
              <Button
                isDisabled={!tool.enabled}
                onClick={handleSetupClick}
                margin={"auto"}
                size={"xs"}
                colorScheme={buttonColorScheme}
              >
                Setup
              </Button>
            </Tooltip>
          </Flex>
        </CardBody>
      </Card>
    </>
  );
}
