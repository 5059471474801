import { Button, ModalBody, ModalFooter, Wrap } from "@chakra-ui/react";
import WrapProviders from "../WarpProviders";
import { useContext } from "react";
import { AgencyContext } from "../../../Settings/AgencyProvider/AgencyProvider";

export default function ProviderStep({
  widgetForm,
  onSubmit,
  onClose,
  providers,
}) {
  const { watch } = widgetForm;
  const agency = useContext(AgencyContext);
  const { insighto, ...restProviders } = providers;

  const name = agency?.myDetails?.agency_id
    ? agency?.myDetails?.branding?.agency_name
    : "Insighto.AI";
  const logo = agency?.faviconUrl || "/logo/favicon.png";

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Wrap>
          {Object.keys(restProviders).map((provider, idx) => (
            <WrapProviders
              key={idx}
              provider={provider}
              name={providers[provider].label}
              logo={providers[provider].logo}
              widgetForm={widgetForm}
            />
          ))}
          <WrapProviders
            provider={"insighto"}
            name={name}
            logo={logo}
            widgetForm={widgetForm}
          />
        </Wrap>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onClose}>
          {"Close"}
        </Button>
        <Button
          isDisabled={!watch("provider")}
          type="submit"
          colorScheme={"yellow"}
        >
          {"Next"}
        </Button>
      </ModalFooter>
    </form>
  );
}
