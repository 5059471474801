import { Box, Flex, Image, Input, Stack, Text } from "@chakra-ui/react";
import { FaMicrophone, FaTelegramPlane } from "react-icons/fa";
import Message from "./Message";
import "./widget.css";
import React, { useContext } from "react";
import { IoInformationCircleOutline, IoCallOutline } from "react-icons/io5";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { MdPictureAsPdf } from "react-icons/md";
import { MdOutlineCalendarToday } from "react-icons/md";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { FiRefreshCw } from "react-icons/fi";
export default function ChatWidget({
  widgetCustomizationOptions,
  userOpeningMessages = [],
  buttons,
}) {
  const botTextMessageColor = widgetCustomizationOptions.bot_text_message_color;
  const userTextMessageColor =
    widgetCustomizationOptions.user_text_message_color;
  const actionButtonsColor =
    widgetCustomizationOptions.action_buttons_color ||
    widgetCustomizationOptions.ice_break_color ||
    "var(--primary-color)";
  const headerTextColor = widgetCustomizationOptions.header_text_color;
  const icons = {
    calendar: <MdOutlineCalendarToday color={actionButtonsColor} />,
    info: <IoInformationCircleOutline color={actionButtonsColor} />,
    quote: <HiOutlineCurrencyDollar color={actionButtonsColor} />,
    call: <IoCallOutline color={actionButtonsColor} />,
    pdf: <MdPictureAsPdf color={actionButtonsColor} />,
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg: bg, myDetails } = agency;
  const agency_id = myDetails ? myDetails?.agency_id : null;
  const agencyName = myDetails?.branding?.agency_name;

  return (
    <Stack>
      <Flex
        overflow={"auto"}
        mb={0}
        mt={2}
        justifyContent={"start"}
        width={550}
        flexDirection={"column"}
        pb={0}
      >
        <Stack
          backgroundColor={bg}
          width={"100%"}
          border={"1px solid lightgray"}
          borderTopRadius={10}
        >
          <Flex
            borderTopRadius={10}
            backgroundColor={
              widgetCustomizationOptions.header_color || "#3b81f6"
            }
            gap={3}
            justifyContent={"flex-start"}
            alignItems={"center"}
            padding={"0.3rem"}
          >
            <Text fontSize={"1rem"} fontWeight={500} color={headerTextColor}>
              {agency_id
                ? agencyName
                : widgetCustomizationOptions.display_name || "Insighto.ai"}
            </Text>
            {widgetCustomizationOptions.style_params?.show_reset_button ? (
              <FiRefreshCw color="white" />
            ) : null}
          </Flex>
          <Stack
            position={"relative"}
            gap={2}
            marginTop={0}
            padding={3}
            borderRadius={"10px 10px 0 0"}
          >
            <Text
              textAlign={"center"}
              fontWeight={"bold"}
              left={0}
              right={0}
              color={"lightgrey"}
              position={"absolute"}
              fontSize={25}
            >
              {widgetCustomizationOptions.intro_message || "How may I help you"}
            </Text>
            <>
              <Message
                color={userTextMessageColor}
                text={"Hey"}
                sentBy={"me"}
                bgColor={
                  widgetCustomizationOptions.user_message_color || "#3b81f6"
                }
              />
              <Message
                color={botTextMessageColor}
                text={"How can I assist you ?"}
                sentBy={"bot"}
                bgColor={
                  widgetCustomizationOptions.bot_message_color || "#f1f1f0"
                }
                conversationBotIcon={
                  widgetCustomizationOptions.conversation_bot_icon
                }
              />
              <Message
                color={userTextMessageColor}
                text={"Can you help me with leave policies?"}
                sentBy={"me"}
                bgColor={
                  widgetCustomizationOptions.user_message_color || "#3b81f6"
                }
              />
              <Message
                color={botTextMessageColor}
                text={"How can I assist you ?"}
                sentBy={"bot"}
                bgColor={
                  widgetCustomizationOptions.bot_message_color || "#f1f1f0"
                }
                conversationBotIcon={
                  widgetCustomizationOptions.conversation_bot_icon
                }
              />
              <Flex gap={2} justifyContent={"flex-start"} alignItems={"center"}>
                {buttons.map((button, index) => (
                  <Flex display="flex" alignItems="center" key={index}>
                    {button.label && button.link && button.icon && (
                      <Box
                        padding={1}
                        cursor={"pointer"}
                        onClick={() => {
                          window.open(button.link, "_blank");
                        }}
                        display="flex"
                        gap={2}
                        alignItems="center"
                        border={`2px solid ${actionButtonsColor}`}
                        borderRadius={20}
                      >
                        {icons[button.icon]}
                        <Text
                          marginBlock={1}
                          fontSize={"xs"}
                          color={actionButtonsColor}
                        >
                          {button.label}
                        </Text>
                      </Box>
                    )}
                  </Flex>
                ))}
              </Flex>
            </>
          </Stack>
          {userOpeningMessages?.length ? (
            <Flex
              borderTop={`1px solid lightgray`}
              padding={2}
              justifyContent={"center"}
              gap={2}
              flexWrap="wrap"
              border={"1px sloid red"}
            >
              {userOpeningMessages?.map((val, i) => (
                <span
                  style={{
                    border: `2px solid ${
                      widgetCustomizationOptions.ice_break_color ||
                      "var(--primary-color)"
                    }`,
                    color:
                      widgetCustomizationOptions.ice_break_color ||
                      "var(--primary-color)",
                  }}
                  className="my-text-suggest"
                  key={i}
                >
                  {val}
                </span>
              ))}
            </Flex>
          ) : null}
          <Flex
            width={"100%"}
            style={{
              borderTop: "1px solid lightgray",
              borderBottom: "1px solid lightgray",
            }}
          >
            {/* <input
              placeholder="Text message"
              style={{
                outline: "none",
                padding: 3,
                width: "100%",
              }}
            /> */}
            <Input
              placeholder={
                widgetCustomizationOptions?.style_params?.placeholder ||
                "Type or use mic to speak"
              }
            />
            <Flex gap={0} p={3}>
              <FaMicrophone size={20} />
              <FaTelegramPlane size={20} />
            </Flex>
          </Flex>
          {widgetCustomizationOptions.remove_branding ? null : (
            <Stack>
              <div
                className="widget_powered__footer"
                style={{ textAlign: "center" }}
              >
                <small>
                  powered by{" "}
                  <strong>{agency_id ? agencyName : "Insighto.ai"}</strong>
                </small>
              </div>
            </Stack>
          )}
        </Stack>
      </Flex>
      <Flex justifyContent={"flex-end"} alignItems={"center"}>
        <Box
          boxShadow={"md"}
          backgroundColor={"white"}
          borderRadius={4}
          padding={4}
          maxWidth={300}
        >
          <Text>{widgetCustomizationOptions.bubble_text}</Text>
        </Box>
      </Flex>
      <Flex justifyContent={"flex-end"} alignItems={"center"}>
        <Box
          height={10}
          width={10}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"50%"}
          backgroundColor={
            widgetCustomizationOptions.bot_icon_color || "#3b81f6"
          }
        >
          <Image
            width={8}
            height={8}
            src={widgetCustomizationOptions.bubble_bot_icon || "/icons/bot.svg"}
          />
        </Box>
      </Flex>
    </Stack>
  );
}
