import { Box, Flex, Stack } from "@chakra-ui/react";
import { IoSend } from "react-icons/io5";
import { TiMicrophoneOutline } from "react-icons/ti";
import MessageChatV2 from "./MessageChatV2";
import { useContext } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
export default function ChatMainScreenV2({
  header_color,
  user_message_color,
  user_text_message_color,
  bot_message_color,
  bot_text_message_color,
  remove_branding,
  conversation_bot_icon,
  style_params,
  user_opening_messages,
}) {
  const defaultMessages = [
    {
      message: "Hi How are you?",
      tiles: [],
      sentBy: "me",
      id: "ppoaz0w-ZVvEuUydjynhd",
      timestamp: "05/12/2024, 10:56",
    },
    {
      message: "I am good. Thank you for asking. How about you?",
      tiles: [],
      images: [],
      form: {},
      msg_type: "text",
      id: "qoa8jUeagFaZyxLuhbyKR",
      sentBy: "bot",
      timestamp: "05/12/2024, 10:55",
    },
    {
      message:
        "Can you give your contact information so that we can contact you later.",
      tiles: [],
      sentBy: "me",
      id: "Lb7L_Mdb7qOqVN8OlTxzg",
      timestamp: "05/12/2024, 10:58",
    },
    {
      message: "yes I can share my details. Here is it is.",
      tiles: [],
      images: [],
      form: {},
      msg_type: "text",
      id: "siuTXVJGKxs_bud7J20Hh",
      sentBy: "bot",
      timestamp: "05/12/2024, 10:56",
    },
  ];
  const messageNodes = defaultMessages.map((messageNode) => {
    const isMyMessage = messageNode.sentBy === "me";
    return {
      ...messageNode,
      bg: isMyMessage ? user_message_color : bot_message_color,
      color: isMyMessage ? user_text_message_color : bot_text_message_color,
      textAlign: isMyMessage ? "end" : "left",
      justifyContent: isMyMessage ? "flex-end" : "flex-start",
      bot_icon: isMyMessage ? null : conversation_bot_icon,
    };
  });
  const agency = useContext(AgencyContext);
  const { myDetails } = agency;
  const getParamsForWidget = () => {
    let poweredByName = "Insighto.AI";
    let poweredByLogo = "/logo/favicon.png";
    if (myDetails?.agency_id) {
      poweredByName = myDetails?.branding?.agency_name;
      poweredByLogo = myDetails?.branding?.logo_url
        ? `https://${myDetails?.domain?.apiDomain}${myDetails?.branding?.logo_url}`
        : null;
    }
    return {
      poweredByLogo,
      poweredByName,
    };
  };
  const { poweredByLogo, poweredByName } = getParamsForWidget();
  return (
    <Flex
      justifyContent={"space-between"}
      alignItems={"center"}
      flexDir={"column"}
      width={"100%"}
      flex={1}
    >
      <Stack p={2} width={"100%"} spacing={2}>
        {messageNodes.map((messageNode) => (
          <MessageChatV2 messageNode={messageNode} key={messageNode.id} />
        ))}
      </Stack>
      <Flex gap={2} flexWrap={"wrap"}>
        {user_opening_messages.map((message, index) => (
          <Box
            p={1}
            bg={"lightgray"}
            borderRadius={"2xl"}
            border={"1px solid lightgray"}
            key={index}
          >
            {message}
          </Box>
        ))}
      </Flex>
      <Box p={4} width={"100%"}>
        <Box
          border={"1px solid lightgray"}
          p={3}
          borderRadius={10}
          width={"100%"}
        >
          <Flex justifyContent={"center"} alignItems={"center"}>
            <input
              style={{
                border: "none",
                outline: "none",
                flex: 1,
                boxSizing: "border-box",
                padding: 8,
                borderRadius: 10,
              }}
              placeholder={
                style_params?.placeholder || "Message or tap to speak"
              }
            />
            <Flex justifyContent={"center"} alignItems={"center"} gap={2}>
              <TiMicrophoneOutline size={25} />
              <IoSend size={25} color={header_color} />
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Flex justifyContent={"center"} alignItems={"center"}>
        {!remove_branding && (
          <>
            <span>powered by</span>
            <img src={poweredByLogo} width={18} />
            <span>{poweredByName}</span>
          </>
        )}
      </Flex>
    </Flex>
  );
}
