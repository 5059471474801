import { Flex, Tag, TagLabel, Tooltip } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { BiUserVoice } from "react-icons/bi";
import { GiArtificialIntelligence } from "react-icons/gi";
import { SiElevenlabs } from "react-icons/si";
import { VscAzure } from "react-icons/vsc";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { MdTapAndPlay } from "react-icons/md";
import { GrSystem } from "react-icons/gr";
import { FaRegUser } from "react-icons/fa";

export default function useVoicesHook(props) {
  const { tab = "syncVoices" } = props || {};
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const { textColor } = agency;
  const [availableVoices, setAvailableVoices] = useState();
  const [providers, setProviders] = useState();
  const [languages, setLanguages] = useState();
  const [specificProviderList, setSpecificProviderList] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [pages, setPages] = useState(0);

  const speakerTtsProvider = {
    azure: VscAzure,
    elevenlabs: SiElevenlabs,
    cartesia: GiArtificialIntelligence,
    playht: MdTapAndPlay,
    default: BiUserVoice,
  };

  const voiceOwnerDetails = {
    system: {
      icon: GrSystem,
      label: "Platform",
      value: "platform",
    },
    user: {
      icon: FaRegUser,
      label: "User",
      value: "user",
    },
  };

  const getVoiceOwnerIcon = (ownerType) => {
    return voiceOwnerDetails[ownerType] || voiceOwnerDetails.system;
  };
  const providersDetails = {
    azure: { icon: VscAzure, label: "Azure", value: "azure_speech" },
    elevenlabs: {
      icon: SiElevenlabs,
      label: "Eleven Labs",
      value: "elevenlabs",
    },
    cartesia: {
      icon: GiArtificialIntelligence,
      label: "Cartesia",
      value: "cartesia",
    },
    playht: {
      icon: MdTapAndPlay,
      label: "PlayHT",
      value: "playht",
    },
  };

  const getProvicerIcon = (providerName) => {
    return speakerTtsProvider[providerName] || BiUserVoice;
  };
  const types = {
    all: { value: "all", label: "All" },
    system: { value: "system", label: "System" },
    user: { value: "user", label: "User" },
  };
  const [queries, setQueries] = useState({
    search: null,
    provider: null,
    specificProvider: null,
    type: { value: "all", label: "All" },
    filterByProvider: null,
    filterBySpecificProvider: null,
    filterByLanguage: null,
  });

  const getProviders = async () => {
    try {
      const response = await fetchController(
        `${baseUrl}/api/v1/voice/syncing_providers`,
        "GET"
      );
      const providerOptions = response.map((item) => {
        const simpleLabel = item.name.split("_").join(" ");
        return {
          value: item.name.toLowerCase(),
          label:
            simpleLabel.charAt(0).toUpperCase() +
            simpleLabel.slice(1).toLowerCase(),
        };
      });
      setProviders(providerOptions);
      return providerOptions;
    } catch (e) {
      console.log("Something went wrong.");
      return [];
    }
  };

  const getLanguages = async () => {
    try {
      const response = await fetchController(
        `${baseUrl}/api/v1/voice/languages`,
        "GET"
      );
      const languageOptions = response.data.map((language) => ({
        label: language.charAt(0).toUpperCase() + language.slice(1),
        value: language,
      }));
      setLanguages(languageOptions);
    } catch (e) {
      console.log("Something went wrong.");
      return [];
    }
  };

  const fetchVoicesData = async (changedPage) => {
    setLoading(true);
    try {
      const {
        type,
        search,
        filterByProvider,
        filterBySpecificProvider,
        filterByLanguage,
      } = queries;
      let queryParams = [];

      if (filterByProvider?.value)
        queryParams.push(`provider=${filterByProvider.value}`);

      if (filterBySpecificProvider?.value)
        queryParams.push(`provider_id=${filterBySpecificProvider.id}`);

      if (filterByLanguage?.value)
        queryParams.push(`language=${filterByLanguage.value}`);

      if (search) queryParams.push(`search_term=${search}`);

      if (type?.value !== "all") queryParams.push(`owner_type=${type.value}`);

      const queryString = queryParams.length ? `&${queryParams.join("&")}` : "";

      const response = await fetchController(
        `${baseUrl}/api/v1/voice/list/voicetts?size=${paginationSize}&page=${
          changedPage ? changedPage : page
        }${queryString}`,
        "GET"
      );

      setAvailableVoices(response.data.items);
      setPages(response?.data?.pages);
    } catch (e) {
      console.log("Something went wrong while fetching voices", e);
    } finally {
      setLoading(false);
    }
  };

  const getAllSpecificProvider = async (selectedOption) => {
    try {
      const response = await fetchController(
        `${baseUrl}/api/v1/provider/list/by_provider?provider_name=${selectedOption}`,
        "GET"
      );
      const options = response?.data?.items.map((item) => {
        const val = item.name.split("_").join(" ");
        return {
          id: item.id,
          label: val.charAt(0).toUpperCase() + val.slice(1).toLowerCase(),
          value: val.toLowerCase(),
        };
      });
      setSpecificProviderList(options);
    } catch (e) {
      console.log("Some thing went wrong.", e);
    }
  };

  const getSubHeadingDescriptionLabel = (labels = {}) => {
    const knownLabels = Object.keys(labels).reduce((acc, key) => {
      const formattedValue = key
        .replace(/_/g, " ")
        .replace(/\b\w/, (char) => char.toUpperCase());
      acc[key] = formattedValue;
      return acc;
    }, {});
    return (
      <Flex gap={2}>
        {Object.entries(labels)?.map(([labelKey, labelValue]) => {
          const label = knownLabels[labelKey] || labelKey;
          return (
            <Tag size="sm" key={label} variant="outline" colorScheme="blue">
              <TagLabel textTransform="capitalize">
                <Tooltip label={label} textTransform="capitalize">
                  {labelValue ? labelValue : "-"}
                </Tooltip>
              </TagLabel>
            </Tag>
          );
        })}
      </Flex>
    );
  };

  const previewDefaultAudio = async (voicetts_id) => {
    try {
      const response = await fetchController(
        `${baseUrl}/api/v1/voice/voice_preview?voicetts_id=${voicetts_id}`,
        "GET"
      );
      return response.data?.attributes?.preview_url;
    } catch (e) {
      console.log("Some thing went wrong ", e);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const providerList = await getProviders();
    setProviders(providerList);
    if (tab === "availableVoices") await fetchVoicesData();
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, paginationSize]);

  useEffect(() => {
    getLanguages();
  }, []);

  const dependencies = [
    queries?.type,
    queries?.search,
    queries?.filterByProvider,
    queries?.filterBySpecificProvider,
    queries?.filterByLanguage,
  ];

  useEffect(() => {
    setPage(1);
    fetchVoicesData(1);
  }, dependencies);

  return {
    page,
    pages,
    types,
    queries,
    loading,
    languages,
    providers,
    textColor,
    paginationSize,
    availableVoices,
    providersDetails,
    specificProviderList,
    getVoiceOwnerIcon,
    previewDefaultAudio,
    getSubHeadingDescriptionLabel,
    getProvicerIcon,
    setQueries,
    getAllSpecificProvider,
    setPaginationSize,
    setPage,
  };
}
