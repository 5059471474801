import React, { useState } from "react";
import { Box, Input, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select"

const EmailSelector = ({ emails, setEmails }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputErrors, setInputErrors] = useState({});

  const handleEmailChange = (name, email) => {
    const updatedEmails = emails.map((item) =>
      item.name === name ? { ...item, email } : item
    );
    setEmails(updatedEmails);
    // Clear the error when the email is changed
    setInputErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  const handleSelectChange = (selectedOption) => {
    const newEmails = selectedOption.map((option) => {
      const existingEmail = emails.find((item) => item.name === option.value);
      return {
        name: option.value,
        email: existingEmail ? existingEmail.email : "", // Use existing email if available
      };
    });

    setEmails(newEmails);
    setSelectedOptions(selectedOption);
  };

  const validateEmail = (email) => {
    // Simple email validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleBlur = (name, email) => {
    // Validate email on blur and set the error state
    setInputErrors((prevErrors) => ({ ...prevErrors, [name]: !validateEmail(email) }));
  };

  return (
    <Box display="flex" gap={20} spacing={4} align="flex-start">
      <Box>
        <Select
          placeholder="Select Department"
          options={[
            { value: "LEAD", label: "Lead" },
            { value: "SUPPORT", label: "Support" },
            { value: "SALES", label: "Sales" },
            { value: "CAREER", label: "Career" },
          ]}
          onChange={handleSelectChange}
          isMulti
        />
      </Box>
      <Box flex={1}>
        {selectedOptions?.map((option, index) => (
          <Box mt={1} key={option.value} display="flex" alignItems="center">
            <Text width="80px" fontWeight="bold" mr={2}>
              {option.label}
            </Text>
            <Input
            width="220px"
              height="30px"
              type="text"
              placeholder={`Enter email for ${option.label}`}
              value={emails[index]?.email || ""}
              onChange={(e) => handleEmailChange(option.value, e.target.value)}
              onBlur={() => handleBlur(option.value, emails[index]?.email || "")}
              style={{ marginLeft: "4px" }}
              required
              pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
              isInvalid={inputErrors[option.value]}
            />
            {inputErrors[option.value] && (
              <Text width="200px" color="red" ml={2}>
                Invalid email
              </Text>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default EmailSelector;
