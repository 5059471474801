import {
  Alert,
  AlertIcon,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import GettingStarted from "./GettingStarted";
import Branding from "./Tabs/Branding";
import DomainPlan from "./Tabs/DomainPlan";
import RebillingPlan from "./Tabs/RebillingPlan";
import SubAccount from "./Tabs/SubAccount";

const tabsConfig = (agency, activeTabIndex) => [
  { value: "Getting Started", children: <GettingStarted agency={agency} /> },
  {
    value: "Branding",
    children: (
      <Branding tab="Branding" activeTab={activeTabIndex} agency={agency} />
    ),
  },
  {
    value: "Sub Account",
    children: <SubAccount tab="Sub-Account" activeTab={activeTabIndex} />,
  },
  {
    value: "Rebilling",
    children: <RebillingPlan tab="Rebilling-Plan" activeTab={activeTabIndex} />,
  },
  {
    value: "Domain",
    children: (
      <DomainPlan
        tab="Domain-Plan"
        agency={agency}
        activeTab={activeTabIndex}
      />
    ),
  },
];

export default function Agency() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { state, loading } = useContext(SettingContext);
  const agency = useContext(AgencyContext);
  const { textColor, agencyData, getAgencies } = agency;

  useEffect(() => getAgencies(), []);

  if (loading && agency.loading) return <Loader />;

  if (!state?.credits?.agency) {
    return (
      <Layout>
        <Box
          minH="81svh"
          border="1px"
          p="5"
          borderRadius="md"
          h="90vh"
          overflowY="auto"
        >
          <Alert status="info">
            <AlertIcon /> Please contact the sales team to enable Agency plan
          </Alert>
        </Box>
      </Layout>
    );
  }

  const tabs = tabsConfig(agency, activeTabIndex);

  return (
    <Layout>
      <Box
        minH="81svh"
        border="1px"
        p="5"
        borderRadius="md"
        h="90vh"
        overflowY="auto"
      >
        <Tabs isLazy index={activeTabIndex} onChange={setActiveTabIndex}>
          <TabList>
            {tabs.map((tab, idx) => (
              <Tab
                key={idx}
                isDisabled={!agencyData && tab.value !== "Branding"}
                color={textColor}
              >
                {tab.value}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((tab, idx) => (
              <TabPanel key={idx} mt={4}>
                {tab.children}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
}
