import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { Background, Controls, ReactFlow } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import CustomEdge from "./CustomEdge";
import CustomNode from "./CustomNode";
import EdgePropertiesModal from "./EdgePropertiesModal";
import FlowInstructionsModal from "./FlowInstructionModal";
import NodeModal from "./NodeModal";
import OperationalHeader from "./OperationalHeader";
import useAppFlowNodes from "./hooks/useAppFlowNodes";
import useNodeForm from "./hooks/useNodeForm";
import { FlowProvider } from "./FlowContext";

export default function ConversationFlow() {
  const { id } = useParams();

  const nodeTypes = {
    customNode: CustomNode,
  };
  const edgeTypes = {
    customEdge: CustomEdge,
  };

  const {
    onConnect,
    onEdgesChange,
    onNodesChange,
    handleFlowAutoFill,
    edges,
    nodes,
    flowName,
    operations,
    status,
  } = useAppFlowNodes(id);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isInstrctonModalOpen,
    onOpen: openInstructionModal,
    onClose: closeInstructionModal,
  } = useDisclosure();
  const {
    isOpen: isEdgePropertiesOpen,
    onOpen: openEdgeProperties,
    onClose: closeEdgeProperties,
  } = useDisclosure();

  const nodeForm = useNodeForm({
    operations,
    onCloseForm: onClose,
  });
  const edgeForm = useForm({
    defaultValues: {
      edgeProperties: { name: "", type: "" },
      selectedEdge: null,
    },
  });

  useEffect(() => {
    if (id) handleFlowAutoFill(id);
  }, [id]);

  return (
    <Layout>
      <Box w={"100%"} overflowY={"auto"} h={"90svh"}>
        {status === "loading" && <Loader />}

        <Box height={"100%"}>
          <OperationalHeader
            onOpen={() => {
              onOpen();
              nodeForm.form.reset({});
            }}
            flowName={flowName}
          />

          <Box height={"85%"}>
            <FlowProvider value={{ nodes, edges, flowName, operations }}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={(params) => {
                  edgeForm.setValue("params", params);
                  openEdgeProperties();
                }}
                onNodeDoubleClick={(_, node) => {
                  if (node.data.node_type === "start") return;
                  nodeForm.form.reset({ ...node });
                  onOpen();
                }}
                onEdgeDoubleClick={(_, edge) => {
                  edgeForm.setValue("edgeProperties", edge?.data);
                  edgeForm.setValue("selectedEdge", edge);
                  openEdgeProperties();
                }}
                fitView
              >
                <Background />
                <Controls />
              </ReactFlow>
            </FlowProvider>
          </Box>

          <Box p={2}>
            <Button colorScheme="yellow" onClick={openInstructionModal}>
              Instructions
            </Button>
          </Box>
        </Box>
      </Box>

      <NodeModal
        isOpen={isOpen}
        onClose={onClose}
        operations={operations}
        nodeForm={nodeForm}
        nodes={nodes}
        edges={edges}
        flowName={flowName}
      />
      <FlowInstructionsModal
        isOpen={isInstrctonModalOpen}
        onClose={closeInstructionModal}
      />
      {isEdgePropertiesOpen && (
        <EdgePropertiesModal
          isOpen={isEdgePropertiesOpen}
          onClose={closeEdgeProperties}
          edgeForm={edgeForm}
          onConnect={onConnect}
          operations={operations}
          nodes={nodes}
          edges={edges}
          flowName={flowName}
        />
      )}
    </Layout>
  );
}
