import { FormControl, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select"
import { getHostDomain } from "../../utils/utils";
import useExternalAuth from "../Tools/useExternalAuth";
import useToolUserRunStaticFunction from "../Tools/useToolUserRunStaticFunction";

export default function Plivo({ setValue, watch }) {
  const baseUrl = getHostDomain();

  const { auths } = useExternalAuth({
    auth_provider: "plivo",
  });

  const pageOptions = auths
    ? auths?.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : [];

  const handleSelect = ({ value }) => {
    setValue("tool_user_id", value);
  };

  const { tool_user_id } = watch();

  const { response } = useToolUserRunStaticFunction({
    tool_user_id,
    staticFnPayload: {
      function_name: "get_phone_numbers",
    },
  });

  const phoneNumberOption = response
    ? response.map((data) => ({
        value: data.phone_number,
        label: data.phone_number,
      }))
    : [];

  const handlePhoneNumberSelect = ({ value }) => {
    setValue("phone_number", value);
  };

  return (
    <>
      <FormControl isRequired>
        <FormLabel>{"Select Plivo"}</FormLabel>
        <Select options={pageOptions} onChange={handleSelect} />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>{"Select phone number"}</FormLabel>
        <Select
          options={phoneNumberOption}
          onChange={handlePhoneNumberSelect}
        />
      </FormControl>
    </>
  );
}
