import {
  Box,
  Button,
  ButtonGroup,
  Center,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { useEffect, useRef, useState } from "react";
import { FaImage } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { MdBrowserUpdated } from "react-icons/md";
import { TbWorldWww } from "react-icons/tb";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import Table from "../Tables/Table";
import DataSourcesModal from "./SourcesModal";
import TagDrawer from "../Tags/TagDrawer";
import TagSelect from "../Tags/TagSelect";
import useTagSelect from "../Tags/useTagSelect";

const DataSourcesModalLIST = ({
  isOpen,
  onOpen,
  onClose,
  selectedItem,
  fetch,
}) => {
  const baseUrl = getHostDomain();
  const {
    isOpen: isOpenUpload,
    onOpen: onOpenUpload,
    onClose: onCloseUpload,
  } = useDisclosure();
  const finalRef = useRef(null);

  const [status, setStatus] = useState("idle");
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const [page, setPage] = useState(1); // Add state for current page
  const [pages, setPages] = useState(0); // Add state for total pages

  const endpoint =
    selectedItem &&
    `/api/v1/datasource/${selectedItem?.id}/datasourcefiles?page=${page}`; // Include pagination in API request
  const urlHit = baseUrl + endpoint;
  useEffect(() => {
    setPage(1);
  }, [selectedItem]);
  const IconComponent = ({ dsType }) => {
    if (dsType === "pdf") {
      return (
        <div style={{ marginLeft: "8px" }}>
          <Icon as={FaFilePdf} color="red" />
        </div>
      );
    } else if (dsType === "http") {
      return (
        <div style={{ marginLeft: "8px" }}>
          <Icon as={TbWorldWww} color="blue" />
        </div>
      );
    } else if (dsType === "image") {
      return (
        <div style={{ marginLeft: "8px" }}>
          <Icon as={FaImage} color="blue" />
        </div>
      );
    }
    return null;
  };

  const hitFetch = async () => {
    try {
      setStatus("loading");
      const getData = await fetchController(urlHit, "GET");
      if (getData?.data) {
        const allowedProperties = ["name", "description", "ds_type"];
        if (getData.data.items.length === 0) {
          setTableData([]);
          setStatus("success");
          return;
        }
        const filteredHeads = allowedProperties.filter((prop) =>
          Object.keys(getData.data.items[0]).includes(prop)
        );

        const columns = filteredHeads.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));

        const addIcon = getData.data.items.map((item) => {
          return {
            ...item,
            ds_type: <IconComponent dsType={item.ds_type} />,
          };
        });
        setTableData(addIcon);
        setHeads(columns);
        setPages(getData.data.pages); // Set total pages
      }
      setStatus("success");
    } catch (error) {
      setStatus("failure");
    }
  };

  useEffect(() => {
    if (isOpen) {
      hitFetch();
    }
  }, [isOpen, page]); // Include page in dependency array

  const loading = status === "loading";
  async function onAddNewSource() {
    await hitFetch();
    await fetch();
  }

  const isFirstPage = page <= 1;
  const hasReachedLastPage = page >= pages;
  const tagSelectHook = useTagSelect({
    entity_id: selectedItem?.id,
    entity_type: "data_source",
    onSelectTagCb: fetch,
  });

  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setTableData([]);
          setHeads([]);
        }}
      >
        <ModalOverlay />
        <ModalContent height="80vh" width="100%" maxWidth="800px">
          <ModalHeader>Data Sources Files</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedItem?.id && (
              <FormControl mb="4">
                <FormLabel
                  htmlFor="tagSelect"
                  display={"flex"}
                  alignContent="center"
                >
                  <Text alignSelf="center">Select tags</Text>
                </FormLabel>
                <TagSelect tagSelectHook={tagSelectHook} />
              </FormControl>
            )}
            <Button
              cursor="pointer"
              padding="1px"
              display="flex"
              marginLeft="auto"
              marginRight="10px"
              height="30px"
              colorScheme="blue"
              onClick={() => {
                onOpenUpload();
              }}
            >
              <MdBrowserUpdated />
            </Button>
            {loading ? (
              <Center display="flex" alignItems="center" height="20vh">
                <Spinner />
              </Center>
            ) : (
              <Box height="54vh" overflowY="scroll">
                {tableData.length > 0 ? (
                  <Table
                    columns={heads}
                    data={tableData}
                    table={"sourcesFileList"}
                    fetch={hitFetch}
                  />
                ) : (
                  <Box>
                    <Center>No Files</Center>
                  </Box>
                )}
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup
              justifyContent={"center"}
              alignItems={"center"}
              gap={3}
            >
              <Button
                size={"sm"}
                isDisabled={isFirstPage}
                onClick={() => {
                  setPage((prev) => prev - 1);
                }}
              >
                Previous
              </Button>
              <code>
                {page} / {pages}
              </code>
              <Button
                size={"sm"}
                isDisabled={hasReachedLastPage}
                onClick={() => {
                  setPage((prev) => prev + 1);
                }}
              >
                Next
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
        <DataSourcesModal
          isOpen={isOpenUpload}
          onOpen={onOpenUpload}
          onClose={onCloseUpload}
          selectedItem={selectedItem}
          fetch={onAddNewSource}
        />
        {tagSelectHook.tagDrawerDisclosure.isOpen && (
          <TagDrawer
            isOpen={tagSelectHook.tagDrawerDisclosure.isOpen}
            onClose={tagSelectHook.tagDrawerDisclosure.onClose}
            tagToEdit={tagSelectHook.tagToEdit}
            setTagToEdit={tagSelectHook.setTagToEdit}
            cb={tagSelectHook.onCreateTag}
          />
        )}
      </Modal>
    </>
  );
};

export default DataSourcesModalLIST;
