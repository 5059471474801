import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import { getHostDomain } from "../../utils/utils";
import PasswordInput from "../Forms/PasswordInput";

const WhatsAppModal = ({
  isOpen,
  onOpen,
  onClose,
  data,
  isUpdating,
  userId,
  loadUserWhatsAppDetails,
}) => {
  const baseUrl = getHostDomain();
  // const { isOpen, onOpen, onClose, data, last_updatedAt } = props;
  const [defaultVal, setDefaultVal] = useState({
    phone_number_id: "",
    phone_business_id: "",
    facebook_app_secret: "",
    whatsapp_access_token: "",
    whatsapp_phone_number: "",
  });

  const finalRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data]),
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      let endpoint;
      if (isUpdating) {
        endpoint = `/api/v1/user/${userId}/UserWhatsApp`;
      } else {
        endpoint = "/api/v1/user/UserWhatsApp";
      }
      const urlHit = baseUrl + endpoint;
      const body = {
        phone_number_id: data.phone_number_id || "string",
        phone_business_id: data.phone_business_id || "string",
        facebook_app_secret: data.facebook_app_secret || "string",
        whatsapp_access_token: data.whatsapp_access_token || "string",
        whatsapp_phone_number: data.whatsapp_phone_number || "string",
      };
      const getData = await fetchController(
        urlHit,
        isUpdating ? "PUT" : "POST",
        body
      );

      if (getData?.message) {
        showToast(
          isUpdating ? "Whatsapp Details updated" : "Whatsapp Details Added",
          "success"
        );
        loadUserWhatsAppDetails();
      }
      onClose();
      reset(); // Reset the form after successful submission
    } catch (error) {
      showToast("Oops! Something went wrong.", "error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reset(data);
  }, [data]);

  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent height="auto" width="95%" maxWidth="600px">
          {isLoading && (
            <Center
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              background="rgba(255, 255, 255, 0.8)"
              zIndex="999"
            >
              <Spinner />
            </Center>
          )}
          <ModalHeader>WhatsApp Details </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb="4">
                <Input
                  type="number"
                  placeholder="WhatsApp Phone Number"
                  {...register("whatsapp_phone_number", {
                    required: true,
                  })}
                />
                {errors.whatsapp_phone_number && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    {errors.whatsapp_phone_number.message}
                  </p>
                )}
              </Box>
              <Box mb="4">
                <Input
                  placeholder="Phone Number ID"
                  {...register("phone_number_id", { required: true })}
                />
                {errors.phone_number_id && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    Phone Number ID is required
                  </p>
                )}
              </Box>
              <Box mb="4">
                <Input
                  placeholder="Phone Business ID"
                  {...register("phone_business_id", { required: true })}
                />
                {errors.phone_business_id && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    Phone Business ID is required
                  </p>
                )}
              </Box>
              <Box mb="4">
                <PasswordInput
                  placeholder={"WhatsApp Access Token"}
                  register={{
                    ...register("whatsapp_access_token", { required: true }),
                  }}
                />
                {errors.whatsapp_access_token && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    WhatsApp Access Token is required
                  </p>
                )}
              </Box>
              <Box mb="4">
                <Input
                  placeholder="Facebook App Secret"
                  {...register("facebook_app_secret", { required: true })}
                />
                {errors.facebook_app_secret && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    Facebook App Secret is required
                  </p>
                )}
              </Box>
              <Flex justifyContent={"flex-start"}>
                <Link
                  color={"blue.500"}
                  href="https://developers.facebook.com/docs/whatsapp/cloud-api/get-started"
                  isExternal
                >
                  Get Keys <ExternalLinkIcon mx="2px" />
                </Link>
              </Flex>
              <Flex justifyContent={"center"} alignItems={"center"}>
                <Button
                  margin={"auto"}
                  type="submit"
                  colorScheme="blue"
                  isLoading={isLoading}
                  isDisabled={isLoading}
                >
                  {isUpdating ? "Update" : "Submit"}
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WhatsAppModal;
