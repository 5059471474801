import {
  Box,
  Button,
  Center,
  Table as ChakraTable,
  Flex,
  Input,
  Radio,
  Spinner,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useClipboard,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BiConversation } from "react-icons/bi";
import { FaWpforms } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa6";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { GoBlocked } from "react-icons/go";
import { IoIosContact } from "react-icons/io";
import { LiaToolsSolid } from "react-icons/lia";
import { LuFileClock } from "react-icons/lu";
import { MdDelete, MdOutlineBlock } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import { getHostDomain } from "../../utils/utils";
import useRecaptcha from "../Auth/useRecaptcha";
import ConversationDrawer from "../Contacts/ConversationDrawer";
import NewConversationFlowModal from "../ConversationFlow/NewConversationFlowModal";
import ConfigureContactFieldsModal from "../Forms/ConfigureContactFieldsModal";
import TriggerToolsModal from "../Forms/TriggerToolsModal";
import AddContact from "../Modals/AddContact";
import AddCustomBillingPlan from "../Modals/AddCustomBillingPlan";
import AddEditContactCustomField from "../Modals/AddEditContactCustomField";
import AddPromptModal from "../Modals/AddPrompt";
import AssignAssistant from "../Modals/AssignAssistant";
import AssignIntent from "../Modals/AssignIntent";
import DataSourcesList from "../Modals/AssistantLinked";
import Code from "../Modals/Code";
import ConfirmationModal from "../Modals/Confirmation";
import EditWidget from "../Modals/EditWidget";
import ResetSubAccPassword from "../Modals/ResetSubAccPassword";
import DataSourcesModalLIST from "../Modals/SourceFileList";
import DataSourcesModal from "../Modals/SourcesModal";
import WidgetDataSources from "../Modals/WidgetAssistantLinkedDataSources";
import FormList from "../Modals/formList";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import AssignWebhookModal from "../Settings/Webhooks/AssignWebhook";
import WebhookModal from "../Settings/Webhooks/WebhookModal";
import WebhookLogsModal from "../Settings/Webhooks/WebhooksLogsModal";
import AssignToolModal from "../Tools/AssignToolModal";
import RenderOperationTableColumn from "./RenderOperationTableColumn";
import RenderOperationTableHeader from "./RenderOperationTableHeader";
import ChangeBillingPlanModal from "../Agency/ChangeBillingPlanModal";
import TagTableHeader from "../Tags/TagTableHeader";

const Table = ({ columns, data, table, fetch, selectedForm }) => {
  const baseUrl = getHostDomain();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: openList,
    onOpen: onOpenList,
    onClose: onCloseList,
  } = useDisclosure();
  const {
    isOpen: isWebhookLogsOpen,
    onOpen: openWebhookLogs,
    onClose: closeWebhookLogs,
  } = useDisclosure();
  const {
    isOpen: openSourceList,
    onOpen: onOpenSourceList,
    onClose: onCloseSourceList,
  } = useDisclosure();
  const {
    isOpen: OpenAssistantList,
    onOpen: onOpenAssistantList,
    onClose: onCloseAssistantLIst,
  } = useDisclosure();
  const {
    isOpen: isContactFormOpen,
    onOpen: openContactForm,
    onClose: closeContactsForm,
  } = useDisclosure();
  const {
    isOpen: isWebhookAssignOpen,
    onOpen: openWebhookAssign,
    onClose: closeWebhookAssign,
  } = useDisclosure();
  const {
    isOpen: isOpenCode,
    onOpen: onOpenCode,
    onClose: onCloseCode,
  } = useDisclosure();
  const {
    isOpen: OpenWidgetSources,
    onOpen: onOpenWidgetSources,
    onClose: onCloseWidgetSources,
  } = useDisclosure();
  const {
    isOpen: isOpenIntent,
    onOpen: onOpenIntent,
    onClose: onCloseIntent,
  } = useDisclosure();
  const {
    isOpen: isContactCustomFieldOpen,
    onOpen: openContactCustomField,
    onClose: closeContactCustomField,
  } = useDisclosure();
  const {
    isOpen: isTriggerCreateContactOpen,
    onOpen: onOpenTriggerContact,
    onClose: onCloseTriggerContact,
  } = useDisclosure();
  const {
    isOpen: isResetSubAccPassOpen,
    onOpen: onOpenResetSubAccPass,
    onClose: onCloseResetSubAccPass,
  } = useDisclosure();
  const {
    isOpen: isConversationDrawerOpen,
    onOpen: onOpenConversationDrawer,
    onClose: onCloseConversationDrawer,
  } = useDisclosure();
  const {
    isOpen: isWidgetEditOpen,
    onOpen: onOpenWidgetEdit,
    onClose: onCloseWidgetEdit,
  } = useDisclosure();

  const [selectedItem, setSelectedItem] = useState(null);

  const handleUploadClick = (item) => {
    setSelectedItem(item);
    onOpen();
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    { columns, data },
    useFilters,
    useGlobalFilter,
    useSortBy // Keep using useSortBy
  );

  const [loadingRow, setLoadingRow] = useState(null);
  const [blockLoading, setBlockLoading] = useState(null);

  const navigate = useNavigate();
  const { globalFilter } = state;
  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();
  const {
    isOpen: isToolUserFormModalOpen,
    onOpen: openUserFormToolModal,
    onClose: closeUserFormToolModal,
  } = useDisclosure();
  const {
    isOpen: isAssignToolModalOpen,
    onOpen: openAssignToolModal,
    onClose: closeAssignToolModal,
  } = useDisclosure();
  const {
    isOpen: isFormOpen,
    onOpen: onOpenForm,
    onClose: onFormClose,
  } = useDisclosure();
  const {
    isOpen: isAddCustomBillingPlanOpen,
    onOpen: onCustomBillingPlanOpen,
    onClose: onCloseCustomBillingPlanOpen,
  } = useDisclosure();
  const {
    isOpen: isPromptModalOpen,
    onOpen: onPromptModalOpen,
    onClose: onPromptModalClose,
  } = useDisclosure();
  const {
    isOpen: isChangeBillingPlanOpen,
    onOpen: openChangeBillingPlan,
    onClose: closeChangeBillingPlan,
  } = useDisclosure();

  const handleListModal = (item) => {
    setSelectedItem(item);
    onOpenList();
  };
  const handleSourceListModal = (item) => {
    setSelectedItem(item);
    onOpenSourceList();
  };
  const handleAssistantModal = async (item) => {
    setSelectedItem(item);
    onOpenAssistantList();
  };
  const handleFormList = async (item) => {
    setSelectedItem(item);
    onOpenForm();
  };

  const [itemToDelete, setItemToDelete] = useState(null);
  const {
    isOpen: isWebhookModalOpen,
    onOpen: openWebhookModal,
    onClose: closeWebhookModal,
  } = useDisclosure();

  const handleDeleteWebhook = async (webhook) => {
    await fetchController(
      baseUrl + `/api/v1/outbound_webhook/${webhook.id}`,
      "DELETE"
    );
    toast.success("Webhook deleted");
    fetch();
  };
  const handleDeleteForm = async (form) => {
    try {
      await fetchController(baseUrl + `/api/v1/form/${form.id}`, "DELETE");
      navigate("/forms");
      fetch();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const handledeleteClick = async (item) => {
    try {
      setLoadingRow(item.id);
      const endpoint = `/api/v1/datasource/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      setLoadingRow(null);
      toast.error(error);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };

  const handleDeleteAssistant = async (item) => {
    try {
      setLoadingRow(item.id);
      const endpoint = `/api/v1/assistant/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      toast.success(response.detail, "success");
    } catch (error) {
      setLoadingRow(null);
      toast.error(error);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };

  const handleDeleteWidget = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/widget/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
      fetch();
    } catch (error) {
      setLoadingRow(null);
      toast.error(error);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };

  const handleDeleteSourceFile = async (sourceFile) => {
    try {
      setLoadingRow(sourceFile.id);
      const endpoint = `/api/v1/datasource/datasourcefile/${sourceFile.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      toast.success(response.detail);
      fetch();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoadingRow(null);
    }
  };
  const handleDeleteIntent = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/intent/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      toast.error(error);
      setLoadingRow(null);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };
  const handleIntentModal = async (item) => {
    setSelectedItem(item);
    onOpenIntent();
  };
  const handleDeleteApikey = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/apikey/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      toast.error(error);
      setLoadingRow(null);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };
  const handleDeleteCapturedForm = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/capturedform/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      toast.error(error);
      setLoadingRow(null);
    } finally {
      if (selectedForm) {
        const data = await fetch(selectedForm.id);
        if (data) {
          setLoadingRow(null);
        }
      }
    }
  };
  const handleDeleteContact = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/contact/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      toast.error(error);
      setLoadingRow(null);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };
  const handleDeleteBillingPlan = async (item) => {
    try {
      setLoadingRow(item.id);
      const response = await fetchController(
        `${baseUrl}/api/v1/agency/billing/${item.id}`,
        "DELETE"
      );
      toast.success(response.detail, "success");
    } catch (e) {
      console.error(e);
      setLoadingRow(null);
      toast.error(e?.response?.data?.detail);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };

  const handleOpenFormToEdit = (item) => {
    navigate(`/forms/${item.id}`);
  };

  const handleCopyFormId = async (item) => {
    await navigator.clipboard.writeText(item.id);
    toast.success("Copied!");
  };
  const getRecaptchaToken = useRecaptcha();

  const handleClickOnSubAccLogin = async (subAccount) => {
    if (!agencyData?.domain?.appDomain) {
      toast.error("Please setupt APP domain first");
      return;
    }
    try {
      const reCaptchaToken = await getRecaptchaToken("subAccountLogin");
      const endpoint = `/api/v1/login/sub-account-login`;
      const payload = {
        email: subAccount.email,
        captcha_response: reCaptchaToken,
      };
      const url = baseUrl + endpoint;
      const response = await fetchController(url, "POST", payload);
      const { refresh_token } = response.data;
      // localStorage.clear() // for testing in local
      // window.open(`http://localhost:3000/login?token=${refresh_token}`) // for testing in local

      const agencyAppDomain = `https://${agencyData?.domain?.appDomain}`;
      window.open(`${agencyAppDomain}/login?token=${refresh_token}`);
    } catch (error) {
      toast.error(error);
      console.error({ error });
    }
  };

  const handleClickOnSubAccActivate = async (account) => {
    try {
      setLoadingRow(account.id);
      const endpoint = `/api/v1/login/activate_subaccount`;
      const payload = {
        email: account.email,
      };
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "POST", payload);
      toast.success(response?.message || "Account activated successfully");
      await fetch();
    } catch (error) {
      toast.error(error);
      setLoadingRow(null);
      console.error({ error });
    } finally {
      setLoadingRow(null);
    }
  };
  const handleDeleteContactCustomField = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/contact_custom_field/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      toast.error(error);
      setLoadingRow(null);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };
  const handleDeleteSubaccount = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/login/remove_subaccount/${item.id}`;
      const url = baseUrl + endpoint;
      const response = await fetchController(url, "DELETE");
      toast.success(response.message || "Sub account deleted successfully");
      await fetch();
    } catch (error) {
      toast.error(error?.message || "Something went wrong.");
      console.log({ error });
    } finally {
      setLoadingRow(null);
    }
  };

  const handleDeletePrompt = async (item) => {
    try {
      setLoadingRow(item.id);
      const response = await fetchController(
        baseUrl + `/api/v1/prompt/${item.id}`,
        "DELETE"
      );
      toast.success(response.message || "Prompt deleted successfully");
      await fetch();
    } catch (error) {
      toast.error(error?.message || "Something went wrong.");
      console.error(error);
    } finally {
      setLoadingRow(null);
    }
  };

  const handleDeleteConversationFlow = async (item) => {
    try {
      setLoadingRow(item.id);
      const response = await fetchController(
        `/api/v1/conversation_flow/${item.id}`,
        "DELETE"
      );
      toast.success(
        response.message || "Conversation Flow deleted successfully"
      );
      await fetch();
    } catch (error) {
      toast.error(error?.message || "Something went wrong.");
      console.error(error);
    } finally {
      setLoadingRow(null);
    }
  };

  const handleOnchangeDefaultPlan = async (e, row) => {
    setLoadingRow(null);
    try {
      setLoadingRow(row.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/billing/set_default_plan/${row.id}`;
      const url = baseUrl + endpoint;
      const response = await fetchController(url, "PUT");
      toast.success(response.message || "Default plan changed successfully");
      await fetch();
    } catch (error) {
      toast.error(error?.message || "Something went wrong.");
      console.log({ error });
    } finally {
      setLoadingRow(null);
    }
  };

  const handleContactBlock = async (item, status) => {
    try {
      setBlockLoading(item.id);
      const payload = {
        user_attributes: {
          block: status,
        },
      };
      const response = await fetchController(
        baseUrl + `/api/v1/contact/${item.id}`,
        "PUT",
        payload
      );
      showToast(response?.message, "success");
    } catch (error) {
      console.error(error);
    } finally {
      setBlockLoading(null);
      await fetch();
    }
  };

  const [chatbotDomain, setChatbotDomain] = useState("cdn.insighto.ai");
  const [apiDomain, setApiDomain] = useState("api.insighto.ai");

  const getDomains = async () => {
    try {
      const response = await fetchController(
        `${baseUrl}/api/v1/user/get_domains`,
        "GET"
      );
      setChatbotDomain(response?.data?.chatbotDomain || "cdn.insighto.ai");
      setApiDomain(response?.data?.apiDomain || "api.insighto.ai");
    } catch (e) {
      console.error(e);
    }
  };

  const agency = useContext(AgencyContext);
  const { agencyData, buttonColorScheme, cardBg: tableBg, myDetails } = agency;
  const agencyId = agencyData?.id;

  useEffect(() => {
    if (table === "widget") {
      getDomains();
      if (myDetails) {
        setChatbotDomain(myDetails?.domain?.chatbotDomain || "cdn.insighto.ai");
        setApiDomain(myDetails?.domain?.apiDomain || "cdn.insighto.ai");
      }
    }
  }, [agency]);

  const { onCopy, setValue, hasCopied } = useClipboard("");

  const handleRefreshCredit = async () => {
    try {
      const response = await fetchController(
        `${baseUrl}/api/v1/agency/billing/subaccount/refresh_billing_cycle?agency_id=${agencyId}&subaccount_user_id=${selectedItem?.id}`,
        "GET"
      );
      toast.success(response.message || "Credit refreshed successfully");
      await fetch();
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Something went wrong");
    }
  };

  const deleteHandlers = {
    "data-sources": handledeleteClick,
    assistant: handleDeleteAssistant,
    widget: handleDeleteWidget,
    sourcesFileList: handleDeleteSourceFile,
    intent: handleDeleteIntent,
    forms: handleDeleteForm,
    apikey: handleDeleteApikey,
    capturedForm: handleDeleteCapturedForm,
    contact: handleDeleteContact,
    webhooks: handleDeleteWebhook,
    contactCustomField: handleDeleteContactCustomField,
    agencyBillingPlan_regular: handleDeleteBillingPlan,
    agencyBillingPlan_addons: handleDeleteBillingPlan,
    agencyBillingPlan_hidden: handleDeleteBillingPlan,
    subaccount: handleDeleteSubaccount,
    prompt: handleDeletePrompt,
    conversationFlow: handleDeleteConversationFlow,
  };

  const handleDelete = async (table, itemToDelete) => {
    if (deleteHandlers[table]) {
      await deleteHandlers[table](itemToDelete);
    }
  };

  const onConfirmation = async () => {
    if (selectedItem) {
      if (table === "subaccount") {
        await handleRefreshCredit(selectedItem);
      }
    }
    if (itemToDelete) {
      handleDelete(table, itemToDelete);
    }
    onCloseConfirmationModal();
  };

  return (
    <Box p={4} shadow="lg" borderRadius="md" bg={tableBg} overflowY="auto">
      <>
        <Input
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          mb={4}
        />
        <ChakraTable {...getTableProps()} width="100%">
          <Thead>
            <Tr>
              {headerGroups.map((headerGroup, headerGroupIndex) => (
                <React.Fragment key={headerGroupIndex}>
                  {headerGroup.headers.map((column) => (
                    <>
                      {column.Header === "Tags" ? (
                        <TagTableHeader tableBg={tableBg} />
                      ) : (
                        <Th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          textAlign="left"
                          p={2}
                          borderBottom="1px"
                          borderColor="gray.300"
                          style={{ textWrap: "nowrap" }}
                          bg={tableBg}
                          key={column.id}
                        >
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {column.render("Header").replace(/_/g, " ")}
                            {column.canSort && (
                              <Box ml={2}>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <FiArrowDown />
                                  ) : (
                                    <FiArrowUp />
                                  )
                                ) : (
                                  <FiArrowUp opacity={0.4} />
                                )}
                              </Box>
                            )}
                          </Box>
                        </Th>
                      )}
                    </>
                  ))}
                  {table === "agencyBillingPlan_regular" && (
                    <Th
                      p={2}
                      borderBottom="1px"
                      borderColor="gray.300"
                      bg={tableBg}
                      style={{ textWrap: "nowrap" }}
                    >
                      Is default
                    </Th>
                  )}
                  {[
                    "data-sources",
                    "assistant",
                    "widget",
                    "sourcesFileList",
                    "intent",
                    "forms",
                    "apikey",
                    "capturedForm",
                    "contact",
                    "webhooks",
                    "contactCustomField",
                    "agencyBillingPlan_regular",
                    "agencyBillingPlan_addons",
                    "agencyBillingPlan_hidden",
                    "subaccount",
                    "prompt",
                    "conversationFlow",
                  ].includes(table) && (
                    <RenderOperationTableHeader tableBg={tableBg} />
                  )}
                  {table === "widget" && (
                    <Th
                      p={2}
                      borderBottom="1px"
                      borderColor="gray.300"
                      bg={tableBg}
                      style={{ textWrap: "nowrap" }}
                    >
                      Demo
                    </Th>
                  )}
                </React.Fragment>
              ))}
            </Tr>
          </Thead>
          <Tbody fontSize={"large"} {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  borderBottom="1px"
                  borderColor="gray.200"
                  key={row.original.id}
                >
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()} p={2} key={cell.column.id}>
                      {cell.render("Cell")}
                    </Td>
                  ))}

                  {table === "data-sources" && (
                    <RenderOperationTableColumn
                      tableName={table}
                      row={row}
                      loadingRow={loadingRow}
                      hasCopied={hasCopied}
                      setValue={setValue}
                      onCopy={onCopy}
                      handleSourceListModal={handleSourceListModal}
                      handleListModal={handleListModal}
                      handleUploadClick={handleUploadClick}
                      handleDelete={() => {
                        setItemToDelete(row.original);
                        onOpenConfirmationModal();
                      }}
                    />
                  )}

                  {table === "assistant" && (
                    <RenderOperationTableColumn
                      tableName={table}
                      row={row}
                      loadingRow={loadingRow}
                      hasCopied={hasCopied}
                      setValue={setValue}
                      onCopy={onCopy}
                      fetch={fetch}
                      handleAssistantModal={handleAssistantModal}
                      handleFormList={handleFormList}
                      handleAssignWebhook={() => {
                        setSelectedItem(row.original);
                        openWebhookAssign();
                      }}
                      handleIntentModal={handleIntentModal}
                      handleEditAssistant={() => {
                        navigate(`/ai-agents/assistants/${row.original.id}`);
                      }}
                      handleDeleteAssistant={() => {
                        setItemToDelete(row.original);
                        onOpenConfirmationModal();
                      }}
                    />
                  )}

                  {table === "widget" && (
                    <>
                      <RenderOperationTableColumn
                        tableName={table}
                        row={row}
                        loadingRow={loadingRow}
                        hasCopied={hasCopied}
                        setValue={setValue}
                        onCopy={onCopy}
                        navigate={navigate}
                        handleGetCode={() => {
                          setSelectedItem(row.original);
                          onOpenCode();
                        }}
                        handleWidgetEdit={() => {
                          setSelectedItem(row.original);
                          onOpenWidgetEdit();
                        }}
                        handleWidgetDelete={() => {
                          setItemToDelete(row.original);
                          onOpenConfirmationModal();
                        }}
                      />

                      <Td pl="4px">
                        <Tooltip label="Demo" aria-label="Get Demo">
                          <Button
                            isDisabled={[
                              "phone",
                              "whatsapp",
                              "telnyx_call",
                            ].includes(row.original.widget_type)}
                            onClick={() => {
                              window.open(
                                row.original.widget_type === "web_call"
                                  ? `https://${chatbotDomain}/web-call-iframe.html?widgetId=${row.original.id}`
                                  : row.original.widget_type === "html_call"
                                  ? `https://${chatbotDomain}/call-iframe.html?widgetId=${row.original.id}`
                                  : `https://${chatbotDomain}/widget.html?widgetId=${row.original.id}`
                              );
                            }}
                            colorScheme={buttonColorScheme}
                            width="50px"
                            height="26px"
                            fontSize="13px"
                          >
                            Visit
                          </Button>
                        </Tooltip>
                      </Td>
                    </>
                  )}

                  {table === "intent" && (
                    <>
                      <Td cursor="pointer" p={2}>
                        <Flex gap="2" marginLeft="20px">
                          <Tooltip
                            label="Delete intent"
                            aria-label="Delete tooltip"
                          >
                            <Center>
                              {loadingRow === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  {row.original.userType === "user" ? (
                                    <>
                                      <MdDelete
                                        onClick={() => {
                                          setItemToDelete(row.original);
                                          onOpenConfirmationModal();
                                        }}
                                        color="red"
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                  {table === "sourcesFileList" && (
                    <Td cursor="pointer">
                      {loadingRow === row.original.id ? (
                        <Spinner size="sm" />
                      ) : (
                        <Tooltip label="Delete" aria-label="Delete tooltip">
                          <Box width={"fit-content"}>
                            <MdDelete
                              onClick={() => {
                                setItemToDelete(row.original);
                                onOpenConfirmationModal();
                              }}
                              color="red"
                            />
                          </Box>
                        </Tooltip>
                      )}
                    </Td>
                  )}
                  {table === "forms" && (
                    <Td cursor="pointer" p={2}>
                      <Flex gap="2" marginLeft="14px">
                        <Tooltip
                          label="Copy ID"
                          aria-label="Copy ID"
                          closeOnClick={false}
                        >
                          <Center>
                            <FaRegCopy
                              onClick={() => {
                                handleCopyFormId(row.original);
                              }}
                              color="black"
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip
                          label="Assign tool"
                          aria-label="Copy ID"
                          closeOnClick={false}
                        >
                          <Center>
                            <LiaToolsSolid
                              onClick={() => {
                                setSelectedItem(row.original);
                                openUserFormToolModal();
                              }}
                              color="black"
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip
                          label="Edit form fields"
                          aria-label="Edit form fields"
                        >
                          <Center>
                            <FaWpforms
                              onClick={() => {
                                handleOpenFormToEdit(row.original);
                              }}
                              color="blue"
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip
                          label="Trigger create contact field"
                          aria-label="Trigger create contact field"
                        >
                          <Center>
                            <IoIosContact
                              onClick={() => {
                                setSelectedItem(row.original);
                                onOpenTriggerContact();
                              }}
                              color="blue"
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip
                          label="Delete form field"
                          aria-label="Delete form field"
                        >
                          <Center>
                            <MdDelete
                              onClick={() => {
                                setItemToDelete(row.original);
                                onOpenConfirmationModal();
                              }}
                              color="red"
                            />
                          </Center>
                        </Tooltip>
                      </Flex>
                    </Td>
                  )}
                  {table === "capturedForm" && (
                    <>
                      <Td cursor="pointer" p={2}>
                        <Flex gap="2" marginLeft="20px">
                          <Tooltip
                            label="Delete form data"
                            aria-label="Delete tooltip"
                          >
                            <Center>
                              {loadingRow === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  <MdDelete
                                    onClick={() => {
                                      setItemToDelete(row.original);
                                      onOpenConfirmationModal();
                                    }}
                                    color="red"
                                  />
                                </>
                              )}
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                  {table === "apikey" && (
                    <>
                      <Td cursor="pointer" p={2}>
                        <Flex gap="2" marginLeft="20px">
                          <Tooltip
                            label="Delete API key"
                            aria-label="Delete tooltip"
                          >
                            <Center>
                              {loadingRow === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  <MdDelete
                                    onClick={() => {
                                      setItemToDelete(row.original);
                                      onOpenConfirmationModal();
                                    }}
                                    color="red"
                                  />
                                </>
                              )}
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                  {table === "contact" && (
                    <>
                      <Td p={2}>
                        <Flex gap="2" justifyContent={"center"}>
                          <Tooltip
                            label={
                              row.original.value?.user_attributes?.block
                                ? "Unblock"
                                : "Block"
                            }
                          >
                            <Center>
                              {blockLoading === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  {row.original.value?.user_attributes
                                    ?.block ? (
                                    <MdOutlineBlock
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleContactBlock(row.original, false);
                                      }}
                                      color="red"
                                    />
                                  ) : (
                                    <GoBlocked
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleContactBlock(row.original, true);
                                      }}
                                      color="blue"
                                    />
                                  )}
                                </>
                              )}
                            </Center>
                          </Tooltip>
                          <Tooltip label="Edit Contact" aria-label="Edit">
                            <Center>
                              <AiOutlineEdit
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  openContactForm();
                                  setSelectedItem(row.original.value);
                                }}
                              />
                            </Center>
                          </Tooltip>
                          <Tooltip
                            label="Conversation"
                            aria-label="Conversations"
                          >
                            <Center>
                              <BiConversation
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setSelectedItem(row.original);
                                  onOpenConversationDrawer();
                                }}
                              />
                            </Center>
                          </Tooltip>
                          <Tooltip
                            label="Delete Contact "
                            aria-label="Delete tooltip"
                          >
                            <Center>
                              {loadingRow === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  <MdDelete
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setItemToDelete(row.original);
                                      onOpenConfirmationModal();
                                    }}
                                    color="red"
                                  />
                                </>
                              )}
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                  {table === "webhooks" && (
                    <>
                      <Td p={2}>
                        <Flex gap="2" marginLeft="5px">
                          <Tooltip label="Logs" aria-label="Logs">
                            <Center>
                              <LuFileClock
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setSelectedItem(row.original);
                                  openWebhookLogs();
                                }}
                              />
                            </Center>
                          </Tooltip>
                          <Tooltip label="Edit Webhook" aria-label="Edit">
                            <Center>
                              <AiOutlineEdit
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setSelectedItem(row.original);
                                  openWebhookModal();
                                }}
                              />
                            </Center>
                          </Tooltip>
                          <Tooltip
                            label="Delete Webhook "
                            aria-label="Delete tooltip"
                          >
                            <Center>
                              {loadingRow === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  <MdDelete
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setItemToDelete(row.original);
                                      onOpenConfirmationModal();
                                    }}
                                    color="red"
                                  />
                                </>
                              )}
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                  {table === "contactCustomField" && (
                    <>
                      <Td p={2}>
                        <Flex gap="2" marginLeft="20px">
                          <Tooltip label="Edit" aria-label="Edit">
                            <Center>
                              <AiOutlineEdit
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  openContactCustomField();
                                  setSelectedItem(row.original);
                                }}
                              />
                            </Center>
                          </Tooltip>
                          <Tooltip label="Delete" aria-label="Delete tooltip">
                            <Center>
                              <MdDelete
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setItemToDelete(row.original);
                                  onOpenConfirmationModal();
                                }}
                                color="red"
                              />
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                  {table === "subaccount" && (
                    <RenderOperationTableColumn
                      tableName={table}
                      row={row}
                      loadingRow={loadingRow}
                      handleClickOnSubAccLogin={handleClickOnSubAccLogin}
                      handleOpenResetPasswordModal={() => {
                        setSelectedItem(row.original);
                        onOpenResetSubAccPass();
                      }}
                      handleDeleteSubaccount={() => {
                        setItemToDelete(row.original);
                        onOpenConfirmationModal();
                      }}
                      handleClickOnSubAccActivate={handleClickOnSubAccActivate}
                      handleChangePlan={() => {
                        setSelectedItem(row.original);
                        openChangeBillingPlan();
                      }}
                      handleRefreshCredit={() => {
                        setSelectedItem(row.original);
                        onOpenConfirmationModal();
                      }}
                    />
                  )}
                  {[
                    "agencyBillingPlan_regular",
                    "agencyBillingPlan_addons",
                    "agencyBillingPlan_hidden",
                  ].includes(table) && (
                    <>
                      {table === "agencyBillingPlan_regular" && (
                        <Td pl="4px">
                          <Center>
                            <Radio
                              isChecked={
                                row.original.default_plan ? true : false
                              }
                              onChange={(e) =>
                                handleOnchangeDefaultPlan(e, row.original)
                              }
                            >
                              {" "}
                            </Radio>
                          </Center>
                        </Td>
                      )}
                      <RenderOperationTableColumn
                        tableName={table}
                        row={row}
                        loadingRow={loadingRow}
                        handleEditBillingPlan={() => {
                          setSelectedItem(row.original);
                          onCustomBillingPlanOpen();
                        }}
                        handleDeleteBillingPlan={() => {
                          setItemToDelete(row.original);
                          onOpenConfirmationModal();
                        }}
                      />
                    </>
                  )}
                  {table === "prompt" && (
                    <RenderOperationTableColumn
                      tableName={table}
                      row={row}
                      loadingRow={loadingRow}
                      setValue={setValue}
                      handleEditPrompt={() => {
                        onPromptModalOpen();
                        setSelectedItem(row.original);
                      }}
                      handleDeletePrompt={() => {
                        setItemToDelete(row.original);
                        onOpenConfirmationModal();
                      }}
                    />
                  )}

                  {table === "conversationFlow" && (
                    <RenderOperationTableColumn
                      tableName={table}
                      row={row}
                      loadingRow={loadingRow}
                      setValue={setValue}
                      handleEditFlow={() => {
                        navigate(`/conversation-flow/${row.original.id}`);
                      }}
                      handleDeleteFlow={() => {
                        setItemToDelete(row.original);
                        onOpenConfirmationModal();
                      }}
                    />
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
        <DataSourcesModal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          selectedItem={selectedItem}
          fetch={fetch}
        />
        <DataSourcesModalLIST
          isOpen={openList}
          onOpen={onOpenList}
          onClose={onCloseList}
          fetch={fetch}
          selectedItem={selectedItem}
        />
        <DataSourcesList
          isOpen={openSourceList}
          onOpen={onOpenSourceList}
          onClose={onCloseSourceList}
          selectedItem={selectedItem}
        />
        <AssignAssistant
          isOpen={OpenAssistantList}
          onOpen={onOpenAssistantList}
          onClose={onCloseAssistantLIst}
          selectedItem={selectedItem}
          fetch={fetch}
        />
        <WidgetDataSources
          isOpen={OpenWidgetSources}
          onOpen={onOpenWidgetSources}
          onClose={onCloseWidgetSources}
          selectedItem={selectedItem}
        />
        <AssignToolModal
          isOpen={isAssignToolModalOpen}
          onClose={closeAssignToolModal}
          table={table}
          selectedAssistant={selectedItem}
        />
        {table === "widget" && selectedItem && isOpenCode && (
          <Code
            isOpen={isOpenCode}
            onOpen={onOpenCode}
            onClose={onCloseCode}
            selectedItem={selectedItem}
            chatbotDomain={chatbotDomain}
            apiDomain={apiDomain}
          />
        )}
        <AssignIntent
          isOpen={isOpenIntent}
          onOpen={onOpenIntent}
          onClose={onCloseIntent}
          selectedItem={selectedItem}
          fetch={fetch}
        />
        {table === "webhooks" && selectedItem ? (
          <WebhookLogsModal
            isOpen={isWebhookLogsOpen}
            onClose={closeWebhookLogs}
            selectedWebhook={selectedItem}
            table={table}
          />
        ) : null}
        <ConfirmationModal
          table={table}
          isOpen={isConfirmationModalOpen}
          onConfirm={onConfirmation}
          onClose={onCloseConfirmationModal}
          itemToDelete={itemToDelete}
        />
        {table === "assistant" && selectedItem ? (
          <AssignWebhookModal
            isOpen={isWebhookAssignOpen}
            selectedAssistant={selectedItem}
            onClose={closeWebhookAssign}
          />
        ) : null}
        <FormList
          isOpen={isFormOpen}
          onConfirm={onOpenForm}
          onClose={onFormClose}
          selectedItem={selectedItem}
          fetch={fetch}
        />
        {isContactFormOpen && table === "contact" && selectedItem ? (
          <AddContact
            contactToEdit={selectedItem}
            fetchContact={fetch}
            isOpen={isContactFormOpen}
            onClose={closeContactsForm}
          />
        ) : null}
        <WebhookModal
          fetchWebhooks={fetch}
          isOpen={isWebhookModalOpen}
          onClose={closeWebhookModal}
          selectedWebhook={selectedItem}
        />
        {table === "contactCustomField" && selectedItem ? (
          <AddEditContactCustomField
            fieldsToEdit={selectedItem}
            reFreshTable={fetch}
            isOpen={isContactCustomFieldOpen}
            onClose={closeContactCustomField}
          />
        ) : null}
        {table === "forms" && selectedItem ? (
          <TriggerToolsModal
            form={selectedItem}
            fetchTools={fetch}
            isOpen={isToolUserFormModalOpen}
            onClose={closeUserFormToolModal}
          />
        ) : null}
        {table === "forms" && selectedItem && isTriggerCreateContactOpen && (
          <ConfigureContactFieldsModal
            formId={selectedItem.id}
            isOpen={isTriggerCreateContactOpen}
            onClose={onCloseTriggerContact}
          />
        )}
        {[
          "agencyBillingPlan_regular",
          "agencyBillingPlan_addons",
          "agencyBillingPlan_hidden",
        ].includes(table) &&
          selectedItem &&
          isAddCustomBillingPlanOpen && (
            <AddCustomBillingPlan
              itemToUpdate={selectedItem}
              isOpen={isAddCustomBillingPlanOpen}
              onClose={onCloseCustomBillingPlanOpen}
              fetchAgencyBillingPlanList={fetch}
            />
          )}
        {table === "subaccount" && selectedItem && onOpenResetSubAccPass && (
          <ResetSubAccPassword
            accToBeReset={selectedItem}
            isOpen={isResetSubAccPassOpen}
            onClose={onCloseResetSubAccPass}
          />
        )}
        {table === "contact" && selectedItem && isConversationDrawerOpen && (
          <ConversationDrawer
            isOpen={isConversationDrawerOpen}
            onClose={onCloseConversationDrawer}
            selectedContact={selectedItem}
          />
        )}
        {table === "prompt" && selectedItem && isPromptModalOpen && (
          <AddPromptModal
            isOpen={isPromptModalOpen}
            onClose={onPromptModalClose}
            selectedPrompt={selectedItem}
            refreshTable={fetch}
          />
        )}
        {table === "widget" && selectedItem && isWidgetEditOpen && (
          <EditWidget
            isOpen={isWidgetEditOpen}
            onClose={onCloseWidgetEdit}
            selectedWidget={selectedItem}
            refreshTable={fetch}
          />
        )}
        {table === "subaccount" && isChangeBillingPlanOpen && selectedItem && (
          <ChangeBillingPlanModal
            isOpen={isChangeBillingPlanOpen}
            onClose={closeChangeBillingPlan}
            selectedAccount={selectedItem}
            refreshTable={fetch}
          />
        )}
      </>
    </Box>
  );
};

export default Table;
