import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import useStepper from "./hooks/useStepper";
import NodeCreationStepper from "./NodeCreationStepper";
export default function NodeModal({
  isOpen,
  onClose,
  nodeForm,
  operations,
  nodes,
  edges,
  flowName,
}) {
  const { stepper, steps, nodeTypeOptions } = useStepper({
    nodeForm,
    onClose,
    operations,
    nodes,
    edges,
    flowName,
  });

  const currentStep = steps[stepper.activeStep];

  const handleClose = () => {
    stepper.setActiveStep(0);
    nodeForm.form.reset({});
    onClose();
  };

  return (
    <Modal size={"5xl"} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Step</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <NodeCreationStepper steps={steps} stepper={stepper} />
        </ModalBody>
        {currentStep ? (
          <currentStep.Children
            onClose={handleClose}
            nodeForm={nodeForm}
            onSubmit={currentStep.onSubmit}
            hasFinished={stepper.isCompleteStep}
            nodeTypeOptions={nodeTypeOptions}
            onBack={currentStep.onBack}
          />
        ) : null}
      </ModalContent>
    </Modal>
  );
}
