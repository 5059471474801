export const HEADS_DATA = [
    "Status",
    "Type",
    "name",
    "description",
    "assistants",
    "data_source_files",
    "word_count",
    "Tags"
  ];

  export const DS_ICONS = {
    pdf: { icon: "FaFilePdf", color: "red" },
    http: { icon: "TbWorldWww", color: "blue" },
    image: { icon: "FaImage", color: "blue" },
    text_blob: { icon: "MdOutlineTextSnippet", color: "blue" },
    text_image: { icon: "FaFileImage", color: "blue" },
  };