import { VscAzure } from "react-icons/vsc";
import { SiElevenlabs } from "react-icons/si";

export const providers = {
  azure: {
    icon: <VscAzure />,
  },
  elevenlabs: {
    icon: <SiElevenlabs />,
  },
};
