import { BsLightningCharge } from "react-icons/bs";
import { FaPhone } from "react-icons/fa";
import { TbMessageChatbot } from "react-icons/tb";

export const ASSISTANT_TYPES = [
  {
    type: "simple",
    children: TbMessageChatbot,
    title: "Chat",
    subheading:
      "Chat Assistants are optimized for fetching and answering your customers’ queries as Chatbots.",
  },
  {
    type: "phone",
    children: FaPhone,
    title: "Phone",
    subheading:
      "Conversational Voice AI Agents that can be deployed over a phone number or on web",
  },
  {
    type: "realtime_openai",
    children: BsLightningCharge,
    title: "OpenAI Realtime",
    subheading: "Advanced Voice from OpenAI Realtime is in BETA mode",
  },
];

export const CONVERSATION_SYNC_STRING =
  "Automatically pushes every conversation to the selected CRM within the contact to keep everything organized. Contact would be identified by the user’s phone number or through a Captured Form converted into a Contact.";
export const CONTACT_SYNC_STRING =
  "Automatically pushes every contact to the selected CRM to organize all contacts. Contact would be identified by the user’s phone number or through a Captured Form converted into a Contact.";
export const TOOLTIP_MESSAGES = {
  SIMPLE:
    "Lets you render Simple Forms to users as per the trigger instructions or use Natural Forms to extract data from the Conversation.",
  DEFAULT:
    "Lets you extract data from the Conversation once the call is ended.",
};

export const DEFAULT_LANGS = [
  { value: "en-US", label: "English (US)" },
  { value: "ar-SA", label: "Arabic (Saudi Arabia)" },
  { value: "ar-AE", label: "Arabic (United Arab Emirates)" },
];

export const assistantTypes = [
  {
    type: "simple",
    children: TbMessageChatbot,
    title: "Chat",
    subheading:
      "Chat Assistants are optimized for fetching and answering your customers’ queries as Chatbots.",
  },
  {
    type: "phone",
    children: FaPhone,
    title: "Phone",
    subheading:
      "Conversational Voice AI Agents that can be deployed over a phone number or on web",
  },
  {
    type: "realtime_openai",
    children: BsLightningCharge,
    title: "OpenAI Realtime",
    subheading:
      "Advanced Voice from OpenAI Realtime is in BETA mode and available only to Agency or with your own keys",
  },
];
