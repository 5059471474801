import React, { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function usePricingInAssistant(pricingParams) {
  const baseUrl = getHostDomain();
  const defaultPricing = {};
  const [pricing, setPricing] = useState(defaultPricing);
  const [status, setStatus] = useState("loading");
  const fetchPricingDependency = Object.values(pricingParams);
  useEffect(() => {
    (async () => {
      try {
        setStatus("loading");
        if (!pricingParams?.llm_model_id) return;
        const url = baseUrl + "/api/v1/pricing";
        const response = await fetchController(url, "POST", pricingParams);
        setPricing(response.data);
        setStatus("idle");
      } catch (error) {
        setStatus("idle");
      }
    })();
    return () => setPricing({});
  }, fetchPricingDependency);

  const knownCostBreakups = {
    transcriber_cost: { label: "Transcriber", color: "blue.400" },
    llm_cost: { label: "LLM", color: "purple.400" },
    voice_cost: { label: "Voice", color: "teal.400" },
    platform_cost: { label: "Platform ", color: "orange.400" },
  };
  const makeCostBreakups = ([breakup, value]) => ({
    label: knownCostBreakups[breakup].label,
    value: value,
    bg: knownCostBreakups[breakup].color,
  });
  const filterKnownCostBreakups = ([breakup, value]) =>
    knownCostBreakups[breakup] && value;

  const costBreakups = Object.entries(pricing)
    .filter(filterKnownCostBreakups)
    .map(makeCostBreakups);

  const costBreakupTotal = pricing?.total_cost;
  const makeCostBreakupPercentages = (costBreakup) => {
    const percentage = (costBreakup.value / costBreakupTotal) * 100;
    return {
      ...costBreakup,
      percentage,
      label: `${costBreakup.label} - ${pricing?.currency_symbol}${costBreakup.value}/min`,
    };
  };
  const costBreakupPercentages = costBreakups.map(makeCostBreakupPercentages);
  return {
    costBreakupPercentages,
    costBreakups,
    status,
    knownCostBreakups,
    pricing,
  };
}
