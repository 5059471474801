import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { getHostDomain } from "../../utils/utils";

export default function useToolUserRunStaticFunction({
  tool_user_id,
  staticFnPayload = {},
  cb,
}) {
  const baseUrl = getHostDomain();
  const [response, setResponse] = useState(null);
  const [status, setStatus] = useState("loading");
  const run = async () => {
    try {
      if (!tool_user_id) return;
      setStatus("loading");
      const response = await fetchController(
        baseUrl + `/api/v1/tool/ToolUser/${tool_user_id}/run_static_function`,
        "POST",
        staticFnPayload
      );
      setResponse(response);
      cb && cb(response);
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle")
    }
  };
  useEffect(() => {
    run();
  }, [tool_user_id]);

  return { response, status, run };
}
