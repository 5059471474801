import { Box, Heading } from "@chakra-ui/react";
import { useContext, useState } from "react";
import Layout from "../Layout/Layout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import IntentTable from "./IntentTable";
import IntentTour from "./IntentTour";

const Intent = () => {
  const [intentTourVisibility, setIntentTourVisibility] = useState(
    localStorage.getItem("intentTourVisibility") ? false : true
  );
  const onCloseIntentTour = () => {
    localStorage.setItem("intentTourVisibility", false);
    setIntentTourVisibility(false);
  };
  const agency = useContext(AgencyContext);
  const { textColor } = agency;
  return (
    <Layout>
      <Box
        border="1px"
        borderColor={"black"}
        borderRadius={"md"}
        h="90svh"
        p={5}
        overflowY={"auto"}
      >
        <Heading color={textColor} fontSize="xl">
          Intents
        </Heading>
        {intentTourVisibility ? (
          <IntentTour onCloseIntentTour={onCloseIntentTour} />
        ) : null}
        <Box mt={4}>
          <IntentTable />
        </Box>
      </Box>
    </Layout>
  );
};

export default Intent;
