import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";

export const PLANUSER = {
  END_USER: "endUser",
  AGENCY: "agency",
};
const baseUrl = getHostDomain();

function mapMetadata(data, metadataMap) {
  return Object.keys(metadataMap).reduce((acc, key) => {
    if (key === "bots_count" && data.bots_count === 999999) {
      acc["Unlimited Bots"] = true;
    } else {
      acc[metadataMap[key]] = data.hasOwnProperty(key) ? data[key] : null;
    }
    return acc;
  }, {});
}

function handleCredits(data, metadataMap, features) {
  Object.entries(metadataMap).forEach(([metaKey, displayName]) => {
    if (metaKey.startsWith("credits.")) {
      const keyPath = metaKey.split(".");
      let value = keyPath.reduce(
        (obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined),
        data
      );
      if (value !== undefined) {
        features[displayName] = value;
      }
    }
  });
}

function sortFeatures(features) {
  return Object.fromEntries(
    Object.entries(features).sort(([_, aValue], [__, bValue]) => {
      if (!aValue) return 1;
      if (!bValue) return -1;
      return 0;
    })
  );
}

export function getFormattedFeatures(data, metadataMap) {
  let features = mapMetadata(data, metadataMap);
  handleCredits(data, metadataMap, features);
  return sortFeatures(features);
}

export const planUpgradeOrDowngrade = (
  nextPlanId,
  currentPlanId,
  billingPlans = []
) => {
  const currentPlanIndex = billingPlans
    .map((billingPlan) => billingPlan.id)
    .indexOf(currentPlanId);

  const decideUpgradeOrDowngrade = (acc, prev, index) => {
    if (nextPlanId && nextPlanId === prev.id) {
      acc[prev.plan_lookup_key] = "Active from next billing cycle";
      return acc;
    }
    acc[prev.plan_lookup_key] =
      currentPlanIndex >= 0
        ? index > currentPlanIndex
          ? "upgrade"
          : "downgrade"
        : "switch";
    return acc;
  };

  const allBillingPlansLookupKeys = billingPlans.reduce(
    decideUpgradeOrDowngrade,
    {}
  );

  return allBillingPlansLookupKeys;
};

export const fetchBillingData = async () => {
  try {
    const [billingPlanRes, metadataRes, userDetailsRes] = await Promise.all([
      fetchController(
        `${baseUrl}/api/v1/billing/get_active_billing_plans`,
        "GET"
      ),
      fetchController(
        `${baseUrl}/api/v1/billing/get_all_metadata_billing_plans`,
        "GET"
      ),
      fetchController(`${baseUrl}/api/v1/user`, "GET"),
    ]);

    return {
      billingPlansData: billingPlanRes.data,
      metadataMap: Object.fromEntries(
        metadataRes.metadata.map((meta) => [meta.key, meta.label])
      ),
      userDetails: userDetailsRes.data,
    };
  } catch (error) {
    console.error("Error fetching billing data:", error);
    return null;
  }
};

export const processBillingPlans = (
  billingPlansData,
  userDetails,
  metadataMap
) => {
  const activePlan = billingPlansData.items.find(
    (plan) => plan.id === userDetails.active_billing_plan_id
  );
  const isSpecialPlan = !activePlan;
  const selectPlan = planUpgradeOrDowngrade(
    userDetails.next_billing_plan_id,
    userDetails.active_billing_plan_id,
    billingPlansData.items
  );

  return billingPlansData.items.map((plan) => {
    const isActive = isSpecialPlan
      ? false
      : userDetails.active_billing_plan_id === plan.id ||
        plan.plan_lookup_key === "insightoai_free";

    const isDisable =
      isSpecialPlan && plan.plan_lookup_key === "insightoai_free"
        ? true
        : userDetails.is_trial && activePlan?.queries_count > plan.queries_count
        ? true
        : userDetails?.next_billing_plan_id === plan.id
        ? true
        : false;
    return {
      id: plan.id,
      plan: plan.plan_lookup_key,
      isActive,
      label: selectPlan[plan.plan_lookup_key],
      isDisable,
      features: [getFormattedFeatures(plan, metadataMap)],
      pricing: plan.description,
      popular: plan.attributes && plan.attributes.is_popular === true,
      name: plan.name,
      is_trial: userDetails.is_trial,
      description: plan.description,
      erlyBirdsOffer:
        plan.plan_lookup_key === "insightoai_gold" &&
        process.env.REACT_APP_TRIAL_DURATION
          ? `Early Adopters - ${process.env.REACT_APP_TRIAL_DURATION} free`
          : "",
    };
  });
};

export const fetchCouponPlan = async (userDetails, metadataMap) => {
  try {
    const specialPlanRes = await fetchController(
      `${baseUrl}/api/v1/billing/billing_plan/${userDetails.active_billing_plan_id}`,
      "GET"
    );

    return {
      ...specialPlanRes.data,
      plan: specialPlanRes.data.plan_lookup_key,
      isActive: true,
      pricing: specialPlanRes.data.description,
      label: "Activated",
      features: [getFormattedFeatures(specialPlanRes.data, metadataMap)],
      popular: specialPlanRes.data.plan_lookup_key === "insightoai_platinum",
    };
  } catch (error) {
    console.error("Error fetching special plan:", error);
    return null;
  }
};
