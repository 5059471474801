import {
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import FormSMS from "../FormSMS";
import { useEffect } from "react";

export default function CallToolModal({
  onClose,
  toolUserForm,
}) {
  const baseUrl = getHostDomain();
  const { watch, register, handleSubmit, formState: { errors } ,setError, clearErrors } = toolUserForm;

  const requiredDropdownFields = ["smart_handoff.form_id", "smart_handoff.sub_widget_id"]
  const requiredMsg = {
    "smart_handoff.form_id": "Form is required",
    "smart_handoff.sub_widget_id": "Handoff widget is required",
  };
  const checkRequired = () => {
    requiredDropdownFields.map((reqField) => {
      if(!watch(`credentials.configuration.${reqField}`)) {
        setError(`credentials.configuration.${reqField}`, { type: "required" , message: requiredMsg[`${reqField}`]})
      }
    })
  }

  useEffect(() => {
    requiredDropdownFields.map((reqField) => {
      if (watch(`credentials.configuration.${reqField}`)) {
        clearErrors(`credentials.configuration.${reqField}`);
      }
    });
  }, [
    watch("credentials.configuration.smart_handoff.form_id"),
    watch("credentials.configuration.smart_handoff.sub_widget_id"),
  ]);

  const onSubmit = async (data) => {
    checkRequired()
    if(Object.keys(errors)?.length) return
    try {
      await fetchController(
        baseUrl +
          `/api/v1/tool/${data.tool_user_id}/LinkToolUser`,
        "PUT",
        {
          credentials: data.credentials,
        }
      );
      onClose();
      toast.success("Tool setup done");
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Call Forwarding Number</FormLabel>
            <Input
              {...register("credentials.configuration.call_forward_number")}
            />
          </FormControl>
          <FormSMS toolUserForm={toolUserForm}/>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" type="submit">
          Finish
        </Button>
      </ModalFooter>
    </form>
  );
}
