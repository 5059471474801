import { Flex } from "@chakra-ui/react";
import React from "react";
import { components } from "chakra-react-select";
import { BiUserVoice } from "react-icons/bi";
const { Option } = components;
const IconWidgetOption = (props) => {
  const { Icon = BiUserVoice } = props.data;
  return (
    <Option {...props}>
      <Flex justifyContent={"flex-start"} gap={3} alignItems={"center"}>
        <Icon size={15} />
        {props.data.label}
      </Flex>
    </Option>
  );
};

export default IconWidgetOption;
