import React, { useContext } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from "@chakra-ui/react";
import { IoIosRocket } from "react-icons/io";
import { LuWallet } from "react-icons/lu";
import { FaPlusSquare } from "react-icons/fa";
import Loader from "../../../utils/Loader/Loader";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import ExtrasSection from "./ExtrasSection";
import WalletCoupon from "./WalletCoupon";
import PlansSection from "./PlansSection";
import useBillingPlans from "./BilingHook";

const Billing = ({ activeTab }) => {
  const agency = useContext(AgencyContext);
  const { myDetails } = agency;

  const isSalesBtnisvisible = agency.loading
    ? false
    : myDetails?.agency_id
    ? false
    : true;

  const {
    billingPlans,
    userDetails,
    isLoading,
    coupanBillingPlans,
    setIsLoading,
  } = useBillingPlans(activeTab);
  
  const sections = [
    ...(isSalesBtnisvisible
      ? [
          {
            id: "walletCoupon",
            title: (
              <Flex alignItems="center" gap={2}>
                <LuWallet />
                Your Wallet
              </Flex>
            ),
            component: (
              <WalletCoupon
                isSalesBtnisvisible={isSalesBtnisvisible}
                userDetails={userDetails}
                coupanBillingPlans={coupanBillingPlans}
              />
            ),
          },
        ]
      : []),
    {
      id: "plans",
      title: (
        <Flex alignItems="center" gap={2}>
          <IoIosRocket />
          {isSalesBtnisvisible ? "Boosters" : "Plans"}
        </Flex>
      ),
      component: (
        <PlansSection
          billingPlans={billingPlans}
          isSalesBtnisvisible={isSalesBtnisvisible}
          userDetails={userDetails}
        />
      ),
    },
    {
      id: "extras",
      title: (
        <Flex alignItems="center" gap={2}>
          <FaPlusSquare />
          Extras
        </Flex>
      ),
      component: (
        <ExtrasSection
          userDetails={userDetails}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      ),
    },
  ];

  return (
    <>
      <Box border="1px" h="81vh" overflowY="scroll" p="5" borderRadius="md">
        {isLoading && <Loader />}

        <Accordion
          defaultIndex={sections.map((_, index) => index)}
          allowMultiple
        >
          {sections.map(({ id, title, component }) => (
            <AccordionItem key={id}>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                    {title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>{component}</AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </>
  );
};

export default Billing;
