import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import useLinkAssistantContactSync from "./useLinkAssistantContactSync";

const LinkAssistantContactSync = ({ isOpen, onClose, tool_user_id }) => {
  const {
    status,
    fields,
    linkAssistantStatus,
    onSubmitFieldContactSyncs,
    onChangeDescription,
    onChangeStatus,
  } = useLinkAssistantContactSync({
    tool_user_id,
  });

  const isLoading = [status, linkAssistantStatus].includes("loading");
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      scrollBehavior="inside"
    >
      <ModalOverlay />

      <ModalContent maxW="60vw" height="80vh">
        <ModalHeader
          style={{ fontWeight: 700, fontSize: "18px" }}
          alignContent="center"
        >
          <Text>Select & customize fields to sync</Text>
        </ModalHeader>
        <ModalCloseButton alignItems="center" />
        <ModalBody>
          {isLoading ? (
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Spinner />
            </Flex>
          ) : (
            <Stack spacing={4}>
              <Text>
                Select fields that should be extracted from the Conversation,
                and modify the description to suit your particular business
                needs. It will be used by LLM to extract data and populate your
                CRM using this description. You can also define the format,
                provide options, etc. to make it adhere to your CRM field type.
              </Text>
              <TableContainer>
                <Table size={"sm"} colorScheme="blue">
                  <Thead>
                    <Tr>
                      <Th>Status</Th>
                      <Th>Name</Th>
                      <Th>Description</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.entries(fields).map(
                      ([name, { description, status, isRoot }]) => {
                        return (
                          <Tr>
                            <Td>
                              <Checkbox
                                isDisabled={isRoot}
                                isChecked={status}
                                onChange={onChangeStatus(name)}
                              />
                            </Td>
                            <Td>{name}</Td>
                            <Td>
                              <Input
                                isDisabled={!status || isRoot}
                                name={name}
                                value={description}
                                placeholder="Description"
                                onChange={onChangeDescription(name)}
                              />
                            </Td>
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose} variant="outline" width="104px">
              Close
            </Button>
            <Button
              onClick={onSubmitFieldContactSyncs}
              isLoading={status === "loading"}
              colorScheme="blue"
              width="104px"
            >
              Save
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LinkAssistantContactSync;
