/* eslint-disable no-useless-computed-key */
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { BiLogoTelegram } from "react-icons/bi";
import { FaMixcloud, FaPhoneAlt, FaSms } from "react-icons/fa";
import { FaFacebookMessenger, FaInstagram } from "react-icons/fa6";
import { FiPhoneMissed } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { IoLogoWhatsapp } from "react-icons/io5";
import {
  MdOutlineLeaderboard,
  MdOutlineWebAsset,
  MdOutlineWifiCalling3,
} from "react-icons/md";
import { PiFileHtml } from "react-icons/pi";
import { TbMessageChatbot } from "react-icons/tb";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../utils/Loader/Paginator";
import { getHostDomain, getTagsData, providers } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import Table from "../Tables/Table";
import AddWidgetModal from "./AddWidget/AddWidgetModal";
import WidgetTour from "./WidgetTour";
import { HEADS_DATA, LIVE_WIDGET_TYPES, widgetIcons } from "./constants";
import TagsColumn from "../Tags/TagsColumn";

const Widgets = ({ tab }) => {
  const baseUrl = getHostDomain();
  
 
  const [status, setStatus] = useState("idle");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);

  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;
  const liveWidgetTypes = new Set(LIVE_WIDGET_TYPES);
  const endpoint = `/api/v1/widget/get_my_details?size=${paginationSize}&page=${page}`;

  const agencyLogoUrl = agency?.myDetails?.agency_id
    ? agency?.myDetails?.branding?.logo_url
    : null;
  const makeAgencyLogourl = (logo_url) => {
    if (!logo_url) return;
    return logo_url.includes(window.origin)
      ? logo_url
      : `${window.location.origin}${logo_url}`;
  };
  const logo = agency?.myDetails?.agency_id
    ? makeAgencyLogourl(agencyLogoUrl)
    : "/logo/favicon.png";
  const name = agency?.myDetails?.agency_id
    ? agency?.myDetails?.branding?.agency_name
    : "Insighto.AI";

  const hitFetch = async () => {
    try {
      setStatus("loading");
      const urlHit = baseUrl + endpoint;

      const getData = await fetchController(urlHit, "GET");
      setPages(getData?.data?.pages);

      const updatedData = getData.data.items.map((item) => {
        const provider = providers[item?.widget_type] || {
          label: name,
          icon: <img src={logo} alt="logo" width={25} />,
        };
        const tagsData = getTagsData(item?.tags)

        return {
          type: (
            <Box ml={2}>
              {widgetIcons[item.widget_type] || <TbMessageChatbot />}
            </Box>
          ),
          status: (
            <Tooltip
              label={
                item.status || liveWidgetTypes.has(item.widget_type)
                  ? "Live"
                  : "Add Data Sources"
              }
              aria-label={
                item.status || liveWidgetTypes.has(item.widget_type)
                  ? "Live"
                  : "Add Data Sources"
              }
            >
              <Center ml={4}>
                <Icon
                  as={GoDotFill}
                  color={
                    item.status || liveWidgetTypes.has(item.widget_type)
                      ? "green.500"
                      : "red.500"
                  }
                />
              </Center>
            </Tooltip>
          ),
          assistant_id: item.assistant_id,
          assistant: item.assistant_name,
          data_Source: (
            <div style={{ marginLeft: "35px" }}>
              {item.widget_type === "phone"
                ? "-"
                : item.data_sources_count.toLocaleString()}
            </div>
          ),
          widget_name: item.name,
          name: (
            <Box>
              <Text>{item.name}</Text>
              {item?.provider_user_friendly_label && (
                <Text fontSize={"xs"} fontStyle={"italic"}>
                  {item?.provider_user_friendly_label}
                </Text>
              )}
            </Box>
          ),
          description: item.description,
          widget_type: item.widget_type,
          id: item.id,
          provider: (
            <Tooltip label={provider.label}>
              <Box ml={4}>{provider.icon}</Box>
            </Tooltip>
          ),
          Tags: <TagsColumn tagsData={tagsData} refreshTable={hitFetch} />
        };
      });
      const columns = HEADS_DATA.map((key) => ({
        Header: key,
        accessor: key,
        Filter: ({ column }) => <input {...column.filterProps} />,
      }));
      setTableData(updatedData);
      setHeads(columns);
      setStatus("success");
    } catch (error) {
      setStatus("failure");
    }
  };
  useEffect(() => {
    if (tab === "Widgets") {
      hitFetch();
    }
  }, [tab, page, paginationSize]);

  const [widgetTourVisibility, setWidgetTourVisibility] = useState(
    localStorage.getItem("widgetTourVisibility") ? false : true
  );
  const onCloseWidgetTour = () => {
    localStorage.setItem("widgetTourVisibility", false);
    setWidgetTourVisibility(false);
  };

  const onGotoFirstPage = () => setPage(1);

  return (
    <>
      <Box
        border="1px"
        p="5"
        height={"81vh"}
        overflowY="scroll"
        borderRadius="md"
      >
        <Heading color={textColor} fontSize="xl">
          Widgets
        </Heading>
        {widgetTourVisibility ? (
          <WidgetTour onCloseWidgetTour={onCloseWidgetTour} />
        ) : null}
        <Flex justifyContent="flex-end" alignItems="center" mb="4">
          <Button
            colorScheme={buttonColorScheme}
            onClick={() => {
              onOpen();
            }}
          >
            Add a Widget
          </Button>
        </Flex>
        {status === "loading" ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              fetch={hitFetch}
              columns={heads}
              data={tableData}
              table="widget"
            />

            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              marginBlock={2}
              gap={2}
            >
              <PaginationSizeSelect
                paginationSize={paginationSize}
                setPaginationSize={setPaginationSize}
                tableLength={tableData.length}
              />
              <Paginator
                colorScheme={buttonColorScheme}
                onChangePage={setPage}
                page={page}
                pages={pages}
              />
            </Flex>
          </Box>
        )}
      </Box>
      {/* <AddWidget
          fetchAssistants={hitFetch}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          onGotoFirstPage={onGotoFirstPage}
        /> */}
      {isOpen && (
        <AddWidgetModal
          isOpen={isOpen}
          onClose={onClose}
          fetchWidgets={hitFetch}
          onGotoFirstPage={onGotoFirstPage}
        />
      )}
    </>
  );
};

export default Widgets;
