import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoInformationCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import {
  agencyBillingPlanGroupENUM,
  getHostDomain,
} from "../../../utils/utils";
import AddCustomBillingPlan from "../../Modals/AddCustomBillingPlan";
import { AgencyContext } from "../../Settings/AgencyProvider/AgencyProvider";
import AgencyBillingPlanTable from "../AgencyBillingPlanTable";
import RebillingMethod from "../RebillingMethod";

export default function RebillingPlan() {
  const baseUrl = getHostDomain();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, agencyData, getAgencies } = agency;
  const agency_id = agencyData?.id;
  const [agencyBillingMethod, setAgencyBillingMethod] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [stripeKeyStatus, setStripeKeyStatus] = useState("valid");
  const [webhokkBtnErrorMsg, setWebhokkBtnErrorMsg] = useState("");
  const fetchAgencyBillingPlanList = async () => {
    try {
      setIsLoading(true);
      const url =
        baseUrl + `/api/v1/agency/billing/${agency_id}/billing_plan/list`;

      const response = await fetchController(url, "GET");
      const arrangedTableData = response.data?.items.map((item) => {
        return {
          ...item,
          ["Billing Plan Name"]: item.name,
          ["Stripe Lookup Key"]: item.plan_lookup_key,
          ["Assistant"]: item.premium_support ? "Yes" : "No",
          ["Queries"]: item.queries_count,
          ["Seconds"]: item.voice_seconds,
          ["Users"]: item.user_count,
          id: item.id,
          billing_plan_group: item.billing_plan_group,
        };
      });
      const heads = [
        "Billing Plan Name",
        "Stripe Lookup Key",
        "Assistant",
        "Queries",
        "Seconds",
        "Users",
      ];
      const columns = heads.map((key) => ({
        Header: key,
        accessor: key,
        Filter: ({ column }) => <input {...column.filterProps} />,
      }));
      setTableData(arrangedTableData);
      setHeads(columns);
    } catch (error) {
      console.log("Some went wrong while fetching billing plan list");
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAgencyBillingPlanList();
  }, []);

  const tabs = Object.keys(agencyBillingPlanGroupENUM).map((key) => {
    return {
      value: agencyBillingPlanGroupENUM[key],
      children: (
        <AgencyBillingPlanTable
          tab={key}
          activeTab={activeTabIndex}
          tableData={tableData.filter((val) => val.billing_plan_group === key)}
          columns={heads}
          fetch={fetchAgencyBillingPlanList}
          loading={loading}
          tabName={agencyBillingPlanGroupENUM[key]}
          tableName={`agencyBillingPlan_${key}`}
        />
      ),
    };
  });
  const handleClickOnsetWebHook = async () => {
    try {
      const url = baseUrl;
      const res = await fetchController(
        `${url}/api/v1/agency/billing/${agencyData?.id}/setup_stripe_webhook`,
        "GET"
      );
      checkStripeDetails();
      toast.success("Webhook set up Successfully.");
    } catch (error) {
      toast.error("Something went wrong... !");
      console.log({ error });
    }
  };

  const onSubmit = async (data) => {
    try {
      const body = {
        billing_plan: {
          ...agencyData?.billing_plan,
          stripe_api_key: data.stripeKey,
          deduct_agency_wallet: data.deductAgencyWallet,
        },
      };
      setIsLoading(true);
      const endpoint = `/api/v1/agency/${agencyData?.id}`;
      const url = baseUrl + endpoint;

      const agencyRes = await fetchController(url, "PUT", body);
      toast.success("Updated");
      getAgencies();
    } catch (error) {
      toast.error("Oops! Something went wrong !");
      console.log("Getting error while creating Agency:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const checkStripeDetails = async () => {
    try {
      setStripeKeyStatus("loading");
      const endpoint = `/api/v1/agency/billing/${agencyData?.id}/check_stripe_webhook`;
      const url = baseUrl + endpoint;

      await fetchController(url, "GET");
      setStripeKeyStatus("valid");
    } catch (error) {
      setStripeKeyStatus("invalid");
      setWebhokkBtnErrorMsg(error.message);
    }
  };

  useEffect(() => {
    setAgencyBillingMethod(
      agencyData?.billing_plan?.billing_method || "stripe"
    );
    setValue("stripeKey", agencyData?.billing_plan?.stripe_api_key || "");
    setValue(
      "deductAgencyWallet",
      agencyData?.billing_plan?.deduct_agency_wallet
    );
  }, [agencyData]);

  useEffect(() => {
    checkStripeDetails();
  }, []);

  const webhokkBtnColorScheme = stripeKeyStatus === "valid" ? "green" : "red";
  const isStripeKeyInvalid =
    agencyBillingMethod === "stripe" ? stripeKeyStatus === "invalid" : false;

  return (
    <>
      <Box border="1px" p="5" borderRadius="md" h="81vh" overflowY="auto">
      <RebillingMethod
          agencyData={agencyData}
          buttonColorScheme={buttonColorScheme}
          agencyBillingMethod={agencyBillingMethod}
          textColor={textColor}
          setAgencyBillingMethod={setAgencyBillingMethod}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card variant={"elevated"} my={4}>
            <CardBody>
              <Flex
                justifyItems="flex-start"
                alignItems="center"
                gap={2}
                py={2}
              >
                <Checkbox
                  {...register("deductAgencyWallet")}
                  isChecked={watch("deductAgencyWallet")}
                  alignItems="flex-start"
                  onChange={(e) => {
                    onSubmit({
                      stripeKey: agencyData?.billing_plan?.stripe_api_key,
                      deductAgencyWallet: e.target.checked,
                    });
                  }}
                >
                  Enable automatic wallet deductions to enable services for my
                  subaccounts
                </Checkbox>
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      icon={<IoInformationCircleOutline />}
                      variant="ghost"
                      size="sm"
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody p={4}>
                      <Text>
                        Your subaccounts can use your account keys from
                        providers like OpenAI, ElevenLabs, etc., or they can
                        rely on our platform’s accounts. Check this box if you
                        want your subaccounts to use platform keys & deduct any
                        usage from your wallet. You don’t need to configure or
                        manage your account with any provider if enabled.
                        Rather, your Wallet will be charged as per the
                        <a
                          href="https://insightoai.freshdesk.com/support/solutions/articles/1070000091215-pay-as-you-go-wallet"
                          target="_blank"
                          style={{ color: textColor }}
                        >
                          {" pricing here "}
                        </a>
                        for usage from every subaccount.
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>
              <Flex direction="column" gap={2}>
                <Flex gap={2} width={"100%"}>
                  {agencyBillingMethod === "stripe" && (
                    <Box flex={1}>
                      <Input
                        type={
                          agencyData?.billing_plan?.stripe_api_key
                            ? "password"
                            : "text"
                        }
                        variant="outline"
                        placeholder="Enter Stripe Key"
                        {...register("stripeKey", { required: true })}
                      />
                      {errors.stripeKey && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "11px",
                            padding: "0px 10px",
                          }}
                        >
                          Stripe Key is required
                        </p>
                      )}
                    </Box>
                  )}
                  <Flex gap={2} flex={1} justifyContent={"space-between"}>
                    {agencyBillingMethod === "custom" ? null : (
                      <Button type="submit" colorScheme={buttonColorScheme}>
                        Submit
                      </Button>
                    )}
                    {agencyBillingMethod === "stripe" && (
                      <Tooltip
                        label={webhokkBtnErrorMsg ? webhokkBtnErrorMsg : ""}
                        aria-label={webhokkBtnErrorMsg}
                      >
                        <Button
                          isDisabled={!agencyData?.billing_plan?.stripe_api_key}
                          colorScheme={
                            webhokkBtnColorScheme || buttonColorScheme
                          }
                          onClick={handleClickOnsetWebHook}
                        >
                          Set Webhook
                        </Button>
                      </Tooltip>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </form>
       
        <Flex
          justifyContent="space-between"
          gap={5}
          alignItems="center"
          my="4"
          pr={5}
        >
          <Alert status="info" width={"auto"}>
            <AlertIcon />
            {
              "The first billing plan created by you will be the default billing plan when any user signs up. It must be a free plan."
            }
          </Alert>
          <Button
            isDisabled={isStripeKeyInvalid}
            colorScheme={buttonColorScheme}
            onClick={() => onOpen()}
          >
            Add
          </Button>
        </Flex>
        <Tabs
          isLazy
          index={activeTabIndex}
          onChange={(index) => setActiveTabIndex(index)}
        >
          <TabList>
            {tabs.map((tab, listIndex) => (
              <Tab
                key={listIndex}
                color={textColor}
                isDisabled={!agencyData?.billing_plan?.stripe_api_key}
              >
                {tab.value}
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map((tab, panelIndex) => (
              <TabPanel key={panelIndex}>
                <Box mt={4}>{tab.children}</Box>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      {isOpen && (
        <AddCustomBillingPlan
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          tableData={tableData}
          fetchAgencyBillingPlanList={fetchAgencyBillingPlanList}
          agencyBillingMethod={agencyBillingMethod}
        />
      )}
    </>
  );
}
