import { useEffect, useState } from "react";
import { Select } from "chakra-react-select"
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
export default function FormSelect({ onChange, selectedForm }) {
  const baseUrl = getHostDomain();
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false)

  const getFormList = async () => {
    try {
      setLoading(true)
      const response = await fetchController(
        baseUrl + `/api/v1/form/list`
      );
      setForms(response.data.items);
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getFormList();
  }, []);

  const options = forms.map((form) => ({
    value: form,
    label: form.name,
  }));

  const selectedFormId = typeof selectedForm === "string" ? selectedForm : selectedForm?.id
  const formCurrentlySelectedValue = options.find(
    (form) => form?.value.id === selectedFormId
  );

  return (
    <Select
      isClearable
      options={options}
      onChange={onChange}
      value={formCurrentlySelectedValue}
      isLoading={loading}
    />
  );
}
