import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { PiCalendarDots } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import AddContact from "./AddContact";
import AddEditCampaign from "./AddEditCampaign";
import CampaignTable from "./CampaignTable";
import NoDataPage from "./NoDataPage";
import useCampaign from "./useCampaign";

const Campaigns = () => {
  const {
    status,
    page,
    pages,
    paginationSize,
    selectedRow,
    textColor,
    buttonColorScheme,
    isAddEditCampaignOpen,
    selectedCampaigns,
    tableData,
    existingTotalCampaigns,
    setTableData,
    setPage,
    setSelectedCampaigns,
    setPaginationSize,
    setSelectedRow,
    onAddEditCampaignOpen,
    onAddEditCampaignClose,
    getAllCampaign,
    updateCampaignById,
    getCampaignById,
    handleMultipleDelete,
  } = useCampaign();

  const navigate = useNavigate();

  const { state, loading } = useContext(SettingContext);

  const allowedCampaignsToCreate = state?.credits?.campaign_count || 0;
  const isCampaignCreationAllowed =
    (state?.credits?.campaign_count || 0) <= existingTotalCampaigns;

  const {
    isOpen: isAddContactOpen,
    onOpen: onAddContactOpen,
    onClose: onAddContactClose,
  } = useDisclosure();
  const [operation, setOperation] = useState(null);

  const handleClose = async () => {
    onAddContactClose();
  };

  return (
    <Layout>
      <Box
        border="1px"
        borderColor={"black"}
        borderRadius={"md"}
        h="90svh"
        p={5}
        overflowY={"auto"}
      >
        {status === "pending" ? (
          <Loader />
        ) : state?.credits?.campaign_count ? (
          <>
            <Flex justifyContent={"space-between"} mb="2">
              <Box>
                <Heading color={textColor} fontSize="xl">
                  Campaigns
                </Heading>
              </Box>
              <Flex gap={2}>
                <IconButton
                  icon={<RiDeleteBin6Line />}
                  bg="#FEF2F2"
                  variant="ghost"
                  color="#EF4444"
                  _hover={{ bg: "#FEF2F2" }}
                  isDisabled={selectedCampaigns.length !== 1}
                  onClick={handleMultipleDelete}
                />
                <Tooltip
                  label={
                    isCampaignCreationAllowed ? (
                      <Text maxWidth="250px">
                        You have reached the maximum number of Campaigns. Please
                        reach out to our team to increase the limit or delete
                        any of the existing campaign(s).
                      </Text>
                    ) : (
                      ""
                    )
                  }
                >
                  <Button
                    leftIcon={<FaPlus />}
                    colorScheme="blue"
                    onClick={onAddEditCampaignOpen}
                    isDisabled={isCampaignCreationAllowed}
                  >
                    Create Campaign
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
            {tableData.length ? (
              <>
                <CampaignTable
                  onAddEditCampaignOpen={onAddEditCampaignOpen}
                  paginationSize={paginationSize}
                  setPaginationSize={setPaginationSize}
                  tableData={tableData}
                  setTableData={setTableData}
                  buttonColorScheme={buttonColorScheme}
                  setPage={setPage}
                  page={page}
                  pages={pages}
                  setSelectedCampaigns={setSelectedCampaigns}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  updateCampaignById={updateCampaignById}
                  getAllCampaign={getAllCampaign}
                  getCampaignById={getCampaignById}
                  isAddContactOpen={isAddContactOpen}
                  onAddContactOpen={onAddContactOpen}
                  onAddContactClose={onAddContactClose}
                />
                <Text
                  bg="#EFF6FF"
                  padding={2}
                  borderRadius="8px"
                  width="112px"
                  justifySelf="flex-end"
                  textAlign="center"
                >
                  {existingTotalCampaigns} of {allowedCampaignsToCreate} used
                </Text>
              </>
            ) : (
              <NoDataPage
                icon={<PiCalendarDots color="#3B81F6" />}
                title="Campaign"
                description="Click on the button to create the new campaign"
                buttonIcon={<FaPlus />}
                colorScheme="blue"
                onClick={onAddEditCampaignOpen}
                isDisabled={isCampaignCreationAllowed}
                buttonTtile="Create Campaign"
              />
            )}
            {isAddEditCampaignOpen && (
              <AddEditCampaign
                isAddEditCampaignOpen={isAddEditCampaignOpen}
                onAddEditCampaignClose={onAddEditCampaignClose}
                setSelectedRow={setSelectedRow}
                selectedRow={selectedRow}
                getAllCampaign={getAllCampaign}
                getCampaignById={getCampaignById}
                setTableData={setTableData}
                onAddContactOpen={onAddContactOpen}
              />
            )}
            {isAddContactOpen && (
              <AddContact
                operation={"adding"}
                selectedRow={selectedRow}
                setOperation={setOperation}
                isAddContactOpen={isAddContactOpen}
                onAddContactClose={handleClose}
                buttonColorScheme={buttonColorScheme}
                getCampaignById={getCampaignById}
                setTableData={setTableData}
                setSelectedRow={setSelectedRow}
                getAllCampaign={getAllCampaign}
              />
            )}
          </>
        ) : (
          <Box
            border="1px"
            borderColor={"black"}
            borderRadius={"md"}
            h="90svh"
            p={5}
            overflowY={"auto"}
          >
            <Alert status="info">
              <Flex gap={2} alignItems="center">
                <AlertIcon />
                <Text>Upgrade your plan to start running Campaigns.</Text>
                <Button
                  colorScheme="blue"
                  onClick={() => navigate("/settings/billing")}
                >
                  Upgrade Plan
                </Button>
              </Flex>
            </Alert>
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default Campaigns;
