import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { useForm } from "react-hook-form";
import { useReactMediaRecorder } from "react-media-recorder";
import { toast } from "react-toastify";
import { showToast } from "../../utils/Toast/Toast";
import { getHostDomain } from "../../utils/utils";
import VoiceRecorder from "../Settings/VoiceRecorder/VoiceRecorder";
const AddMyVoice = ({
  isAddMyVoiceModalOpen,
  onAddMyVoiceModalClose,
  loadOwnVoice,
  isUpdatingVoice = false,
  buttonColorScheme,
}) => {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);
  const [audioDuration, setAudioDuration] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } =
    useReactMediaRecorder({ audio: true });
  const onSubmit = async (data) => {
    stopRecording();
    try {
      if (mediaBlobUrl) {
        if (audioDuration < 20 || audioDuration === null) {
          alert("Recording should be at least 20 seconds.");
          return;
        } else {
          setIsLoading(true);
          const audioBlob = await fetch(mediaBlobUrl).then((res) => res.blob());
          const audioFile = new File([audioBlob], "audio.wav", {
            type: audioBlob.type,
          });

          const apiFormData = new FormData();
          apiFormData.append("custom_voice_file", audioFile);
          // const timestamp = "2024-02-22T11:42:55.449Z";  //default
          axios
            .post(
              baseUrl +
                `/api/v1/voice/custom_voice?name=${data.name}&description=${data.description}`,
              apiFormData,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              loadOwnVoice();
              setIsLoading(false);
              showToast(
                isUpdatingVoice
                  ? "Your voice updated successfully"
                  : "Your voice created  successfully",
                "success"
              );
              onAddMyVoiceModalClose();
            })
            .catch((error) => {
              setIsLoading(false);
              showToast("Something went wrong. Please try again.", "error");
            });
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    } finally {
      // setIsLoading( false);
    }
  };

  let timer;
  const handleClickOnStartRecording = () => {
    timer = setTimeout(() => {
      setAudioDuration(20);
    }, 20000);
    startRecording();
  };
  const handleClickOnStopRecording = () => {
    clearTimeout(timer);
    stopRecording();
  };
  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);
  return (
    <>
      <Modal
        size="xl"
        isOpen={isAddMyVoiceModalOpen}
        onClose={() => {
          onAddMyVoiceModalClose();
          setValue("name", null);
          setValue("description", null);
        }}
      >
        <ModalOverlay />
        <ModalContent height="auto" width="95%" maxWidth="600px">
          <ModalHeader>Add my voice</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* First line */}
              <Stack direction="column" mb="4">
                <Box flex="1">
                  <Input
                    placeholder="Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Name is required
                    </p>
                  )}
                </Box>
                <Textarea
                  placeholder="only for your reference, not used as part of the prompt"
                  {...register("description", { required: true })}
                  resize="vertical"
                />
                {errors.description && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    Description is required
                  </p>
                )}
              </Stack>
              <Box>
                <VoiceRecorder
                  status={status}
                  // startRecording={startRecording}
                  // stopRecording={stopRecording}
                  mediaBlobUrl={mediaBlobUrl}
                  previewStream={previewStream}
                  handleClickOnStartRecording={handleClickOnStartRecording}
                  handleClickOnStopRecording={handleClickOnStopRecording}
                />
              </Box>

              {/*  */}

              <Button
                isLoading={isLoading}
                width="80px"
                colorScheme={buttonColorScheme}
                type="submit"
                mt="4"
              >
                {isLoading ? <Spinner size="sm" /> : "Submit"}
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddMyVoice;
