import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import FunctionFieldMapper from "./FunctionFieldMapper";
import ToolSelect from "./ToolSelect";
import ToolUserSelect from "./ToolUserSelect";
export default function TriggerToolsModal({
  isOpen,
  onClose,
  form,
  fetchTools,
}) {
  const baseUrl = getHostDomain();
  const defaulToolForm = {
    trigger_tools: [],
    tool_id: "",
  };
  const { watch, setValue, handleSubmit, formState, reset } =
    useForm(defaulToolForm);
  const trigger_tools = watch("trigger_tools") || [];
  const [toolFunctionsUserMap, setToolFunctionsMap] = useState({});
  const [toolUsers, setToolUsers] = useState([]);
  useEffect(() => {
    reset({
      trigger_tools: form.trigger_tools,
      tool_id:
        (form?.trigger_tools || []).find(
          (trigger_tool) => "tool_id" in trigger_tool
        )?.tool_id || "",
    });
  }, [form]);
  useEffect(() => {
    (async () => {
      if (watch("tool_id")) {
        const toolUsersEndpoint = `/api/v1/tool/${watch(
          "tool_id"
        )}/LinkToolUser`;
        const response = await fetchController(baseUrl + toolUsersEndpoint);
        setToolUsers(response.data.items);
      }
    })();
  }, [watch("tool_id"), form]);
  useEffect(() => {
    (async () => {
      const response = await fetchController(
        baseUrl + "/api/v1/tool/LinkToolUser/bulk_list_functions",
        "POST",
        trigger_tools.map((trigger_tool) => trigger_tool.tool_user_id)
      );
      setToolFunctionsMap(
        response.data.reduce(
          (newToolUserIdToolFunctionMap, toolUserFunction) => {
            newToolUserIdToolFunctionMap[toolUserFunction.tool_user_id] =
              toolUserFunction.tool_functions;
            return newToolUserIdToolFunctionMap;
          },
          {}
        )
      );
    })();
  }, [trigger_tools.length]);
  const onSubmit = async () => {
    try {
      await fetchController(baseUrl + `/api/v1/form/${form.id}`, "PUT", {
        trigger_tools: trigger_tools.map((trigger_tool) => ({
          tool_id: watch("tool_id"),
          ...trigger_tool,
        })),
      });
      fetchTools();
      toast.success("Tool linked");
      onClose();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;
  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>Trigger Tools on Form Submission</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Tool</FormLabel>
                <ToolSelect
                  isClearable={true}
                  setValue={setValue}
                  form={form}
                  toolId={watch("tool_id")}
                  onChange={(option) => {
                    setValue("tool_id", option ? option.value.id : null);
                    setValue("trigger_tools", []);
                  }}
                />
              </FormControl>
              {watch("tool_id") ? (
                <>
                  <FormControl isRequired>
                    <FormLabel> Tool Configuration</FormLabel>
                    <ToolUserSelect
                      toolUsers={toolUsers}
                      setValue={setValue}
                      trigger_tools={trigger_tools}
                    />
                  </FormControl>
                  <FunctionFieldMapper
                    fields={form.formFields}
                    toolUsers={toolUsers}
                    toolUserFunctionsMap={toolFunctionsUserMap}
                    triggerTools={trigger_tools}
                    setValue={setValue}
                  />
                </>
              ) : null}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              loadingText={"Submitting"}
              isLoading={formState.isSubmitting}
              type="submit"
              colorScheme={buttonColorScheme}
              mr={3}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
