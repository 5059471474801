import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import CustomSelect from "../../utils/CustomSelect/CustomSelect";
import { options } from "../../utils/Data/data";
import { fetchController } from "../../utils/FetchController/fetchController";
import { fileUploadFetch } from "../../utils/FetchController/fileUplaodFetch";
import { showToast } from "../../utils/Toast/Toast";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const DataSourcesModal = ({ isOpen, onOpen, onClose, selectedItem, fetch }) => {
  const baseUrl = getHostDomain();
  const [selectedOption, setSelectedOption] = useState(null);
  const finalRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [textBlob, setTextBlob] = useState("");

  const [textImageField, setTextImageField] = useState({
    imageUrl: "",
    text: "",
  });
  const [isValidate, setIsValidate] = useState(false);

  const handleChange = (file) => {
    setFiles([...files, ...file]);
  };

  const handleRemove = (indexToRemove) => {
    const updatedFiles = files.filter((_, index) => index !== indexToRemove);
    setFiles(updatedFiles);
  };
  useEffect(() => {
    if (!detailsVisible) {
      setName("");
      setDescription("");
    }
  }, [detailsVisible]);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    setIsValidate(true);
    if (
      selectedOption.value !== "text_blob" &&
      selectedOption.value !== "text_image" &&
      files.length === 0
    ) {
      toast.error("Add files");
      setIsLoading(false);

      return;
    }
    if (selectedOption.value === "text_blob") {
      if (textBlob === "") {
        toast.error("Empty Text Blob");
        setIsLoading(false);
        return;
      }
    }
    const endPoint = `/api/v1/datasource/${selectedItem?.id}/file?ds_type=${
      selectedItem?.ds_type
    }&name=${name ? name : ""}&description=${description ? description : ""}`;
    const urlHit = baseUrl + endPoint;
    const responses = [];
    try {
      if (selectedOption.value === "image" || selectedOption.value === "pdf") {
        for (const file of files) {
          const formData = new FormData();
          formData.append("datasourcefile_file", file);

          const response = await fileUploadFetch(formData, urlHit);
          responses.push(response);
        }
        if (responses.some((response) => response.error)) {
          throw new Error("Error uploading ");
        }
        setFiles([]);
        showToast("Files Added", "success");
        fetch();
        setName("");
        setDescription("");
        return;
      }

      if (selectedOption.value === "text_blob") {
        await fetchController(
          baseUrl +
            `/api/v1/datasource/${selectedItem.id}/text_blob?datasource_id=${selectedItem.id}&ds_type=${selectedItem.ds_type}&name=${selectedItem.name}&description=${selectedItem.description}`,
          "POST",
          { text_blob: textBlob }
        );
        showToast("Blob Added", "success");
        setTextBlob("");
        fetch();
      }

      if (selectedOption.value === "text_image") {
        await fetchController(
          baseUrl +
            `/api/v1/datasource/${selectedItem.id}/text_blob?datasource_id=${
              selectedItem.id
            }&ds_type=${"text_image"}&name=${selectedItem.name}&description=${
              selectedItem.description
            }`,
          "POST",
          { text_blob: textImageField.text, images: [textImageField.imageUrl] }
        );
        showToast("Blob Added", "success");
        setTextBlob("");
        fetch();
        setIsValidate(false);
        setTextImageField({ text: "", imageUrl: "" });
      }
    } catch (error) {
      toast.error("Oops! One more file not uploaded.");
    } finally {
      setIsLoading(false);
    }
  };
  const [fileTypes, setFileTypes] = useState([]);
  useEffect(() => {
    // Update fileTypes based on dsType
    if (selectedOption != null) {
      if (selectedOption.value === "pdf") {
        setFileTypes(["PDF"]);
      } else if (selectedOption.value === "image") {
        setFileTypes(["JPG", "PNG", "JPEG"]);
      }
    }
  }, [selectedOption]);

  const handleModalClose = () => {
    onClose();
    setSelectedOption(null);
    setIsValidate(false);
    setTextImageField({ text: "", imageUrl: "" });
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;
  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleModalClose}
      >
        <ModalOverlay />
        <ModalContent height="auto" width="95%" maxWidth="600px">
          {isLoading && (
            <Center
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              background="rgba(255, 255, 255, 0.8)"
              zIndex="999"
            >
              <Spinner />
            </Center>
          )}
          <ModalHeader>Add a Knowledge Source</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <CustomSelect
                value={selectedOption?.value ? selectedOption?.value : ""}
                options={options}
                onChange={(val) => setSelectedOption(val)}
              />
              {(selectedOption?.value === "pdf" ||
                selectedOption?.value === "image") && (
                <Flex
                  align="center"
                  justify="center"
                  direction="column"
                  height="30vh"
                >
                  <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    multiple={true}
                  />
                  <div
                    className="source-modal-scroll"
                    style={{
                      maxHeight: "12vh",
                      overflowY: "auto",
                      width: "300px",
                      marginTop: "10px",
                    }}
                  >
                    {files.length > 0 && (
                      <Text color="green.500" fontSize="sm">
                        Uploaded Files:
                      </Text>
                    )}
                    {files.map((file, index) => (
                      <Flex
                        key={index}
                        align="center"
                        justifyContent="space-between"
                        width="90%"
                      >
                        <Text fontWeight="500" fontSize="sm">
                          {file.name.length > 22
                            ? `${file.name.slice(0, 22)}...`
                            : file.name}
                        </Text>
                        <IconButton
                          icon={<CloseIcon />}
                          colorScheme="red"
                          size="xsm"
                          style={{
                            height: "13px",
                            width: "13px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => handleRemove(index)}
                        />
                      </Flex>
                    ))}
                  </div>
                  {files.length > 0 && (
                    <Button
                      onClick={() => setDetailsVisible(!detailsVisible)}
                      colorScheme={buttonColorScheme}
                      mt="3"
                      size="sm"
                    >
                      {detailsVisible ? "No Details" : "Add details"}
                    </Button>
                  )}
                  {detailsVisible && files.length > 0 && (
                    <Stack spacing={3} mt="3">
                      <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="outline"
                        placeholder="Name"
                      />
                      <Textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Add Description"
                      />
                    </Stack>
                  )}
                </Flex>
              )}

              {selectedOption?.value === "text_blob" && (
                <Flex
                  align="center"
                  justify="center"
                  direction="column"
                  height="10vh"
                >
                  <Textarea
                    value={textBlob}
                    onChange={(e) => setTextBlob(e.target.value)}
                    placeholder="Enter text"
                    isRequired
                  />
                </Flex>
              )}

              {selectedOption?.value === "text_image" && (
                <Flex direction="column" gap={4}>
                  <FormControl
                    isRequired
                    isInvalid={isValidate && textImageField.imageUrl === ""}
                  >
                    <FormLabel fontSize={"small"}>{"Image URL"}</FormLabel>
                    <InputGroup>
                      <InputLeftAddon>{"https://"}</InputLeftAddon>
                      <Input
                        value={textImageField.imageUrl}
                        onChange={(e) =>
                          setTextImageField((prevState) => ({
                            ...prevState,
                            imageUrl: e.target.value,
                          }))
                        }
                        isRequired
                        variant="outline"
                        placeholder="example.com"
                      />
                    </InputGroup>
                    {isValidate && textImageField.imageUrl === "" && (
                      <FormErrorMessage>
                        {"Image URL is required"}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl
                    isRequired
                    isInvalid={isValidate && textImageField.text === ""}
                  >
                    <FormLabel fontSize={"small"}>
                      {"Text description"}
                    </FormLabel>
                    <Textarea
                      value={textImageField.text}
                      onChange={(e) =>
                        setTextImageField((prevState) => ({
                          ...prevState,
                          text: e.target.value,
                        }))
                      }
                      placeholder="Add Description"
                    />
                    {isValidate && textImageField.text === "" && (
                      <FormErrorMessage>
                        {"Text Description is required"}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={!selectedOption?.value}
              colorScheme={buttonColorScheme}
              mr={3}
              onClick={onSubmit}
            >
              Submit
            </Button>
            <Button
              colorScheme={buttonColorScheme}
              mr={3}
              onClick={handleModalClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DataSourcesModal;
