import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const ResetSubAccPassword = ({ isOpen, onOpen, onClose, accToBeReset }) => {
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;
  const [status, setStatus] = useState("idle");
  const [show, setShow] = useState(false);
  const defaultSubAcc = {
    email: accToBeReset?.email,
    new_password: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: defaultSubAcc,
  });

  const onSubmit = async (data) => {
    try {
      setStatus("loading");
      const endpoint = `/api/v1/login/subaccount_reset_password`;
      const url = baseUrl + endpoint;
      const method = "POST";
      const response = await fetchController(url, method, data);
      toast.success(response?.message || "Password reset successfully.");
      reset();
      onClose();
      setStatus("success");
    } catch (error) {
      console.error(error);
      toast.error("Some error occurred");
      setStatus("failed");
    }
  };
  const handleClose = () => {
    onClose();
    reset();
  };
  const generateRandomPassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";
    const passwordLength = 8;
    let randomPassword = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomPassword += characters[randomIndex];
    }
    setValue("new_password", randomPassword);
  };
  useEffect(() => {
    setValue("email", accToBeReset?.email || "");
  }, []);
  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={() => {
          handleClose();
        }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent height="auto" width="95%" maxWidth="600px">
          <ModalHeader>Reset password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box flex="1" mb="4">
                <Input
                  type="email"
                  placeholder="Email"
                  {...register("email", { required: "Email is required." })}
                  disabled={true}
                />
                {errors.email && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    {errors?.email?.message}
                  </p>
                )}
              </Box>
              <Box flex="1">
                <InputGroup size="md">
                  <Input
                    type={show ? "text" : "password"}
                    placeholder="Click to generate password"
                    onClick={generateRandomPassword}
                    {...register("new_password", {
                      required: "Password is required.",
                    })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShow(!show)}
                    >
                      {show ? <IoEyeOffOutline /> : <IoEyeOutline />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.new_password && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    Password is required
                  </p>
                )}
              </Box>
              <Button
                isLoading={status === "loading"}
                colorScheme={buttonColorScheme}
                type="submit"
                mt="4"
              >
                Submit
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ResetSubAccPassword;
