import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IoInformationCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";

export default function RebillingMethod({
  textColor,
  agencyData,
  buttonColorScheme,
  agencyBillingMethod,
  setAgencyBillingMethod,
}) {
  const baseUrl = getHostDomain();

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      billing_method: "",
    },
  });

  useEffect(() => {
    setValue("billing_method", agencyBillingMethod);
  }, [agencyBillingMethod]);

  const selectedOption = {
    stripe: { label: "Stripe", value: "stripe" },
    custom: { label: "Custom", value: "custom" },
  };

  const methodOptions = [
    { label: "Stripe", value: "stripe" },
    { label: "Custom", value: "custom" },
  ];

  const onSubmit = async (data) => {
    try {
      const payload = {
        billing_plan: {
          ...agencyData?.billing_plan,
          billing_method: data?.billing_method,
        },
      };
      const response = await fetchController(
        `${baseUrl}/api/v1/agency/${agencyData?.id}`,
        "PUT",
        payload
      );
      toast.success(response?.message || "Billing method updated successfully");
      setAgencyBillingMethod(data?.billing_method);
    } catch (error) {
      console.error(error);
      toast.error(error?.message || "Something went wrong");
    }
  };

  return (
    <Card variant={"elevated"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
          <Stack spacing={2}>
            <Flex gap={2} alignItems={"flex-end"}>
              <Box flex={1}>
                <FormControl>
                  <FormLabel>
                    Select Billing Method{" "}
                    <Popover>
                      <PopoverTrigger>
                        <IconButton
                          icon={<IoInformationCircleOutline />}
                          variant="ghost"
                          size="sm"
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody p={4}>
                          <Text>
                            Once Custom is enabled for your Agency, you will not
                            be able to revert back to Stripe.
                            <a
                              href="https://insightoai.freshdesk.com/support/solutions/articles/1070000116637-billing-methods-for-agency"
                              target="_blank"
                              style={{ color: textColor }}
                            >
                              {" More info"}
                            </a>
                          </Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </FormLabel>
                  <Select
                    options={methodOptions}
                    value={selectedOption[watch("billing_method")]}
                    onChange={({ value }) => {
                      setValue("billing_method", value);
                    }}
                    isDisabled={agencyBillingMethod === "custom"}
                  />
                </FormControl>
              </Box>
              <Box flex={1}>
                <Button
                  type="submit"
                  colorScheme={buttonColorScheme}
                  isLoading={isSubmitting}
                  isDisabled={agencyBillingMethod === "custom"}
                >
                  Submit
                </Button>
              </Box>
            </Flex>
          </Stack>
        </CardBody>
      </form>
    </Card>
  );
}
