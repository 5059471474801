import {
  Grid,
  Input,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderTrack,
} from "@chakra-ui/react";
import React from "react";
import FormLabelWithInfoTooltip from "../components/Widgets/FormLabelWithInfoTooltip";

const CommonSlider = (props) => {
  const {
    setValue,
    label,
    min,
    max,
    defaultValue,
    name,
    step,
    toolTipLabel,
    watch,
    isInputDisabled = true,
    width = "auto",
    markLabel,
    handleSliderInputChange,
    ml = "0",
  } = props;

  return (
    <div>
      <FormLabelWithInfoTooltip label={label} tip={toolTipLabel} />
      <div style={{ display: "flex" }}>
        <Slider
          min={min}
          max={max}
          defaultValue={defaultValue}
          name={name}
          onChange={(val) => {
            setValue(name, Number(val));
          }}
          value={watch(name)}
          mb="4"
          step={step}
        >
          <Grid>
            <SliderMark value={min} mt="3">
              {min} {markLabel}
            </SliderMark>
            <SliderMark value={max} mt="3" ml={ml}>
              {max} {markLabel}
            </SliderMark>
          </Grid>

          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
        </Slider>
        <Input
          type="number"
          defaultValue={defaultValue}
          value={watch(name)}
          disabled={isInputDisabled}
          width={width}
          maxWidth="18%"
          ml="2%"
          textAlign="center"
          onChange={(event) =>
            handleSliderInputChange(event, min, max, setValue, name)
          }
        />
      </div>
    </div>
  );
};

export default CommonSlider;
