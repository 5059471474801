import {
  Box,
  Heading,
  Stack,
  useColorModeValue
} from "@chakra-ui/react";
import ConversationsUtility from "./ConversationsUtility";
import { useContext } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
const Conversations = () => {
  const agency = useContext(AgencyContext);
  const color_preset = agency?.data?.branding?.color_preset
  const textColor = useColorModeValue(color_preset || "#3182ce", "#3182ce") 
  return (
      <Box border="1px" h="90vh" p="5" borderRadius="md">
        <Stack justifyContent={"space-between"} mb={2} direction="row">
        <Heading color={textColor} fontSize="xl">
            Conversations
          </Heading>
        </Stack>
        <ConversationsUtility screenType="convertation" />
      </Box>
  );
};
export default Conversations;
