import { Box } from "@chakra-ui/react";
import React from "react";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../utils/Loader/Paginator";
import useVoicesHook from "./useVoicesHook";
import VoicesFilter from "./VoicesFilter";
import VoicesTable from "./VoicesTable";

const AvailableVoices = () => {
  const {
    page,
    types,
    pages,
    loading,
    paginationSize,
    languages,
    providersDetails,
    queries,
    availableVoices,
    setQueries,
    getProvicerIcon,
    setPaginationSize,
    getVoiceOwnerIcon,
    previewDefaultAudio,
    specificProviderList,
    getAllSpecificProvider,
    getSubHeadingDescriptionLabel,
    setPage,
  } = useVoicesHook({ tab: "availableVoices" });

  const columns = [
    "Provider",
    "Language",
    "Name",
    "Description",
    "Owner Type",
    "Preview",
  ];

  const tableData = availableVoices;

  return (
    <Box>
      <VoicesFilter
        tab="availableVoices"
        types={types}
        queries={queries}
        languages={languages}
        providersDetails={providersDetails}
        specificProviderList={specificProviderList}
        setQueries={setQueries}
        getAllSpecificProvider={getAllSpecificProvider}
      />
      <Box pt={2}>
        <VoicesTable
          data={tableData}
          columns={columns}
          queries={queries}
          loading={loading}
          getVoiceOwnerIcon={getVoiceOwnerIcon}
          previewDefaultAudio={previewDefaultAudio}
          providersDetails={providersDetails}
          setQueries={setQueries}
          getProvicerIcon={getProvicerIcon}
          getSubHeadingDescriptionLabel={getSubHeadingDescriptionLabel}
        />
      </Box>
      <Box mt={2} display="flex" gap={2} justifyContent="end">
        <PaginationSizeSelect
          paginationSize={paginationSize}
          tableLength={availableVoices?.length}
          setPaginationSize={setPaginationSize}
        />
        <Paginator
          colorScheme={"blue"}
          page={page}
          pages={pages}
          onChangePage={setPage}
        />
      </Box>
    </Box>
  );
};

export default AvailableVoices;
