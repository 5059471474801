import React, { useContext } from "react";
import BillingCard from "../BillingCard/BillingCard";
import AlertPayment from "./AlertPayment";
import { Box, Text } from "@chakra-ui/react";
import Wallet from "./Wallet";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import useLookupWallet from "./useLookupWallet";

const WalletCoupon = ({
  isSalesBtnisvisible,
  userDetails,
  coupanBillingPlans,
}) => {
  const agency = useContext(AgencyContext);
  const { textColor, cardBg } = agency;
  const { status, walletLookups } = useLookupWallet({
    auto: isSalesBtnisvisible,
  });
  return (
    <Box display="flex" gap={3}>
      {isSalesBtnisvisible && status === "loaded" && (
        <Box
          padding={4}
          bg={cardBg}
          gap={2}
          boxShadow="md"
          borderRadius="8px"
          width="400px"
        >
          <Text fontSize={"md"} fontWeight={"bold"}>
            Wallet
          </Text>
            <Wallet userDetails={userDetails}  lookup_key={walletLookups[0]?.plan_lookup_key} />
        </Box>
      )}
      <Box display="flex" flexWrap="wrap" gap={2} width="400px">
        {coupanBillingPlans.map((item, index) => (
          <BillingCard
            key={index}
            features={item.features}
            popular={false}
            active={true}
            planName={item.name}
            pricing={item.pricing}
            item={item}
            label={item.label}
            erlyBirdsOffer={item.erlyBirdsOffer}
            isDisable={item.isDisable}
            isCoupan={true}
            color={textColor}
            customWidth="600px"
          />
        ))}
      </Box>
    </Box>
  );
};

export default WalletCoupon;
