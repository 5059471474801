import { AsyncCreatableSelect } from "chakra-react-select";
import React from "react";
export default function TagSelect({ tagSelectHook }) {
  const {
    onChangTags,
    loadOptions,
    status,
    linkedTagsToEntity,
    tagDrawerDisclosure,
    setTagToEdit,
  } = tagSelectHook;
  const selectedOptions = Object.entries(linkedTagsToEntity).map(
    ([_, item]) => {
      return {
        value: item?.tag,
        label: item?.tag.name,
        id: item?.link_tag_entity_id,
        colorScheme: item?.tag.color_code,
      };
    }
  );
  return (
    <AsyncCreatableSelect
      isMulti
      isClearable={false}
      value={selectedOptions}
      isLoading={["removing", "selecting", "loading"].includes(status)}
      loadOptions={loadOptions}
      onChange={onChangTags}
      onCreateOption={(name) => {
        setTagToEdit({
          value: {
            name,
            description: "",
            color_code: "",
          },
        });
        tagDrawerDisclosure.onOpen();
      }}
      components={{
        MultiValueLabel: (props) => {
          return (
            <div
              style={{
                cursor: "pointer",
              }}
              onDoubleClick={() => {
                setTagToEdit(props.data);
                tagDrawerDisclosure.onOpen();
              }}
            >
              {props.children}
            </div>
          );
        },
      }}
    />
  );
}
