import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { CiMoneyBill } from "react-icons/ci";
import useWallet from "./useWallet";
import WalletBalance from "./WalletBalance";
import WalletProperties from "./WalletProperties";

export default function Wallet({ lookup_key, userDetails }) {
  const { wallet, status, onRechargeSubmit, onUpdateStatus } = useWallet();
  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: "",
      lookup_key,
    },
  });
  return (
    <Box>
      <Grid gap={2}>
        <Divider />
        <WalletBalance value={wallet?.wallet_balance} />
        <Divider />
        <form onSubmit={handleSubmit(onRechargeSubmit)}>
          <FormControl isInvalid={errors.amount}>
            <FormLabel>Topup / Add funds (USD):</FormLabel>
            <Flex justifyContent={"center"} gap={2} alignItems={"flex-start"}>
              <Input
                flex={1}
                type="number"
                {...register("amount", {
                  required: "Amount is required",
                  min: {
                    value: 5,
                    message: "Min Amount $5",
                  },
                })}
              />
              <Tooltip
                isDisabled={userDetails?.stripe_has_payment_method}
                label="Please add your card to recharge your wallet"
              >
                <Button
                  flex={2}
                  type="submit"
                  leftIcon={<CiMoneyBill />}
                  isLoading={status === "recharging"}
                  isDisabled={
                    !watch("amount") ||
                    watch("amount") < 5 ||
                    !userDetails?.stripe_has_payment_method
                  }
                  colorScheme="blue"
                >
                  Pay now
                </Button>
              </Tooltip>
            </Flex>
            <FormErrorMessage>
              {errors.amount && errors.amount.message}
            </FormErrorMessage>
            <FormHelperText>Min amount $5</FormHelperText>
          </FormControl>
        </form>
        <Divider />
        {wallet && (
          <WalletProperties
            wallet={wallet}
            onUpdateStatus={onUpdateStatus}
            status={status}
            userDetails={userDetails}
          />
        )}
      </Grid>
    </Box>
  );
}
