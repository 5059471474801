import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import Layout from "../Layout/Layout";
import FilterListByToolType from "./FilterListByToolType";
import SearchInput from "./SearchInput";
import ToolsList from "./ToolsList";
import ToolsNotFound from "./ToolsNotFound";
import ToolsTourGuide from "./ToolsTourGuide";
import useFilterTool from "./useFilterTool";
import useTools from "./useTools";
import Loader from "../../utils/Loader/Loader";
import AddToolModal from "./AddEditToolModal";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { useContext } from "react";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
export default function Tools() {
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: localStorage.getItem("toolsTourVisibility") ? false : true,
  });

  const {
    isOpen: isAddToolOpen,
    onOpen: addToolOpen,
    onClose: addToolClose,
  } = useDisclosure();

  const onCloseTour = () => {
    localStorage.setItem("toolsTourVisibility", true);
    onClose();
  };
  const { toolsList, toolsTypes, status, getToolList } = useTools();

  const loading = status === "loading";
  const filter = useFilterTool();

  const filteredTools = toolsList.filter(
    (tool) =>
      (filter.typeOfTool === "all" || filter.typeOfTool === tool.category) &&
      (!filter.search ||
        tool.name
          .toLocaleLowerCase()
          .includes(filter.search.toLocaleLowerCase()))
  );
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, colorPreset } = agency;
  const { state } = useContext(SettingContext);
  const disableAddCustomTool = (state?.credits?.custom_tool || 0) <= 0;
  return (
    <Layout>
      <Box
        border="1px"
        p="5"
        height={"90svh"}
        overflowY={"auto"}
        borderRadius="md"
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <Heading color={textColor} fontSize="xl">
              Tools
            </Heading>
            {isOpen ? <ToolsTourGuide onCloseTour={onCloseTour} /> : null}

            <Grid
              height={"80svh"}
              gap={3}
              paddingBlock={4}
              gridTemplateColumns={"300px 1fr"}
            >
              <GridItem
                height={"80svh"}
                overflowY={"auto"}
                border={"1px solid lightgray"}
                borderRadius={"md"}
                p={3}
              >
                <FilterListByToolType
                  toolsList={toolsList}
                  toolTypes={toolsTypes}
                  search={filter.search}
                  selectedToolType={filter.typeOfTool}
                  onSetTypeOfTool={filter.onSetTypeOfTool}
                />
              </GridItem>
              <GridItem>
                <Stack spacing={2} gap={4} height={"100%"}>
                  <Flex justifyContent={"space-between"} gap={4}>
                    <SearchInput {...filter} />
                    <Tooltip label={disableAddCustomTool && "Not available"}>
                      <Button
                        isDisabled={disableAddCustomTool}
                        colorScheme={buttonColorScheme}
                        onClick={addToolOpen}
                        px={"40px"}
                      >
                        Add a Custom Tool
                      </Button>
                    </Tooltip>
                  </Flex>
                  {filteredTools.length ? (
                    <ToolsList
                      filteredTools={filteredTools}
                      getToolList={getToolList}
                    />
                  ) : (
                    <ToolsNotFound />
                  )}
                </Stack>
              </GridItem>
            </Grid>
          </>
        )}
      </Box>

      {isAddToolOpen && (
        <AddToolModal
          isOpen={isAddToolOpen}
          onClose={addToolClose}
          refreshToolList={getToolList}
        />
      )}
    </Layout>
  );
}
