import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../Layout/Layout";

import {
  Box,
  Button,
  Heading,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import { showToast } from "../../utils/Toast/Toast";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import WidgetCustomizationV1 from "./WidgetCustomizationV1";
import WidgetCustomizationV2 from "./WidgetCustomizationV2";
export default function CustomizeWidget() {
  const baseUrl = getHostDomain();
  let { widgetId } = useParams();
  const action_buttons = [];
  const [buttons, setButtons] = useState(action_buttons);

  const navigate = useNavigate();
  const defaultWidgetOptions = {
    style_params: {
      auto_close_bubble_everytime: true,
      auto_open_widget: false,
      hide_ice_breaker_for_session: false,
      cta: {
        heading: "Talk with chatbot",
        sub_heading: "The chatbot respond immedietely",
      },
    },
    display_name: "",
    intro_message: "",
    header_color: "#3b81f6",
    user_text_message_color: "#FFFFFF",
    header_text_color: "#FFFFFF",
    bot_text_message_color: "#000000",
    bot_icon_color: "#3b81f6",
    remove_branding: false,
    user_message_color: "#3b81f6",
    bot_message_color: "#f1f1f0",
    bubble_text: "",
    conversation_bot_icon: "",
    ice_break_color: "#3b81f6",
    bubble_bot_icon: "",
    action_buttons_color: "#3b81f6",
  };
  const [status, setStatus] = useState("idle");
  const [widgetCustomizationOptions, setWidgetCustomizationOptions] =
    useState(defaultWidgetOptions);
  const [userOpeningMessages, setUserOpeningMessages] = useState([]);
  const onChangeWidgetCustomizationOption = (e) => {
    const { name, value, type } = e.currentTarget;
    if (type === "checkbox") {
      setWidgetCustomizationOptions({
        ...widgetCustomizationOptions,
        [name]: !widgetCustomizationOptions[name],
      });
      return;
    }
    setWidgetCustomizationOptions({
      ...widgetCustomizationOptions,
      [name]: value,
    });
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleOnLoad = async () => {
    setStatus("loading");
    const response = await fetchController(
      baseUrl + `/api/v1/widget/${widgetId}`,
      "GET"
    );
    v2Form.reset(response.data);
    const style_params = response.data?.style_params || {};
    setButtons(
      response.data.action_buttons != null
        ? response.data.action_buttons
        : action_buttons
    );
    const styleParams = Object.keys(style_params).length
      ? style_params
      : defaultWidgetOptions.style_params;
    setWidgetCustomizationOptions({
      style_params: styleParams,
      display_name:
        response.data.display_name || defaultWidgetOptions.display_name,
      intro_message:
        response.data.intro_message || defaultWidgetOptions.intro_message,
      header_color:
        response.data.header_color || defaultWidgetOptions.header_color,
      user_message_color:
        response.data.user_message_color ||
        defaultWidgetOptions.user_message_color,
      bot_message_color:
        response.data.bot_message_color ||
        defaultWidgetOptions.bot_message_color,
      user_text_message_color:
        response.data.user_text_message_color ||
        defaultWidgetOptions.user_text_message_color,
      bot_text_message_color:
        response.data.bot_text_message_color ||
        defaultWidgetOptions.bot_text_message_color,
      bot_icon_color:
        response.data.bot_icon_color || defaultWidgetOptions.bot_icon_color,
      remove_branding: response.data.remove_branding,
      bubble_bot_icon:
        response.data.bubble_bot_icon || defaultWidgetOptions.bubble_bot_icon,
      ice_break_color:
        response.data.ice_break_color || defaultWidgetOptions.ice_break_color,
      conversation_bot_icon:
        response.data.conversation_bot_icon ||
        defaultWidgetOptions.conversation_bot_icon,
      bubble_text:
        response.data.bubble_text || defaultWidgetOptions.bubble_text,
      header_text_color:
        response.data.header_text_color ||
        defaultWidgetOptions.header_text_color,
      action_buttons_color:
        response.data.action_buttons_color ||
        defaultWidgetOptions.action_buttons_color,
      widget_type: response.data.widget_type,
    });
    setUserOpeningMessages(response.data.user_opening_messages);
    setStatus("success");
  };
  const handleOnWidgetUpdate = async (e) => {
    e.preventDefault();
    const errors = [];

    buttons.forEach(({ label, link, icon }, index) => {
      if (!label || !link || !icon) {
        errors.push(`Button ${index + 1} : has empty fields.`);
      }
    });

    if (errors.length > 0) {
      showToast(errors.join(" "));
      return; // Exit early if there are errors
    }

    const payload = {
      style_params: {
        ...widgetCustomizationOptions.style_params,
        auto_close_bubble_everytime:
          widgetCustomizationOptions?.style_params?.auto_close_bubble_everytime,
        auto_open_widget:
          widgetCustomizationOptions?.style_params?.auto_open_widget,
        hide_ice_breaker_for_session:
          widgetCustomizationOptions?.style_params
            ?.hide_ice_breaker_for_session,
        show_reset_button:
          widgetCustomizationOptions?.style_params?.show_reset_button,
        placeholder: widgetCustomizationOptions?.style_params?.placeholder,
      },
      display_name: widgetCustomizationOptions.display_name,
      intro_message: widgetCustomizationOptions.intro_message,
      header_color: widgetCustomizationOptions.header_color,
      header_text_color: widgetCustomizationOptions.header_text_color,
      widget_type: widgetCustomizationOptions.widget_type,
      name: widgetCustomizationOptions.name,
      description: widgetCustomizationOptions.description,
      attributes: widgetCustomizationOptions.attributes,
      user_message_color: widgetCustomizationOptions.user_message_color,
      user_text_message_color:
        widgetCustomizationOptions.user_text_message_color,
      bot_text_message_color: widgetCustomizationOptions.bot_text_message_color,
      bot_message_color: widgetCustomizationOptions.bot_message_color,
      bot_icon_color: widgetCustomizationOptions.bot_icon_color,
      remove_branding: widgetCustomizationOptions.remove_branding,
      action_buttons_color: widgetCustomizationOptions.action_buttons_color,
      user_opening_messages: userOpeningMessages || [],
      bubble_text: widgetCustomizationOptions.bubble_text,
      widget_type: widgetCustomizationOptions.widget_type,
      ice_break_color: widgetCustomizationOptions.ice_break_color,
      action_buttons: buttons,
    };
    const getData = await fetchController(
      baseUrl + `/api/v1/widget/${widgetId}`,
      "PUT",
      payload
    );
    if (getData?.message) {
      showToast(getData?.message, "success");
      handleOnLoad();
    } else {
      showToast("Oops! Something went wrong.", "error");
    }
  };
  const handleOnBackButtonClick = (e) => {
    e.preventDefault();
    navigate("/ai-agents");
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg: bg } = agency;
  const [selectedVersion, setSelectedVersion] = useState("v1");
  const v2Form = useForm({
    defaultValues: {},
  });
  const versionRadioChildren = {
    v1: {
      label: "Legacy (v1) ",
      Component: WidgetCustomizationV1,
      onSubmit: handleOnWidgetUpdate,
    },
    v2: {
      label: "Modern (v2)",
      Component: WidgetCustomizationV2,
      onSubmit: v2Form.handleSubmit(async (values) => {
        await fetchController(
          baseUrl + `/api/v1/widget/${widgetId}`,
          "PUT",
          values
        );
        handleOnLoad();
      }),
    },
  };
  const { Component, onSubmit } = versionRadioChildren[selectedVersion];
  const customizationProps = {
    onChangeWidgetCustomizationOption,
    setWidgetCustomizationOptions,
    buttons,
    setButtons,
    widgetCustomizationOptions,
    userOpeningMessages,
    setUserOpeningMessages,
    handleOnLoad,
    handleOnWidgetUpdate,
    v2Form,
  };
  return (
    <Layout>
      <Box border="1px" h="90vh" p="5" overflowY={"auto"} borderRadius="md">
        {status === "loading" ? (
          <Loader />
        ) : (
          <form onSubmit={onSubmit}>
            <Stack justifyContent={"space-between"} direction="row">
              <Heading color={textColor} fontSize="xl">
                Customize Widget
              </Heading>
              <Stack direction="row">
                <Button
                  colorScheme={buttonColorScheme}
                  onClick={handleOnBackButtonClick}
                  maxWidth={"100px"}
                  variant="outline"
                >
                  Back
                </Button>
                <Button
                  colorScheme={buttonColorScheme}
                  type="submit"
                  maxWidth={"100px"}
                >
                  Update
                </Button>
              </Stack>
            </Stack>
            <RadioGroup onChange={setSelectedVersion} value={selectedVersion}>
              <Stack direction="row">
                {Object.entries(versionRadioChildren).map(
                  ([version, props]) => (
                    <Radio key={version} value={version}>
                      {props.label}
                    </Radio>
                  )
                )}
              </Stack>
            </RadioGroup>
            <Component {...customizationProps} />
          </form>
        )}
      </Box>
    </Layout>
  );
}
