import { FaRegCheckCircle } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { GiSandsOfTime } from "react-icons/gi";
import { IoTimeOutline } from "react-icons/io5";
import { LuTimerReset } from "react-icons/lu";
import { MdMotionPhotosPaused, MdOutlineCancel } from "react-icons/md";
import { TbProgressCheck } from "react-icons/tb";

export const columns = [
  "",
  "Enable",
  "Campaign",
  "Widget",
  "Start Date",
  "Contacts",
  "Status",
  "Actions",
];
export const tableColumns = ["", "Name", "Email", "Contact Number", "Actions"];

export const statusIconsData = {
  not_attempted: {
    icon: <IoTimeOutline />,
    toolTipLabel: "Not Attempted",
    color: "gray",
  },
  to_be_run: { icon: <LuTimerReset />, toolTipLabel: "To Be Run", color: "" },
  in_progress: {
    icon: <TbProgressCheck />,
    toolTipLabel: "In Progress",
    color: "yellow",
  },
  to_be_retried: {
    icon: <GiSandsOfTime />,
    toolTipLabel: "To Be Retried",
    color: "blue",
  },
  paused: {
    icon: <MdMotionPhotosPaused />,
    toolTipLabel: "Paused",
    color: "grey",
  },
  failed: {
    icon: <MdOutlineCancel />,
    toolTipLabel: "failed",
    color: "red",
  },
  completed: {
    icon: <FaRegCircleCheck />,
    toolTipLabel: "Completed",
    color: "green",
  },
  cancelled: {
    icon: <MdOutlineCancel />,
    toolTipLabel: "Cancelled",
    color: "red",
  },
  not_started: {
    icon: <IoTimeOutline />,
    toolTipLabel: "Not Started",
    color: "gray",
  },
};

export const editTableColumns = [
  "",
  "Status",
  "Name",
  "Email",
  "Contact Number",
  "Duration",
  "Actions",
];

export const statusIconsCampaignContacts = {
  not_attempted: {
    icon: <IoTimeOutline />,
    toolTipLabel: "Not Attempted",
  },
  in_progress: { icon: <TbProgressCheck />, toolTipLabel: "In Progress" },
  failed: { icon: <MdOutlineCancel />, toolTipLabel: "Failed" },
  completed: { icon: <FaRegCheckCircle />, toolTipLabel: "Completed" },
};

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
