import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import { getHostDomain } from "../../utils/utils";
import AddSubAccount from "../Modals/AddSubAccount";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import Table from "../Tables/Table";

const SubAccountTable = () => {
  const [status, setStatus] = useState("loading");
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const { agencyData, textColor, buttonColorScheme } = agency;
  const agency_id = agencyData?.id;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loadSubAccount = async () => {
    try {
      setStatus("loading");
      const urlHit = baseUrl + `/api/v1/agency/${agency_id}/list_users`;
      const getData = await fetchController(urlHit, "GET");

      if (getData?.data) {
        const updatedData = getData.data?.items.map((item) => {
          return {
            name: item.first_name + " " + item.last_name,
            email: item.email,
            phone: item.phone,
            id: item.id,
            ["billing plan"]: item.billing_plan_name
              ? item?.billing_plan_name.includes("-")
                ? item.billing_plan_name.split("-")[
                    item.billing_plan_name.split("-").length - 1
                  ]
                : item.billing_plan_name
              : null,
            active: item.is_active ? (
              <>
                <Tooltip label="Active" aria-label="Active">
                  <span>
                    <Icon as={GoDotFill} color="green.500" />
                  </span>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip label="Not active" aria-label="Not active">
                  <span>
                    <Icon as={GoDotFill} color="red.500" />
                  </span>
                </Tooltip>
              </>
            ),
            plan_lookup_key: item.plan_lookup_key
          };
        });

        const heads = ["active", "name", "email", "phone", "billing plan"];
        const columns = heads.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));
        setTableData(updatedData);
        setHeads(columns);
      }
    } catch (error) {
      console.error(error)
    } finally {
      setStatus("idle")
    }
  };
  useEffect(() => {
    loadSubAccount();
  }, []);

  return (
    <>
      <Box h="75vh" overflowY="scroll">
        <Flex
          justifyContent="space-between"
          gap={5}
          alignItems="center"
          my="2"
          pr={5}
        >
          <Heading color={textColor} p={4} fontSize="xl">
            Sub Account
          </Heading>
          <Button colorScheme={buttonColorScheme} onClick={() => onOpen()}>
            Add
          </Button>
        </Flex>
        {status === "loading" ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              fetch={loadSubAccount}
              columns={heads}
              data={tableData}
              table="subaccount"
            />
          </Box>
        )}
      </Box>
      {isOpen && (
        <AddSubAccount
          loadSubAccount={loadSubAccount}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default SubAccountTable;
