import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { useContext } from "react";
import TourLayout from "../Layout/TourLayout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export default function FormsTour({ onCloseTour }) {
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;
  return (
    <TourLayout onCloseTour={onCloseTour}>
      <Box fontSize={"md"}>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          What are Forms?
        </Text>
        <UnorderedList>
          <ListItem>
            A Form lets you collect any piece of data from your users and store
            it in structured format. You can export this to any place like a
            CRM, marketing automation tool for fur there processing using our
            Zapier integration.
          </ListItem>
          <ListItem>
            Typical use of a form includes lead generation, case ticket,
            feedback collection, surveys and many more
          </ListItem>
          <ListItem>
            Form can be of two types: Simple or Natural
            <UnorderedList>
              <ListItem>
                <strong> Simple form </strong> appear in the regular label &
                field boxes format
              </ListItem>
              <ListItem>
                <strong> Natural form </strong>are naturally blended in the
                conversation based on a certain trigger (like if someone shows
                interest in a product listing or makes a search query)
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          How to create a new form?
        </Text>
        <Text>
          Get started by adding a new form and then link it to one of the
          assistants. Once linked, it will be pushed to the user during the
          conversation based on the trigger instructions provided by you{" "}
        </Text>
      </Box>
    </TourLayout>
  );
}
