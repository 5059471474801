/* eslint-disable no-useless-computed-key */
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { FaFileImage } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";

import React, { useContext, useEffect, useState } from "react";
import { FaImage } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { MdOutlineTextSnippet } from "react-icons/md";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../utils/Loader/Paginator";
import { getHostDomain, getTagsData } from "../../utils/utils";
import AddDataSources from "../Modals/AddDataSource";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import Table from "../Tables/Table";
import DataSourceStatusIcon from "./DataSourceStateIcon";
import DatasourcesTour from "./DatasourcesTour";
import { DS_ICONS, HEADS_DATA } from "./constants";
import TagsColumn from "../Tags/TagsColumn";

const IconComponent = ({ dsType }) => {
  const iconData = DS_ICONS[dsType];
  if (!iconData) return null;

  const IconTag = {
    FaFilePdf,
    TbWorldWww,
    FaImage,
    FaFileImage,
    MdOutlineTextSnippet,
  }[iconData.icon];

  return (
    <Box marginLeft={2}>
      <Icon as={IconTag} color={iconData.color} />
    </Box>
  );
};

const DataSources = ({ tab }) => {
  const baseUrl = getHostDomain();
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const [status, setStatus] = useState("idle");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);

  const endpoint = `/api/v1/datasource/get_my_details?size=${paginationSize}&page=${page}`;
  const urlHit = baseUrl + endpoint;

  const hitFetch = async () => {
    try {
      setStatus("loading");
      const response = await fetchController(urlHit, "GET");
      const updatedData = response.data.items.map((item) => {
        const tagsData = getTagsData(item?.tags)

        return {
          Status: <DataSourceStatusIcon ds_status={item.ds_status} />,
          ...item,
          Type: <IconComponent dsType={item.ds_type} />,
          assistants: (
            <Box ml={4}>{item.assistants_count.toLocaleString()}</Box>
          ),
          data_source_files: (
            <Box ml={4}>{item.data_source_files_count.toLocaleString()}</Box>
          ),
          word_count: (
            <Box ml={4}>{item.total_word_count.toLocaleString()}</Box>
          ),
          Tags: <TagsColumn tagsData={tagsData} refreshTable={hitFetch} />,
        };
      });
      const columns = HEADS_DATA.map((key) => ({
        Header: key,
        accessor: key,
        Filter: ({ column }) => <input {...column.filterProps} />,
        Cell: ({ value }) => {
          if (Array.isArray(value)) {
            return <div style={{ marginLeft: "20px" }}>{value.length}</div>;
          }
          return value;
        },
      }));
      setTableData(updatedData);
      setHeads(columns);
      setPages(response?.data?.pages);
      setStatus("success");
    } catch (error) {
      setStatus("failure");
    }
  };
  useEffect(() => {
    if (tab === "Data Sources") {
      hitFetch();
    }
  }, [tab, page, paginationSize]);

  const [datasourcesTourVisibility, setDatasourcesTourVisibility] = useState(
    localStorage.getItem("datasourcesTourVisibility") ? false : true
  );
  const onCloseDatasourcesTour = () => {
    localStorage.setItem("datasourcesTourVisibility", false);
    setDatasourcesTourVisibility(false);
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, colorPreset } = agency;

  const onGotoFirstPage = () => setPage(1);

  return (
    <>
      <Box
        border="1px"
        h="81vh"
        // scrollBehavior={"smooth"}
        overflowY="scroll"
        p="5"
        borderRadius="md"
      >
        <Heading color={textColor} fontSize="xl">
          Data Sources
        </Heading>
        {datasourcesTourVisibility ? (
          <DatasourcesTour onCloseDatasourcesTour={onCloseDatasourcesTour} />
        ) : null}
        <Flex justifyContent="flex-end" alignItems="center" mb="4">
          <Button
            colorScheme={buttonColorScheme}
            onClick={() => {
              onOpen();
            }}
          >
            Add Data Source
          </Button>
        </Flex>
        {status === "loading" ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              columns={heads}
              data={tableData}
              table="data-sources"
              fetch={hitFetch}
            />

            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              marginBlock={2}
              gap={2}
            >
              <PaginationSizeSelect
                paginationSize={paginationSize}
                setPaginationSize={setPaginationSize}
                tableLength={tableData.length}
              />
              <Paginator
                colorScheme={buttonColorScheme}
                onChangePage={setPage}
                page={page}
                pages={pages}
              />
            </Flex>
          </Box>
        )}
      </Box>
      <AddDataSources
        fetchSources={hitFetch}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onGotoFirstPage={onGotoFirstPage}
      />
    </>
  );
};

export default DataSources;
