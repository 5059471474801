import { Button, ButtonGroup } from "@chakra-ui/react";
const options = [
  { label: "For businesses", value: "endUser" },
  { label: "For Agencies", value: "agency" },
];

export const BillingOptions = ({ selectedOption, setSelectedOption }) => {
  return (
    <ButtonGroup isAttached mb={2}>
      {options.map(({ label, value }) => (
        <Button
          fontSize="13px"
          fontWeight="bold"
          key={value}
          bg={selectedOption === value ? "#3182ce" : "gray.300"}
          color="white"
          onClick={() => setSelectedOption(value)}
          _hover={{ bg: selectedOption === value ? "#3182ce" : "gray.300" }}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
