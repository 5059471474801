import { Flex, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { LiaSmsSolid } from "react-icons/lia";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function FollowUpFieldTypes({ automateForm }) {
  const baseUrl = getHostDomain();
  const { watch, setValue } = automateForm;
  const [status, setStatus] = useState("idle");
  const [whatsappDetails, setWhatsappDetails] = useState();
  const fetchWhatsappAccountSetup = useCallback(async () => {
    setStatus("loading");
    const userResponse = await fetchController(
      baseUrl + `/api/v1/user/list/UserWhatsApp`,
      "GET"
    );
    if (userResponse.data.items?.length)
      setWhatsappDetails(userResponse.data.items[0]);
    setStatus("success");
  }, []);
  useEffect(() => {
    fetchWhatsappAccountSetup();
  }, []);
  return (
    <RadioGroup
      value={watch("automate_type")}
      onChange={(value) => {
        setValue("automate_type", value);
        setValue("twilio_auth", undefined);
        setValue("phone_number", undefined);
      }}
    >
      <Stack spacing={2} direction={"row"}>
        <Flex gap={3} justifyContent={"center"} alignItems={"center"}>
          <Radio
            isDisabled={!whatsappDetails || status === "loading"}
            value="whatsapp"
          >
            Whatsapp
          </Radio>
          <FaWhatsapp size={20} />
        </Flex>
        <Flex gap={3} justifyContent={"center"} alignItems={"center"}>
          <Radio value="sms">SMS</Radio>
          <LiaSmsSolid size={20} />
        </Flex>
      </Stack>
    </RadioGroup>
  );
}
