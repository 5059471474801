import {
  Box,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../utils/Loader/Paginator";
import Layout from "../Layout/Layout";
import ContactSyncTable from "./ContactSyncTable";
import LogDeleteAlert from "./LogDeleteAlert";
import LogJsonModal from "./LogJsonModal";
import NoLogsFound from "./NoLogsFound";
import ShowModal from "./ShowModal";
import SystemLogTable from "./SystemLogTable";
import ToolFunctionInvokeLogs from "./ToolFunctionInvokeLogs";
import useLogs from "./useLogs";
import useSelectedLog from "./useSelectedLog";

export default function LogsPage() {
  const navigate = useNavigate();
  const { selectedLogType = "user_log" } = useParams();

  const logTypes = {
    system_log: {
      value: "user_log",
      Table: SystemLogTable,
      heading: "System Logs",
    },
    tool_function_invoke_log: {
      value: "tool_function_invoke_log",
      Table: ToolFunctionInvokeLogs,
      heading: "Tool Functions Invocation",
    },
    contact_sync_log: {
      value: "contact_sync_log",
      Table: ContactSyncTable,
      heading: "Contact Sync",
    },
  };

  const {
    selectedLog,
    isShowModalOpen,
    onCloseShowModal,
    onOpenShowModal,
    selectedJson,
    onOpenCallDetailsModal,
    onCloseCallDetailsModal,
    isCallDetailsModal,
    isDeleteAlertOpen,
    closeDeleteAlert,
    onDeleteLog,
  } = useSelectedLog({ selectedLogType });

  const {
    paginator,
    response,
    isLoading,
    deleteLog,
    status,
    paginationSize,
    setPaginationSize,
    onSetResponse,
    onSetStatus,
  } = useLogs({
    logType: selectedLogType,
    closeDeleteAlert,
    logTypes,
  });
  const totalPages = response?.data?.pages;
  const logs = response?.data?.items || [];

  return (
    <Layout>
      <Box border="1px" h="91vh" overflowY="auto" p="5" borderRadius="md">
        <Tabs isLazy>
          <TabList>
            {Object.keys(logTypes).map((key) => {
              return (
                <Tab
                  onClick={() => {
                    paginator.onSetPage(1);
                    onSetStatus("loading");
                    onSetResponse(null);
                    navigate(`/logs/${key}`);
                  }}
                  key={key}
                >
                  {logTypes[key].heading}
                </Tab>
              );
            })}
          </TabList>

          <TabPanels>
            {Object.keys(logTypes).map((key) => {
              const { Table: SelectedTable, tableProps } = logTypes[key];
              return (
                <TabPanel fontSize={"xl"} key={key}>
                  <Stack spacing={4}>
                    <Flex>
                      <Heading fontSize={"xl"} color={"#3182ce"}>
                        {logTypes[key].heading}
                      </Heading>
                    </Flex>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {logs.length ? (
                          <SelectedTable
                            logs={logs}
                            onOpenCallDetailsModal={onOpenCallDetailsModal}
                            onOpenShowModal={onOpenShowModal}
                            onDeleteLog={onDeleteLog}
                            tableProps={tableProps}
                            isCallDetailsModal={isCallDetailsModal}
                            onCloseCallDetailsModal={onCloseCallDetailsModal}
                          />
                        ) : (
                          <NoLogsFound />
                        )}
                        <Flex
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          gap={2}
                        >
                          <PaginationSizeSelect
                            paginationSize={paginationSize}
                            setPaginationSize={setPaginationSize}
                            tableLength={logs.length}
                          />
                          <Paginator
                            onChangePage={paginator.onSetPage}
                            page={paginator.page}
                            pages={totalPages}
                          />
                        </Flex>
                      </>
                    )}
                  </Stack>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Box>
      {selectedLog && isShowModalOpen ? (
        <ShowModal
          selectedLog={selectedLog}
          isOpen={isShowModalOpen}
          onClose={onCloseShowModal}
        />
      ) : null}
      {selectedJson && isCallDetailsModal ? (
        <LogJsonModal
          isOpen={isCallDetailsModal}
          onClose={onCloseCallDetailsModal}
          selectedJson={selectedJson}
        />
      ) : null}
      {selectedLog && isDeleteAlertOpen ? (
        <LogDeleteAlert
          isOpen={isDeleteAlertOpen}
          onClose={closeDeleteAlert}
          selectedLog={selectedLog}
          onConfirmDelete={deleteLog}
          status={status}
        />
      ) : null}
    </Layout>
  );
}
