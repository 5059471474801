import { Box, theme, useColorModeValue } from "@chakra-ui/react";
import moment from "moment";
import React, { useContext } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import AudioPlayback from "./AudioPlayback";
import ChatRecipientIcon from "./ChatRecipientIcon";
import "./Message.css";
import MessageText from "./MessageText";
import TilesList from "./TilesList";
const Message = (props) => {
  const className = `message ${props.messageData.sender_type}`;
  const isMyMessage = props.messageData.sender_type === "user";
  const wrapperClassName = `${
    isMyMessage ? "message__wrapper" : "message__wrappperMe"
  }`;
  const conversationDataSource = props.messageData?.data_sources || [];
  const agency = useContext(AgencyContext);
  const {
    textColor,
    buttonColorScheme,
    cardBg: bg,
    colorPreset,
    myDetails,
  } = agency;

  // const color_preset = agency?.data?.branding?.color_preset
  const boatMsgBgColor = useColorModeValue("#E8E8E8", theme.colors.gray[700]);
  const useMsgBgColor = useColorModeValue(colorPreset || "#3182ce", textColor);
  const chatTimeColor = useColorModeValue("grey", theme.colors.white);
  const agencyLogoUrl = myDetails?.branding?.logo_url;

  return (
    <div className={className} key={props?.key}>
      {isMyMessage ? null : (
        <ChatRecipientIcon
          children={
            <img
              src={
                agencyLogoUrl
                  ? agencyLogoUrl.includes(window.origin)
                    ? agencyLogoUrl
                    : `${window.location.origin}${agencyLogoUrl}`
                  : "/logo/favicon.png"
              }
              height={32}
              width={32}
            />
          }
        />
      )}
      <div
        className={`message__timestamp message__timestamp__${props.messageData.sender_type}`}
      >
        {/* <div className={wrapperClassName} style={{ border: "1px solid red", backgroundColor: isMyMessage ? "red":"green" }}>
          <MessageText text={props?.messageData?.text} />
          {props.messageData?.voice_base64 ? (
            <AudioPlayback audioBinary={props.messageData?.voice_base64} />
          ) : null}
        </div> */}
        <Box
          className={wrapperClassName}
          bg={isMyMessage ? useMsgBgColor : boatMsgBgColor}
        >
          <MessageText text={props?.messageData?.text} />
          {props.messageData?.voice_base64 ? (
            <AudioPlayback audioBinary={props.messageData?.voice_base64} />
          ) : null}
        </Box>
        <TilesList
          bgColor={boatMsgBgColor}
          tiles={conversationDataSource}
          isMyMessage={isMyMessage}
        />
        <small style={{ color: chatTimeColor }}>
          {moment
            .utc(props?.messageData?.created_at)
            .local()
            .format("MM/DD/YYYY, h:mm A")}
        </small>
      </div>
    </div>
  );
};

export default Message;
