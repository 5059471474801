import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";

const ConfirmationModal = ({
  onClose,
  isOpen,
  onConfirm,
  table,
  itemToDelete,
}) => {
  const finalRef = useRef(null);

  const confirmationMessages = {
    "data-sources": "Do you want to delete the data source?",
    assistant:
      "All the Captured Forms, Intents, Conversations & Widgets associated with this Assistant shall be deleted. Are you sure?",
    widget:
      "All the captured Forms, Intents and Conversations shall be deleted. Are you sure?",
    sourceFileList: "Do you want to delete the file?",
    intent: "Do you want to delete the intent?",
    webhooks: "Do you want to delete the webhook?",
    conversation: "Do you want to delete this conversation?",
    checkedConversationDelete: "Do you want to delete selected conversation?",
    contactCustomField: "Do you want to delete this custom field?",
    agencybillingPlan: "Do you want to delete this Billing Plan?",
    customTool: "Do you want to delete this Custom tool?",
    prompt: "Do you want to delete this prompt?",
    conversationFlow: "Do you want to delete this conversation flow?",
    campaigns:
      "Are you sure you want to delete this Campaign ? This action cannot be undone.",
    changeBillingPlan:
      "Are you sure you want to the change billing plan for this sub-account?",
    subaccount: "Do you want to refresh the credit for this subaccount? Credits will be reset immediately.",
    default: "This action cannot be undone. Are you sure you want to proceed?",
  };

  const getConfirmationMessage = (table, itemToDelete) => {
    if (table === "subaccount" && !itemToDelete) {
      return confirmationMessages["subaccount"];
    }
    return confirmationMessages[table] || confirmationMessages["default"];
  };
  const confirmationMessage = getConfirmationMessage(table, itemToDelete);

  return (
    <>
      <Modal
        size="lg"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent
          height="auto"
          width="95%"
          margin="auto"
          maxWidth="650px"
          minHeight="200px" // Set a minimum height to center vertically
        >
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Center> */}
            <Text fontSize={"lg"}>{confirmationMessage}</Text>
            {/* </Center> */}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onConfirm}>
              Yes
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Go back
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
