import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { BiSupport } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import { IoHelpCircleOutline } from "react-icons/io5";
import { RiDiscordLine } from "react-icons/ri";

export default function HelpMenu({ agency, navSize }) {
  const onClickDiscord = () => {
    const link =
      agency?.myDetails?.branding?.socials?.discord ||
      "https://discord.gg/3baqJDqGYQ";
    window.open(link, "_blank");
  };
  const onClickFreshDesk = () => {
    const link =
      agency?.myDetails?.branding?.socials?.docs ||
      "https://insightoai.freshdesk.com";
    window.open(link, "_blank");
  };
  const onClickAffiliate = () => {
    window.open("https://affiliates.insighto.ai/program", "_blank");
  };

  const menuItems = [
    {
      label: "Discord",
      value: onClickDiscord,
      icon: <RiDiscordLine />,
      isVisible: agency?.myDetails?.branding?.socials?.discord || true,
    },
    {
      label: "Docs",
      value: onClickFreshDesk,
      icon: <BiSupport />,
      isVisible: agency?.myDetails?.branding?.socials?.docs || true,
    },
    {
      label: "Affiliate ",
      value: onClickAffiliate,
      icon: <FiExternalLink />,
      isVisible: agency?.myDetails?.agency_id ? false : true,
    },
  ];

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<IoHelpCircleOutline />}
        textAlign={"start"}
        marginX={2}
      >
        {navSize !== "small" && "Help"}
      </MenuButton>
      <MenuList display={"flex"} justifyContent={"center"} gap={2}>
        {menuItems
          .filter((item) => item.isVisible)
          .map((item, index) => (
            <MenuItem
              borderRadius="6px"
              key={index}
              onClick={item.value}
              width="14"
              gap="1"
              flexDirection="column"
              padding={2}
              justifyContent="center"
            >
              {item.icon}
              <Text fontWeight="bold" fontSize="12px">{item.label}</Text>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
}
