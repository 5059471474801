import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../Auth/AuthContext";
import Layout from "../Layout/Layout";
import { AgencyContext } from "./AgencyProvider/AgencyProvider";
import Agent from "./Agent/Agent";
import ApiKey from "./APIKey/ApiKey";
import Billing from "./Billing/Billing";
import Channels from "./Channels";
import Profile from "./Profile/Profile";
import Usage from "./Usage/Usage";
import Webhooks from "./Webhooks";
import YourKeys from "./YourKeys/YourKeys";

const Settings = () => {
  const navigate = useNavigate();
  const { name = "profile" } = useParams();
  const { authUser } = useContext(AuthContext);
  const userRoles = authUser?.roles || [];
  const isSuperAdmin = userRoles.includes("superadmin") || authUser?.is_superuser;
  const agency = useContext(AgencyContext);
  const tabs = useMemo(() => {
    const defaultTabs = [
      { name: "Profile", route: "/settings/profile", component: Profile },
      { name: "Channels", route: "/settings/channels", component: Channels },
      { name: "Webhooks", route: "/settings/webhooks", component: Webhooks },
      { name: "Usage", route: "/settings/usage", component: Usage },
      { name:  agency?.myDetails?.agency_id ? "Billing" : "Billing & Wallet", route: "/settings/billing", component: Billing },
      { name: "API Keys", route: "/settings/api-keys", component: ApiKey },
      { name: "Your Keys", route: "/settings/your-keys", component: YourKeys },
    ];

    if (isSuperAdmin) {
      defaultTabs.push({ name: "Human Agents", route: "/settings/agents", component: Agent });
    }
    return defaultTabs;
  }, [isSuperAdmin]);

  const defaultTab = tabs.findIndex(tab => tab.route === `/settings/${name}`);

  return (
    <Layout>
      <Box minH="81svh">
        <Tabs isLazy index={defaultTab < 0 ? 0 : defaultTab} onChange={(index) => navigate(tabs[index].route)}>
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab.route} color={agency.textColor}>{tab.name}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab.route} mt={4}>
                <tab.component tab={tab.name} activeTab={name} />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
};

export default Settings;
