import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React from "react";

const VoicesFilter = (props) => {
  const {
    tab = "voices",
    queries,
    setQueries,
    types,
    providersDetails,
    onSyncVoicesOpen,
    languages,
    specificProviderList,
    getAllSpecificProvider,
  } = props;
  const handleTypeSelect = (e) => {
    if (queries.type?.value === e.target.innerText.toLowerCase())
      setQueries((prevQueries) => ({
        ...prevQueries,
        type: { value: "all", label: "All" },
      }));
    else
      setQueries((prevQueries) => ({
        ...prevQueries,
        type: types[e.target.innerText.toLowerCase()],
      }));
  };
  return (
    <Box gap={2}>
      <Text alignContent="end" fontWeight="bold">
        Filters{" "}
      </Text>
      <Grid display="flex" gap={2} justifyContent="space-around">
        <FormControl width="fit-content">
          <FormLabel>Voice Provider</FormLabel>
          <ButtonGroup spacing={0} isAttached>
            {Object.keys(providersDetails).map((provider) => {
              return (
                <Button
                  key={provider.value}
                  title={provider.label}
                  onClick={(e) => {
                    setQueries((prevQueries) => {
                      const newQueries = {
                        ...prevQueries,
                        filterByProvider:
                          prevQueries?.filterByProvider?.value?.includes(
                            provider
                          )
                            ? ""
                            : providersDetails[provider],
                        filterBySpecificProvider: "",
                      };
                      return newQueries;
                    });
                    getAllSpecificProvider(providersDetails[provider].value);
                  }}
                  colorScheme={
                    queries?.filterByProvider?.value?.includes(provider)
                      ? "gray"
                      : undefined
                  }
                  isActive={queries?.filterByProvider?.value?.includes(
                    provider
                  )}
                >
                  <Tooltip
                    label={providersDetails[provider].label}
                    textTransform="capitalize"
                    placement="top"
                  >
                    <span>
                      <Icon as={providersDetails[provider].icon} size="sm" />
                    </span>
                  </Tooltip>
                </Button>
              );
            })}
          </ButtonGroup>
        </FormControl>
        <FormControl width="40vw">
          <FormLabel>Select Keys </FormLabel>
          <Select
            name="filterBySpecificProvider"
            onChange={(selectedOption) => {
              setQueries((prevQueries) => ({
                ...prevQueries,
                filterBySpecificProvider: selectedOption,
              }));
            }}
            value={queries?.filterBySpecificProvider}
            options={specificProviderList}
            isClearable
          />
        </FormControl>
        <FormControl width="40vw">
          <FormLabel>Language </FormLabel>
          <Select
            name="filterByLanguage"
            onChange={(selectedOption) => {
              setQueries((prevQueries) => ({
                ...prevQueries,
                filterByLanguage: selectedOption,
              }));
            }}
            value={queries?.filterByLanguage}
            options={languages}
            style={{ textTransform: "capitalize" }}
            isClearable
          />
        </FormControl>
        {tab === "availableVoices" && (
          <FormControl>
            <FormLabel>Voice Owned By</FormLabel>
            <ButtonGroup spacing={0} isAttached>
              {Object.keys(types).map((type) => (
                <Button
                  key={types[type].value}
                  title={types[type].label}
                  onClick={(e) => handleTypeSelect(e)}
                  colorScheme={
                    types[type].value === queries?.type?.value
                      ? "gray"
                      : undefined
                  }
                  isActive={types[type].value === queries?.type?.value}
                >
                  {types[type].label}
                </Button>
              ))}
            </ButtonGroup>
          </FormControl>
        )}
        {tab === "syncVoices" && (
          <FormControl alignContent="end">
            <Button
              onClick={() => {
                onSyncVoicesOpen();
              }}
              colorScheme="blue"
              ml="auto"
              display="block"
            >
              Sync Your Voices
            </Button>
          </FormControl>
        )}
      </Grid>
    </Box>
  );
};

export default VoicesFilter;
