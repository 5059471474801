import { Checkbox } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
export default function useWebhooks() {
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const { textColor, colorPreset, buttonColorScheme } = agency;
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const [status, setStatus] = useState("idle");
  const webhookTableMapper = (webhook) => ({
    ...webhook,
    last_invoked: webhook?.last_invoked
      ? new Date(webhook?.last_invoked).toLocaleDateString()
      : "-",
    status: (
      <Checkbox
        isChecked={webhook.enabled}
        onChange={async (e) => {
          const { id, name, endpoint } = webhook;
          const url = `/api/v1/outbound_webhook/${id}`;
          setStatus("loading");
          await fetchController(baseUrl + url, "PUT", {
            name,
            endpoint,
            enabled: e.currentTarget.checked,
          });
          fetchWebhooks();
        }}
        colorScheme={buttonColorScheme}
      />
    ),
  });
  const fetchWebhooks = useCallback(async () => {
    setStatus("loading");
    const response = await fetchController(
      baseUrl + "/api/v1/outbound_webhook/list"
    );
    const selectedWebhookHeadings = [
      "status",
      "name",
      "endpoint",
      "last_invoked",
    ];
    const columns = selectedWebhookHeadings.map((key) => ({
      Header: key,
      accessor: key,
      Filter: ({ column }) => <input {...column.filterProps} />,
    }));
    setTableData(response.data.items.map(webhookTableMapper));
    setHeads(columns);
    setStatus("idle");
  }, []);

  useEffect(() => {
    fetchWebhooks();
  }, []);
  const webhookTable = { tableData, heads };
  return { fetchWebhooks, webhookTable, status };
}
