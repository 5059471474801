import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { GoShieldLock } from "react-icons/go";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MdOutlineMail } from "react-icons/md";
import { useContext, useState } from "react";
import { getHostDomain, validateRegex } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import useRecaptcha from "./useRecaptcha";
import AgencyLogo from "./AgencyLogo";

function ForgotPasswordForm() {
  const baseUrl = getHostDomain();
  const getRecaptchaToken = useRecaptcha();
  const emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$";

  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsValidate(true);
    if (!email || !validateRegex(emailPattern, email)) return;

    try {
      setLoading(true);
      const data = { email };
      const reCaptchaToken = await getRecaptchaToken("forgotPassword");
      data.captcha_response = reCaptchaToken;

      await fetchController(
        `${baseUrl}/api/v1/login/forgot_password`,
        "POST",
        data,
        {
          "Content-Type": "application/json",
        }
      );
      setEmailSent(true);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, agencyData, myDetails } = agency;
  const agency_id = myDetails ? myDetails?.agency_id : null;

  return (
    <Box height={"100vh"} bgColor={"#F0F1F8"} p={1}>
      <Flex height={"100%"}>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          width={"50%"}
          spacing={4}
          py={10}
        >
          <Box>
            <AgencyLogo />
          </Box>
          {!agency_id && (
            <>
              <Heading size="xl" color={textColor}>
                Welcome
              </Heading>
              <Heading size="md" color={textColor}>
                Log in to Insighto.ai to continue to App.
              </Heading>
            </>
          )}
        </Stack>

        <Stack
          width={"50%"}
          px={10}
          py={20}
          spacing={6}
          justifyContent="center"
          alignItems={"center"}
          borderRadius={20}
          bgColor={"#C7C7C7"}
        >
          {!emailSent && (
            <>
              <Flex
                gap={4}
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                mb={10}
              >
                <GoShieldLock fontSize={"40px"} />
                <Heading size="xl">Forgot Password</Heading>
              </Flex>

              <form onSubmit={onSubmit} style={{ width: "80%" }}>
                <Flex direction={"column"} gap={6}>
                  <FormControl
                    isInvalid={
                      (isValidate && !email) ||
                      (isValidate && !validateRegex(emailPattern, email))
                    }
                  >
                    <FormLabel>Email</FormLabel>
                    <Input
                      placeholder={"Enter your email"}
                      type="email"
                      bgColor={"white"}
                      isDisabled={emailSent}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {isValidate &&
                      (!email ? (
                        <FormErrorMessage>Email is required</FormErrorMessage>
                      ) : (
                        !validateRegex(emailPattern, email) && (
                          <FormErrorMessage>
                            Invalid email address
                          </FormErrorMessage>
                        )
                      ))}
                  </FormControl>

                  <Button
                    type="submit"
                    colorScheme={buttonColorScheme}
                    isLoading={loading}
                    w={"100%"}
                    isDisabled={emailSent}
                  >
                    Submit
                  </Button>
                </Flex>
              </form>

              <Flex
                alignItems={"center"}
                gap={1}
                color={textColor}
                fontWeight="bold"
                cursor={"pointer"}
                fontSize={"14px"}
                onClick={() => navigate("/login")}
              >
                <IoArrowBackCircleOutline fontSize={"18px"} />
                Back to Login
              </Flex>
            </>
          )}
          {emailSent && (
            <Flex
              gap={4}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              mb={10}
            >
              <MdOutlineMail fontSize={"40px"} />
              <Heading size="xl">Check your email</Heading>
              <Text color={"gray.900"} opacity={0.8}>
                Instructions to change password have been sent to your
                registered email id.
              </Text>
            </Flex>
          )}
        </Stack>
      </Flex>
    </Box>
  );
}

export default function ForgotPassword() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <ForgotPasswordForm />
    </GoogleReCaptchaProvider>
  );
}
