import { Box } from "@chakra-ui/react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import SubAccountTable from "../SubAccountTable";

export default function SubAccount() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <Box border="1px" borderRadius="md" h="81vh" overflowY="auto">
        <SubAccountTable />
      </Box>
    </GoogleReCaptchaProvider>
  );
}
