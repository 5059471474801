import { useEffect, useState } from "react";
import { getHostDomain } from "../../utils/utils";
import { fetchController } from "../../utils/FetchController/fetchController";

export default function useWidgetType() {
  const baseUrl = getHostDomain();
  const [widgetList, setWidgetList] = useState([]);
  const [widgetTypesOpt, setWidgetTypesObjOpt] = useState(null);
  const [status, setStatus] = useState("idle");

  const getWidgetList = async () => {
    try {
      setStatus("loading");
      const response = await fetchController(
        `${baseUrl}/api/v1/widget?size=100`,
        "GET"
      );
      
      const widgetTypes = response?.data?.items.reduce((acc, widget) => {
        if (!widget?.widget_type) return;
        const option = {
          label: widget.name,
          value: widget.id,
          data: { ...widget },
        };
        return acc[widget.widget_type]
          ? {
              ...acc,
              [widget.widget_type]: [...acc[widget.widget_type], option],
            }
          : { ...acc, [widget.widget_type]: [option] };
      }, {});

      setWidgetList(response?.data?.items || []);
      setWidgetTypesObjOpt(widgetTypes || null);
    } catch (error) {
      console.error(error);
    } finally {
      setStatus("idle");
    }
  };

  useEffect(() => {
    getWidgetList();
  }, []);

  return { widgetList, widgetTypesOpt, status, getWidgetList };
}
