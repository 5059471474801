import { FormControl, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select"
import { timezones } from "../../utils/CountryList/CountryList";
export default function SelectTimzone({ toolUserForm }) {
  const { watch, setValue } = toolUserForm;
  const { credentials = {} } = watch();

  const { configuration = {} } = credentials;
  const areaTimezones = timezones.reduce((prev, current) => {
    return [...prev, ...current.utc];
  }, []);
  const timezoneOptions = areaTimezones.map((timezone) => ({
    value: timezone,
    label: timezone,
  }));
  return (
    <FormControl isRequired>
      <FormLabel fontWeight={"bold"}>Select Timezone</FormLabel>
      <Select
        onChange={({ value }) => {
          setValue("credentials.configuration.timezone", value);
        }}
        options={timezoneOptions}
        value={timezoneOptions.find(
          (timezoneOption) => timezoneOption.value === configuration.timezone
        )}
        required
      />
    </FormControl>
  );
}
