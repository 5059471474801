import { Flex, Image, Text } from "@chakra-ui/react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { useContext } from "react";
import { getAbsLogoUrl } from "../../utils/utils";

export default function Message({
  text,
  sentBy,
  bgColor,
  conversationBotIcon,
  color,
}) {
  const agency = useContext(AgencyContext);
  const { myDetails } = agency;
  const agency_id = myDetails ? myDetails.agency_id : null;
  const agencyLogoUrl = agency_id ? myDetails?.branding?.logo_url : null;
  const url =
    conversationBotIcon ||
    (agency_id ? agencyLogoUrl : "https://cdn.insighto.ai/bot.png");
  const absLogourl = getAbsLogoUrl(url);

  return (
    <Flex
      gap={2}
      justifyContent={sentBy === "me" ? "flex-end" : "flex-start"}
      alignItems={"center"}
    >
      {sentBy === "me" ? null : absLogourl ? (
        <Image
          src={absLogourl}
          alt="Logo"
          flex={"1fr"}
          height={"32px"}
          width={"32px"}
        />
      ) : null}
      <Flex>
        <Flex
          borderRadius={10}
          padding={1}
          color={color}
          justifyContent={sentBy === "me" ? "flex-end" : "flex-start"}
          backgroundColor={bgColor}
          flex={"auto"}
        >
          <Text fontWeight={500} fontSize={"1rem"} padding={"0.5rem"}>
            {text}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
