import { useContext, useState, useMemo } from "react";
import { getHostDomain } from "../../../utils/utils";
import { AgencyContext } from "../../Settings/AgencyProvider/AgencyProvider";
import { fetchController } from "../../../utils/FetchController/fetchController";

export default function useFinalSubmit() {
  const baseUrl = getHostDomain();
  const { myDetails } = useContext(AgencyContext);
  const { agency_id, branding, domain } = myDetails || {};
  const agency_name = branding?.agency_name;
  const agency_app_domain = domain?.appDomain;
  const agency_logo_url = agency_id ? baseUrl + branding?.logo_url : undefined;

  const [status, setStatus] = useState("idle");

  const attributesMap = useMemo(() => ({
    chat: (data) => ({ initial_form_id: data?.form?.id || null }),
    whatsapp: (data) => ({
      whatsapp_phone_number: data?.whatsapp_phone_number,
      phone_number_id: data?.phone_number_id,
      phone_business_id: data?.phone_business_id,
      whatsapp_access_token: data?.whatsapp_access_token,
      facebook_app_secret: data?.facebook_app_secret,
    }),
    phone: (data) => ({
      sid: data?.sid,
      phone_number: data?.phone_number,
      twilio_auth_id: data?.twilio_auth_id,
      phone_provider: "twilio",
      recording: data?.recording,
      max_call_duration: data?.max_call_duration,
    }),
    html_call: (data) => ({
      ...attributesMap.phone(data),
      form_phone_number_field: data?.form_phone_number_field,
      form_id: data?.form?.id,
    }),
    sms: (data) => ({
      sid: data?.sid,
      phone_number: data?.phone_number,
      tool_user_id: data?.tool_user_id,
    }),
    leadconnector: (data) => ({
      location_id: data?.location_id,
      channels: data?.channels,
      tool_user_id: data?.tool_user_id,
    }),
    leadconnector_call: (data) => ({
      ...attributesMap.phone(data),
      call_status: data?.call_status,
      location_id: data?.location_id,
    }),
    fb_messenger: (data) => ({
      fb_page_id: data?.fb_page_id,
      fb_page_name: data?.fb_page_name,
      tool_user_id: data?.tool_user_id,
    }),
    instagram: (data) => ({
      tool_user_id: data?.tool_user_id,
      ig_user_id: data?.ig_user_id,
      ig_name: data?.ig_name,
      ig_username: data?.ig_username,
    }),
    plivo_call: (data) => ({
      max_call_duration: data?.max_call_duration,
      recording: data?.recording,
      phone_number: data?.phone_number,
      tool_user_id: data?.tool_user_id,
    }),
    telnyx_call: (data) => ({
      max_call_duration: data?.max_call_duration,
      recording: data?.recording,
      phone_number_id: data?.attributes?.phone_number_id,
      tool_user_id: data?.attributes?.tool_user_id,
    }),
    telegram: (data) => ({ tool_user_id: data?.tool_user_id }),
  }), []);

  const handleSubmit = async (data) => {
    setStatus("loading");
    try {
      const body = {
        ...data,
        attributes: attributesMap[data.widget_type]?.(data) || {},
        user_opening_messages: [],
        action_buttons: [],
        style_params: {
          agency_name: agency_id ? agency_name : "Insighto.AI",
          agency_app_domain: agency_id ? agency_app_domain : "https://insighto.ai",
          agency_logo_url,
        },
      };

      const response = await fetchController(`${baseUrl}/api/v1/widget`, "POST", body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setStatus("idle");
    }
  };

  return { status, handleSubmit, getAttributesByWidgetType: (type, data) => attributesMap[type]?.(data) || {} };
}
