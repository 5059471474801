import {
  Box,
  FormControl,
  FormLabel,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select"
export default function ToolFunctionFormField({
  setValue,
  selectedToolFunction,
  toolFunctionOptions,
  currentToolUserIndex,
  triggerTool,
  triggerTools,
  toolUser,
  fields,
}) {
  const toolFunctionProperties =
    selectedToolFunction?.value.details?.openai_spec?.function?.parameters
      ?.properties || {};
  const fieldOptions = fields.map((field) => ({
    value: field.name,
    label: field.label,
    isDisabled: Object.values(triggerTool?.tool_arguments_mapping || {}).some(
      (tool_arguments_mapping_field) =>
        tool_arguments_mapping_field === field.name
    ),
  }));
  const propertyFields = Object.keys(toolFunctionProperties);
  return (
    <Box p={2} border={"1px solid lightgray"} key={triggerTool.tool_user_id}>
      <Box fontWeight={"bold"} marginBlock={2}>
        <Text fontSize={"md"} textAlign="left">
          {toolUser.name}
        </Text>
      </Box>
      <FormControl>
        <FormLabel>Function</FormLabel>
        <Select
          required
          value={selectedToolFunction}
          options={toolFunctionOptions}
          onChange={({ value }) =>
            setValue(
              "trigger_tools",
              triggerTools.map((triggerTool, currentIndex) => ({
                tool_user_id: triggerTool.tool_user_id,
                tool_function_id:
                  currentToolUserIndex === currentIndex
                    ? value.id
                    : triggerTool?.tool_function_id,
                tool_arguments_mapping: Object.keys(
                  toolFunctionProperties
                ).reduce((prev, toolFnProp) => {
                  prev[toolFnProp] = "";
                  return prev;
                }, {}),
              }))
            )
          }
        />
      </FormControl>
      {propertyFields.length ? (
        <Stack marginBlock={2} bg={"gray.100"} p={2} spacing={2}>
          <Text fontWeight={"bold"} color="blue">
            {" "}
            Select Form Fields
          </Text>
          <SimpleGrid gap={2} minChildWidth={300}>
            {propertyFields.map((toolFunctionField, index) => (
              <FormControl
                key={index}
                isRequired={selectedToolFunction?.values?.details?.openai_spec?.function?.parameters.required.some(
                  (requriedField) => requriedField === toolFunctionField
                )}
              >
                <FormLabel textTransform={"capitalize"}>
                  {toolFunctionField}
                </FormLabel>
                <Select
                  required={selectedToolFunction?.value?.details?.openai_spec?.function?.parameters.required.some(
                    (requriedField) => requriedField === toolFunctionField
                  )}
                  value={fieldOptions.find(
                    (fieldOption) =>
                      fieldOption?.value ===
                      triggerTool?.tool_arguments_mapping[toolFunctionField]
                  )}
                  onChange={({ value }) => {
                    setValue(
                      "trigger_tools",
                      triggerTools.map((triggerTool) => ({
                        ...triggerTool,
                        tool_arguments_mapping: {
                          ...triggerTool.tool_arguments_mapping,
                          [toolFunctionField]: value,
                        },
                      }))
                    );
                  }}
                  options={fieldOptions}
                />
              </FormControl>
            ))}
          </SimpleGrid>
        </Stack>
      ) : null}
    </Box>
  );
}
