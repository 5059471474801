import { Box, Button, Flex, Grid } from "@chakra-ui/react";
import React from "react";
import Loader from "../../utils/Loader/Loader";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../utils/Loader/Paginator";
import SyncTable from "./SyncTable";
import SyncVoicesModal from "./SyncVoicesModal";
import useSyncVoices from "./useSyncVoices";
import useVoicesHook from "./useVoicesHook";
import VoicesFilter from "./VoicesFilter";

const SyncVoices = () => {
  const {
    providers,
    types,
    queries,
    setQueries,
    specificProviderList,
    languages,
    getAllSpecificProvider,
    providersDetails,
    getSubHeadingDescriptionLabel,
    getProvicerIcon,
    previewDefaultAudio,
  } = useVoicesHook();

  const {
    page,
    pages,
    loading,
    columns,
    paginationSize,
    syncVoicesList,
    isSyncVoicesOpen,
    onSyncVoicesOpen,
    onSyncVoicesClose,
    setPage,
    addToAvailableVoice,
    fetchSyncVoicesData,
    setPaginationSize,
    syncSelectedProviderVoice,
    isAddNewSyncVoiceModalOpen,
    onAddNewSyncVoiceModalOpen,
    onAddNewSyncVoiceModalClose,
  } = useSyncVoices({ queries, setQueries });

  return (
    <Box>
      <VoicesFilter
        tab="syncVoices"
        types={types}
        queries={queries}
        providersDetails={providersDetails}
        languages={languages}
        onSyncVoicesOpen={onSyncVoicesOpen}
        setQueries={setQueries}
        specificProviderList={specificProviderList}
        getAllSpecificProvider={getAllSpecificProvider}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <SyncTable
            columns={columns}
            syncVoicesList={syncVoicesList}
            previewDefaultAudio={previewDefaultAudio}
            addToAvailableVoice={addToAvailableVoice}
            fetchSyncVoicesData={fetchSyncVoicesData}
            getProvicerIcon={getProvicerIcon}
            getSubHeadingDescriptionLabel={getSubHeadingDescriptionLabel}
          />
          <Box mt={2} display="flex" gap={2} justifyContent="end">
            <PaginationSizeSelect
              paginationSize={paginationSize}
              setPaginationSize={setPaginationSize}
              tableLength={syncVoicesList?.length}
            />
            <Paginator
              colorScheme={"blue"}
              onChangePage={setPage}
              page={page}
              pages={pages}
            />
          </Box>
        </>
      )}
      <SyncVoicesModal
        setQueries={setQueries}
        getAllSpecificProvider={getAllSpecificProvider}
        isSyncVoicesOpen={isSyncVoicesOpen}
        onSyncVoicesClose={onSyncVoicesClose}
        queries={queries}
        providersDetails={providersDetails}
        providers={providers}
        specificProviderList={specificProviderList}
        syncSelectedProviderVoice={syncSelectedProviderVoice}
        isAddNewSyncVoiceModalOpen={isAddNewSyncVoiceModalOpen}
        onAddNewSyncVoiceModalOpen={onAddNewSyncVoiceModalOpen}
        onAddNewSyncVoiceModalClose={onAddNewSyncVoiceModalClose}
      />
    </Box>
  );
};

export default SyncVoices;
