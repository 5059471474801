import { FormControl, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select"
import useExternalAuth from "../Tools/useExternalAuth";
import useToolUserRunStaticFunction from "../Tools/useToolUserRunStaticFunction";
import { useEffect, useState } from "react";

export default function Instagram({ setValue }) {
  const [selectedToolUserId, setSelectedToolUserId] = useState(null);
  const { auths } = useExternalAuth({
    auth_provider: "instagram",
  });

  const { response: instaDetails, status } = useToolUserRunStaticFunction({
    tool_user_id: selectedToolUserId,
    staticFnPayload: {
      function_name: "get_insta_details",
    },
  });

  useEffect(() => {
    if (instaDetails) {
      setValue("ig_name", instaDetails?.name);
      setValue("ig_id", instaDetails?.id);
      setValue("ig_username", instaDetails?.username);
      setValue("ig_user_id", instaDetails?.user_id);
    }
  }, [instaDetails]);

  const pageOptions = auths
    ? auths?.map((data) => ({
        value: data.id,
        label: `${data.name} ${
          instaDetails ? ` - ${instaDetails?.name} (@${instaDetails?.username})` : ""
        }`,
      }))
    : [];

  const handleSelect = ({ value }) => {
    setSelectedToolUserId(value);
    setValue("tool_user_id", value);
  };

  return (
    <>
      <FormControl isRequired>
        <FormLabel>{"Select IG"}</FormLabel>
        <Select options={pageOptions} onChange={handleSelect} isLoading={status === "loading"} />
      </FormControl>
    </>
  );
}