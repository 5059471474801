import {
  Box,
  Button,
  Table as ChakraTable,
  Checkbox,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { BiConversation, BiSolidContact } from "react-icons/bi";
import { CgRemove } from "react-icons/cg";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../utils/Loader/Paginator";
import { getHostDomain } from "../../utils/utils";
import ConversationDrawer from "../Contacts/ConversationDrawer";
import AddContact from "./AddContact";
import { editTableColumns, statusIconsCampaignContacts } from "./constants";
import NoDataPage from "./NoDataPage";
import { getReadableSeconds } from "./utils";
const AddEditContact = (props) => {
  const baseUrl = getHostDomain();

  const {
    isEditContactOpen,
    onEditContactClose,
    buttonColorScheme,
    setOperation,
    selectedRow,
    setTableData,
    getCampaignById,
    setSelectedRow,
    getAllCampaign,
    isAddContactOpen,
    onAddContactOpen,
    onAddContactClose,
  } = props;

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);
  const [status, setStatus] = useState("loading");
  const [contactList, setContactList] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  const [selectedRowCopy, setSelectedRowCopy] = useState(selectedRow);
  const [selectAll, setSelectAll] = useState(false);

  const dependency = [paginationSize, page];
  const [contactForCampaign, setContactForCampaign] = useState([]);

  const {
    isOpen: isConversationDrawerOpen,
    onOpen: onOpenConversationDrawer,
    onClose: onCloseConversationDrawer,
  } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);

  const getContactForCampaign = async () => {
    try {
      const endpoint = `/api/v1/campaign_contact/list/${selectedRowCopy?.id}?size=${paginationSize}&page=${page}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      setPages(response.data.pages);
      const newData = response.data.items.map((item) => ({
        ...item,
        isChecked: false,
      }));
      setContactForCampaign(newData);
      return newData;
    } catch (e) {
      console.log(e);
    }
  };
  const getContactList = useCallback(async () => {
    try {
      const endpoint = `/api/v1/campaign_contact/list/${selectedRow.id}?size=${paginationSize}&page=${page}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      const newData = response.data.items.map((item) => ({
        ...item,
        isChecked: false,
      }));

      setContactList(() => newData);
      setPages(response.data.pages);
      setStatus("success");
    } catch (e) {
      console.log(e);
      setStatus("failure");
    }
  }, dependency);

  const DeleteContactToCampaign = async (contact) => {
    try {
      const endpoint = `/api/v1/campaign_contact/${contact.campaign_contact_id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      setTableData((prevData) => {
        const newData = [...prevData];
        newData[selectedRow.index].contacts.pop();
        return newData;
      });
      const contactsAfterDelete = await getContactForCampaign();
      await getAllCampaign();
      setContactList(contactsAfterDelete);
      toast.success("Removed Contact from Campaign !");
      setStatus("success");
    } catch (e) {
      console.log(e);
      setStatus("failure");
    }
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedContact([]);
    setStatus("loading");
    getContactList();
  }, dependency);

  const handleClose = async () => {
    const updatedContacts = await getContactForCampaign();
    setContactList(updatedContacts);
    onAddContactClose();
    setSelectedRow(selectedRowCopy);
  };

  useEffect(() => {
    if (!selectedRow) {
      setSelectedRow(selectedRowCopy);
      getContactList();
      getContactForCampaign();
    }
  }, [selectedRow]);

  return (
    <>
      <Modal
        isOpen={isEditContactOpen}
        onClose={() => {
          setSelectedRow(null);
          onEditContactClose();
        }}
        blockScrollOnMount={false}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent maxW="60vw" height="80vh">
          <ModalHeader
            style={{ fontWeight: 700, fontSize: "18px" }}
            alignContent="center"
          >
            <Flex justifyContent="space-between" mr="5%">
              View Contacts
              <Button
                leftIcon={<FaPlus />}
                style={{
                  bg: "#eff6ff",
                  color: "#173DA6",
                  borderRadius: "4px",
                  marginLeft: "16px",
                }}
                onClick={() => {
                  setSelectedContact([]);
                  onAddContactOpen();
                }}
              >
                <Text>Add Contact</Text>
              </Button>
            </Flex>

            <Flex style={{ fontWeight: 400, fontSize: "14px" }}>
              <Text>Edit Contacts from here.</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton alignItems="center" />
          <ModalBody>
            {status === "loading" ? (
              <Loader />
            ) : !contactList.length ? (
              <NoDataPage
                icon={<BiSolidContact color="#3B81F6" />}
                title="Contacts"
                description="Click on the button to create the new Contact"
                buttonIcon={<FaPlus />}
                colorScheme="blue"
                onClick={() => {
                  setSelectedContact([]);
                  onAddContactOpen();
                }}
                buttonTtile="Add Contact"
              />
            ) : (
              <ChakraTable width="100%">
                <Thead>
                  <Tr>
                    {editTableColumns.map((column, index) => (
                      <Th
                        textAlign="left"
                        borderBottom="1px"
                        borderColor="#E4E4E7"
                        style={{ textWrap: "nowrap" }}
                        key={column}
                      >
                        {index === 0 ? (
                          <Checkbox
                            onChange={(e) => {
                              setSelectAll(e.target.checked);
                              setContactList((prevContactData) => {
                                const newData = prevContactData.map((row) => {
                                  return {
                                    ...row,
                                    isChecked: e.target.checked,
                                  };
                                });
                                if (!e.target.checked) setSelectedContact([]);
                                return newData;
                              });
                            }}
                            isChecked={selectAll}
                          ></Checkbox>
                        ) : (
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {column}
                          </Box>
                        )}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {contactList?.map((contact, index) => (
                    <Tr>
                      <Td>
                        <Checkbox
                          onChange={(e) => {
                            setContactList((prevContactData) => {
                              const newData = [...prevContactData];
                              newData[index].isChecked = e.target.checked;
                              return newData;
                            });
                            setSelectedContact((prevSelected) => {
                              const isAlreadySelected = prevSelected.some(
                                (item) => item.id === contact.id
                              );
                              if (isAlreadySelected) {
                                return prevSelected.filter(
                                  (item) => item.id !== contact.id
                                );
                              } else {
                                return [...prevSelected, contact];
                              }
                            });
                          }}
                          isChecked={contact.isChecked}
                        ></Checkbox>
                      </Td>
                      <Td>
                        <Tooltip
                          label={
                            statusIconsCampaignContacts[contact?.status]
                              .toolTipLabel
                          }
                        >
                          <IconButton
                            cursor="default"
                            variant="ghost"
                            _hover={{ bg: "initial" }}
                            icon={
                              statusIconsCampaignContacts[contact?.status].icon
                            }
                          />
                        </Tooltip>
                      </Td>
                      <Td>
                        {contact.first_name} {contact.last_name}
                      </Td>
                      <Td>{contact.email}</Td>
                      <Td>{contact?.channels?.telephone}</Td>
                      <Td>
                        {contact?.conversation_attributes?.call_duration ? (
                          <Tooltip
                            label={
                              contact?.conversation_attributes?.call_duration
                            }
                          >
                            {getReadableSeconds(
                              contact?.conversation_attributes?.call_duration
                            )}
                          </Tooltip>
                        ) : (
                          <span>-</span>
                        )}
                      </Td>
                      <Td>
                        <Flex justifyContent="center" alignItems="center">
                          {contact.conversation_id && (
                            <Tooltip label="Conversation">
                              <IconButton
                                icon={<BiConversation />}
                                cursor="pointer"
                                variant="ghost"
                                colorScheme="blue"
                                onClick={() => {
                                  setSelectedItem(contact);
                                  onOpenConversationDrawer();
                                }}
                              />
                            </Tooltip>
                          )}
                          <Tooltip label="Remove Contact">
                            <IconButton
                              size="lg"
                              icon={<CgRemove />}
                              variant="ghost"
                              colorScheme={"red"}
                              onClick={async () => {
                                setStatus("loading");
                                await DeleteContactToCampaign(contact);
                              }}
                            />
                          </Tooltip>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </ChakraTable>
            )}
          </ModalBody>
          <ModalFooter>
            <Box>
              <Flex
                justifyContent={"flex-end"}
                alignItems={"center"}
                marginBlock={2}
                gap={4}
              >
                <PaginationSizeSelect
                  paginationSize={paginationSize}
                  setPaginationSize={setPaginationSize}
                  tableLength={contactList?.length}
                />
                <Paginator
                  colorScheme={buttonColorScheme}
                  onChangePage={setPage}
                  page={page}
                  pages={pages}
                />
              </Flex>
              <Box>
                <Flex float="right" gap={4}>
                  <Button
                    onClick={() => {
                      setSelectedRow(null);
                      onEditContactClose();
                    }}
                    variant="outline"
                    width="104px"
                  >
                    Close
                  </Button>
                </Flex>
              </Box>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isAddContactOpen && (
        <AddContact
          operation={"adding"}
          selectedRow={selectedRow}
          setOperation={setOperation}
          isAddContactOpen={isAddContactOpen}
          onAddContactClose={handleClose}
          buttonColorScheme={buttonColorScheme}
          getCampaignById={getCampaignById}
          setTableData={setTableData}
          setSelectedRow={setSelectedRow}
          getAllCampaign={getAllCampaign}
        />
      )}

      <ConversationDrawer
        isOpen={isConversationDrawerOpen}
        onClose={onCloseConversationDrawer}
        selectedContact={selectedItem}
      />
    </>
  );
};

export default AddEditContact;
