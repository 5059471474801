import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  Spinner,
  Stack,
  Tag,
  TagLabel,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useNavigate } from "react-router-dom";
import { Select } from "chakra-react-select"
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import FormLabelWithInfoTooltip from "../../Widgets/FormLabelWithInfoTooltip";
import CustomDuration from "../CustomDuration";
import SelectAvailability from "../SelectAvailability";
import SelectTimzone from "../SelectTimezone";
import useToolUserRunStaticFunction from "../useToolUserRunStaticFunction";

export default function GoogleCalendarModal({ onClose, toolUserForm }) {
  const baseUrl = getHostDomain();
  const { watch, setValue, register, handleSubmit, formState } = toolUserForm;
  const { tool_user_id, credentials = {} } = watch();
  const { configuration = {} } = credentials;

  const { response, status } = useToolUserRunStaticFunction({
    tool_user_id,
    staticFnPayload: {
      function_name: "get_calendars",
    },
  });

  const googleCalendarOptions = response
    ? response.map((item) => ({
        value: item.id,
        label: item.id,
      }))
    : [];

  const navigate = useNavigate();
  const availability = configuration?.availability || {};
  const minNoticeDefaultOptions = [
    { value: "minutes", label: "minutes", convertToMinutes: (val) => val },
    { value: "hours", label: "hours", convertToMinutes: (val) => 60 * val },
    { value: "days", label: "days", convertToMinutes: (val) => 24 * 60 * val },
  ];
  const selectedNoticeOption = minNoticeDefaultOptions.find(
    (o) => o.value === configuration?.minimum_notice_option
  );

  const minutesConverter =
    selectedNoticeOption?.convertToMinutes ||
    function (val) {
      return val;
    };

  const onSubmitGoogleCalendarForm = async (data) => {
    try {
      const checkIfSlotIsSelected = (day) => !(availability[day] || []).length;
      if (Object.keys(availability).every(checkIfSlotIsSelected)) {
        toast.error("Select at least one slot");
        onToggle();
        return;
      }
      await fetchController(
        baseUrl + `/api/v1/tool/${data.tool_user_id}/LinkToolUser`,
        "PUT",
        {
          credentials,
        }
      );
      toast.success("Tool setup done");
      onClose();
      navigate(`/tools`);
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const { onToggle, isOpen } = useDisclosure();

  const onChangeNoticeOption = ({ value, convertToMinutes }) => {
    setValue("credentials.configuration.minimum_notice_option", value);
    const minutes = convertToMinutes(configuration?.minimum_notice_value || 0);
    setValue("credentials.configuration.minimum_notice", minutes);
  };

  const onChangeNoticeValue = (e) => {
    setValue("credentials.configuration.minimum_notice_value", e.currentTarget.value);
    const minutes = minutesConverter(e.currentTarget.value);
    setValue("credentials.configuration.minimum_notice", minutes);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitGoogleCalendarForm)}>
        <ModalBody>
          {status === "loading" ? (
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Spinner />
            </Flex>
          ) : (
            <Stack spacing={3}>
              <FormControl isRequired>
                <FormLabel fontWeight={"bold"}>Select Calendar</FormLabel>
                <Select
                  value={googleCalendarOptions.find(
                    (calendarOption) => calendarOption.value === configuration.calendar_id
                  )}
                  onChange={({ value }) => {
                    setValue("credentials.configuration.calendar_id", value);
                  }}
                  options={googleCalendarOptions}
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel fontWeight={"bold"}>CC Email on Invites</FormLabel>
                <Input type="email" {...register("credentials.configuration.appointment_email_copy")} />
              </FormControl>
              <CustomDuration toolUserForm={toolUserForm} />
              <SelectTimzone toolUserForm={toolUserForm} />
              <FormControl>
                <FormLabel fontWeight={"bold"}>Invite Title</FormLabel>
                <Textarea {...register("credentials.configuration.summary")} />
              </FormControl>
              <FormControl>
                <FormLabel fontWeight={"bold"}>Invite Description</FormLabel>
                <SunEditor
                  setContents={watch("credentials.configuration.invite_description") || ""}
                  onChange={(content) => {
                    setValue("credentials.configuration.invite_description", content);
                  }}
                  setOptions={{
                    height: 200,
                    buttonList: [
                      ["bold", "italic", "underline", "strike"],
                      ["list", "align", "fontColor", "removeFormat"],
                      ["undo", "redo"],
                    ],
                  }}
                />
              </FormControl>
              <FormControl>
                <Flex justifyContent={"space-between"}>
                  <Text fontWeight={"bold"}>Minimum Notice</Text>
                  <Text>
                    {configuration.minimum_notice_value} {configuration.minimum_notice_option}
                  </Text>
                </Flex>
                <FormLabelWithInfoTooltip
                  tip={"Minimum heads up needed off the current time to make the appointment"}
                  label={"Invitees cannot schedule within..."}
                />
                <Flex gap={2} justifyContent={"flex-start"} alignItems={"center"}>
                  <Input type="number" width={125} value={configuration?.minimum_notice_value} onChange={onChangeNoticeValue} />
                  <Box width={125}>
                    <Select options={minNoticeDefaultOptions} value={selectedNoticeOption} onChange={onChangeNoticeOption} />
                  </Box>
                  <Text>of an event start time</Text>
                </Flex>
              </FormControl>
              <Flex justifyContent={"flex-start"} alignItems={"center"}>
                <Button onClick={onToggle} size={"sm"} colorScheme="yellow">
                  Select Availability
                </Button>
              </Flex>
              <Divider />
              <Text fontWeight={"bold"}>Available on</Text>
              <HStack>
                {Object.keys(availability)
                  .filter((day) => availability[day].length)
                  .map((day) => (
                    <Tag key={day} variant="subtle" colorScheme="cyan">
                      <TagLabel>{day}</TagLabel>
                    </Tag>
                  ))}
              </HStack>
              <Text fontStyle={"italic"}>
                Your calendar will be used in real time to find available free slots within these selected time windows.
              </Text>
            </Stack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button isLoading={formState.isSubmitting} colorScheme="blue" mr={3} type="submit">
            Finish
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </form>
      {isOpen ? <SelectAvailability isOpen={isOpen} onClose={onToggle} toolUserForm={toolUserForm} /> : null}
    </>
  );
}

