import {
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Select } from "chakra-react-select"
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import useToolUserRunStaticFunction from "../useToolUserRunStaticFunction";

export default function ZohoConfigModal({ onClose, toolUserForm }) {
  const baseUrl = getHostDomain();
  const { watch, setValue, handleSubmit, formState } = toolUserForm;
  const { tool_user_id, credentials = {} } = watch();
  const { configuration = {} } = credentials;

  const { response: serviceRes, status: serviceStatus } =
    useToolUserRunStaticFunction({
      tool_user_id,
      staticFnPayload: {
        function_name: "fetch_services",
      },
    });

  const { response: staffRes, status: staffStatus } =
    useToolUserRunStaticFunction({
      tool_user_id,
      staticFnPayload: {
        function_name: "fetch_staffs",
      },
    });

  const serviceOptions = serviceRes
    ? serviceRes
        .filter((service) => service.service_type === "APPOINTMENT")
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))
    : [];

  const staffOptions = staffRes
    ? staffRes.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    : [];

  const navigate = useNavigate();

  const onSubmitZohoConf = async (data) => {
    try {
      await fetchController(
        baseUrl + `/api/v1/tool/${data.tool_user_id}/LinkToolUser`,
        "PUT",
        {
          credentials,
        }
      );
      toast.success("Tool setup done");
      onClose();
      navigate(`/tools`);
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitZohoConf)}>
      <ModalBody>
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Select Services</FormLabel>
            <Select
              value={serviceOptions.find(
                (serviceOption) =>
                  serviceOption.value === configuration.service_id
              )}
              onChange={({ value }) => {
                setValue("credentials.configuration.service_id", value);
              }}
              options={serviceOptions}
              isLoading={serviceStatus === "loading"}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Select Staff</FormLabel>
            <Select
              value={staffOptions.find(
                (staffOption) => staffOption.value === configuration.staff_id
              )}
              onChange={({ value }) => {
                setValue("credentials.configuration.staff_id", value);
              }}
              options={staffOptions}
              isLoading={staffStatus === "loading"}
            />
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          isLoading={formState.isSubmitting}
          colorScheme="blue"
          mr={3}
          type="submit"
        >
          Finish
        </Button>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </form>
  );
}
