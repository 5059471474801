import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Textarea,
} from "@chakra-ui/react";
import FormSelect from "../Widgets/FormSelect";
import { FaSms, FaWpforms } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io5";
import { Select } from "chakra-react-select";
import IconWidgetOption from "../Playground/IconWidgetOption";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";
import { useEffect, useState } from "react";
import useVoicesLanguage from "./useVoicesLanguage";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";

export default function SmartHandoff({
  toolUserForm,
  widgetTypesOpt,
  smsMessageTooltip,
}) {
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = toolUserForm;

  const formError = errors?.credentials?.configuration?.smart_handoff?.form_id;
  const handoffWidgetError =
    errors?.credentials?.configuration?.smart_handoff?.sub_widget_id;

  const [handoffWidgetType, setHandoffWidgetType] = useState("");
  const [bodyParameters, setBodyParameters] = useState([]);

  const selectedHandoffType =
    watch("credentials.configuration.smart_handoff.type") ||
    setValue("credentials.configuration.smart_handoff.type", "conversational");

  const selectedWhatsAppType =
    handoffWidgetType === "whatsapp"
      ? watch("credentials.configuration.smart_handoff.whatsapp.type") ||
        setValue(
          "credentials.configuration.smart_handoff.whatsapp.type",
          "text"
        )
      : "";

  const handoffInterruptionStatus = watch(
    "credentials.configuration.smart_handoff.enable_handoff_interruption"
  )

  const handoffTypeWidget = {
    web_form: ["render_form"],
    conversational: ["sms", "whatsapp"],
  };

  const handoffWidgetTypeIcon = {
    render_form: FaWpforms,
    whatsapp: IoLogoWhatsapp,
    sms: FaSms,
  };

  const handoffWidgetOptions = handoffTypeWidget[selectedHandoffType]
    ?.reduce((acc, type) => {
      const widgetOptions =
        widgetTypesOpt && widgetTypesOpt[type] ? widgetTypesOpt[type] : [];
      return acc.length ? [...acc, ...widgetOptions] : [...widgetOptions];
    }, [])
    ?.map((option) => ({
      ...option,
      Icon: handoffWidgetTypeIcon[option?.data?.widget_type],
    }));

  const handleHandOffTypeSelect = (value) => {
    const obj = {
      type: value,
      form_id: watch("credentials.configuration.smart_handoff.form_id"),
      sub_widget_id: "",
      message_body: "",
    };
    setValue("credentials.configuration.smart_handoff", obj);
    setHandoffWidgetType("");
  };

  const handleHandoffWidgetSelect = (selectedOpt) => {
    setValue(
      "credentials.configuration.smart_handoff.sub_widget_id",
      selectedOpt.value
    );
    setHandoffWidgetType(selectedOpt.data.widget_type);
  };

  const handleWhatsAppTypeSelect = (value) => {
    const obj = {
      ...watch("credentials.configuration.smart_handoff"),
      whatsapp: { type: value },
      message_body: "",
    };
    setValue("credentials.configuration.smart_handoff", obj);
  };

  const handleAddParameter = () => {
    const parameters = [...bodyParameters];
    parameters.push("");
    setBodyParameters(parameters);
    setValue(
      "credentials.configuration.smart_handoff.whatsapp.body_parameters",
      parameters
    );
  };

  const handleRemoveParameter = (index) => {
    const parameters = [...bodyParameters];
    parameters.splice(index, 1);
    setBodyParameters(parameters);
    setValue(
      "credentials.configuration.smart_handoff.whatsapp.body_parameters",
      parameters
    );
  };

  const isTemplateType =
    watch("credentials.configuration.smart_handoff.whatsapp.type") ===
    "template";

  const selectedHandoffWidget = handoffWidgetOptions?.find(
    (option) =>
      option.value ===
      watch("credentials.configuration.smart_handoff.sub_widget_id")
  );

  useEffect(() => {
    const parameters = watch(
      "credentials.configuration.smart_handoff.whatsapp.body_parameters"
    );
    if (parameters) {
      setBodyParameters(parameters);
    }
  }, []);

  return (
    <Stack spacing={2}>
      <FormLabel>Smart handoff</FormLabel>
      <FormControl display={"flex"} flexDirection={"row"}>
        <FormLabel>Type: </FormLabel>
        <RadioGroup
          onChange={handleHandOffTypeSelect}
          value={selectedHandoffType}
        >
          <Stack direction="row">
            <Radio value="web_form">Web form</Radio>
            <Radio value="conversational">Conversational</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormLabelWithInfoTooltip
          label={"Enable Handoff Interruption"}
          tip={
            "As your second assistant is talking with the user, it will feed the captured form fields back into the parent assistant, if enabled."
          }
        />
        <Switch
          onChange={(e) => {
            setValue(
              "credentials.configuration.smart_handoff.enable_handoff_interruption",
              e.target.checked
            );
          }}
          isChecked={handoffInterruptionStatus}
        />
      </FormControl>

      <FormControl isInvalid={formError}>
        <FormLabel>Form</FormLabel>
        <FormSelect
          selectedForm={watch(
            "credentials.configuration.smart_handoff.form_id"
          )}
          onChange={(selectedForm) => {
            setValue(
              "credentials.configuration.smart_handoff.form_id",
              selectedForm?.value?.id || ""
            );
          }}
        />
        <FormErrorMessage>{formError && formError.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={handoffWidgetError}>
        <FormLabel>Handoff widget</FormLabel>
        <Select
          value={selectedHandoffWidget}
          options={handoffWidgetOptions}
          components={{ Option: IconWidgetOption }}
          onChange={handleHandoffWidgetSelect}
        />
        <FormErrorMessage>
          {handoffWidgetError && handoffWidgetError.message}
        </FormErrorMessage>
      </FormControl>

      {handoffWidgetType === "whatsapp" && (
        <FormControl display={"flex"} flexDirection={"row"}>
          <FormLabel>WhatsApp message type: </FormLabel>
          <RadioGroup
            onChange={handleWhatsAppTypeSelect}
            value={selectedWhatsAppType}
          >
            <Stack direction="row">
              <Radio value="text">Text</Radio>
              <Radio value="template">Template</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      )}

      {!isTemplateType && (
        <FormControl>
          <FormLabelWithInfoTooltip
            label={"Message body"}
            tip={smsMessageTooltip}
          />
          <Textarea
            {...register(
              "credentials.configuration.smart_handoff.message_body"
            )}
          />
        </FormControl>
      )}

      {isTemplateType && (
        <Stack spacing={2}>
          <Flex gap={2}>
            <FormControl>
              <FormLabel>Template name</FormLabel>
              <Input
                placeholder="Enter template name"
                {...register(
                  "credentials.configuration.smart_handoff.whatsapp.template_name"
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Language code</FormLabel>
              <Input
                placeholder="en"
                {...register(
                  "credentials.configuration.smart_handoff.whatsapp.language_code"
                )}
              />
            </FormControl>
          </Flex>

          <FormControl>
            <FormLabel>
              Parameters{" "}
              <IconButton
                isRound={true}
                colorScheme="blue"
                variant="outline"
                icon={<AddIcon />}
                size={"xs"}
                onClick={handleAddParameter}
              />
            </FormLabel>

            <Stack spacing={2}>
              {bodyParameters &&
                bodyParameters.map((param, index) => (
                  <Flex
                    key={index}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Input
                      placeholder="Enter template parameter"
                      value={param}
                      onChange={(e) => {
                        const params = [...bodyParameters];
                        params[index] = e.target.value;
                        setValue(
                          "credentials.configuration.smart_handoff.whatsapp.body_parameters",
                          params
                        );
                        setBodyParameters(params);
                      }}
                    />
                    <IconButton
                      isRound={true}
                      colorScheme="blue"
                      variant="outline"
                      aria-label="Add Field"
                      icon={<MinusIcon />}
                      size={"xs"}
                      onClick={() => handleRemoveParameter(index)}
                    />
                  </Flex>
                ))}
            </Stack>
          </FormControl>
        </Stack>
      )}
    </Stack>
  );
}
