import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";
import { Select } from "chakra-react-select";
import useWidgetType from "./useWidgetType";
import useLinkToolUsers from "./useLinkToolUsers";
import SmartHandoff from "./SmartHandoff";

const FORM_LINK_REGEX = /\{\{\s*form_link\s*\}\}/;
const smsTooltip = `It is difficult to capture important details such as email, addresses, and specifications over a call, so you can send a form link to the user by SMS in the middle of the call, ask them to fill it out, and once submitted, carry forward the conversation to capture error-free information.`;
const smsMessageTooltip = `Must have {{form_link}} which will be replaced with the actual form URL where the user can fill up the Form`;

export default function FormSMS({ toolUserForm }) {
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = toolUserForm;

  const widget = watch("credentials.configuration.widget_id");
  const toolUser = watch("credentials.configuration.sms_tool_user_id");
  const widgetError = errors?.credentials?.configuration?.widget_id;
  const smsMessageError = errors?.credentials?.configuration?.sms_form_link_message;

  const twilioToolId = "74d08d92-758b-41ad-856d-2853c77d1454";
  const { widgetTypesOpt, status } = useWidgetType();
  const { linkToolUsers, toolUserStatus = status } = useLinkToolUsers({
    tool_id: twilioToolId,
  });

  const renderFormOptions = widgetTypesOpt?.render_form || [];

  const toolUserOptions = linkToolUsers.map((linkToolUserOption) => ({
    value: linkToolUserOption.id,
    label: linkToolUserOption.name,
  }));

  const handleWidgetSelect = (selectedOpt) => {
    const { value } = selectedOpt;
    setValue("credentials.configuration.widget_id", value);
  };

  const handleToolUserSelect = (selectedOpt) => {
    const { value } = selectedOpt;
    setValue("credentials.configuration.sms_tool_user_id", value);
  };

  const selectedWidget = renderFormOptions?.find((option) => option.value === widget);
  const selectedToolUser = toolUserOptions.find((option) => option.value === toolUser);

  return (
    <Flex gap={2} direction={"column"}>
      {/* <FormLabelWithInfoTooltip label={"Form via SMS"} tip={smsTooltip} /> */}
      {/* <Stack spacing={2}>
        <FormControl isInvalid={widgetError}>
          <FormLabel>Select widget</FormLabel>
          <Select
            value={selectedWidget}
            options={renderFormOptions}
            isLoading={status === "loading"}
            onChange={handleWidgetSelect}
          />
          <FormErrorMessage>
            {widgetError && widgetError.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel>Select tool user</FormLabel>
          <Select
            value={selectedToolUser}
            options={toolUserOptions}
            isLoading={toolUserStatus === "loading"}
            onChange={handleToolUserSelect}
          />
        </FormControl>
        <FormControl isInvalid={smsMessageError}>
          <FormLabelWithInfoTooltip
            label={"SMS message"}
            tip={smsMessageTooltip}
          />
          <Textarea
            {...register("credentials.configuration.sms_form_link_message", {
              required: "SMS message is required",
              pattern: {
                value: FORM_LINK_REGEX,
                message: "{{form_link}} is required",
              },
            })}
          />
          <FormErrorMessage>
            {smsMessageError && smsMessageError.message}
          </FormErrorMessage>
        </FormControl>
      </Stack> */}

      <SmartHandoff
        toolUserForm={toolUserForm}
        widgetTypesOpt={widgetTypesOpt}
        smsMessageTooltip={smsMessageTooltip}
      />
    </Flex>
  );
}
