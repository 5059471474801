import {
  Button,
  Flex,
  FormControl,
  Link,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import CommonWidgetTypeForm from "./CommonWidgetTypeForm";
import SelectTwilioAccount from "../../SelectTwilioAccount";
import SelectPhoneNumber from "../../SelectPhoneNumber";
import useTwilioAccounts from "../../useTwilioAccounts";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import FormLabelWithInfoTooltip from "../../FormLabelWithInfoTooltip";
import { Select } from "chakra-react-select"
import FormSelect from "../../FormSelect";

export default function HTMLCallForm({
  widgetForm,
  onSubmit,
  loadingStatus,
  onBack,
}) {
  const { setValue, watch, formState } = widgetForm;
  const { errors } = formState;

  const { accounts, phoneNumbers } = useTwilioAccounts({
    widgetType: watch("widget_type"),
    twilio_auth_id: watch("twilio_auth_id"),
  });

  const telephoneFieldsOptions = (watch("form")?.fields || [])
    .filter((field) => field.type === "tel")
    .map((field) => ({
      label: field.name,
      value: field.name,
    }));

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          <CommonWidgetTypeForm widgetForm={widgetForm} />
          <SelectTwilioAccount
            accounts={accounts}
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
          <SelectPhoneNumber
            watch={watch}
            setValue={setValue}
            phoneNumbers={phoneNumbers}
          />

          <FormControl isRequired>
            <FormLabelWithInfoTooltip
              label={"Form"}
              tip={
                "This Form will be rendered to your users. Use this to collect info before initiating the call"
              }
            />
            <FormSelect
              selectedForm={watch("form")}
              onChange={({ value }) => {
                setValue("form", value);
                setValue("form_phone_number_field", "");
              }}
            />
          </FormControl>
          {watch("form") && (
            <FormControl isRequired>
              <FormLabelWithInfoTooltip
                label={" Telephone field"}
                tip={
                  "User will provide their phone number in this field. AI Agent will call this number as soon as they submit the form"
                }
              />
              <Select
                required
                onChange={({ value }) => {
                  setValue("form_phone_number_field", value);
                }}
                options={telephoneFieldsOptions}
                value={telephoneFieldsOptions.find(
                  (field) => field.value === watch("form_phone_number_field")
                )}
              />
            </FormControl>
          )}

          <Flex justifyContent={"flex-start"}>
            <Link
              color={"blue.500"}
              href={
                "https://www.twilio.com/en-us/blog/better-twilio-authentication-csharp-twilio-api-keys"
              }
              isExternal
            >
              Get Keys <ExternalLinkIcon mx="2px" />
            </Link>
          </Flex>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          type="submit"
          isLoading={loadingStatus === "loading"}
          colorScheme={"blue"}
        >
          {"Finish"}
        </Button>
      </ModalFooter>
    </form>
  );
}
