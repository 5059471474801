import { Box, Flex, IconButton, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { IoCallOutline, IoInformationCircleOutline } from "react-icons/io5";
import { MdOutlineCalendarToday, MdPictureAsPdf } from "react-icons/md";

const ActionButtonsForm = ({ buttons, setButtons }) => {
  const [errors, setErrors] = useState([]);

  const handleAddRow = () => {
    if (buttons.length < 3) {
      setButtons([...buttons, { label: "", link: "", icon: "" }]);
      setErrors([...errors, false]); // Add error state for new row
    }
  };

  const handleRemoveRow = (index) => {
    const updatedButtons = [...buttons];
    updatedButtons.splice(index, 1);
    setButtons(updatedButtons);
    setErrors(errors.filter((_, i) => i !== index)); // Remove error state for removed row
  };

  const handleChange = (index, field, value) => {
    const updatedButtons = [...buttons];
    const updatedErrors = [...errors];

    if (field === "label") {
      updatedButtons[index][field] = value.slice(0, 25);
    } else if (field === "link") {
      updatedButtons[index][field] = value;
    } else {
      updatedButtons[index][field] = value;
    }

    setButtons(updatedButtons);
    setErrors(updatedErrors);
  };

  const icons = {
    calendar: MdOutlineCalendarToday,
    info: IoInformationCircleOutline,
    quote: HiOutlineCurrencyDollar,
    call: IoCallOutline,
    pdf: MdPictureAsPdf,
  };

  return (
    <Box>
      {buttons.map((button, index) => (
        <Flex flexDirection="column" key={index} mt={2}>
          <Box display="flex" alignItems="center">
            <Input
              width="50%"
              value={button.label}
              placeholder="Label"
              onChange={(e) => handleChange(index, "label", e.target.value)}
              mr={2}
            />
            <Input
              value={button.link}
              placeholder="Link"
              onChange={(e) => handleChange(index, "link", e.target.value)}
              mr={2}
            />
            <Box ml={2} cursor="pointer">
              <RxCross2 onClick={() => handleRemoveRow(index)} />
            </Box>
          </Box>

          <Flex mt={2} gap={1}>
            {Object.keys(icons).map((iconKey) => (
              <IconButton
                key={iconKey}
                icon={React.createElement(icons[iconKey])}
                aria-label={iconKey}
                onClick={() => handleChange(index, "icon", iconKey)}
                colorScheme={button.icon === iconKey ? "blue" : undefined}
              />
            ))}
          </Flex>
          {index < buttons.length - 1 && (
            <Box borderBottom="1px solid #e2e8f0" mt={3} mb={2}></Box>
          )}
        </Flex>
      ))}
      {buttons.length < 3 && (
        <IconButton
          icon={<FaPlus />}
          aria-label="Add Row"
          onClick={handleAddRow}
          mt={2}
        />
      )}
    </Box>
  );
};

export default ActionButtonsForm;
