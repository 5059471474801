import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import AddAssistantBegginer from "../Assistants/AddAssistantBegginer";
import DataSourcesBegginers from "../DataSources/DataSourcesBegginers/DataSourcesBegginers";
import AddWidgetBegginer from "../Widgets/AddWidgetBegginer";

const InstructionModal = ({ onClose, isOpen }) => {
  const finalRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [dataSourcesCompleted, setDataSourcesCompleted] = useState(false);
  const [assistantCompleted, setAssistantCompleted] = useState(false);
  const [assistantId, setAssistantId] = useState("");
  const [intentId, setIntentId] = useState("");
  const [intentEmail, setIntentEmail] = useState("");
  const [dataSourceId, setDataSourceId] = useState("");
  const [files, setFiles] = useState([]);
  // Simulating API calls or user progress tracking
  const checkDataSourcesCompletion = async () => {
    // Replace this with your actual API call or logic to check data sources completion
    // For example:
    // const result = await fetchDataSourcesCompletion();
    // setDataSourcesCompleted(result);
    setDataSourcesCompleted(true); // Placeholder
  };

  const checkAssistantCompletion = async () => {
    // Replace this with your actual API call or logic to check assistant completion
    // For example:
    // const result = await fetchAssistantCompletion();
    // setAssistantCompleted(result);
    setAssistantCompleted(true); // Placeholder
  };

  return (
    <>
      <Modal
        size="2xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          setActiveIndex(0);

          onClose();
        }}
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
        <ModalContent
          height="81vh"
          overflowX="scroll"
          width="100%"
          maxWidth="1000px"
          p={5}
        >
          {activeIndex === 0 && (
            <ModalHeader pt={2} pb={0} fontSize="25px" color="#3182ce">
              Let's get started!
            </ModalHeader>
          )}
          {activeIndex === 1 && (
            <ModalHeader
              pt={0}
              pb={0}
              display="flex"
              gap="5px"
              alignItems="center"
            >
              <MdArrowBackIosNew
                onClick={() => {
                  setActiveIndex(0);
                }}
                size="18px"
                style={{ marginTop: "5px", cursor: "pointer" }}
              />
              <Box fontSize="25px" color="#3182ce">
                Great going, we're almost there!
              </Box>
            </ModalHeader>
          )}
          {activeIndex === 2 && (
            <ModalHeader pt={2} pb={0} fontSize="25px" color="#3182ce">
              Final step! Launching your Bot
            </ModalHeader>
          )}
          <ModalCloseButton />
          <ModalBody>
            <Tabs index={activeIndex}>
              <TabList>
                <Tab isDisabled={activeIndex != 0}>Data sources</Tab>
                <Tab isDisabled={activeIndex != 1}>Assistant</Tab>
                <Tab isDisabled={activeIndex != 2}>Widget</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Box>
                    <DataSourcesBegginers
                      setFiles={setFiles}
                      setDataSourceId={setDataSourceId}
                      files={files}
                      setActiveIndex={setActiveIndex}
                      activeIndex={activeIndex}
                    />
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box>
                    <AddAssistantBegginer
                      dataSourceId={dataSourceId}
                      setActiveIndex={setActiveIndex}
                      activeIndex={activeIndex}
                      setAssistantId={setAssistantId}
                    />
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box mt={4}>
                    <AddWidgetBegginer
                      setActiveIndex={setActiveIndex}
                      activeIndex={activeIndex}
                      assistantId={assistantId}
                      onClose={onClose}
                    />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InstructionModal;
