import { theme } from "@chakra-ui/react";

export const predefinedColors = [
    { color: theme.colors.yellow[400], name: "Yellow[400]" },
    { color: theme.colors.yellow[500], name: "Yellow[500]" },
    { color: theme.colors.yellow[600], name: "Yellow[600]" },
    { color: theme.colors.yellow[700], name: "Yellow[700]" },
    { color: theme.colors.yellow[800], name: "Yellow[800]" },
    { color: theme.colors.yellow[900], name: "Yellow[900]" },
    { color: theme.colors.blue[400], name: "Blue[400]" },
    { color: theme.colors.blue[500], name: "Blue[500]" },
    { color: theme.colors.blue[600], name: "Blue[600]" },
    { color: theme.colors.blue[700], name: "Blue[700]" },
    { color: theme.colors.blue[800], name: "Blue[800]" },
    { color: theme.colors.blue[900], name: "Blue[900]" },
    { color: theme.colors.green[400], name: "Green[400]" },
    { color: theme.colors.green[500], name: "Green[500]" },
    { color: theme.colors.green[600], name: "Green[600]" },
    { color: theme.colors.green[700], name: "Green[700]" },
    { color: theme.colors.green[800], name: "Green[800]" },
    { color: theme.colors.green[900], name: "Green[900]" },
    { color: theme.colors.red[400], name: "Red[400]" },
    { color: theme.colors.red[500], name: "Red[500]" },
    { color: theme.colors.red[600], name: "Red[600]" },
    { color: theme.colors.red[700], name: "Red[700]" },
    { color: theme.colors.red[800], name: "Red[800]" },
    { color: theme.colors.red[900], name: "Red[900]" },
    { color: theme.colors.gray[400], name: "Gray[400]" },
    { color: theme.colors.gray[500], name: "Gray[500]" },
    { color: theme.colors.gray[600], name: "Gray[600]" },
    { color: theme.colors.gray[700], name: "Gray[700]" },
    { color: theme.colors.gray[800], name: "Gray[800]" },
    { color: theme.colors.gray[900], name: "Gray[900]" },
    // { color: theme.colors.black, name: "Black" },
    { color: theme.colors.purple[400], name: "Purple[400]" },
    { color: theme.colors.purple[500], name: "Purple[500]" },
    { color: theme.colors.purple[600], name: "Purple[600]" },
    { color: theme.colors.purple[700], name: "Purple[700]" },
    { color: theme.colors.purple[800], name: "Purple[800]" },
    { color: theme.colors.purple[900], name: "Purple[900]" },
    { color: theme.colors.pink[400], name: "Pink[400]" },
    { color: theme.colors.pink[500], name: "Pink[500]" },
    { color: theme.colors.pink[600], name: "Pink[600]" },
    { color: theme.colors.pink[700], name: "Pink[700]" },
    { color: theme.colors.pink[800], name: "Pink[800]" },
    { color: theme.colors.pink[900], name: "Pink[900]" },
  ];