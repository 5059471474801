export function getReadableSeconds(seconds) {
  if (!seconds) return "-";
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursLabel = hours === 1 ? "hour" : "hours";
  const minutesLabel = minutes === 1 ? "min" : "mins";
  const secondsLabel = remainingSeconds === 1 ? "sec" : "secs";

  let result = "";
  if (hours > 0) result += `${hours} ${hoursLabel} `;
  if (minutes > 0 || hours > 0) result += `${minutes} ${minutesLabel} `;
  result += `${remainingSeconds} ${secondsLabel}`;

  return result.trim();
}