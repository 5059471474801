import {
  Box,
  Center,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useContext, useRef } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../utils/Loader/Paginator";
import { showToast } from "../../utils/Toast/Toast";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const LinkFormAssistantModal = ({
  isOpen,
  onOpen,
  onClose,
  selectedItem,
  loadForm,
  setIsLoading,
  isLoading,
  page,
  pages,
  setPage,
  tableData,
  searchTerm,
  setSearchTerm,
  setPaginationSize,
  paginationSize,
}) => {
  const baseUrl = getHostDomain();
  const finalRef = useRef(null);

  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;
  const handleCheckboxChange = async (item) => {
    const endpoint = `/api/v1/assistant/${selectedItem?.id}/${item?.id}/LinkAssistantForm`;
    const urlHit = baseUrl + endpoint;
    const body = {
      org_id: null,
      assistant_id: selectedItem?.id,
      form_id: item?.id,
      attributes: {},
    };
    try {
      setIsLoading(true);

      // If the checkbox is checked, unlink the data source
      if (item.linked) {
        // Unlink the data source
        const unlinkEndpoint = `/api/v1/assistant/${selectedItem?.id}/${item?.id}/LinkAssistantForm`;
        const unlinkUrl = baseUrl + unlinkEndpoint;
        await fetchController(unlinkUrl, "DELETE");
        toast.success("Assistant Unlinked");
      } else {
        // If the checkbox is unchecked, link the data source
        const getData = await fetchController(urlHit, "POST", body);
        if (getData?.data) {
          showToast("Assistant Linked", "success");
        }
      }
      item.linked = !item.linked;
      loadForm();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent height="70vh" width="95%" maxWidth="600px">
          {isLoading && (
            <Center
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              background="rgba(255, 255, 255, 0.8)"
              zIndex="999"
            >
              <Spinner />
            </Center>
          )}
          <ModalHeader>Linked Forms</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb="4">
              <Input
                placeholder="Search by name or description"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
            <TableContainer height="50vh" overflowY="Scroll">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Assign</Th>
                    <Th>Name</Th>
                    <Th>Form Type</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {tableData &&
                    tableData.map((item) => {
                      return (
                        <Tr key={item.id}>
                          <Td>
                            <Checkbox
                              mt="10px"
                              ml="15px"
                              isChecked={item.linked}
                              onChange={() => handleCheckboxChange(item)}
                            />
                          </Td>
                          <Td>{item.name}</Td>
                          <Td>{item.form_type}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
              <Flex
                justifyContent={"flex-end"}
                alignItems={"center"}
                marginBlock={2}
                gap={2}
              >
                <PaginationSizeSelect
                  paginationSize={paginationSize}
                  setPaginationSize={setPaginationSize}
                  tableLength={tableData.length}
                />
                <Paginator
                  colorScheme={buttonColorScheme}
                  onChangePage={setPage}
                  page={page}
                  pages={pages}
                />
              </Flex>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LinkFormAssistantModal;
