import { FormControl, FormLabel } from "@chakra-ui/react";
import React from "react";
import { Select } from "chakra-react-select"
export default function ModMedSelectField({ field, value }) {
  const { name, response, label, onChange, getOptions } = field;
  const configurationName = `credentials.configuration.${name}`;
  const options = getOptions(response) || [];
  return (
    <FormControl isRequired>
      <FormLabel fontWeight={"bold"}>{label}</FormLabel>
      <Select
        required
        onChange={onChange(configurationName)}
        options={options}
        name={configurationName}
        value={options.find((option) => option.value === value)}
      />
    </FormControl>
  );
}
