import { BiLogoTelegram } from "react-icons/bi";
import {
  FaFacebookMessenger,
  FaInstagram,
  FaPhone,
  FaSms,
  FaWpforms,
} from "react-icons/fa";
import { FiPhoneMissed } from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io5";
import {
  MdOutlineLeaderboard,
  MdOutlineWebAsset,
  MdOutlineWifiCalling3,
} from "react-icons/md";
import { TbMessageChatbot } from "react-icons/tb";

export const providers = {
  twilio: {
    label: "Twilio",
    widgetTypes: [
      {
        value: "phone",
        label: "Deploy AI Agent Voice using Twilio",
        icon: <FaPhone />,
      },
      {
        value: "sms",
        label: "Deploy AI Agent Chat over SMS using Twilio",
        icon: <FaSms />,
      },
      {
        value: "html_call",
        label: "Embed Form that triggers AI Agent Callback to your visitors",
        icon: <MdOutlineWebAsset />,
      },
    ],
    logo: "/icons/twilio.svg",
  },
  whatsapp: {
    label: "WhatsApp",
    widgetTypes: [
      {
        value: "whatsapp",
        label:
          "Deploy your chatbot on WhatsApp. Also, responds to voice messages.",
        icon: <IoLogoWhatsapp />,
      },
    ],
    logo: "/icons/whatsapp.svg",
  },
  leadconnector: {
    label: "GHL",
    widgetTypes: [
      {
        value: "leadconnector",
        label: "Deploy chatbot to power your GoHighLevel Chat Widget",
        icon: <MdOutlineLeaderboard />,
      },
      {
        value: "leadconnector_call",
        label: "Automate AI Agent callback on missed call on your GHL number",
        icon: <FiPhoneMissed />,
      },
    ],
    logo: "https://images.saasworthy.com/tr:w-160,h-0,c-at_max,e-sharpen-1/highlevel_29566_logo_1712921952_nwbzw.png",
  },
  instagram: {
    label: "Instagram",
    widgetTypes: [
      {
        value: "instagram",
        label: "Deploy AI Agent Chat on your Instagram",
        icon: <FaInstagram />,
      },
    ],
    logo: "/icons/instagram.svg",
  },
  plivo: {
    label: "Plivo",
    widgetTypes: [
      {
        value: "plivo_call",
        label: "Deploy AI Agent Voice using Plivo",
        icon: "/icons/plivo.svg",
      },
    ],
    logo: "/icons/plivo.svg",
  },
  telnyx: {
    label: "Telnyx",
    widgetTypes: [
      {
        value: "telnyx_call",
        label: "Deploy AI Agent Voice using Telnyx",
        icon: "/icons/telnyx.svg",
      },
    ],
    logo: "/icons/telnyx.svg",
  },
  telegram: {
    label: "Telegram",
    widgetTypes: [
      {
        value: "telegram",
        label: "Deploy AI Agent Chat on Telegram",
        icon: <BiLogoTelegram />,
      },
    ],
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/2048px-Telegram_2019_Logo.svg.png",
  },
  fb_messenger: {
    label: "FB Messenger",
    widgetTypes: [
      {
        value: "fb_messenger",
        label: "Deploy AI Agent Chat on your Facebook Page",
        icon: <FaFacebookMessenger />,
      },
    ],
    logo: "https://cdn4.iconfinder.com/data/icons/social-media-2285/1024/logo-512.png",
  },
  insighto: {
    label: "System",
    widgetTypes: [
      {
        value: "chat",
        label: "Deploy or embed chatbot on your website",
        icon: <TbMessageChatbot />,
      },
      {
        value: "web_call",
        label: "Embed AI Agent voice on any webpage",
        icon: <MdOutlineWifiCalling3 />,
      },
      {
        value: "render_form",
        label: "Render form",
        icon: <FaWpforms />,
      },
    ],
    logo: "/logo/favicon.png",
  },
};


export const allowedAssistantTypesByWidgets = {
  chat: ["simple"],
  whatsapp: ["simple"],
  sms: ["simple"],
  fb_messenger: ["simple"],
  leadconnector: ["simple"],
  instagram: ["simple"],
  telegram: ["simple"],
  phone: ["phone", "realtime_openai"],
  html_call: ["phone", "realtime_openai"],
  leadconnector_call: ["phone", "realtime_openai"],
  web_call: ["phone", "realtime_openai"],
  plivo_call: ["phone", "realtime_openai"],
  telnyx_call: ["phone", "realtime_openai"],
};

export const getWidgetProvider = ({ type, logo, name }) => {
  const widgetProviders = Object.entries(providers).reduce(
    (acc, [provider, providerMeta]) => {
      if (provider !== "insighto") {
        providerMeta.widgetTypes.forEach((widgetType) => {
          acc[widgetType.value] = {
            label: providerMeta.label,
            logo: providerMeta.logo,
          };
        });
      }
      return acc;
    },
    {}
  );

  return (
    widgetProviders[type] || {
      label: name,
      logo,
    }
  );
};

export const getProviderByAgency = ({ provider, logo, name }) => {
  if (provider === "insighto") {
    return { logo, label: name };
  }
  return providers[provider];
};

export const ghlLogo =
  "https://images.saasworthy.com/tr:w-160,h-0,c-at_max,e-sharpen-1/highlevel_29566_logo_1712921952_nwbzw.png";

export const WIDGET_PROVIDERS = {
  phone: {
    label: "Twilio",
    icon: <img src="/icons/twilio.svg" width={25} />,
  },
  sms: {
    label: "SMS",
    icon: <img src="/icons/twilio.svg" width={25} />,
  },
  whatsapp: {
    label: "WhatsApp",
    icon: <img src="/icons/whatsapp.svg" width={25} />,
  },
  leadconnector: {
    label: "GHL Connect",
    icon: <img src={ghlLogo} width={25} />,
  },
  leadconnector_call: {
    label: "GHL Connect",
    icon: <img src={ghlLogo} width={25} />,
  },
  instagram: {
    label: "Instagram",
    icon: <img src="/icons/instagram.svg" width={25} />,
  },
  plivo_call: {
    label: "Plivo Call",
    icon: <img src="/icons/plivo.svg" width={25} />,
  },
  telnyx_call: {
    label: "Telnyx Call",
    icon: <img src="/icons/telnyx.svg" width={25} />,
  },
  telegram: {
    label: "Telegram",
    icon: (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/2048px-Telegram_2019_Logo.svg.png"
        width={25}
      />
    ),
  },
  fb_messenger: {
    label: "Facebook Messenger",
    icon: (
      <img
        src="https://cdn4.iconfinder.com/data/icons/social-media-2285/1024/logo-512.png"
        width={25}
      />
    ),
  },
};
