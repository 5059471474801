import { useCallback, useEffect, useState } from "react";
import {
  fetchBillingData,
  fetchCouponPlan,
  processBillingPlans,
} from "../Billing/utils";

const useBillingPlans = () => {
  const [billingPlans, setBillingPlans] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [coupanBillingPlans, setCoupanBillingPlans] = useState([]);

  const getBillingPlan = useCallback(async () => {
    setIsLoading(true);
    const response = await fetchBillingData();
    if (!response) return setIsLoading(false);
    const { billingPlansData, userDetails, metadataMap } = response;
    setUserDetails(userDetails);
    const formattedPlans = processBillingPlans(
      billingPlansData,
      userDetails,
      metadataMap
    );
    setBillingPlans(formattedPlans);

    const isCouponPlan = !billingPlansData.items.some(
      (plan) => plan.id === userDetails.active_billing_plan_id
    );
    if (isCouponPlan) {
      const couponPlanRes = await fetchCouponPlan(userDetails, metadataMap);
      if (couponPlanRes) setCoupanBillingPlans([couponPlanRes]);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
      getBillingPlan();
  }, [getBillingPlan]);

  return {
    billingPlans,
    userDetails,
    isLoading,
    coupanBillingPlans,
    setIsLoading,
  };
};

export default useBillingPlans;
