import { Box, Button, FormControl, Stack, Textarea } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";

const AgencyScript = ({ brandingForm, getAgencies }) => {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = brandingForm;
  const agency = useContext(AgencyContext);
  const { textColor, agencyData, buttonColorScheme, colorPreset } = agency;

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await fetchController(
        baseUrl + `/api/v1/agency/${agencyData.id}`,
        "PUT",
        data
      );
      getAgencies();
      toast.success(response?.message || "Script uploaded successfully");
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          spacing={4}
          mb="4"
          width={"100%"}
          justifyContent={"center"}
        >
          <FormControl>
            <FormLabelWithInfoTooltip label={"Script"} />
            <Textarea
              placeholder="Enter your script here..."
              {...register("branding.agency_script")}
              resize="vertical"
              minHeight={"200px"}
            />
            {errors?.branding?.agency_script && (
              <Box color="red.500" fontSize="sm" mt="1">
                {errors.branding.agency_script.message}
              </Box>
            )}
          </FormControl>
        </Stack>

        <Button
          isLoading={isLoading}
          width="80px"
          colorScheme={buttonColorScheme}
          type="submit"
          mt="2"
          isDisabled={!agencyData}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default AgencyScript;
