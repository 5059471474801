import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tag,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import ContactFieldsList from "../Modals/ContactList";
import PromptTemplateSelect from "./PromptTemplateSelect";
import ConversationFlowSelect from "./ConversationFlowSelect";

const Prompt = ({ promptType }) => {
  const [customFieldTags, setCustomFieldTags] = useState([]);

  const {
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    isOpen: contactIsOpen,
    onOpen: contactOnOpen,
    onClose: contactOnClose,
  } = useDisclosure();

  const inputRef = useRef(null);

  const extractCustomFieldKeywords = (text) => {
    const regex = /\{{(.*?)\}}/g;
    let matches = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1]);
    }
    return matches;
  };

  useEffect(() => {
    const keywords = extractCustomFieldKeywords(watch("systemPrompt"));
    setCustomFieldTags(keywords);
  }, [watch("systemPrompt")]);

  return (
    <Box px={4} py={2}>
      <Flex
        flexDirection={"column"}
        gap={2}
        height={watch("assistant_type") === "simple" ? "95%" : ""}
      >
        {promptType?.value === "conversation_flow" && (
          <Box>
            <ConversationFlowSelect setValue={setValue} watch={watch} />
          </Box>
        )}

        {promptType?.value === "prompt" && (
          <>
            <Box>
              <PromptTemplateSelect setValue={setValue} watch={watch} />
            </Box>
            <Box>
              {customFieldTags.map((keyword, index) => (
                <Tag key={index} colorScheme="blue" mr={2} mb={2}>
                  {keyword}
                </Tag>
              ))}
            </Box>
            <Box>
              <FormControl height={"100%"} isInvalid={errors.systemPrompt}>
                <FormLabel>Prompt</FormLabel>
                <Textarea
                  ref={inputRef}
                  rows={watch("assistant_type") === "simple" ? 27 : 18}
                  placeholder="System Prompt"
                  {...register("systemPrompt", {
                    required: "System Prompt is required",
                  })}
                  resize="vertical"
                  onChange={(e) => {
                    const text = e.currentTarget.value;
                    setValue("systemPrompt", text);
                    setValue("conversation_flow_id", null);
                    if (/\/$/.test(text)) contactOnOpen();
                  }}
                  h={"100%"}
                />
                <Text fontSize="sm" fontStyle="italic" mt={1}>
                  {`To personalize, use your contact, dynamic, or your CRM variables within your prompt. Dynamic variables can just be enclosed within double curly braces. For rest, start by pressing "/"`}
                </Text>
                <FormErrorMessage>
                  {errors.systemPrompt?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </>
        )}
      </Flex>
      <ContactFieldsList
        getValue={getValues("systemPrompt")}
        setValue={setValue}
        isOpen={contactIsOpen}
        onClose={contactOnClose}
        watch={watch}
      />
    </Box>
  );
};

export default Prompt;
