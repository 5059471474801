import { Box, Button, Stack, useTheme } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { GrPhone } from "react-icons/gr";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import CampaignFields from "../Contacts/CampaignFields";
import PhoneDialer from "./PhoneDialer";
export default function CallDropDownModal() {
  const baseUrl = getHostDomain();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      widget: undefined,
      message: "",
      phoneNumber: "",
      countryCode: "",
    },
  });
  const { errors } = formState;
  const onStartCampaign = async (values) => {
    try {
      const contactsEndpoint = "/api/v1/contact";
      const newContactsEndpoint = baseUrl + contactsEndpoint;
      if (!values.widget) {
        setError("widget", {
          type: "required",
        });
      }
      if (!values.countryCode) {
        setError("countryCode", {
          type: "required",
        });
        return;
      }
      if (!values.phoneNumber) {
        setError("phoneNumber", {
          type: "required",
        });
        return;
      }
      const body = {
        first_name: null,
        last_name: null,
        email: null,
        last_seen: new Date().toISOString(),
        last_sent: new Date().toISOString(),
        channels: {
          whatsapp: null,
          web: null,
          instagram: null,
          telephone: `${values.countryCode}${values.phoneNumber}`,
          microsoft_teams: null,
          dial_code: values.countryCode,
        },
      };
      const response = await fetchController(newContactsEndpoint, "POST", body);
      const widgetId = values.widget.value.id;
      const campaignResponse = await fetchController(
        baseUrl + `/api/v1/campaign/run/${widgetId}`,
        "POST",
        {
          contact_ids: [response.data.id],
          message: values.message,
        }
      );
      toast.success(campaignResponse.status);
      reset();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const onDialerKeyPress = (key) => () => {
    const values = getValues();
    setValue("phoneNumber", values.phoneNumber + key);
  };
  const theme = useTheme();
  // const colorScheme = useColorModeValue("green", theme.colors.teal[200])
  return (
    <Box>
      <form onSubmit={handleSubmit(onStartCampaign)}>
        <Stack spacing={2}>
          <CampaignFields
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
          />
          <PhoneDialer
            errors={errors}
            onDialerKeyPress={onDialerKeyPress}
            register={register}
          />
          <Button
            isLoading={formState.isSubmitting}
            type="submit"
            leftIcon={<GrPhone />}
            colorScheme={"blue"}
          >
            Call
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
