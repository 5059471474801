import { forwardRef, useState, useEffect } from "react";
import _ from "lodash";
import {
  Input,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Text,
  useDisclosure,
  IconButton,
  InputRightElement,
  InputGroup,
  Spinner,
  Tag,
} from "@chakra-ui/react";
import { formatName, providers } from "../../utils/utils";
import { TbMessageChatbot } from "react-icons/tb";
import EditWidget from "../Modals/EditWidget";
import { useNavigate } from "react-router-dom";
import DataSourcesModalLIST from "../Modals/SourceFileList";
import { CloseIcon } from "@chakra-ui/icons";
const excludedEntityType = "form";
const ENTITY_TYPES = ["widget_type", "assistant_type", "ds_type"];
const SearchInput = forwardRef(
  (
    { name, options, value, onChange, onTextChange, loading, tag, ...props },
    ref
  ) => {
 
    const [active, setActive] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();
    const {
      isOpen: isWidgetEditOpen,
      onOpen: onOpenWidgetEdit,
      onClose: onCloseWidgetEdit,
    } = useDisclosure();
    const {
      isOpen: openList,
      onOpen: onOpenList,
      onClose: onCloseList,
    } = useDisclosure();
    const handleTextChange = (text) => {
      onTextChange(text);
      onChange(text);
      setActive(0);
    };
    useEffect(() => {
      setIsOpen(value.length > 0);
    }, [value, options]);
    const groupedOptions = options.reduce((acc, option) => {
      if (option.entity_type === excludedEntityType) return acc; // Skip this entity type

      const group = option.entity_type;
      if (!acc[group]) acc[group] = [];
      acc[group].push(option);

      return acc;
    }, {});
    const handleOption = (option) => {
      if (option.entity_type === "widget") {
        setSelectedItem(option.entity_body);
        onOpenWidgetEdit();
      } else if (option.entity_type === "assistant") {
        navigate(`/ai-agents/assistants/${option.entity_body.id}`);
      } else if (option.entity_type === "data_source") {
        setSelectedItem(option.entity_body);
        onOpenList();
      } else if (option.entity_type === "form") {
        navigate(`/forms/${option.entity_body.id}`);
      }
      onChange("");
    };
    return (
      <Box width="400px" ml={4}>
        <Popover {...props} isOpen={isOpen} autoFocus={false} matchWidth>
          <PopoverTrigger>
            <InputGroup>
              <Input
                placeholder="search by tag"
                ref={ref}
                name={name}
                type="text"
                value={value}
                autoComplete="off"
                onChange={(e) => handleTextChange(e.target.value)}
                isRequired
                id="dropdownId"
              />
              {value && (
                <InputRightElement>
                  <IconButton
                    size="sm"
                    icon={<CloseIcon />}
                    onClick={() => handleTextChange("")}
                    variant="ghost"
                    aria-label="Clear search"
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </PopoverTrigger>

          <PopoverContent w="400px">
            {loading ? (
              <Box p={2} display="flex" justifyContent="center">
                <Spinner size="sm" />
                <Text ml={2} fontSize="14px">
                  Loading...
                </Text>
              </Box>
            ) : options.length === 0 ? (
              <Box p={2} display="flex" justifyContent="center">
                <Text fontSize="14px" color="gray.500">
                  Nothing to show
                </Text>
              </Box>
            ) : (
              Object.entries(groupedOptions).map(([group, groupOptions]) => (
                <Box key={group} mt={2}>
                  <Text
                    fontWeight="bold"
                    bg="gray.200"
                    fontSize="14px"
                    ml={1}
                    mb={1}
                    p={1}
                  >
                    {formatName(group)}
                  </Text>
                  {groupOptions.map((option, index) => (
                    <Box key={index} onClick={() => handleOption(option)}>
                      <Option
                        option={option}
                        index={index}
                        active={active}
                        setIsOpen={setIsOpen}
                        tag={tag}
                      />
                    </Box>
                  ))}
                </Box>
              ))
            )}
          </PopoverContent>
        </Popover>
        <EditWidget
          isOpen={isWidgetEditOpen}
          onClose={onCloseWidgetEdit}
          selectedWidget={selectedItem}
        />
        <DataSourcesModalLIST
          isOpen={openList}
          onOpen={onOpenList}
          onClose={onCloseList}
          selectedItem={selectedItem}
        />
      </Box>
    );
    function Option({ option, index, active, setIsOpen, tag }) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          onClick={() => {
            setIsOpen(false);
          }}
          p={2}
          bgColor={"transparent"}
          _hover={{ bgColor: "gray.100" }}
        >
          <Box display="flex" gap={2} alignItems="center">
            <Text noOfLines={1} cursor="pointer" flex="1">
              {option.entity_body.name}
            </Text>
            <Tag size="sm" colorScheme={tag.color_code}>
              {tag.name}
            </Tag>
          </Box>

          <Box
            display="flex"
            mt="5px"
            flexDirection="column"
            alignItems="flex-end"
          >
            <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {(() => {
                const key = ENTITY_TYPES.find(
                  (type) => option.entity_body[type]
                );
                return (
                  providers[option.entity_body[key]]?.icon || (
                    <TbMessageChatbot />
                  )
                );
              })()}
            </Box>
          </Box>
        </Box>
      );
    }
  }
);

SearchInput.displayName = "Dropdown";

export default SearchInput;
