import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Progress,
  Spinner,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaLanguage, FaRobot } from "react-icons/fa6";
import { Select } from "chakra-react-select"
import { toast } from "react-toastify";
import { languages } from "../../utils/Data/data";
import { fetchController } from "../../utils/FetchController/fetchController";
import { addIntentsToAssistant, getHostDomain } from "../../utils/utils";
import EmailSelector from "./Intent";

import { GrVmMaintenance } from "react-icons/gr";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { DEFAULT_LANGS } from "./constants";
const AddAssistantBegginer = ({
  setActiveIndex,
  setAssistantId,
  dataSourceId,
}) => {
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const { myDetails } = agency;
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      voice: true,
      llmModel: {
        value: "gpt-3.5-turbo-0125",
        label: "GPT-3.5-Turbo-0125",
      },
      systemPrompt: `As an AI assistant built by a team of engineers at ${
        myDetails?.branding?.agency_name || "Algoscale"
      }, please answer the user query with the context provided.`,
    },
  });
  const [llmModels, setLlmModels] = useState([]);

  const loadLLModels = useCallback(async () => {
    const response = await fetchController(baseUrl + "/api/v1/llmmodel/list");
    setLlmModels(
      response.data.items.map((llmModel) => ({
        value: llmModel.llm_model_label,
        label: llmModel.name,
      }))
    );
  }, []);
  const [defaultIntents, setDefaultIntents] = useState([]);

  const [emails, setEmails] = useState([]);
  useEffect(() => {
    loadLLModels();
    setValue("action", true);
    // Set default values for the Select components using setValue
    setValue("voiceLanguages", DEFAULT_LANGS);
  }, [setValue]);
  useEffect(() => {
    const intentList = "/api/v1/intent/list?page=1&size=50";
    const urlHitIntent = baseUrl + intentList;
    const fetch = async () => {
      const getIntentData = await fetchController(urlHitIntent, "GET");
      const filteredItems = getIntentData.data.items.filter((item) =>
        ["CAREER", "SALES", "LEAD", "SUPPORT"].includes(item.name)
      );
      setDefaultIntents(filteredItems);
    };
    fetch();
  }, []);
  const onSubmit = async (data) => {
    try {
      const endpoint = "/api/v1/assistant";
      const urlHit = baseUrl + endpoint;
      const body = {
        assistant_type: "simple",
        llm_model: data.llmModel.value,
        name: data.name || "string",
        description: data.description || "string",
        attributes: {},
        system_prompt: data.systemPrompt || "",
        voice: data.voice || false,
        voice_languages: data.voiceLanguages?.map((lang) => lang.value) || [],
        show_images: data.show_images || false,
      };

      const getAssistantData = await fetchController(urlHit, "POST", body);

      if (getAssistantData && getAssistantData.message) {
        const endpoint = `/api/v1/assistant/${getAssistantData?.data.id}/add_datasource/${dataSourceId}`;
        const urlHit = baseUrl + endpoint;
        await fetchController(urlHit, "POST");
        setAssistantId(getAssistantData.data.id);
        if (data.email !== "") {
          addIntentsToAssistant(getAssistantData, defaultIntents, emails);
        }

        reset();
        setActiveIndex(2);
      } else {
        console.error("Error in creating assistant:", getAssistantData);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Box
      height="83vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLabel
            display="flex"
            alignItems="center"
            gap={2}
            htmlFor="llmModel"
            fontSize="19px"
            fontWeight="bold"
          >
            Configure Assistant
          </FormLabel>
          <FormLabel
            display="flex"
            alignItems="center"
            gap={2}
            htmlFor="llmModel"
            fontSize="18px"
            fontWeight="bold"
          >
            <FaRobot size="18" color="#3182ce" />
            Model
          </FormLabel>
          <Box width="100%" mb="4" display="flex" gap="2px">
            <Box width="30%">
              <Input
                placeholder="Name"
                {...register("name", { required: "Name is required" })}
              />
              {errors?.name && (
                <Text fontSize="11px" color="red" mt="1">
                  {errors.name.message}
                </Text>
              )}
            </Box>

            <Box width="70%">
              <Input
                placeholder="Not used by the LLM. Please use system prompt field to finetune the model"
                {...register("description", {
                  required: "Description is required",
                })}
                resize="vertical"
              />
              {errors?.description && (
                <Text fontSize="11px" color="red" mt="1">
                  {errors.description.message}
                </Text>
              )}
            </Box>
          </Box>

          <Box mt="4">
            <Box>
              <Box display="flex" gap={2}>
                <FormControl isInvalid={errors?.llmModel} width="40%">
                  <Select
                    placeholder="Select LLM Model"
                    defaultValue={{
                      value: "gpt-3.5-turbo-1106",
                      label: "OpenAI GPT 3.5 Turbo-1106",
                    }}
                    options={llmModels}
                    {...register("llmModel", {
                      required: "LLM Model is required",
                    })}
                    onChange={(selectedOption) => {
                      setValue("llmModel", selectedOption || null, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                  />
                  {errors?.llmModel && (
                    <FormErrorMessage fontSize="11px" color="red" mt="1">
                      {errors?.llmModel.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <Textarea
                  placeholder="System Prompt"
                  {...register("systemPrompt", {
                    required: "System Prompt is required",
                  })}
                  resize="vertical"
                />
                {errors?.systemPrompt && (
                  <Text fontSize="11px" color="red" mt="1">
                    {errors?.systemPrompt.message}
                  </Text>
                )}
              </Box>

              <FormControl isInvalid={errors?.voiceLanguages} mt="3">
                <FormLabel
                  display="flex"
                  alignItems="center"
                  gap={2}
                  htmlFor="llmModel"
                >
                  <FaLanguage size="22" color="#3182ce" />{" "}
                  <Text fontSize="18px" fontWeight="bold">
                    Voice
                  </Text>
                  <Checkbox
                    ml="50px"
                    {...register("voice")}
                    onChange={(e) => setValue("voice", e.target.checked)}
                  >
                    Enable Voice Languages{" "}
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {"(max 4)"}
                    </span>
                  </Checkbox>
                </FormLabel>

                {watch("voice") && (
                  <>
                    <Select
                      placeholder="Select Voice Languages"
                      options={languages}
                      isMulti
                      {...register("voiceLanguages", {
                        required: "Voice Languages are required",
                      })}
                      defaultValue={languages.slice(0, 4)}
                      onChange={(selectedOption) => {
                        setValue("voiceLanguages", selectedOption || null, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                    />
                  </>
                )}
                {errors?.voiceLanguages && (
                  <FormErrorMessage fontSize="11px" color="red" mt="1">
                    {errors.voiceLanguages.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <hr
              style={{
                border: "1px solid #8080804f",
              }}
            />

            <Box pt={2}>
              <FormLabel
                display="flex"
                alignItems="center"
                gap={2}
                htmlFor="llmModel"
              >
                <GrVmMaintenance size="20" color="#3182ce" />
                <Text fontSize="18px" fontWeight="bold">
                  Intents
                </Text>
                <Checkbox
                  ml="37px"
                  type="radio"
                  name="action"
                  value="add"
                  defaultChecked
                  onChange={(e) => setValue("action", e.target.checked)}
                >
                  {" "}
                  Enable default Intent Capture and link with this assistant
                </Checkbox>
              </FormLabel>
              <Box>
                {errors?.action && (
                  <Text fontSize="11px" color="red">
                    {errors?.action.message}
                  </Text>
                )}
                {watch("action") && (
                  <>
                    <EmailSelector emails={emails} setEmails={setEmails} />
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <hr
            style={{
              border: "1px solid #8080804f",
            }}
          />

          <Box mt={3}>
            <Checkbox
              {...register("show_images")}
              onChange={(e) => setValue("show_images", e.target.checked)}
            >
              <Tooltip
                label="Assistant will display the relevant extracted images from the URLs or the ingested images from the data sources"
                aria-label="A tooltip"
              >
                <Text>Show Images</Text>
              </Tooltip>
            </Checkbox>
          </Box>
        </form>
      </Box>
      <hr style={{ border: "1px solid #8080804f" }} />

      <Box
        mt={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width="80%">
          <Text fontWeight="bold">Completed : 50%</Text>
          <Progress isAnimated hasStripe mt={1} width="100%" value={50} />
        </Box>
        <Button
          width="120px"
          colorScheme="blue"
          onClick={handleSubmit(onSubmit)}
        >
          {isSubmitting ? <Spinner size="sm" /> : "Launch Bot"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddAssistantBegginer;
