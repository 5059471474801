import { Select } from "chakra-react-select"
export default function ToolUserSelect({
  setValue,
  trigger_tools,
  toolUsers = [],
}) {
  const selectedToolUsersMap = (trigger_tools || []).reduce(
    (toolUserSelectedMap, currentToolUser) => {
      toolUserSelectedMap[currentToolUser.tool_user_id] = true;
      return toolUserSelectedMap;
    },
    {}
  );
  const options = toolUsers.map((toolUser) => ({
    value: toolUser,
    label: toolUser.name,
  }));
  const selectedToolUsers = options.filter(
    (option) => option.value.id in selectedToolUsersMap
  );
  return (
    <Select
      isMulti
      required
      value={selectedToolUsers}
      options={options}
      onChange={(selectedOptions) => {
        const newTriggerTools = selectedOptions.map((selectedOption) => ({
          tool_user_id: selectedOption.value.id,
        }));
        setValue("trigger_tools", newTriggerTools);
      }}
    />
  );
}
