import { Checkbox, useColorModeValue } from "@chakra-ui/react";
import React, { useContext, forwardRef } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const CustomCheckbox = forwardRef(({ onChange, children, ...props }, ref) => {
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;
  const borderColor = useColorModeValue("black", "white");

  return (
    <Checkbox
      ref={ref}
      colorScheme={buttonColorScheme}
      border={borderColor}
      _checked={{ borderColor: "transparent", boxShadow: "none" }}
      onChange={onChange}
      {...props}
    >
      {children}
    </Checkbox>
  );
});

export default CustomCheckbox;
