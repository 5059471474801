import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  Select,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoPause, IoPlay } from "react-icons/io5";
import { providers } from "./utils";
import { RiUserVoiceLine } from "react-icons/ri";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function PreviewVoices({ ttsList }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedVoice, setSelectedVoice] = useState();
  const [currentAudio, setCurrentAudio] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const uniqueProviders = [
    ...new Set(ttsList.map((tts) => tts.voicetts_provider).filter(Boolean)),
  ];
  const uniqueLanguages = [
    ...new Set(ttsList.map((tts) => tts.language_group).filter(Boolean)),
  ];

  const filteredVoices = ttsList.filter(
    (voice) =>
      voice?.attributes?.preview_url &&
      voice.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedProvider
        ? voice.voicetts_provider === selectedProvider
        : true) &&
      (selectedLanguage ? voice.language_group === selectedLanguage : true)
  );
  const totalPages = Math.ceil(filteredVoices.length / itemsPerPage);
  const paginatedVoices = filteredVoices.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const onClickPlayOfVoiceItem = (voiceItem) => {
    if (currentAudio) {
      currentAudio.pause();
      setCurrentAudio(null);
    }

    const audio = new Audio(voiceItem.attributes?.preview_url);
    audio.onended = () => setSelectedVoice(undefined);
    audio.play().catch((error) => console.error("Audio play failed", error));

    setSelectedVoice(voiceItem);
    setCurrentAudio(audio);
  };

  const onPauseOfVoiceItem = () => {
    if (currentAudio) {
      currentAudio.pause();
      setCurrentAudio(null);
    }
    setSelectedVoice(undefined);
  };

  return (
    <>
      <Button onClick={onOpen} size={"xs"}>
        Preview
      </Button>
      <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Voices Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH={500} overflowY={"auto"} pb={6}>
            <Flex gap={2} mb={4}>
              <Input
                placeholder="Search voice by name..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                flex={2}
              />
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  flex={1}
                >
                  {selectedProvider ? (
                    <Flex alignItems="center" gap={2}>
                      {providers[selectedProvider]?.icon ?? <RiUserVoiceLine />}
                      <Text>{selectedProvider}</Text>
                    </Flex>
                  ) : (
                    "Select Provider"
                  )}
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => setSelectedProvider("")}>
                    All Providers
                  </MenuItem>
                  {uniqueProviders.map((provider) => (
                    <MenuItem
                      key={provider}
                      onClick={() => setSelectedProvider(provider)}
                    >
                      {providers[provider]?.icon ?? <RiUserVoiceLine />}
                      <Text textTransform="capitalize">{provider}</Text>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  flex={1}
                >
                  {selectedLanguage || "Select Language"}
                </MenuButton>
                <MenuList maxH="200px" overflowY="auto">
                  <MenuItem onClick={() => setSelectedLanguage("")}>
                    All Languages
                  </MenuItem>
                  {uniqueLanguages.map((language) => (
                    <MenuItem
                      key={language}
                      onClick={() => setSelectedLanguage(language)}
                    >
                      <Text textTransform="capitalize">{language}</Text>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>
            <List>
              {paginatedVoices.length > 0 ? (
                paginatedVoices
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((voice) => (
                    <VoiceItem
                      key={voice.id}
                      voice={voice}
                      selectedVoice={selectedVoice}
                      onPauseOfVoiceItem={onPauseOfVoiceItem}
                      onClickPlayOfVoiceItem={onClickPlayOfVoiceItem}
                    />
                  ))
              ) : (
                <Text textAlign="center" color="gray.500">
                  No voices found
                </Text>
              )}
            </List>
            {totalPages > 1 && (
              <Flex justifyContent="center" alignItems="center" mt={4} gap={2}>
                <Select
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(Number(e.target.value));
                    setCurrentPage(1);
                  }}
                  width={"auto"}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
                <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  isDisabled={currentPage === 1}
                >
                  Prev
                </Button>
                <Text>
                  {currentPage} / {totalPages}
                </Text>
                <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  isDisabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function VoiceItem({
  voice,
  onClickPlayOfVoiceItem,
  selectedVoice,
  onPauseOfVoiceItem,
}) {
  return (
    <ListItem
      bg={selectedVoice?.id === voice.id ? "gray.100" : undefined}
      borderRadius={"xl"}
      border={"1px solid lightgray"}
      p={2}
      marginBlock={3}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Box>
          <Text fontWeight={"bold"} fontSize={"md"}>
            {voice.name}
          </Text>
          <Text color={"gray"}>
            {voice.voice}, {voice.laguage_group}
          </Text>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          {providers[voice.voicetts_provider]?.icon ?? <RiUserVoiceLine />}
          {selectedVoice?.id === voice.id ? (
            <IconButton icon={<IoPause />} onClick={onPauseOfVoiceItem} />
          ) : (
            <IconButton
              icon={<IoPlay />}
              onClick={() => onClickPlayOfVoiceItem(voice)}
            />
          )}
        </Box>
      </Flex>
    </ListItem>
  );
}
