import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import WebhookSelect from "../Forms/WebhookSelect";
import AssignIntent from "../Modals/AssignIntent";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import CommonSlider from "../../utils/CommonSlider";

const WebhookAndIntentFields = ({ assistantId = null }) => {
  const baseUrl = getHostDomain();
  const [linkedItemIntent, setLinkedItemIntent] = useState(0);
  const [heads, setHeads] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useFormContext();

  const {
    isOpen: isOpenIntent,
    onOpen: onOpenIntent,
    onClose: onCloseIntent,
  } = useDisclosure();

  const loadIntent = async () => {
    try {
      if(!assistantId) return;      
      setIsLoading(true);

      const getData = await fetchController(
        `${baseUrl}/api/v1/assistant/${assistantId}/list_intents`,
        "GET"
      );
      const getDataSources = await fetchController(
        `${baseUrl}/api/v1/intent/list?page=1&size=50`,
        "GET"
      );

      if (getData?.data && getDataSources?.data?.items) {
        const linkedItemIds = getData.data.items.map(
          (linkedItem) => linkedItem.id
        );
        setLinkedItemIntent(linkedItemIds.length);
        const updatedTableData = getDataSources.data.items.map((item) => ({
          ...item,
          linked: linkedItemIds.includes(item.id),
        }));

        const filteredTableData = updatedTableData.filter(
          (item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const heads =
          getDataSources?.data?.items?.length >= 0
            ? Object.keys(getDataSources.data.items[0])
            : [];
        setHeads(heads);
        setTableData(filteredTableData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadIntent();
  }, [isOpenIntent, searchTerm]);
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;

  const [webhook, setWebhook] = useState("");
  const [status, setStatus] = useState("idle");

  const [selectedAssistant, setSelectedAssistant] = useState({
    id: assistantId,
  });

  useEffect(() => {
    (async () => {
      try {
        if (!selectedAssistant?.id) return;        
        setStatus("loading");
        const response = await fetchController(
          baseUrl + `/api/v1/assistant/${selectedAssistant.id}`
        );
        setWebhook(response.data?.webhook_id || "");
        setStatus("idle");
      } catch (e) {
        setStatus("idle");
        console.error(e);
      }
    })();
  }, [selectedAssistant]);

  const loading = status === "loading";
  const onSubmit = async (e) => {
    try {
      setStatus("assigning");
      await fetchController(
        baseUrl + `/api/v1/assistant/${selectedAssistant.id}`,
        "PUT",
        { webhook_id: webhook }
      );
      toast.success(webhook ? "Webhook assigned" : "Webhook un-assigned");
      setStatus("idle");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box px={4} py={2}>
      {watch("assistant_type") !== "phone" ? (
        <Box>
          <Text fontWeight="bold">Intent</Text>
          <Flex mb={5} justifyContent={"space-between"}>
            <Tooltip
              aria-label="Connected Form"
              placement="right"
              label={
                assistantId
                  ? ""
                  : "You can connect Intent after you’ve created the Assistant"
              }
            >
              <Box>
                <Button
                  size="xs"
                  type="button"
                  isDisabled={!assistantId}
                  colorScheme={buttonColorScheme}
                  onClick={() => {
                    onOpenIntent();
                  }}
                >
                  Assign Intent
                </Button>
              </Box>
            </Tooltip>
            <Box>
              <Text as={"b"}>{"Connected intent:"}</Text>{" "}
              <Text as={"b"}>{linkedItemIntent}</Text>
            </Box>
          </Flex>
        </Box>
      ) : null}
      <Flex justifyContent={"space-between"}>
        <Tooltip
          aria-label="Connected Form"
          placement="right"
          label={
            assistantId
              ? ""
              : "You can connect Webhook after you’ve created the Assistant"
          }
        >
          <Box>
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box spacing={3}>
                  {loading ? (
                    <Flex justifyContent={"center"} alignItems={"center"}>
                      <Spinner />
                    </Flex>
                  ) : (
                    <Box>
                      <FormControl>
                        <FormLabel>Select webhook</FormLabel>
                        <Flex gap={2} alignContent="center" width="500px">
                          <Box flex={1}>
                            <WebhookSelect
                              webhook_id={webhook}
                              onChangeWebhook={(value) => {
                                value
                                  ? setWebhook(value.value)
                                  : setWebhook(null);
                              }}
                            />
                          </Box>
                          <Button
                            isLoading={status === "assigning"}
                            isDisabled={status === "loading"}
                            type="submit"
                            colorScheme="blue"
                            onClick={handleSubmit(onSubmit)}
                            width="100px"
                          >
                            Save
                          </Button>
                        </Flex>
                      </FormControl>
                    </Box>
                  )}
                  <Button
                    size="xs"
                    colorScheme="blue"
                    as={Link}
                    to="/settings/webhooks"
                    width="fit-content"
                  >
                    Create webhook
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Tooltip>
      </Flex>
      {watch("assistant_type") === "simple" && (
        <Box mt="2">
          <CommonSlider
            watch={watch}
            label={<b>Conversation Timeout</b>}
            setValue={setValue}
            min={1}
            max={180}
            name="attributes.conversation_timeout"
            step={1}
            markLabel="m"
            ml="-5%"
            toolTipLabel="The Conversation shall be closed & concluded if no activity within the defined duration. Default value for native widget is 3 minutes while for webhook-supported widgets, it is 2 hours."
          />
        </Box>
      )}
      {isOpenIntent && (
        <AssignIntent
          isOpen={isOpenIntent}
          onOpen={onOpenIntent}
          onClose={onCloseIntent}
          selectedItem={{ id: assistantId }}
          tableData={tableData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setSearchTerm={setSearchTerm}
          loadIntent={loadIntent}
        />
      )}
    </Box>
  );
};

export default WebhookAndIntentFields;
