import { ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { useContext } from "react";
import TourLayout from "../Layout/TourLayout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export default function CreateFormGuide({ onCloseTour }) {
  const agency = useContext(AgencyContext);
  const { textColor } = agency;
  return (
    <TourLayout onCloseTour={onCloseTour}>
      <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
        Adding a new Form
      </Text>
      <UnorderedList fontSize={"md"}>
        <ListItem>
          Get started by adding a new form below and then link it to one of the
          Assistants. Once linked, it will be pushed to the user during the
          conversation based on the{" "}
          <i>
            <strong>trigger instructions</strong>
          </i>{" "}
          provided by you
        </ListItem>
        <ListItem>
          Form can be of two types: Simple or Natural
          <UnorderedList>
            <ListItem>
              <strong> Simple form </strong> appear in the regular label & field
              boxes format
            </ListItem>
            <ListItem>
              <strong> Natural form </strong> are naturally blended in the
              conversation based on a certain trigger (like if someone shows
              interest in a product listing or makes a search query)
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          It also lets you follow-up to your customer on WhatsApp as soon as
          they fill up the form. Just map the contact field of the user from the
          form and it will do the rest.
        </ListItem>
      </UnorderedList>
    </TourLayout>
  );
}
