import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import { BillingOptions } from "./BillingTabs";
import BillingCard from "../BillingCard/BillingCard";

const PlansSection = ({ billingPlans, isSalesBtnisvisible, userDetails }) => {
  const agency = useContext(AgencyContext);
  const { textColor, cardBg, buttonColorScheme } = agency;
  const [selectedTab, setSelectedTab] = useState("endUser");

  const activePlan = billingPlans.find(
    (plan) => plan.id === userDetails.active_billing_plan_id
  );
  useEffect(() => {
    if (activePlan) {
      const isAgencyPlan = activePlan.features[0]?.Agency === 1;
      setSelectedTab(isAgencyPlan ? "agency" : "endUser");
    }
  }, [activePlan]);

  const agencyUserPlans = useMemo(() => {
    return billingPlans.filter((plan) => {
      const agencyFeature = plan.features[0]?.Agency;
      return selectedTab === "endUser" ? !agencyFeature : agencyFeature === 1;
    });
  }, [billingPlans, selectedTab]);

  return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={2}>
        <Box>
          {isSalesBtnisvisible && (
            <BillingOptions
              selectedOption={selectedTab}
              setSelectedOption={setSelectedTab}
            />
          )}
        </Box>
        <Flex width="90%" gap={3} justifyContent="center" m="auto" alignContent={"center"}>
          {(isSalesBtnisvisible ? agencyUserPlans : billingPlans).map(
            (item, index) => (
              <BillingCard
                key={index}
                features={item?.features}
                popular={item.popular}
                active={item.isActive}
                planName={item.name}
                lookup_key={item.plan}
                pricing={item.pricing}
                item={item}
                label={item.label}
                erlyBirdsOffer={item.erlyBirdsOffer}
                isDisable={item.isDisable}
                isTrial={item.is_trial}
                bg={cardBg}
                color={textColor}
                buttonColorScheme={buttonColorScheme}
              />
            )
          )}
        </Flex>
        <Text fontSize="18px" p={3}>
          {userDetails.is_trial && <>You're currently using our trial.</>}
        </Text>
      </Box>
  );
};

export default PlansSection;
