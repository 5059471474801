import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";

export default function BillingPortalButton({ buttonColorScheme }) {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);

  const handleBillingPortal = async () => {
    setIsLoading(true);
    try {
      const response = await fetchController(
        baseUrl + "/api/v1/billing/create-billing-portal-session",
        "POST"
      );
      window.open(response?.checkout_url, "_blank");
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
    size={"sm"}
      colorScheme={buttonColorScheme}
      isLoading={isLoading}
      loadingText={"Generating"}
      onClick={handleBillingPortal}
    >
      Access Billing Portal
    </Button>
  );
}
