import {
  Button,
  Flex,
  Text,
  Heading,
  Stack,
  ButtonGroup,
} from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { ASSISTANT_TYPES } from "./constants";

const AssistantType = ({ voices, voiceSsts }) => {
  const { myDetails } = useContext(AgencyContext);
  const { watch, setValue } = useFormContext();

  const selectedType = watch("assistant_type");
  const currentAssistantType = useMemo(
    () => ASSISTANT_TYPES.find((type) => type.type === selectedType),
    [selectedType]
  );

  const handleTypeSelection = (type) => {
    const defaultPrompt = `As an AI assistant built by a team of engineers at ${
      myDetails?.branding?.agency_name || "Algoscale"
    }, please answer the user query with the context provided.`;

    setValue("assistant_type", type);
    setValue("has_human_agent", false);
    setValue("templatePhonePrompt", undefined);
    setValue("custom_voice", false);
    setValue("systemPrompt", watch("systemPrompt") || defaultPrompt);
    setValue("llmModel", type !== "realtime_openai" ? {
      value: "gpt-3.5-turbo-0125",
      label: "GPT-3.5-Turbo-0125",
    } : undefined);

    const voice = voices.find(({ language_group }) => language_group === "english");
    const filteredVoiceLanguages = voiceSsts
      .filter(({ locale_code }) => locale_code === "en-US")
      .map(({ name, locale_code, id, language_group, voicestt_provider }) => ({
        label: name,
        value: locale_code,
        id,
        language_group,
        speaker: voice && {
          label: `${voice.name} (${voice.credits}) credits`,
          value: voice.id,
          voicetts_provider: voice.voicetts_provider,
        },
        voicestt_provider,
      }));
    
    setValue("voiceLanguages", filteredVoiceLanguages);
    setValue("voice", ["simple", "phone"].includes(type));
  };

  return (
    <Flex direction="column" gap={4} pl={4} mb={4}>
      <Flex fontSize="md" justifyContent="flex-start" gap={1} alignItems="center">
        <Text>Assistant Type:</Text>
        <ButtonGroup spacing={0} isAttached>
          {ASSISTANT_TYPES.map(({ children: AssistantIcon, type, title }) => (
            <Button
              key={type}
              title={type}
              onClick={() => handleTypeSelection(type)}
              colorScheme={type === selectedType ? "gray" : undefined}
              isActive={type === selectedType}
              leftIcon={<AssistantIcon />}
            >
              {title}
            </Button>
          ))}
        </ButtonGroup>
      </Flex>
      {currentAssistantType && (
        <Stack spacing={1}>
          <Heading fontSize="md">{currentAssistantType.title}</Heading>
          <Text>{currentAssistantType.subheading}</Text>
        </Stack>
      )}
    </Flex>
  );
};

export default AssistantType;