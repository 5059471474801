import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { MdTapAndPlay } from "react-icons/md";
import { getHostDomain } from "../../utils/utils";
import { fetchController } from "../../utils/FetchController/fetchController";

const AddNewSyncVoiceModal = (props) => {
  const {
    providersDetails,
    isAddNewSyncVoiceModalOpen,
    onAddNewSyncVoiceModalClose,
  } = props;

  const [providersData, setProvidersData] = useState({
    name: "",
    provider: "",
    key: "",
    region: "",
    user_id: "",
  });

  const baseUrl = getHostDomain();

  const handleClose = () => {
    setProvidersData({
      name: "",
      provider: "",
      key: "",
      region: "",
      user_id: "",
    });
    onAddNewSyncVoiceModalClose();
  };

  const addNewProviderKey = async () => {
    try {
      const response = await fetchController(
        `${baseUrl}/api/v1/provider`,
        "POST",
        {
          org_id: null,
          name: providersData?.name,
          provider_key: providersData?.key,
          provider_name: providersData.provider?.value,
          attributes: {
            region: providersData?.region,
            user_id: providersData?.user_id,
          },
          status: true,
        }
      );

      toast.success(response?.message || "Voice added successfully");
    } catch (e) {
      console.log("Something went wrong ", e);
      toast.error("Something went wrong");
    } finally {
      handleClose();
    }
  };

  return (
    <Modal
      isOpen={isAddNewSyncVoiceModalOpen}
      onClose={handleClose}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent maxW="688px">
        <ModalHeader style={{ fontWeight: 700, fontSize: "18px" }}>
          Add Key
        </ModalHeader>
        <ModalCloseButton alignItems="center" />
        <ModalBody>
          <Box gap={2}>
            <Box py={2}>
              <FormLabel>Voice Provider</FormLabel>
              <ButtonGroup spacing={0} isAttached>
                {Object.keys(providersDetails).map((provider) => {
                  return (
                    <Button
                      key={provider.value}
                      title={provider.label}
                      onClick={(e) => {
                        setProvidersData((prevProvidersData) => ({
                          ...prevProvidersData,
                          provider:
                            prevProvidersData?.provider?.value?.includes(
                              provider
                            )
                              ? ""
                              : providersDetails[provider],
                        }));
                      }}
                      colorScheme={
                        providersData?.provider?.value?.includes(provider)
                          ? "gray"
                          : undefined
                      }
                      isActive={providersData?.provider?.value?.includes(
                        provider
                      )}
                    >
                      <Tooltip
                        label={providersDetails[provider].label}
                        textTransform="capitalize"
                        placement="top"
                      >
                        <span>
                          <Icon
                            as={providersDetails[provider].icon}
                            size="sm"
                          />
                        </span>
                      </Tooltip>
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Box>
          </Box>
          <Grid gap={2}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                placeholder="Name"
                onChange={(e) => {
                  setProvidersData((prevProvidersData) => ({
                    ...prevProvidersData,
                    name: e.target.value,
                  }));
                }}
                value={providersData.name}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Key</FormLabel>
              <Input
                type="text"
                placeholder="Key"
                onChange={(e) => {
                  setProvidersData((prevProvidersData) => ({
                    ...prevProvidersData,
                    key: e.target.value,
                  }));
                }}
                value={providersData.key}
              />
            </FormControl>
            {providersData.provider?.value?.includes("azure") && (
              <FormControl>
                <FormLabel>Region (ex: eastus)</FormLabel>
                <Input
                  type="text"
                  placeholder="Region"
                  onChange={(e) => {
                    setProvidersData((prevProvidersData) => ({
                      ...prevProvidersData,
                      region: e.target.value,
                    }));
                  }}
                  value={providersData.region}
                />
              </FormControl>
            )}
            {providersData.provider?.value?.includes("playht") && (
              <FormControl>
                <FormLabel>User Id</FormLabel>
                <Input
                  type="text"
                  placeholder="User Id"
                  onChange={(e) => {
                    setProvidersData((prevProvidersData) => ({
                      ...prevProvidersData,
                      user_id: e.target.value,
                    }));
                  }}
                  value={providersData.user_id}
                />
              </FormControl>
            )}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <Button onClick={handleClose} variant="outline" width="104px">
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                addNewProviderKey();
              }}
            >
              Submit
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNewSyncVoiceModal;
