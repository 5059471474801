import { useEffect, useRef, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { useDisclosure } from "@chakra-ui/react";
import { toast } from "react-toastify";
export default function useTagSelect({
  entity_id,
  entity_type,
  onSelectTagCb,
}) {
  const [status, setStatus] = useState("idle");
  const [tagToEdit, setTagToEdit] = useState(null);

  const baseUrl = getHostDomain();
  const getTagsLinkedToEntity = () =>
    fetchController(
      baseUrl + `/api/v1/link_tag_entity/entity/get_tags?entity_id=${entity_id}`
    );
  const searchByTagName = (tag_term) =>
    fetchController(baseUrl + `/api/v1/tag/search/tags?tag_term=${tag_term}`);
  const createLinkedTagEntity = ({ tag_id }) =>
    fetchController(baseUrl + "/api/v1/link_tag_entity", "POST", {
      entity_id,
      entity_type,
      tag_id,
    });
  const deleteLinkedtagEntity = ({ id }) =>
    fetchController(baseUrl + `/api/v1/link_tag_entity/${id}`, "DELETE");
  const searchRef = useRef(null);
  const [linkedTagsToEntity, setLinkedTagsToEntity] = useState({});

  const loadOptions = async (inputValue) => {
    if (!inputValue) return [];

    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    return new Promise((resolve) => {
      setStatus("loading");
      searchRef.current = setTimeout(async () => {
        try {
          const response = await searchByTagName(inputValue);
          setStatus("idle");
          resolve(
            response.data?.items.map((item) => ({
              value: item,
              label: item.name,
              isDisabled: linkedTagsToEntity[item.id],
            }))
          );
        } catch (error) {
          setStatus("error");
        } finally {
          searchRef.current = null;
        }
      }, 1500);
    });
  };
  const fetchTagsLinkedToEntity = async () => {
    const response = await getTagsLinkedToEntity();
    const makeLinkedTagsToEntityMap = (reducer, item) => {
      reducer[item.tag.id] = item;
      return reducer;
    };
    const alreadyLinkedTagsToEntity = response.data.reduce(
      makeLinkedTagsToEntityMap,
      {}
    );
    setLinkedTagsToEntity(alreadyLinkedTagsToEntity);
  };
  const onChangTags = async (_, ctx) => {
    const actions = {
      "select-option": async () => {
        setStatus("selecting");
        const selectedOption = ctx.option;
        await createLinkedTagEntity({
          tag_id: selectedOption.value.id,
        });
      },
      "remove-value": async () => {
        const removedValue = ctx.removedValue;
        setStatus("removing");
        await deleteLinkedtagEntity({ id: removedValue.id });
      },
    };
    const handlers = actions[ctx.action];
    if (!handlers) return;
    await handlers();
    await fetchTagsLinkedToEntity();
    onSelectTagCb && onSelectTagCb();
    setStatus("idle");
  };
  const tagDrawerDisclosure = useDisclosure();
  useEffect(() => {
    setLinkedTagsToEntity({});
    if (entity_id) fetchTagsLinkedToEntity();
  }, [entity_id]);
  const onCreateTag = async (tag) => {
    try {
      await createLinkedTagEntity({ tag_id: tag.id });
      await fetchTagsLinkedToEntity();
      onSelectTagCb && onSelectTagCb();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  return {
    onChangTags,
    loadOptions,
    getTagsLinkedToEntity,
    status,
    linkedTagsToEntity,
    tagDrawerDisclosure,
    tagToEdit,
    setTagToEdit,
    onCreateTag,
    deleteLinkedtagEntity,
  };
}
