import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import moment from "moment";
import { useContext } from "react";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
export default function IntegrationCard({
  url,
  heading,
  description,
  active,
  onClickIntegration,
  data,
  last_updatedAt,
  isUserEligableForChannel,
}) {

  const bgColor = active ? undefined : "gray.100";
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, myDetails } = agency;
  const toolTipMsg =
    myDetails && myDetails?.agency_id
      ? `Please contact ${myDetails?.branding?.agency_name || ""} `
      : "Please upgrade to platinum or above";

  return (
    <Card bg={bgColor} position={"relative"} maxW="xs">
      <CardBody>
        <Image
          margin={"auto"}
          height={75}
          width={75}
          src={url}
          alt={heading}
          borderRadius="lg"
        />
        <Stack mt="6" spacing="3">
          <Heading size="md" color={textColor}>
            {heading}
          </Heading>
          <Text>{description}</Text>
        </Stack>
      </CardBody>
      <CardFooter>
        <Flex
          direction={"column"}
          gap={2}
          justifyContent={"center"}
          width={"100%"}
        >
          {isUserEligableForChannel && last_updatedAt ? (
            <Box textAlign={"center"}>
              Last updated at:{" "}
              {moment.utc(last_updatedAt).local().format("YYYY-MM-DD HH:mm")}
            </Box>
          ) : (
            <></>
          )}

          <Tooltip
            label={
              !active
                ? "Coming soon"
                : !isUserEligableForChannel
                ? toolTipMsg
                : ""
            }
            aria-label="A tooltip"
          >
            <Button
              cursor={active ? "cursor" : "auto"}
              margin={"auto"}
              variant="solid"
              isActive={!active}
              onClick={active ? onClickIntegration : undefined}
              colorScheme={buttonColorScheme}
              isDisabled={!isUserEligableForChannel}
            >
              {active ? (last_updatedAt ? "Update" : "Set up") : "Coming soon"}
            </Button>
          </Tooltip>
        </Flex>
      </CardFooter>
    </Card>
  );
}
