import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { useDisclosure } from "@chakra-ui/react";

export default function useSyncVoices(props) {
  const { queries, setQueries } = props;
  const columns = [
    "Provider",
    "Language",
    "Name",
    "Description",
    "Preview",
    "Action",
  ];

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(100);
  const [pages, setPages] = useState(0);
  const [syncVoicesList, setSyncVoicesList] = useState();

  const [loading, setLoading] = useState(true);
  const {
    isOpen: isSyncVoicesOpen,
    onOpen: onSyncVoicesOpen,
    onClose: onSyncVoicesClose,
  } = useDisclosure();

  const {
    isOpen: isAddNewSyncVoiceModalOpen,
    onOpen: onAddNewSyncVoiceModalOpen,
    onClose: onAddNewSyncVoiceModalClose,
  } = useDisclosure();

  const baseUrl = getHostDomain();
  const syncSelectedProviderVoice = async () => {
    try {
      const response = await fetchController(
        `${baseUrl}/api/v1/voice/sync_by_provider_id?provider_id=${queries.specificProvider.id}`,
        "GET"
      );
      setQueries((prevQueries) => {
        return { ...prevQueries, provider: "", specificProvider: "" };
      });
      toast.success(response?.message || "Voice added successfully");
    } catch (e) {
      console.log("Some thing went wrong ", e);
      toast.error("Something went wrong");
    }
  };

  const fetchSyncVoicesData = async (changedPage) => {
    setLoading(true);
    try {
      const { filterByProvider, filterBySpecificProvider, filterByLanguage } =
        queries;

      let queryParams = [];

      if (filterByProvider?.value)
        queryParams.push(`provider=${filterByProvider.value}`);

      if (filterBySpecificProvider?.value)
        queryParams.push(`provider_id=${filterBySpecificProvider.id}`);

      if (filterByLanguage?.value)
        queryParams.push(`language=${filterByLanguage.value}`);

      const queryString = queryParams.length ? `&${queryParams.join("&")}` : "";

      const response = await fetchController(
        `${baseUrl}/api/v1/voice/list/voicetts?me=true&size=${paginationSize}&page=${
          changedPage ? changedPage : page
        }${queryString}`,
        "GET"
      );

      setSyncVoicesList(response.data.items);
      setPages(response?.data?.pages);
    } catch (e) {
      console.log("Some thing went wrong ", e);
    } finally {
      setLoading(false);
    }
  };

  const addToAvailableVoice = async (voicetts_id, isEnabled) => {
    try {
      const response = await fetchController(
        `${baseUrl}/api/v1/voice/change_status/${voicetts_id}?status=${!isEnabled}`,
        "PATCH"
      );
      toast.success(response?.message || "Voice added successfully");
    } catch (e) {
      console.log("Something went wrong", e);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    fetchSyncVoicesData();
  }, [page, paginationSize]);

  const dependencies = [
    queries?.filterByProvider,
    queries?.filterBySpecificProvider,
    queries?.filterByLanguage,
  ];

  useEffect(() => {
    setPage(1);
    fetchSyncVoicesData(1);
  }, dependencies);

  return {
    page,
    pages,
    loading,
    columns,
    paginationSize,
    syncVoicesList,
    isSyncVoicesOpen,
    isAddNewSyncVoiceModalOpen,
    setPage,
    onSyncVoicesOpen,
    onSyncVoicesClose,
    setPaginationSize,
    addToAvailableVoice,
    fetchSyncVoicesData,
    syncSelectedProviderVoice,
    onAddNewSyncVoiceModalOpen,
    onAddNewSyncVoiceModalClose,
  };
}
