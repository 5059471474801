import { Box, Flex, Heading } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  FaFacebookMessenger,
  FaInstagram,
  FaMixcloud,
  FaPhoneAlt,
  FaSms,
} from "react-icons/fa";
import { FiPhoneMissed } from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io";
import {
  MdOutlineLeaderboard,
  MdOutlineWebAsset,
  MdOutlineWifiCalling3,
} from "react-icons/md";
import { PiFileHtml } from "react-icons/pi";
import { Select } from "chakra-react-select";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import { getHostDomain } from "../../utils/utils";
import Layout from "../Layout/Layout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getWidgetProvider } from "../Widgets/utils";
import IframeWidget from "./IframeWidget";
import ImageIconWidgetOption from "./ImageIconWidgetOption";
export default function PlaygroundPage() {
  const baseUrl = getHostDomain();
  const [widgets, setWidgets] = useState([]);
  const [status, setStatus] = useState("idle");
  useEffect(() => {
    (async () => {
      setStatus("loading");
      const endpoint = "/api/v1/widget/get_my_details?page=1&size=50";
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      setWidgets(response.data.items);
      setStatus("success");
    })();
  }, []);
  const loading = status === "loading";
  const [widget, setWidget] = useState({
    id: "",
    type: "",
  });
  const onChangeWidget = (widget) => {
    setWidget({
      id: widget.id,
      type: widget.type,
    });
  };
  const selectPlaceholder = widgets.length
    ? "Select widget to start the chat"
    : "Please create a widget first";

  const agency = useContext(AgencyContext);
  const { textColor } = agency;
  const name = agency?.myDetails?.agency_id
    ? agency?.myDetails?.branding?.agency_name
    : "Insighto.AI";
    
  const widgetOptions = widgets
    .filter((widget) =>
      ["web_call", "html_call", "chat"].includes(widget.widget_type)
    )
    .map((widget) => ({
      label: `${widget.name} [${widget.id}]`,
      value: widget.id,
      type: widget.widget_type,
      src: getWidgetProvider({
        type: widget?.widget_type,
        name,
        logo: agency?.faviconUrl,
      }).logo,
    }));

  return (
    <Layout>
      <Box border="1px" h="90svh" p="5" borderRadius="md" overflowY="auto">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Flex justifyContent="start" alignItems="center">
              <Heading mb={2} color={textColor} fontSize="xl">
                Playground
              </Heading>
            </Flex>
            <Flex justifyContent={"flex-start"} alignItems={"center"} gap={2}>
              <Box flex={1}>
                <Select
                  disabled={widgets.length === 0}
                  placeholder={selectPlaceholder}
                  onChange={(widget) => {
                    onChangeWidget({
                      id: widget.value,
                      type: widget.type,
                    });
                  }}
                  options={widgetOptions}
                  components={{ Option: ImageIconWidgetOption }}
                  value={widgetOptions.find((w) => w.id === widget.id)}
                />
              </Box>
            </Flex>
            {widget.id ? (
              <Flex alignItems="center" justifyContent="center" mt={2}>
                <IframeWidget widgetId={widget.id} widgetType={widget.type} />
              </Flex>
            ) : null}
          </>
        )}
      </Box>
    </Layout>
  );
}
