import { BiLogoTelegram } from "react-icons/bi";
import { FaMixcloud, FaPhoneAlt, FaSms } from "react-icons/fa";
import { FaFacebookMessenger, FaInstagram } from "react-icons/fa6";
import { FiPhoneMissed } from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io5";
import { PiFileHtml } from "react-icons/pi";

import {
  MdOutlineLeaderboard,
  MdOutlineWebAsset,
  MdOutlineWifiCalling3,
} from "react-icons/md";
export const HEADS_DATA = [
  "type",
  "provider",
  "name",
  "description",
  "assistant",
  "data_Source",
  "Tags"
];

export const widgetIcons = {
  html: <PiFileHtml />,
  html_call: <MdOutlineWebAsset />,
  whatsapp: <IoLogoWhatsapp />,
  phone: <FaPhoneAlt />,
  sms: <FaSms />,
  leadconnector: <MdOutlineLeaderboard />,
  leadconnector_call: <FiPhoneMissed />,
  fb_messenger: <FaFacebookMessenger />,
  web_call: <MdOutlineWifiCalling3 />,
  instagram: <FaInstagram />,
  plivo_call: <FaMixcloud />,
  telnyx_call: <img src="/icons/telnyx.svg" width={15} />,
  telegram: <BiLogoTelegram />,
};

export const LIVE_WIDGET_TYPES = [
    "phone",
    "html_call",
    "leadconnector",
    "leadconnector_call",
    "sms",
  ]