import React, { forwardRef } from "react";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";

const CustomSelect = forwardRef(
  (
    {
      options,
      value,
      onChange,
      label,
      isOptionDisabled = false,
      placeholder = "Select...",
      ...props
    },
    ref
  ) => {
    return (
      <FormControl>
        {label && <FormLabel>{label}</FormLabel>}
        <Select
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            const selectedValue = e.target.value;
            const selectedOption = options.find(
              (option) => option.value === selectedValue
            );
            return onChange({
              value: selectedOption?.value ? selectedOption.value : "",
              label: selectedOption?.label,
            });
          }}
          {...props}
        >
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={isOptionDisabled}
            >
              {option.label}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export default CustomSelect;

// export default CustomSelect;
