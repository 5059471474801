import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { useContext } from "react";
import TourLayout from "../Layout/TourLayout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export default function DatasourcesTour({ onCloseDatasourcesTour }) {
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, colorPreset } = agency;
  return (
    <TourLayout onCloseTour={onCloseDatasourcesTour}>
      <Box fontSize={"md"}>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          What is Data Source?
        </Text>
        <Text fontSize={"md"}>
          A Data Source (DS) is a collection of Data Source Files (DSF). Each
          DSF represents a single unit of your knowledge base and is used by the
          Assistant to answer a particular query. We support HTTP (any
          website/domain), PDF, and images (Using GPT4V). You can reach out to
          us through support if you’d like us to prioritize any other data
          source for your needs.
        </Text>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          Create your Data Source
        </Text>
        <Text fontSize={"md"}>
          Let’s start by adding your data source from the blue button in the top
          right corner.
        </Text>
        <UnorderedList fontSize={"md"}>
          <ListItem>
            Select the type of data source - HTTPS (weblink) or document type
            (PDF, etc)
          </ListItem>
          <ListItem>
            Enter a name and brief description for your data source.
          </ListItem>
          <ListItem>Click "Submit", and you’re done!</ListItem>
          <ListItem>
            You can further perform operations like view associated assistants,
            upload files, or delete data sources.
          </ListItem>
        </UnorderedList>
      </Box>
    </TourLayout>
  );
}
