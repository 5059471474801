import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Input,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import BillingPortalButton from "./BillingPortalButton";
import AddonsList from "./AddonsList";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import CouponSuccessModal from "../../Modals/CouponSuccessModal";

const ExtrasSection = ({ userDetails, setIsLoading, isLoading }) => {
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const [couponCodeError, setCouponCodeError] = useState(null);
  const [couponCodeSuccessMsg, setCouponCodeSuccessMsg] = useState("");
  const [couponCodeIsLoading, setCouponCodeIsLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  const {
    isOpen: isCouponSuccessModalOpen,
    onOpen: onCouponSuccessModalOpen,
    onClose: onCouponSuccessModalClose,
  } = useDisclosure();
  const { buttonColorScheme, cardBg } = agency;
  const HanleClickOnAddPaymentMethod = async () => {
    try {
      setIsLoading(true);
      const addPaymentMethodResponse = await fetchController(
        baseUrl + `/api/v1/billing/add-payment-method`,
        "GET"
      );
      if (addPaymentMethodResponse.status === "redirect") {
        window.open(addPaymentMethodResponse.checkout_url, "_blank");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleOnApplyCouponCode = async () => {
    setCouponCodeIsLoading(true);
    setIsLoading(true);
    try {
      const endpoint = "/api/v1/billing/apply_coupon";
      const urlHit = baseUrl + endpoint;

      const body = {
        coupon_code: couponCode.toString(),
      };
      const getData = await fetchController(urlHit, "POST", body);
      if (getData.status === "success") {
        setCouponCodeSuccessMsg(getData.message);
        onCouponSuccessModalOpen();
        setCouponCodeError(null);
        setTimeout(() => {
          onCouponSuccessModalClose();
          window.location.reload();
        }, 5000);
      } else {
        setCouponCodeError(getData.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setCouponCodeIsLoading(false);
    }
  };
  return (
    <>
      <Box
        bg={cardBg}
        boxShadow="md"
        borderRadius="8px"
        p={4}
        width="100%"
      >
        <Box >
          {userDetails?.stripe_has_payment_method ? null : (
            <Button
              mr={2}
              size={"sm"}
              colorScheme={buttonColorScheme}
              onClick={HanleClickOnAddPaymentMethod}
            >
              {isLoading ? <Spinner size="xs" /> : "Add Payment Method"}
            </Button>
          )}
          <BillingPortalButton buttonColorScheme={buttonColorScheme} />
        </Box>
        <Box display="flex" gap={4}>
          <AddonsList
            userDetails={userDetails}
            buttonColorScheme={buttonColorScheme}
          />
          <Stack spacing={2} direction={"column"} mt="60px">
            <Text fontSize={"md"} fontWeight={"bold"}>
              Coupon
            </Text>

            <Stack spacing={3} direction={"row"}>
              <Input
                placeholder="Coupon code"
                value={couponCode}
                onChange={(e) => {
                  setCouponCode(e.target.value);
                  setCouponCodeError("");
                }}
                size="md"
              />
              <Button
                colorScheme={buttonColorScheme}
                isLoading={couponCodeIsLoading}
                isDisabled={!couponCode}
                onClick={handleOnApplyCouponCode}
              >
                Apply
              </Button>
            </Stack>
            {couponCodeError && (
              <Text color={"red"} p={1}>
                {couponCodeError}
              </Text>
            )}
          </Stack>
        </Box>
      </Box>
      <CouponSuccessModal
        isOpen={isCouponSuccessModalOpen}
        onOpen={onCouponSuccessModalOpen}
        onClose={onCouponSuccessModalClose}
        couponCodeSuccessMsg={couponCodeSuccessMsg}
      />
    </>
  );
};

export default ExtrasSection;
