import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  HStack,
  Tag,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Tooltip,
} from "@chakra-ui/react";
import useTagSelect from "./useTagSelect";
import { toast } from "react-toastify";

const TagDeleteBtn = ({ handleDelete }) => {
  return (
    <Tooltip label="Delete">
      <SmallCloseIcon
        color={"red"}
        fontSize={"14px"}
        cursor={"pointer"}
        onClick={handleDelete}
      />
    </Tooltip>
  );
};

export default function TagsColumn({ tagsData = [], refreshTable }) {
  const { deleteLinkedtagEntity } = useTagSelect({
    entity_id: null,
    entity_type: null,
  });

  const maxVisibleTags = 2;
  const hasMoreTags = tagsData.length > maxVisibleTags;
  const visibleTags = tagsData.slice(0, maxVisibleTags);
  const remainingTags = tagsData.slice(maxVisibleTags);

  const handleTagRemove = async (id) => {
    try {
      const response = await deleteLinkedtagEntity({ id });
      toast.success(response.message || "Tag removed successfully");
      refreshTable();
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Something went wrong");
    }
  };

  return (
    <HStack>
      {visibleTags.map((tag) => (
        <Tag
          display={"flex"}
          gap={1}
          key={`${tag.tag_id}`}
          size="md"
          colorScheme={tag.color}
        >
          {tag.name}
          <TagDeleteBtn
            handleDelete={() => handleTagRemove(tag.link_tag_entity_id)}
          />
        </Tag>
      ))}
      {hasMoreTags && (
        <Popover>
          <PopoverTrigger>
            <Text fontSize={"xs"} color="blue.500" cursor="pointer">
              +{remainingTags.length} more
            </Text>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>
              <HStack wrap="wrap">
                {remainingTags.map((tag) => (
                  <Tag
                    display={"flex"}
                    gap={1}
                    key={`${tag.tag_id}`}
                    size="md"
                    colorScheme={tag.color}
                  >
                    {tag.name}
                    <TagDeleteBtn
                      handleDelete={() => handleTagRemove(tag.link_tag_entity_id)}
                    />
                  </Tag>
                ))}
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </HStack>
  );
}
