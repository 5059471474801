import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import ConversationsUtility from "../Conversations/ConversationsUtility";
import Layout from "../Layout/Layout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import ActivityTable from "./ActivityTable";

const Activity = () => {
  const selectedTab = useParams().name || "conversations";

  const tabs = [
    {
      label: "Conversations",
      value: "conversations",
      children: <ConversationsUtility />,
    },
    {
      label: "Captured Forms",
      value: "captured-forms",
      children: <ActivityTable />,
    },
  ];

  const agency = useContext(AgencyContext);
  const { textColor } = agency;
  const index = tabs.findIndex((tab) => tab.value === selectedTab);
  const defaultTabIndex = index < 0 ? 0 : index;

  return (
    <Layout>
      <Box
        border="1px"
        borderRadius="md"
        h="90svh"
        padding={4}
        overflowY={"auto"}
      >
        <Tabs isLazy defaultIndex={defaultTabIndex}>
          <TabList>
            {tabs.map((tab) => (
              <Tab color={textColor} key={tab.label}>
                {tab.label}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab.label}>
                <Box>{tab.children}</Box>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
};

export default Activity;
