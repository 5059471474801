import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import PricingAssistantCallRealtime from "./PricingAssistantCallRealtime";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import TagDrawer from "../Tags/TagDrawer";
import useTagSelect from "../Tags/useTagSelect";
import TagSelect from "../Tags/TagSelect";

const BasicDetails = (props) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const { id } = props;
  const { assistant_type = "simple", llmModel, voiceLanguages } = watch();
  const voice_stt_id = voiceLanguages.length ? voiceLanguages[0].id : null;
  const llm_model_id = llmModel?.id;
  const voice_tts_id = voiceLanguages.length
    ? voiceLanguages[0]?.speaker?.value
    : null;
  const makePricingParams = () => {
    if (assistant_type === "realtime_openai")
      return { llm_model_id, voice_stt_id: null, voice_tts_id: null };
    if (assistant_type == "phone")
      return [voice_stt_id, llm_model_id, voice_tts_id].every(Boolean)
        ? { voice_stt_id, voice_tts_id, llm_model_id }
        : null;
    return null;
  };
  const pricingParams = makePricingParams();

  const agency = useContext(AgencyContext);
  const { myDetails } = agency;

  return (
    <Grid templateColumns={assistant_type === "simple" ? null : "1fr 1fr"}>
      <Box px={4} py={2}>
        <FormControl isInvalid={errors.name} mb="4">
          <FormLabel htmlFor="name" display={"flex"} alignContent="center">
            <Text alignSelf="center">Name</Text>
          </FormLabel>
          <Input
            placeholder="Name"
            {...register("name", {
              required: "Name is required",
            })}
          />
          {errors.name && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errors.description} mb="4">
          <FormLabel
            htmlFor="description"
            display={"flex"}
            alignContent="center"
          >
            <Text alignSelf="center">Description</Text>
          </FormLabel>
          <Textarea
            placeholder="Not used by the LLM. Please use system prompt field to finetune the model"
            {...register("description", {
              required: "Description is required",
            })}
            resize="vertical"
          />
          {errors.description && (
            <FormErrorMessage>{errors.description.message}</FormErrorMessage>
          )}
        </FormControl>
        {id && (
          <FormControl isInvalid={errors.tagSelect} mb="4">
            <FormLabel
              htmlFor="tagSelect"
              display={"flex"}
              alignContent="center"
            >
              <Text alignSelf="center">Select tags</Text>
            </FormLabel>
            <TagSelect tagSelectHook={props.tagSelectHook} />
          </FormControl>
        )}
      </Box>
      {pricingParams &&
      ["realtime_openai", "phone"].includes(assistant_type) &&
      !myDetails?.agency_id ? (
        <PricingAssistantCallRealtime {...pricingParams} />
      ) : null}
    </Grid>
  );
};

export default BasicDetails;
