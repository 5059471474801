import { FaFileImage, FaFilePdf, FaImage } from "react-icons/fa";
import { fetchController } from "./FetchController/fetchController";
import { TbWorldWww } from "react-icons/tb";
import { MdOutlineTextSnippet } from "react-icons/md";

export const addIntentsToAssistant = async (
  getAssistantData,
  defaultIntents,
  emails
) => {
  const url = getHostDomain();

  const assistantId = getAssistantData.data.id;

  // Use Promise.all to wait for all asynchronous operations to complete
  const promises = defaultIntents.map(async (intent) => {
    const matchingEmail = emails.find((email) => email.name === intent.name);

    if (matchingEmail) {
      const endpoint = `/api/v1/assistant/${assistantId}/add_intent/${intent.id}`;
      const urlHit = url + endpoint;
      const body = {
        actions: [
          {
            type: "email",
            email: matchingEmail.email,
            emailName: matchingEmail.name.toUpperCase(),
          },
        ],
      };

      try {
        const getData = await fetchController(urlHit, "POST", body);
        return getData;
      } catch (error) {
        throw error; // Propagate the error to the Promise.all catch block
      }
    } else {
      console.warn(`Email not found for intent: ${intent.name}`);
    }
  });

  try {
    const results = await Promise.all(promises);
    return results;
  } catch (error) {
    console.error("Error in one or more API requests:", error);
    throw error;
  }
};
export const isValidURL = (url) => {
  const regex =
    /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?(localhost|(?:[^\s.]+[.](?:[a-z]{2,}|co\.uk|org\.in)))(?::\d+)?(?:[/?]\S*)?$/i;

  return regex.test(url);
};

export const clipText = (text, length = 20) => {
  if (text.length > length) {
    const slicedText = text.slice(0, length);
    return `${slicedText} ...`;
  }

  return text;
};

export const agencyBillingPlanGroupENUM = Object.freeze({
  regular: "Regular",
  addons: "Addon",
  hidden: "Hidden",
});

export const getHostDomain = () => {
  let apiUrl = `https://${localStorage.getItem("API_SERVER_URL")}`;
  if (!apiUrl) {
    window.location.href = "/login";
  }
  return apiUrl;
};

export const validateRegex = (pattern, inputValue) => {
  const regex = new RegExp(pattern);
  return regex.test(inputValue);
};

export const getAbsLogoUrl = (logo) => {
  if (!logo) return null;
  if (logo.includes(window.origin) || logo.includes("insighto")) return logo; // bcoz in case of insighto.ai we are using 'https://cdn.insighto.ai/bot.png' (hardcoded)
  return `${window.location.origin}${logo}`;
};

export const toBoolean = (value) => {
  if (value === true || value === 1 || value === "true") {
    return true;
  }
  const numberValue = Number(value);
  return !isNaN(numberValue) && numberValue > 0;
};

export const isValidDomain = (domain) =>
  /^(https?:\/\/)?(localhost|app\.insighto\.ai|staging\.app\.insighto\.ai)(:\d+)?(\/.*)?$/i.test(
    domain
  );

export const getCookieValue = (cookieName) => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split("=");
    if (name === cookieName) {
      return value;
    }
  }
  return null;
};

const ghlLogo =
  "https://images.saasworthy.com/tr:w-160,h-0,c-at_max,e-sharpen-1/highlevel_29566_logo_1712921952_nwbzw.png";
export const DS_ICONS = {
  pdf: { icon: <FaFilePdf color="red" />, label: "PDF" },
  http: { icon: <TbWorldWww color="blue" />, label: "Website" },
  image: { icon: <FaImage color="blue" />, label: "Image" },
  text_blob: { icon: <MdOutlineTextSnippet color="blue" />, label: "Text" },
  text_image: { icon: <FaFileImage color="blue" />, label: "Text & Image" },
};
export const providers = {
  phone: {
    label: "Twilio",
    icon: <img src="/icons/twilio.svg" width={25} />,
  },
  sms: {
    label: "SMS",
    icon: <img src="/icons/twilio.svg" width={25} />,
  },
  whatsapp: {
    label: "WhatsApp",
    icon: <img src="/icons/whatsapp.svg" width={25} />,
  },
  leadconnector: {
    label: "GHL Connect",
    icon: <img src={ghlLogo} width={25} />,
  },
  leadconnector_call: {
    label: "GHL Connect",
    icon: <img src={ghlLogo} width={25} />,
  },
  instagram: {
    label: "Instagram",
    icon: <img src="/icons/instagram.svg" width={25} />,
  },
  plivo_call: {
    label: "Plivo Call",
    icon: <img src="/icons/plivo.svg" width={25} />,
  },
  telnyx_call: {
    label: "Telnyx Call",
    icon: <img src="/icons/telnyx.svg" width={25} />,
  },
  telegram: {
    label: "Telegram",
    icon: (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/2048px-Telegram_2019_Logo.svg.png"
        width={25}
      />
    ),
  },
  fb_messenger: {
    label: "Facebook Messenger",
    icon: (
      <img
        src="https://cdn4.iconfinder.com/data/icons/social-media-2285/1024/logo-512.png"
        width={25}
      />
    ),
  },
  ...DS_ICONS,
};

  export const formatName = (name) => {
    return name
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  };

  export const getTagsData = (tags) => {
    try {
      return JSON.parse(tags);
    } catch (error) {
      console.error(error);
      return [];
    }
  };
