import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Textarea,
  FormLabel,
  FormControl,
  FormErrorMessage,
  ModalCloseButton,
  Flex,
  Tag,
  Text,
  Stack,
  Box,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { getHostDomain } from "../../utils/utils";
import { fetchController } from "../../utils/FetchController/fetchController";
import { Select } from "chakra-react-select";

export const colorSchemeOptions = [
  { label: "White Alpha", value: "whiteAlpha" },
  { label: "Black Alpha", value: "blackAlpha" },
  { label: "Gray", value: "gray" },
  { label: "Red", value: "red" },
  { label: "Orange", value: "orange" },
  { label: "Yellow", value: "yellow" },
  { label: "Green", value: "green" },
  { label: "Teal", value: "teal" },
  { label: "Blue", value: "blue" },
  { label: "Cyan", value: "cyan" },
  { label: "Purple", value: "purple" },
  { label: "Pink", value: "pink" },
];

export default function TagModal({
  isOpen,
  onClose,
  tagToEdit: tag = null,
  setTagToEdit,
  cb,
}) {
  const baseUrl = getHostDomain();
  const defaultTag = {
    name: "",
    description: "",
    color_code: "#000000",
  };
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: tag?.value || defaultTag,
  });

  useEffect(() => {
    reset({
      id: tag?.value.id,
      name: tag?.value.name,
      description: tag?.value.description,
      color_code: tag?.value.color_code,
    });
  }, [tag]);

  const onSubmit = async (data) => {
    try {
      const url = data?.id ? `/api/v1/tag/${data?.id}` : "/api/v1/tag";
      const method = data?.id ? "PUT" : "POST";

      const response = await fetchController(baseUrl + url, method, {
        ...data,
        attributes: {},
      });
      if (tag) {
        setTagToEdit(null);
      }
      toast.success(
        response?.message || `Tag ${tag ? "updated" : "created"} successfully`
      );
      cb && cb(response.data);
      onClose();
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  };
  const handleClose = () => {
    setTagToEdit(null);
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={"lg"}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader borderBottomWidth="1px">
            {watch("id") ? "Edit Tag" : "Create Tag"}
          </ModalHeader>

          <ModalBody>
            <Stack spacing={1}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  {...register("name", { required: "Name is required" })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.description} mt={4}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  {...register("description", {
                    required: "Description is required",
                  })}
                  height={"200px"}
                />
                <FormErrorMessage>
                  {errors.description && errors.description.message}
                </FormErrorMessage>
              </FormControl>
              <Box>
                <Text>Tag preview</Text>
                <Tag
                  size="lg"
                  colorScheme={watch("color_code")}
                  borderRadius="full"
                >
                  {watch("name")}
                </Tag>
              </Box>
              <FormControl flex={1} isInvalid={errors.color_code}>
                <FormLabel>Pick Color</FormLabel>
                <Select
                  menuPlacement="auto"
                  options={colorSchemeOptions.map((color) => ({
                    ...color,
                    colorScheme: color.value,
                  }))}
                  onChange={({ value }) => setValue("color_code", value)}
                  value={colorSchemeOptions.find(
                    (color) => color.value === watch("color_code")
                  )}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
              {watch("id") ? "Update" : "Create"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
