import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { useContext, useEffect, useState } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { toast } from "react-toastify";
import ConfirmationModal from "../Modals/Confirmation";

export default function ChangeBillingPlanModal({
  isOpen,
  onClose,
  selectedAccount,
  refreshTable,
}) {
  const baseURL = getHostDomain();
  const [tableData, setTableData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [status, setStatus] = useState("loading");

  const agency = useContext(AgencyContext);
  const { agencyData } = agency;
  const agencyId = agencyData?.id;
  const agencyBillingMethod =
    agencyData?.billing_plan?.billing_method || "stripe";
  const billingMethodNote = {
    stripe:
      "In case of a plan upgrade, it will be applied immediately. A plan downgrade will be scheduled to be in effect from the next billing cycle",
    custom: "The billing plan will be applied immediately.",
  };

  const {
    isOpen: isConfirmationOpen,
    onOpen: openConfirmation,
    onClose: closeConfirmation,
  } = useDisclosure();

  const getBillingPlanList = async () => {
    try {
      setStatus("loading");
      const response = await fetchController(
        `${baseURL}/api/v1/agency/billing/${agencyId}/billing_plan/list`,
        "GET"
      );
      const filteredData = response?.data?.items.filter(
        (item) => item.plan_lookup_key !== ""
      );
      setTableData(filteredData || []);
    } catch (error) {
      console.error(error);
    } finally {
      setStatus("idle");
    }
  };

  useEffect(() => {
    getBillingPlanList();
  }, [selectedAccount]);

  const handlePlanChange = async () => {
    try {
      setStatus("new_plan_loading");
      closeConfirmation();
      const response = await fetchController(
        `${baseURL}/api/v1/agency/billing/subaccount/change_billing_plan?agency_id=${agencyId}&subaccount_user_id=${selectedAccount?.id}&new_billing_plan_id=${selectedPlan}`,
        "GET"
      );
      toast.success(response.message || "Plan changed successfully");
      refreshTable()
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Something went wrong");
    } finally {
      setStatus("idle");
      onClose();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent maxHeight="70vh" maxWidth="600px">
          <ModalHeader
            borderBottomWidth="1px"
            style={{ fontWeight: 700, fontSize: "18px" }}
          >
            Change Billing Plan
            <Flex style={{ fontWeight: 400, fontSize: "14px" }}>
              <Text
                style={{ fontWeight: 400, fontSize: "14px" }}
              >{`Current Billing Plan: ${
                selectedAccount?.plan_lookup_key || "N/A"
              }`}</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {status === "loading" ? (
              <Flex justifyContent={"center"}>
                <Spinner size={"lg"} />
              </Flex>
            ) : (
              <Stack gap={4}>
                <Alert status="info" width={"auto"}>
                  <AlertIcon />
                  {billingMethodNote[agencyBillingMethod]}
                </Alert>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th textAlign={"center"}>Operation</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {tableData.map((rowData) => (
                        <Tr key={rowData.id}>
                          <Td>{rowData.name}</Td>
                          <Td textAlign={"center"}>
                            <Button
                              colorScheme="blue"
                              mr={3}
                              isDisabled={
                                selectedAccount?.plan_lookup_key ===
                                rowData?.plan_lookup_key
                              }
                              isLoading={
                                selectedPlan === rowData.id &&
                                status === "new_plan_loading"
                              }
                              onClick={() => {
                                setSelectedPlan(rowData.id);
                                openConfirmation();
                              }}
                            >
                              Set
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {isConfirmationOpen && (
        <ConfirmationModal
          table={"changeBillingPlan"}
          isOpen={isConfirmationOpen}
          onClose={closeConfirmation}
          onConfirm={handlePlanChange}
        />
      )}
    </>
  );
}
