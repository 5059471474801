import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain, toBoolean } from "../../../utils/utils";
import Twillio from "../../Modals/Twillio";
import WhatsAppModal from "../../Modals/WhatsApp";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import { SettingContext } from "../Reducer/SettingProvider";
import IntegrationCard from "./IntegrationCard";
export default function Channels({ activeTab }) {
  const baseUrl = getHostDomain();
  const { state: activatedPlan } = useContext(SettingContext);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [userWhatsAppDetails, setUserWhatsAppDetails] = useState({
    facebook_app_secret: "",
    id: "",
    phone_business_id: "",
    phone_number_id: "",
    whatsapp_access_token: "",
    whatsapp_phone_number: "",
  });
  const [lastUpdatedAt, seLastUpdatedAt] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [userId, setUserId] = useState(null);
  const {
    isOpen: isTwillioModalOpen,
    onOpen: openTwillioModal,
    onClose: closeTwillioModal,
  } = useDisclosure();
  const agency = useContext(AgencyContext);
  const { myDetails } = agency;
  const integrationCards = [
    {
      url: "/icons/whatsapp.svg",
      heading: "WhatsApp",
      description: `Automate your WhatsApp Business Account using ${
        myDetails?.branding?.agency_name || "Insighto.ai"
      } by simply providing the token. Our Assistant will immediately take charge and start responding to your customers queries over WhatsApp`,
      active: true,
      last_updatedAt: lastUpdatedAt,
      data: userWhatsAppDetails,
      onClickIntegration: onOpen,
      isUserEligableForChannel: toBoolean(
        activatedPlan?.credits?.channels?.whatsapp
      ),
    },
    {
      url: "/icons/twillio.svg",
      heading: "Twillio",
      description: `Link your Twilio account to start deploying AI Agents to take over your call center. It would allow you to make or receive calls and converse with your customer in natural language. Give it a try today, its magic!`,
      active: true,
      onClickIntegration: openTwillioModal,
      isUserEligableForChannel: toBoolean(
        activatedPlan?.credits?.channels?.twilio
      ),
    },
  ];
  const loadUserWhatsAppDetails = async () => {
    try {
      const userDetailsRes = await fetchController(
        baseUrl + `/api/v1/user/list/UserWhatsApp`,
        "GET"
      );
      if (userDetailsRes?.data.items.length) {
        setUserWhatsAppDetails(userDetailsRes.data.items[0]);
        seLastUpdatedAt(userDetailsRes.data.items[0].updated_at || null);
        setIsUpdating(userDetailsRes.data.items.length ? true : false);
        setUserId(userDetailsRes.data.items[0]?.id);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (activeTab === "channels") {
      loadUserWhatsAppDetails();
    }
  }, [activeTab]);

  return (
    <Box border="1px" p="5" borderRadius="md" h="81vh" overflowY="auto">
      <Flex gap={3} flexWrap="wrap">
        {integrationCards.map((integration) => (
          <IntegrationCard {...integration} key={integration.heading} />
        ))}
      </Flex>
      <WhatsAppModal
        data={userWhatsAppDetails}
        onClose={onClose}
        onOpen={onOpen}
        isOpen={isOpen}
        isUpdating={isUpdating}
        userId={userId}
        loadUserWhatsAppDetails={loadUserWhatsAppDetails}
      />
      <Twillio isOpen={isTwillioModalOpen} onClose={closeTwillioModal} />
    </Box>
  );
}
