/* eslint-disable no-useless-computed-key */
import { Box, Button, Flex, Heading, Spacer } from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import Table from "../Tables/Table";
import moment from "moment";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import CustomSelect from "../../utils/CustomSelect/CustomSelect";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import CapturedFormsTour from "./CapturedFormsTour";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../utils/Loader/Paginator";

const ActivityTable = () => {
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;
  const baseUrl = getHostDomain();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [allFormsData, setAllFormsData] = useState([]);
  const [formsOptions, setFormsOptions] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [heads, setHeads] = useState([]);
  const [tableData, setTableData] = useState([]);

  const activityTableDependency = [selectedForm, paginationSize, page];
  const [capturedFormsTourVisibility, setCapturedFormsTourVisibility] =
    useState(
      localStorage.getItem("capturedFormsTourVisibility") ? false : true
    );

  const onCloseCapturedFormsTour = () => {
    localStorage.setItem("capturedFormsTourVisibility", false);
    setCapturedFormsTourVisibility(false);
  };

  const hitFetch = async (formId) => {
    const endpoint = `/api/v1/capturedform/${formId}?size=${paginationSize}&page=${page}`;
    try {
      setIsLoading(true);
      const urlHit = baseUrl + endpoint;
      const getData = await fetchController(urlHit, "GET");

      if (getData?.data) {
        const updatedData = getData.data.items.map((item) => {
          let obj = {
            id: item.id,
            ["Created at"]: moment(item.created_at).format("YYYY-MM-DD HH:mm"),
          };
          for (let key in item.field_values) {
            obj[key] = item.field_values[key];
          }
          return obj;
        });
        setTableData(updatedData);
        setPages(getData.data.pages);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const loadForm = async () => {
    try {
      setIsLoading(true);
      const endpoint = "/api/v1/form/list";
      const urlHit = baseUrl + endpoint;
      const getForm = await fetchController(urlHit, "GET");

      setAllFormsData(getForm.data.items);
      const options = getForm.data.items.map((val) => {
        return { label: val.name, value: val.id };
      });
      setFormsOptions(options);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadForm();
  }, []);

  const handleOnChange = (value) => {
    setIsLoading(true);
    setSelectedFormId(value);
    if (value) {
      const fieldOfSelectedForm = allFormsData?.filter(
        (val) => val.id === value.value
      )[0];
      setPage(1);
      setPaginationSize(10);
      setSelectedForm(fieldOfSelectedForm);
      const columns = [...fieldOfSelectedForm?.fields, "Created at"].map(
        (key) => ({
          Header: typeof key === "object" ? key.name : key,
          accessor: typeof key === "object" ? key.name : key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        })
      );
      setHeads(columns);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedForm) {
      hitFetch(selectedForm.id);
    }
  }, activityTableDependency);

  const csvHeaders = heads.map((col) => ({
    label: col.Header,
    key: col.accessor,
  }));
  const filename = "capturedforms.csv";

  return (
    <>
      <Box h="75vh" p="5">
        <Heading flex={2} color={textColor} fontSize="xl">
          Captured Forms
        </Heading>
        {capturedFormsTourVisibility ? (
          <CapturedFormsTour
            onCloseCapturedFormsTour={onCloseCapturedFormsTour}
          />
        ) : null}
        <Flex justifyContent="space-between" mb="4">
          <Spacer />
          <Flex gap={2}>
            {selectedForm && tableData.length > 0 && (
              <Button colorScheme="blue" variant="outline">
                <CSVLink
                  data={tableData}
                  headers={csvHeaders}
                  filename={filename}
                >
                  <FaFileDownload />
                </CSVLink>
              </Button>
            )}

            <CustomSelect
              options={formsOptions}
              value={selectedFormId?.value}
              onChange={(selectedOption) => {
                handleOnChange(selectedOption?.value ? selectedOption : null);
              }}
              placeholder="Select a Form"
            />
          </Flex>
        </Flex>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Table
              fetch={hitFetch}
              columns={heads}
              data={tableData}
              table="capturedForm"
              selectedForm={selectedForm}
            />
            <Flex justifyContent="flex-end" marginBlock={2} gap={2}>
              <PaginationSizeSelect
                paginationSize={paginationSize}
                setPaginationSize={setPaginationSize}
                tableLength={tableData?.length}
              />
              <Paginator
                colorScheme={buttonColorScheme}
                onChangePage={setPage}
                page={page}
                pages={pages}
              />
            </Flex>
          </>
        )}
      </Box>
    </>
  );
};

export default ActivityTable;
