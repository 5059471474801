import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Select } from "chakra-react-select"
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import useToolUserRunStaticFunction from "../useToolUserRunStaticFunction";
import SelectPostgresColName from "../SelectPostgresColName";

export default function PostgresConfigModal({ onClose, toolUserForm }) {
  const baseUrl = getHostDomain();
  const { watch, setValue, register, handleSubmit, formState } = toolUserForm;
  const { tool_user_id, credentials = {} } = watch();
  const { configuration = {} } = credentials;

  const getStaticFunctionParams = (function_name) => {
    return {
      tool_user_id,
      staticFnPayload: {
        ...function_name,
      },
    };
  };

  const tableNameParams = getStaticFunctionParams({
    function_name: "get_table_names",
  });

  const { status: tableNameFetchStatus, response: tableNameFetchResponse } =
    useToolUserRunStaticFunction(tableNameParams);

  const tableNameOptions = tableNameFetchResponse
    ? tableNameFetchResponse.map((item) => ({
        value: item,
        label: item,
      }))
    : [];

  const navigate = useNavigate();

  const onSubmitPostgresConf = async (data) => {
    try {
      await fetchController(
        baseUrl + `/api/v1/tool/${data.tool_user_id}/LinkToolUser`,
        "PUT",
        {
          credentials,
        }
      );
      toast.success("Tool setup done");
      onClose();
      navigate(`/tools`);
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitPostgresConf)}>
        <ModalBody>
          <Stack spacing={2}>
            <FormControl
              isInvalid={
                formState.errors?.credentials?.configuration?.lookup_table
              }
              isRequired
            >
              <FormLabel>Select Table</FormLabel>
              <Select
                value={tableNameOptions.find(
                  (tableName) => tableName.value === configuration.lookup_table
                )}
                {...register("credentials.configuration.lookup_table", {
                  required: "Table name is required",
                })}
                onChange={({ value }) => {
                  setValue("credentials.configuration.lookup_table", value);
                }}
                options={tableNameOptions}
                isLoading={tableNameFetchStatus === "loading"}
              />
              {formState.errors?.credentials?.configuration?.lookup_table && (
                <FormErrorMessage>
                  {
                    formState.errors?.credentials?.configuration?.lookup_table
                      .message
                  }
                </FormErrorMessage>
              )}
            </FormControl>

            {configuration?.lookup_table && (
              <SelectPostgresColName
                getStaticFunctionParams={getStaticFunctionParams}
                credentials={credentials}
                toolUserForm={toolUserForm}
              />
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={formState.isSubmitting}
            colorScheme="blue"
            mr={3}
            type="submit"
          >
            Finish
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </form>
    </>
  );
}
