import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Grid,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import FormLabelWithInfoTooltip from "../../Widgets/FormLabelWithInfoTooltip";

export default function WalletProperties({
  wallet,
  status,
  onUpdateStatus,
  userDetails,
}) {
  const { register, watch, setValue, handleSubmit } = useForm({
    defaultValues: wallet,
  });
  const onSubmit = async (values) => {
    try {
      onUpdateStatus("updating");
      const response = await fetchController(
        "/api/v1/billing/user_wallet",
        "PUT",
        {
          ...values,
          auto_recharge_threshold: parseFloat(values?.auto_recharge_threshold),
          auto_recharge_amount: parseFloat(values?.auto_recharge_amount),
        }
      );
      toast.success(response.message);
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      onUpdateStatus("idle");
    }
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid gap={2}>
          <Checkbox {...register("auto_recharge_enabled")}>
            Auto recharge
          </Checkbox>
          {watch("auto_recharge_enabled") && (
            <Flex justifyContent={"center"} alignItems={"center"} gap={2}>
              <FormControl>
                <FormLabelWithInfoTooltip
                  label={"Threshold"}
                  tip={
                    "Recharge will be attempted when the balance reaches or gets lower than this amount"
                  }
                />
                <NumberInput
                  flex={1}
                  min={0}
                  isRequired
                  value={watch("auto_recharge_threshold")}
                  onChange={(value) => {
                    setValue("auto_recharge_threshold", value);
                  }}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl>
                <FormLabelWithInfoTooltip
                  label={"Recharge By USD"}
                  tip={
                    "On reaching the set threshold, amount that will be charged to your credit to recharge"
                  }
                />
                <NumberInput
                  flex={1}
                  min={0}
                  isRequired
                  value={watch("auto_recharge_amount")}
                  onChange={(value) => {
                    setValue("auto_recharge_amount", value);
                  }}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
            </Flex>
          )}
          <Tooltip
            isDisabled={userDetails?.stripe_has_payment_method}
            label="Please add your card to recharge your wallet"
          >
            <Button
              type="submit"
              isLoading={status === "updating"}
              colorScheme="blue"
              isDisabled={!userDetails?.stripe_has_payment_method}
            >
              Set Autorecharge
            </Button>
          </Tooltip>
        </Grid>
      </form>
    </Box>
  );
}
