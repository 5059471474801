import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdNavigateNext } from "react-icons/md";
import { Select } from "chakra-react-select"

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Progress,
  Spinner,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { fileUploadFetch } from "../../../utils/FetchController/fileUplaodFetch";
import { showToast } from "../../../utils/Toast/Toast";
import { getHostDomain } from "../../../utils/utils";
import FileDragUploader from "./FileDragUploader";

const DataSourcesBegginers = ({
  setActiveIndex,
  activeIndex,
  files,
  setFiles,
  setDataSourceId,
}) => {
  const baseUrl = getHostDomain();
  const options = [
    { value: "pdf", label: "PDF" },
    { value: "http", label: "HTTP" },
    { value: "text_blob", label: "Text" },
  ];

  const [dsType, setDsType] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const fetchFiles = async (urlHit) => {
    const getData = await fetchController(urlHit, "GET");
  };
  useEffect(() => {
    if (activeIndex === 0) {
      const localStorageData = localStorage.getItem("dataSource");
      const parseData = JSON.parse(localStorageData);
      if (parseData?.id) {
        const endpoint = `/api/v1/datasource/${parseData?.id}/datasourcefiles`;
        fetchFiles(baseUrl + endpoint);
      }
    }
  }, [activeIndex]);
  const onSubmit = async (data) => {
    setLoading(true); // Set loading to true when submitting
    const endpoint = "/api/v1/datasource";
    const url = baseUrl;
    const urlHit = url + endpoint;
    const attributesExist = {
      url: data.startURLs,
      max_crawl_pages: Number(data.maxCrawlPages),
    };
    const body = {
      ds_type: dsType,
      name: data.name,
      description: data.description,
      attributes:
        data.dsType === "http"
          ? attributesExist
          : data.dsType === "text_blob"
          ? { text_blob: data.attributes_text_blob }
          : {},
    };

    try {
      if (dsType === "pdf" || (dsType === "image" && files.length > 0)) {
        // Add logic for PDF type
        const getData = await fetchController(urlHit, "POST", body);
        if (getData?.message) {
          setDataSourceId(getData.data.id);
          const endPoint = `/api/v1/datasource/${
            getData?.data?.id
          }/file?ds_type=${getData.data.ds_type}&name=${
            name ? name : ""
          }&description=${description ? description : ""}`;
          for (const file of files) {
            // Generate a unique filename for each file
            const uniqueFilename = `${file.name}-${Date.now()}`;
            const description = "";
            // Update the API URL with the new filename and description
            const endPoint = `/api/v1/datasource/${
              getData?.data?.id
            }/file?ds_type=${getData.data.ds_type}&name=${encodeURIComponent(
              uniqueFilename
            )}&description=${description}`;

            const urlHit = url + endPoint;

            const formData = new FormData();
            formData.append("datasourcefile_file", file);

            await fileUploadFetch(formData, urlHit);
          }
          setActiveIndex(1);
          // Additional logic after adding data sources if needed
        } else {
          showToast("Oops! Something went wrong.", "error");
        }
      } else if (dsType === "http" || dsType === "text_blob") {
        // Add logic for HTTP type
        const getData = await fetchController(urlHit, "POST", body);
        if (dsType === "text_blob") {
          await fetchController(
            baseUrl +
              `/api/v1/datasource/${getData.data.id}/text_blob?datasource_id=${getData.data.id}&ds_type=${getData.data.ds_type}&name=${getData.data.name}&description=${getData.data.description}`,
            "POST",
            { text_blob: data.attributes_text_blob }
          );
        }
        if (getData?.message) {
          setDataSourceId(getData.data.id);
          setActiveIndex(1);
          // Additional logic after adding data sources if needed
        } else {
          showToast("Oops! Something went wrong.", "error");
        }
      }
    } catch (error) {
      showToast("Oops! Something went wrong.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (file) => {
    setFiles([...files, ...file]);
  };

  const fileTypes = ["PDF"];

  const handleRemove = (indexToRemove) => {
    const updatedFiles = files.filter((_, index) => index !== indexToRemove);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    if (!detailsVisible) {
      setName("");
      setDescription("");
    }
  }, [detailsVisible]);
  return (
    <Box
      height="55vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <FormControl isInvalid={errors?.dsType} flex="1">
          <FormLabel fontSize="18px" fontWeight="bold" mb={2} color="#3182ce">
            Add Data source
          </FormLabel>
          <Select
            placeholder="Select Data Source Type"
            {...register("dsType", { required: true })}
            options={options}
            isSearchable={false}
            onChange={(selectedOption) => {
              setValue("dsType", selectedOption?.value || null, {
                shouldDirty: true,
                shouldValidate: true,
              });
              setDsType(selectedOption?.value || null);
            }}
          />
          {errors?.dsType && (
            <FormErrorMessage>
              Data Source Type is required
            </FormErrorMessage>
          )}
        </FormControl>
        <Box display="flex" gap={2} mt={2}>
          <Box>
            <Input
              placeholder="Name"
              {...register("name", { required: true })}
            />
            {errors?.name && (
              <p style={{ color: "red", fontSize: "11px" }}>Name is required</p>
            )}
          </Box>
          <Box flex={1}>
            <Input
              width="100%"
              placeholder="Description"
              {...register("description", { required: true })}
              resize="vertical"
            />
            {errors?.description && (
              <p style={{ color: "red", fontSize: "11px" }}>
                Description is required
              </p>
            )}
          </Box>
        </Box>

        {dsType === "pdf" || dsType === "image" ? (
          <FileDragUploader
            fileTypes={dsType === "image" ? ["JPG", "JPEG", "PNG"] : fileTypes}
            handleChange={handleChange}
            files={files}
            handleRemove={handleRemove}
            requriedMessage={`${dsType} is required`}
          />
        ) : null}
        {dsType === "text_blob" ? (
          <Box marginBlock={2}>
            <Textarea
              placeholder="Text blob"
              name="attributes_text_blob"
              {...register("attributes_text_blob", {
                required: true,
              })}
            />
          </Box>
        ) : null}
        {dsType === "http" && (
          <Stack mb="4" mt="12px">
            <Input
              placeholder="Start URLs"
              {...register("startURLs", { required: true })}
            />
            {errors?.startURLs && (
              <p style={{ color: "red", fontSize: "11px" }}>
                {errors.startURLs.message}
              </p>
            )}
            <Input
              type="number"
              placeholder="Max Crawl Pages"
              defaultValue="100"
              {...register("maxCrawlPages", {
                required: true,
                max: 10000,
              })}
            />
            {errors.maxCrawlPages && (
              <p style={{ color: "red", fontSize: "11px" }}>
                {errors.maxCrawlPages.type === "required"
                  ? "Max crawl pages is required"
                  : errors.maxCrawlPages.type === "max"
                  ? "Max crawl pages cannot be greater than 10000. Please contact us for higher limits"
                  : "Max crawl poages is required !"}
              </p>
            )}
          </Stack>
        )}
      </Box>

      <Box
        mt={4}
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width="80%">
          <Text fontWeight="bold">Completed : 0%</Text>
          <Progress mt={2} isAnimated width="100%" hasStripe value={1} />
        </Box>
        <Button
          isDisabled={loading}
          width="80px"
          colorScheme="blue"
          onClick={handleSubmit(onSubmit)}
          mt="4"
        >
          {loading ? (
            <Spinner size="sm" color="white" />
          ) : (
            <Text display="flex" alignItems="center">
              Next <MdNavigateNext size="25px" />
            </Text>
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default DataSourcesBegginers;
