/* eslint-disable no-useless-computed-key */
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { MdComputer } from "react-icons/md";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import { getHostDomain, toBoolean } from "../../utils/utils";
import AddIntent from "../Modals/AddIntent";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import Table from "../Tables/Table";
import { HEADS } from "./constants";

const commonBoxStyle = {
  display: "flex",
  justifyContent: "left",
  marginLeft: "15px",
};
const IntentTable = () => {
  const baseUrl = getHostDomain();
   const agency = useContext(AgencyContext);
  const { buttonColorScheme, myDetails } = agency;
  const endpoint = "/api/v1/intent/get_my_details?page=1&size=50";
  const { state: activatedPlan } = useContext(SettingContext);
  const [status, setStatus] = useState("idle");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);

  const hitFetch = async () => {
    try {
      setStatus("loading");
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      if (response?.data) {
        const updatedData = response.data.items.map((item) => {
          return {
            active: (
              <Box {...commonBoxStyle}>
                <Tooltip
                  label={item.is_active ? "Active" : "Not active"}
                  aria-label={item.is_active ? "Active" : "Not active"}
                >
                  <Center>
                    <Icon
                      as={GoDotFill}
                      color={item.is_active ? "green.500" : "red.500"}
                    />
                  </Center>
                </Tooltip>
              </Box>
            ),
            Type: (
              <Box {...commonBoxStyle}>
                <Center>
                  <Icon
                    as={item.intent_type === "system" ? MdComputer : FaUser}
                  />
                </Center>
              </Box>
            ),
            userType: item.intent_type,
            ["Assistants #"]: (
              <Box {...commonBoxStyle} minWidth="100px">
                <Center>{item.assistants_count}</Center>
              </Box>
            ),
            name: item.name,
            description: item.description,
            id: item.id,
          };
        });

        const columns = HEADS.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));
        setTableData(updatedData);
        setHeads(columns);
        setStatus("success");
      }
    } catch (error) {
      setStatus("failure");
    }
  };

  useEffect(() => {
    hitFetch();
  }, []);

 
  const isAddIntentBtnEnable = toBoolean(activatedPlan.advanced_intents);
  const toolTipMsg =
    myDetails && myDetails?.agency_id
      ? `Please contact ${myDetails?.branding?.agency_name || ""} `
      : "Please upgrade to Diamond Plan";

  return (
    <>
      <Box h="75vh" overflowY="scroll" p="5">
        <Flex justifyContent="end" alignItems="center" mb="4">
          <Tooltip
            label={!isAddIntentBtnEnable ? toolTipMsg : ""}
            aria-label="A tooltip"
          >
            <Button
              colorScheme={buttonColorScheme}
              onClick={() => {
                onOpen();
              }}
              isDisabled={!isAddIntentBtnEnable}
            >
              Add an Intent
            </Button>
          </Tooltip>
        </Flex>
        {status === "loading" ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              fetch={hitFetch}
              columns={heads}
              data={tableData}
              table="intent"
            />
          </Box>
        )}
      </Box>
      <AddIntent
        fetchAssistants={hitFetch}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </>
  );
};

export default IntentTable;
