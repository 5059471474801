import { useEffect, useState } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";

export default function useLookupWallet({ auto = true }) {
  const [walletLookups, setWalletLookups] = useState([]);
  const [status, setStatus] = useState("loading");
  const fetchLookupsForWallet = async () => {
    try {
      setStatus("loading");
      const response = await fetchController(
        "/api/v1/billing/get_wallet_billing_plans"
      );
      setWalletLookups(response.data.items);
      setStatus(response?.data?.items.length ? "loaded" : "empty");
    } catch (error) {
      setStatus("error");
    }
  };
  useEffect(() => {
    if (auto) fetchLookupsForWallet();
  }, []);
  return {
    fetchLookupsForWallet,
    walletLookups,
    status,
  };
}
