import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { Select } from "chakra-react-select";
import LinkAssistantContactSync from "./LinkAssistantContactSync";
import { useParams } from "react-router-dom";

export default function SelectContactSync({
  watch,
  setValue,
  selectedDropdown,
  setSelectedDropdown,
}) {
  const baseUrl = getHostDomain();
  const { attributes = {} } = watch();
  const { contact_tool_id, contact_syncs = [] } = attributes;
  const { assistantId } = useParams();
  const [CRMOptions, setCRMOptions] = useState([]);
  const [toolConfigurationOptions, setToolConfigurationOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCRMList = async () => {
    try {
      setLoading(true);
      const response = await fetchController(
        baseUrl + "/api/v1/tool/tools/conversation_sync/list",
        "GET"
      );
      const options = response.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));

      setCRMOptions(options);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCRMList();
  }, []);

  const getToolConfigurationList = async (toolId) => {
    try {
      setLoading(true);
      const response = await fetchController(
        baseUrl + `/api/v1/tool/${toolId}/LinkToolUser`,
        "GET"
      );
      const options = response.data?.items.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));

      setToolConfigurationOptions(options);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contact_tool_id) {
      getToolConfigurationList(contact_tool_id);
    }
  }, [contact_tool_id]);

  const handleCRMSelect = ({ value }) => {
    setValue("attributes.contact_tool_id", value);
    setValue("attributes.tool_id", value);
    setSelectedDropdown((prevSelectedDropdown) => ({
      ...prevSelectedDropdown,
      supportedCRM: CRMOptions.find((opt) => opt.value === value),
    }));
  };

  const {
    isOpen: isLinkAssistantContactSyncOpen,
    onOpen: onLinkAssistantContactSyncOpen,
    onClose: onLinkAssistantContactSyncClose,
  } = useDisclosure();
  const isToolUserSelected = !(attributes?.contact_syncs || []).length;
  const isCustomizeFieldSyncDisabled = isToolUserSelected || !assistantId;
  return (
    <Stack dir="row" spacing={2} py={2}>
      <FormControl>
        <FormLabel>Supported CRM</FormLabel>
        <Select
          placeholder="Select CRM"
          value={
            selectedDropdown.supportedCRM ||
            CRMOptions.find((opt) => opt.value === contact_tool_id)
          }
          options={CRMOptions}
          onChange={handleCRMSelect}
          isLoading={loading}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Tool Configuration to use</FormLabel>
        <Select
          placeholder="Select Tool Configuration"
          value={
            selectedDropdown.toolConfigurationToUse ||
            toolConfigurationOptions.find(
              (opt) => opt.value === contact_syncs[0]
            )
          }
          options={toolConfigurationOptions}
          onChange={({ value }) => {
            setValue("attributes.contact_syncs", [value]);
            setValue("attributes.conversation_syncs", [value]);
            setSelectedDropdown((prevSelectedDropdown) => ({
              ...prevSelectedDropdown,
              toolConfigurationToUse: toolConfigurationOptions.find(
                (opt) => opt.value === value
              ),
            }));
            console.log({ value });
          }}
          isLoading={loading}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </FormControl>
      <Box>
        <Tooltip
          label={
            !assistantId
              ? "You can customize fields after creating assistant"
              : !(attributes?.contact_syncs || []).length
              ? "To customize fields select tool user first"
              : null
          }
        >
          <Button
            isDisabled={isCustomizeFieldSyncDisabled}
            mt="2"
            onClick={onLinkAssistantContactSyncOpen}
          >
            Select & customize fields to sync
          </Button>
        </Tooltip>
      </Box>
      {isLinkAssistantContactSyncOpen && (
        <LinkAssistantContactSync
          isOpen={isLinkAssistantContactSyncOpen}
          onClose={onLinkAssistantContactSyncClose}
          tool_user_id={
            (attributes?.contact_syncs || []).length
              ? attributes?.contact_syncs[0]
              : null
          }
        />
      )}
    </Stack>
  );
}
