import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { FileUploader } from "react-drag-drop-files";
import AddMyVoice from "../../Modals/AddMyVoice";
import { fetchController } from "../../../utils/FetchController/fetchController";
import WhatsAppModal from "../../Modals/WhatsApp";
import VoiceTimestampModal from "../../Modals/VoiceTimestampModal";
import { SettingContext } from "../Reducer/SettingProvider";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import AuthContext from "../../Auth/AuthContext";
import { getHostDomain, toBoolean } from "../../../utils/utils";

const Profile = ({ activeTab }) => {
  const baseUrl = getHostDomain();
  const [isMyVoiceRecorded, setIsMyVoiceRecorded] = useState(false);
  const [voiceData, setVoiceData] = useState([]);
  const { state: activatedPlan } = useContext(SettingContext);
  const {
    isOpen: isAddMyVoiceModalOpen,
    onOpen: onAddMyVoiceModalOpen,
    onClose: onAddMyVoiceModalClose,
  } = useDisclosure();
  const {
    isOpen: isOpenWhatsApp,
    onOpen: onOpenWhatsapp,
    onClose: onCloseWhatsapp,
  } = useDisclosure();
  const {
    isOpen: isVoiceTimeStampModalOpen,
    onOpen: onVoiceTimeStampModalOpen,
    onClose: onVoiceTimeStampModalClose,
  } = useDisclosure();

  const { authUser } = useContext(AuthContext);

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (authUser?.picture) {
      setImage(authUser.picture);
    }
  }, [authUser.picture]);

  const handleChange = (files) => {
    const file = files;
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const fileTypes = ["jpeg", "png"]; // Specify the MIME types for JPG and PNG

  const loadOwnVoice = async () => {
    const endpoint = `/api/v1/voice/list`;
    const urlHit = baseUrl + endpoint;
    const getData = await fetchController(urlHit, "GET");

    const modifiedData = getData?.data?.items?.map((val, index) => {
      return {
        value: val.id,
        label: val.name,
      };
    });
    setVoiceData(getData?.data?.items);
    setIsMyVoiceRecorded(modifiedData?.length > 0 ? true : false);
  };

  useEffect(() => {
    if (
      activeTab === "profile" &&
      (activatedPlan.plan_lookup_key !== "insightoai_free" ||
        activatedPlan.plan_lookup_key !== "insightoai_gold")
    ) {
      loadOwnVoice();
    }
  }, [activeTab]);
  const agency = useContext(AgencyContext);
  const { buttonColorScheme, myDetails } = agency;

  const isMyVoiceBtnEnable=toBoolean(activatedPlan?.credits?.custom_voice)
  
  const toolTipMsg=myDetails && myDetails?.agency_id?`Please contact ${myDetails?.branding?.agency_name || "" } `:"Please upgrade to Platinum or above"

  return (
    <Box border="1px" p="5" borderRadius="md" h="81vh" overflowY="auto">
      <Flex direction={"column"}>
        <Box mb="4" mr="4">
          <Flex gap={4}>
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              multiple={false}
            >
              {image ? (
                <Box
                  cursor="pointer"
                  border="2px solid transparent"
                  _hover={{ borderColor: "#3182ce" }}
                >
                  <img
                    src={image}
                    alt="User"
                    style={{ width: "150px", height: "120px" }}
                  />
                </Box>
              ) : (
                <Box
                  w="150px"
                  h="150px"
                  borderRadius="full"
                  border="2px dashed #CBD5E0"
                >
                  Drop image here or click to select
                </Box>
              )}
            </FileUploader>
            <Box>
              <Flex flexDirection="column">
                <Text fontSize="lg" fontWeight="bold">
                  {authUser?.first_name || authUser?.given_name}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {authUser?.email}
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Box>
          <Tooltip
            label={
              !isMyVoiceBtnEnable
                ? toolTipMsg
                : ""
            }
            aria-label="A tooltip"
          >
            <Button
              colorScheme={buttonColorScheme}
              onClick={
                isMyVoiceRecorded
                  ? onVoiceTimeStampModalOpen
                  : onAddMyVoiceModalOpen
              }
              isDisabled={!isMyVoiceBtnEnable}
            >
              My voice
            </Button>
          </Tooltip>
        </Box>
      </Flex>
      <WhatsAppModal
        isOpen={isOpenWhatsApp}
        onOpen={onOpenWhatsapp}
        onClose={onCloseWhatsapp}
      />
      {isAddMyVoiceModalOpen && (
        <AddMyVoice
          isAddMyVoiceModalOpen={isAddMyVoiceModalOpen}
          onAddMyVoiceModalClose={onAddMyVoiceModalClose}
          isMyVoiceRecorded={isMyVoiceRecorded}
          loadOwnVoice={loadOwnVoice}
          isUpdatingVoice={isMyVoiceRecorded}
          buttonColorScheme={buttonColorScheme}
        />
      )}
      <VoiceTimestampModal
        isVoiceTimeStampModalOpen={isVoiceTimeStampModalOpen}
        onVoiceTimeStampModalOpen={onVoiceTimeStampModalOpen}
        onVoiceTimeStampModalClose={onVoiceTimeStampModalClose}
        onAddMyVoiceModalOpen={onAddMyVoiceModalOpen}
        voiceData={voiceData}
        buttonColorScheme={buttonColorScheme}
      />
    </Box>
  );
};

export default Profile;
