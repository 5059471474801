import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import WidgetCreationStepper from "./WidgetCreationStepper";
import { useForm } from "react-hook-form";
import useWidgetStepper from "./useWidgetStepper";
import { providers, allowedAssistantTypesByWidgets } from "../utils";
import useFinalSubmit from "./useFinalSubmit";

export default function AddWidgetModal({ isOpen, onClose, fetchWidgets, onGotoFirstPage }) {
  const widgetForm = useForm({
    defaultValues: {
      widget_type: "",
      assistant_id: null,
      assistant_type: "",
      twilio_auth_id: "",
      phone_number: "",
      max_call_duration: 600,
      form: null,
      recording: false,
      form_phone_number_field: "",
      sid: "",
    },
  });

  const { status, handleSubmit } = useFinalSubmit({ widgetForm });

  const { stepper, steps } = useWidgetStepper({
    widgetForm,
    onClose,
    handleSubmit,
    fetchWidgets,
    onGotoFirstPage,
  });

  const currentStep = steps[stepper.activeStep];
  const hasFinished = steps?.length -1 === stepper?.activeStep

  const handleClose = () => {
    stepper.setActiveStep(0);
    widgetForm.reset({});
    onClose();
  };

  return (
    <Modal size={"3xl"} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Widget</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <WidgetCreationStepper steps={steps} stepper={stepper} />
        </ModalBody>
        {currentStep && (
          <currentStep.Children
            onClose={handleClose}
            widgetForm={widgetForm}
            onSubmit={currentStep.onSubmit}
            loadingStatus={status}
            onBack={currentStep.onBack}
            hasFinished={hasFinished}
            providers={providers}
            allowedAssistantTypesByWidgets={allowedAssistantTypesByWidgets}
          />
        )}
      </ModalContent>
    </Modal>
  );
}
