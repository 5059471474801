import React, { useState } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import useToolUserRunStaticFunction from "../Tools/useToolUserRunStaticFunction";
import { useParams } from "react-router-dom";
import { getHostDomain } from "../../utils/utils";

export default function useLinkAssistantContactSync({ tool_user_id }) {
  const { assistantId: assistant_id } = useParams();
  const [fields, setFields] = useState({});
  const baseUrl = getHostDomain();
  const [linkedAssistantContactSync, setLinkedAssistantContactSync] =
    useState(null);
  const [linkAssistantStatus, setLinkAssistantStatus] = useState("loading");
  const getLinkedAssistantContactSyncByAssistantId = () =>
    fetchController(
      `${baseUrl}/api/v1/link_assistant_contact_sync/assistant/${assistant_id}`
    );
  const getRootRequiredFields = () =>
    fetchController(
      baseUrl + `/api/v1/tool/ToolUser/${tool_user_id}/run_static_function`,
      "POST",
      {
        function_name: "get_root_fields",
      }
    );
  async function getInitialLinkedAssistantContactSyncFields() {
    setLinkAssistantStatus("loading");
    const linkedAssistantContactSyncsResponse = assistant_id
      ? await getLinkedAssistantContactSyncByAssistantId()
      : await Promise.resolve({ data: [] });
    const [linkedAssistantContactSync] =
      linkedAssistantContactSyncsResponse?.data || [];
    setLinkedAssistantContactSync(linkedAssistantContactSync);
    const fields_to_be_synced =
      linkedAssistantContactSync?.fields_to_be_synced || {};
    const totalContactSyncs = linkedAssistantContactSyncsResponse?.data?.length;
    const makeInitialLinkedAssistantContactSync = (
      statusFields,
      [name, description]
    ) => {
      statusFields[name] = {
        name,
        description,
        status: totalContactSyncs ? Boolean(description) : true,
      };
      return statusFields;
    };
    const initalLinkedAssistantContactSync = Object.entries(
      fields_to_be_synced
    ).reduce(makeInitialLinkedAssistantContactSync, {});
    setLinkAssistantStatus("idle");

    return initalLinkedAssistantContactSync;
  }
  const { status, run } = useToolUserRunStaticFunction({
    cb: async (response) => {
      const [initalLinkedAssistantContactSync, rootRequiredFieldsResponse] =
        await Promise.all([
          getInitialLinkedAssistantContactSyncFields(),
          getRootRequiredFields(),
        ]);

      const makeFieldResponse = (statusFields, [name, description]) => ({
        ...statusFields,
        [name]: {
          name,
          description: initalLinkedAssistantContactSync[name]
            ? initalLinkedAssistantContactSync[name]?.description
            : description,
          status: Object.keys(initalLinkedAssistantContactSync).length
            ? Boolean(initalLinkedAssistantContactSync[name]?.status)
            : true,
          isRoot: Boolean(rootRequiredFieldsResponse[name]),
        },
      });

      const fieldResponse = Object.entries(response).reduce(
        makeFieldResponse,
        initalLinkedAssistantContactSync
      );

      setFields(fieldResponse);
    },
    tool_user_id,
    staticFnPayload: { function_name: "get_fields" },
  });

  const onSubmitFieldContactSyncs = async () => {
    try {
      const url = `/api/v1/link_assistant_contact_sync${
        linkedAssistantContactSync?.id
          ? `/${linkedAssistantContactSync.id}`
          : ""
      }`;
      const method = linkedAssistantContactSync?.id ? "PUT" : "POST";
      const response = await fetchController(baseUrl + url, method, {
        assistant_id,
        link_tool_user_id: tool_user_id,
        ...(linkedAssistantContactSync?.id ? {} : { attributes: {} }),
        fields_to_be_synced: Object.entries(fields).reduce(
          (finalFields, [name, { description, status }]) => {
            finalFields[name] = status ? description : status;
            return finalFields;
          },
          {}
        ),
      });
      run();
      toast.success(response.message);
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const onChangeStatus = (name) => (e) => {
    setFields({
      ...fields,
      [name]: {
        ...fields[name],
        status: e.currentTarget.checked,
      },
    });
  };
  const onChangeDescription = (name) => (e) => {
    setFields({
      ...fields,
      [name]: {
        ...fields[name],
        description: e.currentTarget.value,
      },
    });
  };
  return {
    onSubmitFieldContactSyncs,
    status,
    fields,
    linkedAssistantContactSync,
    onChangeDescription,
    onChangeStatus,
    linkAssistantStatus,
  };
}
