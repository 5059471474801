import {
  Box,
  Table as ChakraTable,
  Flex,
  Icon,
  IconButton,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { FaMinus, FaPause, FaPlay, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";

const SyncTable = (props) => {
  const {
    columns,
    syncVoicesList,
    addToAvailableVoice,
    getProvicerIcon,
    fetchSyncVoicesData,
    previewDefaultAudio,
    getSubHeadingDescriptionLabel,
  } = props;
  const audioRefs = useRef({});
  const [playingAudioId, setPlayingAudioId] = useState(null);

  const toggleAudio = (id, previewUrl) => {
    let currentAudio = audioRefs.current[id];
    if (playingAudioId === id) {
      currentAudio?.pause();
      setPlayingAudioId(null);
      return;
    }
    if (playingAudioId && audioRefs.current[playingAudioId]) {
      audioRefs.current[playingAudioId].pause();
      audioRefs.current[playingAudioId].currentTime = 0;
    }

    if (!currentAudio) {
      currentAudio = new Audio(previewUrl);
      audioRefs.current[id] = currentAudio;
      currentAudio.onended = () => {
        setPlayingAudioId(null);
      };
    }
    currentAudio.play().catch((e) => {
      toast("Audio can't be played");
    });
    setPlayingAudioId(id);
  };

  return (
    <Box mt={2}>
      {syncVoicesList?.length === 0 ? (
        <Box textAlign="center" py={5} fontSize="lg" fontWeight="bold">
          No Voices Available
        </Box>
      ) : (
        <ChakraTable width="100%">
          <Thead>
            <Tr backgroundColor="#FAFAFA">
              {columns.map((column) => (
                <Th
                  textAlign="left"
                  borderBottom="1px"
                  borderColor="#E4E4E7"
                  key={column}
                >
                  {column}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {syncVoicesList?.map((voice, index) => {
              const labels = voice?.attributes?.properties?.labels;
              return (
                <Tr key={voice.id}>
                  <Td>
                    <Tooltip
                      label={voice.voicetts_provider}
                      textTransform="capitalize"
                    >
                      <Flex justifyContent="space-around">
                        <Icon
                          as={getProvicerIcon(voice.voicetts_provider)}
                          size="sm"
                        />
                      </Flex>
                    </Tooltip>
                  </Td>
                  <Td textTransform="capitalize">{voice.language_group}</Td>
                  <Td>{voice.name}</Td>
                  <Td>{getSubHeadingDescriptionLabel(labels)}</Td>
                  <Td>
                    <IconButton
                      ml={3}
                      icon={
                        playingAudioId === voice.id ? <FaPause /> : <FaPlay />
                      }
                      colorScheme="blue"
                      onClick={async () => {
                        const previewUrl = voice.attributes?.preview_url
                          ? voice.attributes?.preview_url
                          : await previewDefaultAudio(voice?.id);
                        toggleAudio(voice.id, previewUrl);
                      }}
                      aria-label="Play/Pause Audio"
                      size="sm"
                    />
                  </Td>
                  <Td>
                    <Tooltip
                      label={voice?.enabled ? "Disable Voice" : "Enable Voice"}
                    >
                      <IconButton
                        size="sm"
                        ml={3}
                        icon={voice?.enabled ? <FaMinus /> : <FaPlus />}
                        colorScheme={voice?.enabled ? "red" : "green"}
                        onClick={async () => {
                          await addToAvailableVoice(voice.id, voice?.enabled);
                          await fetchSyncVoicesData();
                        }}
                      />
                    </Tooltip>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
      )}
    </Box>
  );
};

export default SyncTable;
