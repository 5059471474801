import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useContext } from "react";
import Layout from "../Layout/Layout";
import AvailableVoices from "./AvailableVoices";
import SyncVoices from "./SyncVoices";
import useVoicesHook from "./useVoicesHook";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const Index = () => {
  const { textColor } = useVoicesHook();

  const agency = useContext(AgencyContext);
  const tabs = [
    {
      label: "Available Voices",
      value: "availableVoices",
      children: <AvailableVoices />,
      isVisible: true,
    },
    {
      label: "Sync Your Voices",
      value: "syncVoices",
      children: <SyncVoices />,
      isVisible: agency?.myDetails?.agency_id ? false : true,
    },
  ];

  return (
    <Layout>
      <Box
        border="1px"
        p="5"
        height={"90svh"}
        overflowY={"auto"}
        borderRadius="md"
      >
        <Tabs isLazy defaultIndex={0}>
          <TabList>
            {tabs
              .filter((tab) => tab.isVisible)
              .map((tab) => (
                <Tab color={textColor} key={tab.label}>
                  {tab.label}
                </Tab>
              ))}
          </TabList>
          <TabPanels>
            {tabs
              .filter((tab) => tab.isVisible)
              .map((tab) => (
                <TabPanel key={tab.label}>
                  <Box>{tab.children}</Box>
                </TabPanel>
              ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
};

export default Index;
