import {
  FormControl,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import { Select } from "chakra-react-select"
export default function ResponseTypeSelectLlmNode({ nodeForm }) {
  const { register } = nodeForm.form;
  const { data = {} } = nodeForm.form.watch();

  const responseTypeOptions = [
    {
      value: "llm",
      label: "LLM",
      textFieldLabel: "Prompt",
    },
    {
      value: "static",
      label: "Static",
      textFieldLabel: "Text",
    },
  ];
  const selectedResponseOption = responseTypeOptions.find(
    (responseTypeOption) => responseTypeOption.value === data?.response?.type
  );

  return (
    <>
      <FormControl>
        <FormLabel>Response type</FormLabel>
        <Select
          options={responseTypeOptions}
          value={selectedResponseOption}
          onChange={({ value }) => {
            nodeForm.form.setValue("data.response.type", value);
          }}
        />
      </FormControl>
      {data?.response?.type ? (
        <FormControl>
          <FormLabel>{selectedResponseOption.textFieldLabel}</FormLabel>
          <Textarea {...register("data.response.value")} />
        </FormControl>
      ) : null}
    </>
  );
}
