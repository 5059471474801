import {
  Box,
  Grid,
  Icon,
  Menu,
  MenuButton,
  Text,
  theme,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { AgencyContext } from "../../components/Settings/AgencyProvider/AgencyProvider";

const AnalyticsCard = ({
  title,
  value,
  color,
  icon,
  src,
  width = 200,
  w = "18px",
  h = "18px",
  textFontSize = "md",
}) => {
  const agency = useContext(AgencyContext);
  // const color_preset = agency?.data?.branding?.color_preset
  const { textColor, colorPreset, cardBg } = agency;
  const navbarIcon = useColorModeValue(colorPreset || "gray.400", "white");
  const cardTitleColor = useColorModeValue("gray", theme.colors.gray[300]);

  return (
    <Box
      bg={cardBg}
      p={4}
      height={100}
      borderRadius="xl" // Set a fixed height
      width={width} // Set a fixed width
      display="flex"
      gap={5}
      alignItems={"center"}
      justifyContent="flex-between"
      boxShadow="md"
    >
      {/* <Box borderRadius={"full"} p={3} bg={"#f5f7fead"}>
        <Icon icon={icon} color={navbarIcon} w="18px" h="18px" me="10px" />
      </Box> */}
      <Menu>
        <MenuButton p="0px">
          {icon ? (
            <Icon mt="6px" as={icon} color={navbarIcon} w={w} h={h} me="10px" />
          ) : (
            <img src={src} width={18} />
          )}
        </MenuButton>
      </Menu>
      <Grid>
        <Text
          color={cardTitleColor}
          fontWeight={"bold"}
          fontSize={textFontSize}
        >
          {title}
        </Text>
        <Text fontWeight={"bold"} fontSize={"2xl"}>
          {value?.toLocaleString()}
        </Text>
      </Grid>
    </Box>
  );
};

export default AnalyticsCard;
