import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React from "react";
import AddNewSyncVoiceModal from "./AddNewSyncVoiceModal";

const SyncVoicesModal = (props) => {
  const {
    isSyncVoicesOpen,
    setQueries,
    onSyncVoicesClose,
    getAllSpecificProvider,
    queries,
    providersDetails,
    specificProviderList,
    syncSelectedProviderVoice,
    isAddNewSyncVoiceModalOpen,
    onAddNewSyncVoiceModalOpen,
    onAddNewSyncVoiceModalClose,
  } = props;

  return (
    <>
      <Modal
        isOpen={isSyncVoicesOpen}
        onClose={onSyncVoicesClose}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent maxW="688px">
          <ModalHeader style={{ fontWeight: 700, fontSize: "18px" }}>
            Sync Your Voices
          </ModalHeader>
          <ModalCloseButton alignItems="center" />
          <ModalBody>
            <Box gap={2}>
              <Box>
                <FormLabel>Voice Provider</FormLabel>
                <ButtonGroup spacing={0} isAttached>
                  {Object.keys(providersDetails).map((provider) => {
                    return (
                      <Button
                        key={provider.value}
                        title={provider.label}
                        onClick={(e) => {
                          getAllSpecificProvider(
                            providersDetails[provider].value
                          );
                          setQueries((prevQueries) => {
                            const newQueries = {
                              ...prevQueries,
                              provider:
                                prevQueries.provider === provider
                                  ? ""
                                  : provider,
                              specificProvider: "",
                            };
                            return newQueries;
                          });
                        }}
                        colorScheme={
                          provider === queries?.provider ? "gray" : undefined
                        }
                        isActive={provider === queries?.provider}
                      >
                        <Tooltip
                          label={providersDetails[provider].label}
                          textTransform="capitalize"
                          placement="top"
                        >
                          <span>
                            <Icon
                              as={providersDetails[provider].icon}
                              size="sm"
                            />
                          </span>
                        </Tooltip>
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Box>
              <Flex pt={2}>
                <FormControl>
                  <FormLabel>
                    <Flex
                      gap={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text>Select Keys</Text>
                      <Button
                        colorScheme="blue"
                        onClick={onAddNewSyncVoiceModalOpen}
                        size="sm"
                      >
                        Add New
                      </Button>
                    </Flex>
                  </FormLabel>
                  <Select
                    name="specificProvider"
                    onChange={(selectedOption) => {
                      setQueries((prevQueries) => ({
                        ...prevQueries,
                        specificProvider: selectedOption,
                      }));
                    }}
                    value={queries?.specificProvider}
                    options={specificProviderList}
                  />
                </FormControl>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" width="100%">
              <Button
                onClick={onSyncVoicesClose}
                variant="outline"
                width="104px"
              >
                Cancel
              </Button>
              <Button
                visibility={!queries.specificProvider && "hidden"}
                colorScheme="blue"
                onClick={() => {
                  syncSelectedProviderVoice();
                  onSyncVoicesClose();
                }}
              >
                Sync Now
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AddNewSyncVoiceModal
        providersDetails={providersDetails}
        isAddNewSyncVoiceModalOpen={isAddNewSyncVoiceModalOpen}
        onAddNewSyncVoiceModalOpen={onAddNewSyncVoiceModalOpen}
        onAddNewSyncVoiceModalClose={onAddNewSyncVoiceModalClose}
      />
    </>
  );
};

export default SyncVoicesModal;
