import { Box } from "@chakra-ui/react";
import { useContext } from "react";
import { IoMdClose } from "react-icons/io";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export default function TourLayout({ children, onCloseTour }) {
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg: bg } = agency;
  return (
    <Box
      position={"relative"}
      backgroundColor={bg}
      borderRadius={18}
      boxShadow="md"
      marginBlock={2}
      padding={3}
    >
      <IoMdClose
        onClick={onCloseTour}
        size={20}
        style={{
          cursor: "pointer",
          position: "absolute",
          right: 15,
        }}
      />
      {children}
    </Box>
  );
}
