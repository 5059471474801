import { Box, Button, Grid, Icon, Text } from "@chakra-ui/react";
import React from "react";

const NoDataPage = (props) => {
  const {
    icon,
    title,
    description,
    buttonIcon,
    colorScheme = "blue",
    onClick,
    isDisabled = false,
    buttonTtile,
  } = props;

  return (
    <Grid justifyContent="center" alignContent="center" gap={2} height="80%">
      <Box justifyContent="center" textAlign="center">
        <Icon height="200px" width="200px" justifySelf="center">
          {icon}
        </Icon>
        <Text fontWeight="600" fontSize="24px">
          {title}
        </Text>
        <Text fontWeight="400" fontSize="14px">
          {description}
        </Text>
      </Box>
      <Button
        leftIcon={buttonIcon}
        colorScheme={colorScheme}
        onClick={onClick}
        isDisabled={isDisabled}
      >
        {buttonTtile}
      </Button>
    </Grid>
  );
};

export default NoDataPage;
