import {
  Alert,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { fileUploadFetch } from "../../utils/FetchController/fileUplaodFetch";
import { getHostDomain } from "../../utils/utils";

export default function BulkImportContactModal({
  isOpen,
  onGotofirstPage,
  onClose,
  fetchContacts,
}) {
  const baseUrl = getHostDomain();
  const [contactCsv, setContactCsv] = useState(null);
  const handleChange = (file) => {
    setContactCsv(file);
  };
  const [status, setStatus] = useState("idle");

  const onSubmitBulkUploadForm = async (e) => {
    e.preventDefault();
    if (!contactCsv) return;
    setStatus("uploading");
    const formData = new FormData();
    formData.append("contact_file", contactCsv);
    const response = await fileUploadFetch(
      formData,
      baseUrl + `/api/v1/contact/bulk_import`
    );
    toast.success(response.status);
    await fetchContacts();
    setContactCsv(null);
    onGotofirstPage();
    onClose();
    setStatus("idle");
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={onSubmitBulkUploadForm}>
        <ModalContent>
          <ModalHeader>Contacts</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={2}>
              <FileUploader
                handleChange={handleChange}
                multiple={false}
                fileTypes={["CSV"]}
              />
              <a href="/sameBulkUploadContactsCsv.csv" download>
                Sample Contacts Upload CSV
              </a>
              <Alert status="info">
                <AlertIcon />
                {"Import of custom fields is not supported yet"}
              </Alert>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              isDisabled={!contactCsv}
              isLoading={status === "uploading"}
              colorScheme="blue"
              type="submit"
            >
              Import
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
