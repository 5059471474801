import {
  Box,
  Button,
  Flex,
  HStack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import DataSourcesList from "../Modals/AssignAssistant";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import CustomCheckbox from "./CustomCheckbox";

const DataSourcesField = ({ member, assistantId = null }) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;

  const selectedItem = getValues();
  const [selectedDataSource, setSelectedDataSource] = useState([]);

  const {
    isOpen: openSourceList,
    onOpen: onOpenSourceList,
    onClose: onCloseSourceList,
  } = useDisclosure();

  return (
    <Box px={4} py={2}>
      {watch("assistant_type").toLowerCase() === "simple" && (
        <Flex direction={"column"} gap={4}>
          <Box>
            <CustomCheckbox
              onChange={(e) => setValue("hide_ds", e.currentTarget.checked)}
              isChecked={watch("hide_ds")}
            >
              <Tooltip
                aria-label="Hide DS"
                label="Select if you don’t want to show link to your data source in the generated answer"
              >
                <Text> Hide Data Source(s)</Text>
              </Tooltip>
            </CustomCheckbox>
          </Box>

          <Box>
            <CustomCheckbox
              {...register("showImages")}
              isChecked={watch("showImages")}
              onChange={(e) => setValue("showImages", e.target.checked)}
            >
              <Tooltip
                label="Assistant will display the relevant extracted images from the URLs or the ingested images from the data sources"
                aria-label="A tooltip"
              >
                <Text>Show Images</Text>
              </Tooltip>
            </CustomCheckbox>
          </Box>
        </Flex>
      )}
      {watch("assistant_type").toLowerCase() !== "realtime_openai" && (
        <Box>
          <Flex justifyContent="space-between" pt={2}>
            <Tooltip
              aria-label="Connected Form"
              placement="right"
              label={
                !assistantId &&
                "You can connect Data Source after you’ve created the Assistant"
              }
            >
              <Button
                size="xs"
                type="button"
                colorScheme={buttonColorScheme}
                onClick={onOpenSourceList}
                isDisabled={!assistantId}
              >
                Assign Data Source
              </Button>
            </Tooltip>
            <Box>
              <Text as={"b"}>Connected Data Source :</Text>{" "}
              <Text as={"b"}>{selectedDataSource.length}</Text>
            </Box>
          </Flex>
          <DataSourcesList
            isOpen={openSourceList}
            onOpen={onOpenSourceList}
            onClose={onCloseSourceList}
            selectedItem={selectedItem}
            setSelectedDataSource={setSelectedDataSource}
          />
          <HStack spacing={4} mt="4">
            {selectedDataSource.map((item) => (
              <Tag size={"sm"} key={item.id} variant="subtle">
                <TagLabel>{item.name}</TagLabel>
              </Tag>
            ))}
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default DataSourcesField;
