import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useConversationFlow from "./hooks/useConversationFlow";

export default function NewConversationFlowModal({ isOpen, onClose }) {
  const navigate = useNavigate();
  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const { addFlow } = useConversationFlow();
  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data) => {
    try {
      const body = {
        name: data.name,
        num_nodes: 1,
        num_edges: 0,
        flow: {
          nodes: [
            {
              data: {
                node_type: "start",
                name: "Start",
                label: "Start",
                backgroundColor: "#3182CE",
                textColor: "#fff",
              },
              id: `${Date.now()}`,
              type: "customNode",
              position: {
                x: 0,
                y: 0,
              },
            },
          ],
          edges: [],
        },
      };;

      const response = await addFlow(body);

      toast.success(
        response?.message || "Conversation flow created successfully"
      );
      navigate(`/conversation-flow/${response?.data?.id}`);
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
      handleClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create new conversation flow</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl isInvalid={errors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Enter conversation flow name"
                {...register("name", { required: "Name is required" })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={handleClose}>
              Close
            </Button>
            <Button isLoading={isSubmitting} type="submit" colorScheme="yellow">
              Create
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
