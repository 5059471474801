import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { lazy, Suspense, useContext, useEffect, useState } from "react";
import AnalyticsCard from "../../utils/AnalyticsCard/AnalyticsCard";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import { getHostDomain } from "../../utils/utils";
import AuthContext from "../Auth/AuthContext";
import Layout from "../Layout/Layout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import { getProviderByAgency } from "../Widgets/utils";
import { WrappedModal } from "../WithInstructionModal/WrappedModal";
import CalendlyWidgetModal from "./CalendlyWidgetModal";
import DashboardTour from "./DashboardTour";
import useUserUsages from "./useUserUsages";
import useWidgetTypeCount from "./useWidgetTypeCount";
import { analyticsConfig, getDashboardAnalyticsIcons } from "./utils";
import { useNavigate } from "react-router-dom";
const DailyUsageChart = lazy(() => import("./DailyUsageChart"));

const Dashboard = () => {
  const baseUrl = getHostDomain();
  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);
  const { widgetTypeCounts } = useWidgetTypeCount();

  const [analytics, setAnalytics] = useState(
    Object.keys(analyticsConfig).reduce((analyticsPipe, analyticType) => {
      analyticsPipe[analyticType] = 0;
      return analyticsPipe;
    }, {})
  );
  const [loading, setLoading] = useState(true);
  const endpoint = "/api/v1/analytics";
  const urlHit = baseUrl + endpoint;

  const { graphConfig } = useUserUsages();
  const fetchAnalyticsData = async () => {
    setLoading(true);
    try {
      const getData = await fetchController(urlHit, "GET");
      if (getData) {
        setAnalytics(getData.data);
      }
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setLoading(false);
    }
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg, myDetails } = agency;
  const isAgencyLoaded = !agency.loading && !myDetails?.agency_id;

  useEffect(() => {
    fetchAnalyticsData();
  }, [authUser]);
  const { state: activePlan } = useContext(SettingContext);

  useEffect(() => {
    if (!localStorage.getItem("calendly")) onCalendlyToggle();
  }, []);

  const [dashboardTourVisibility, setDashboardTourVisibility] = useState(
    localStorage.getItem("dashTourVisibility") ? false : true
  );
  const onCloseDashtour = () => {
    localStorage.setItem("dashTourVisibility", false);
    setDashboardTourVisibility(false);
  };
  const { onToggle: onCalendlyToggle, isOpen: isCalendlyOpen } =
    useDisclosure();
  const toggleCalendly = () => {
    localStorage.setItem("calendly", !isCalendlyOpen);
    onCalendlyToggle();
  };

  const email = myDetails?.agency_id
    ? myDetails?.branding?.agency_mail
    : "support@insighto.ai";

  return (
    <Layout>
      {loading && <Loader />}
      <Box border="1px" h="90svh" p="5" overflowY={"auto"} borderRadius="md">
        <Flex justifyContent={"space-between"}>
          <Text fontSize="xl" fontWeight="bold" color={textColor} p={2}>
            Hi{" "}
            {authUser?.first_name ||
              authUser?.name?.split(" ")[0] ||
              authUser?.nickname ||
              authUser?.given_name ||
              authUser?.email}
            !
          </Text>
          {!analytics?.assistants_count && (
            <Button
              colorScheme={buttonColorScheme}
              onClick={() => navigate("/ai-agents/assistants/new")}
            >
              {"Create your first AI Agent"}
            </Button>
          )}
        </Flex>

        {dashboardTourVisibility ? (
          <DashboardTour onCloseDashtour={onCloseDashtour} />
        ) : null}

        <Grid mt={3} gap={3}>
          <Flex>
            <Box flex={6}>
              <Box
                width="100%"
                p={3}
                border={"none"}
                borderRadius="xl"
                boxShadow="md"
                background={cardBg}
              >
                <Flex gap={3} justifyContent={"space-between"}>
                  <Text
                    fontSize="xl"
                    fontWeight={"bold"}
                    color={textColor}
                    mb="10px"
                    flex={1}
                  >
                    Daily Usage
                  </Text>
                </Flex>
                <Suspense
                  fallback={
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="300px"
                    >
                      <Spinner size="lg" />
                    </Box>
                  }
                >
                  <DailyUsageChart config={graphConfig} />
                </Suspense>
              </Box>
            </Box>
            <Box flex={6}>
              <Box>
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  flex={6}
                  gap={4}
                >
                  {Object.keys(analytics).map((analyticType, index) => {
                    const analyticCardMeta =
                      getDashboardAnalyticsIcons(analyticType);
                    return (
                      <AnalyticsCard
                        title={analyticCardMeta.label}
                        value={analytics[analyticType]}
                        icon={analyticCardMeta.icon}
                        color={textColor}
                        key={index}
                      />
                    );
                  })}
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Grid>
        <Flex alignItems={"center"} flexWrap={"wrap"} gap={4} marginTop={5}>
          {Object.entries(widgetTypeCounts).map(([provider, count], index) => {
            if(provider === "null") return

            const name = agency?.myDetails?.agency_id
              ? agency?.myDetails?.branding?.agency_name
              : "Insighto.AI";

            const { logo, label } = getProviderByAgency({
              provider,
              logo: agency?.faviconUrl,
              name,
            });
            return (
              <AnalyticsCard
                key={index}
                value={count}
                src={logo}
                color={textColor}
                title={label}
                width="135px"
                w="16px"
                h="16px"
                textFontSize="sm"
              />
            );
          })}
        </Flex>
        <Flex
          justifyContent={"flex-end"}
          alignItems={"center"}
          mt={6}
          color="#00008B"
        >
          {email && (
            <Button colorScheme={buttonColorScheme}>
              <Link
                target="_blank"
                href={`mailto:${email}`}
                style={{ textDecoration: "none" }}
              >
                Queries? We’re here to help! {email}
              </Link>
            </Button>
          )}
        </Flex>
        {isCalendlyOpen &&
          isAgencyLoaded &&
          activePlan?.plan_lookup_key !== "202402_appsumo_4" && (
            <CalendlyWidgetModal
              isOpen={isCalendlyOpen}
              onClose={toggleCalendly}
            />
          )}
      </Box>
    </Layout>
  );
};

export default WrappedModal(Dashboard);
