import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Assistants from "../Assistants/Assistants";
import DataSources from "../DataSources/DataSources";
import Layout from "../Layout/Layout";
import Widgets from "../Widgets/Widgets";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const AiAgents = () => {
  const { name = "data-sources" } = useParams();
  const navigate = useNavigate();
  const { textColor } = useContext(AgencyContext);

  const tabs = useMemo(
    () => [
      { route: "data-sources", tabName: "Data Sources", component: <DataSources tab="Data Sources" /> },
      { route: "assistants", tabName: "Assistants", component: <Assistants tab="Assistants" /> },
      { route: "widgets", tabName: "Widgets", component: <Widgets tab="Widgets" /> },
    ],
    []
  );

  const tabIndex = tabs.findIndex((tab) => tab.route === name);
  const defaultIndex = tabIndex >= 0 ? tabIndex : 0;

  return (
    <Layout>
      <Box>
        <Tabs
          isLazy
          index={defaultIndex} 
          onChange={(index) => navigate(`/ai-agents/${tabs[index].route}`)}
        >
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab.route} color={textColor}>
                {tab.tabName}
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab.route}>
                <Box mt={4}>{tab.component}</Box>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
};

export default AiAgents;
