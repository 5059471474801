import { FormControl, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function ConversationFlowSelect({ setValue, watch }) {
  const baseUrl = getHostDomain();

  const [status, setStatus] = useState("idle");
  const [flowOptions, setFlowOptions] = useState([]);
  const [flowIdMap, setFlowIdMap] = useState(new Map());

  const getConversationFlowList = async () => {
    try {
      setStatus("loading");
      const response = await fetchController(
        `${baseUrl}/api/v1/conversation_flow/list`,
        "GET"
      );
      const map = new Map();
      const options = response?.data?.items?.map((item) => {
        map.set(item.id, { label: item.name, value: item.id });
        return {
          label: item.name,
          value: item.id,
        };
      });
      setFlowIdMap(map);
      setFlowOptions(options);
    } catch (error) {
      console.error(error);
    } finally {
      setStatus("idle");
    }
  };

  useEffect(() => {
    getConversationFlowList();
  }, []);

  return (
    <FormControl>
      <FormLabel>Conversation Flow</FormLabel>
      <Select
        onChange={({ value }) => {
          setValue("conversation_flow_id", value);
          setValue("systemPrompt", "")
          setValue("templatePhonePrompt", "");
        }}
        options={flowOptions}
        isLoading={status === "loading"}
        value={
          watch("conversation_flow_id")
            ? flowIdMap.get(watch("conversation_flow_id"))
            : ""
        }
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 999 }),
        }}
      />
    </FormControl>
  );
}
