import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  Spinner,
  Tooltip,
  SimpleGrid,
} from "@chakra-ui/react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import Loader from "../../../utils/Loader/Loader";
import { getHostDomain } from "../../../utils/utils";
import { toast } from "react-toastify";
import { FaCircleCheck } from "react-icons/fa6";
import { FaFire } from "react-icons/fa";
import { GoDot } from "react-icons/go";
import { FaCheckCircle } from "react-icons/fa";
const BillingCard = ({
  planName,
  features,
  pricing,
  active,
  popular,
  label,
  erlyBirdsOffer,
  isDisable,
  isCoupan,
  isTrial,
  bg,
  color,
  buttonColorScheme,
  lookup_key,
  customWidth,
}) => {
  const baseUrl = getHostDomain();
  const endpoint = "/api/v1/billing/create-checkout-session";
  const url = baseUrl;
  const urlHit = url + endpoint;
  const [loading, setLoading] = useState(false);
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  useEffect(() => {
    return () => setIsFullScreenLoading(false);
  }, []);
  const submit = async (lookup_key, label) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to ${label} plan ?`
    );
    if (!isConfirmed) return;
    setLoading(true);
    try {
      const body = {
        lookup_key: lookup_key,
      };
      const getData = await fetchController(urlHit, "POST", body);
      if (getData.checkout_url) {
        window.location = getData.checkout_url;
      } else {
        if (getData?.status) {
          toast.success(getData?.message, { autoClose: 4000 });
          setIsFullScreenLoading(true);
          setTimeout(() => {
            window.location.reload();
            setIsFullScreenLoading(false);
          }, 5000);
        } else {
          toast.error(getData?.message, { autoClose: 4000 });
        }
        setLoading(false);
      }
    } catch (error) {
      setIsFullScreenLoading(false);
      console.error("Error occour while upgrading plan: ", error);
    } finally {
      setLoading(false);
    }
  };

  const formatKey = (key) => {
    const words = key ? key.split("_") : [];
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  };
  const getPlanName = (plan) => {
    if (!plan) return null;
    let nameArr = formatKey(planName).split("-");
    return nameArr[nameArr.length - 1];
  };
  return (
    <Box
      borderWidth={active ? "3px" : "1px"}
      borderRadius="lg"
      overflow="hidden"
      width={customWidth || "100%"}
      maxW={customWidth || 350}
      boxShadow="lg"
      bg={bg}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      borderStyle="solid"
      borderColor={active ? color : "#EAECF0"}
      position="relative"
    >
      {isFullScreenLoading && (
        <Loader text="We are changing your plan, please wait…" />
      )}

      <Box
        borderBottomColor={active ? color : "#EAECF0"}
        borderBottomWidth={active ? "3px" : "1px"}
        borderStyle="solid"
        bgColor={active && "aliceblue"}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="3px"
      >
        <Text p={2} fontSize="18px" fontWeight="bold">
          {isCoupan ? planName : getPlanName(planName)}
        </Text>
        {popular && (
          <Box
            display="flex"
            height="25px"
            padding="3px"
            alignItems="center"
            justifyContent="center"
            bg="#D1E9FF"
            borderRadius="30px"
          >
            <FaFire color="#F97316" />

            <Text fontSize="12px" fontWeight="bold">
              Popular
            </Text>
          </Box>
        )}
        {active ? <FaCheckCircle color="#3B82F6" /> : <GoDot size="25px" />}
      </Box>

      <Box p={2}>
        <Text fontSize="22px" fontWeight="bold">
          {formatKey(pricing)}
        </Text>

        <Text textAlign="center" fontWeight="bold">
          {erlyBirdsOffer && erlyBirdsOffer}
        </Text>
      </Box>
      <Box p={2} flex="1" overflowY="auto">
        <SimpleGrid columns={customWidth ? 2 : 1} spacing={2}>
          {features[0] &&
            Object.entries(features[0]).map(([key, value], index) => (
              <Box display="flex" alignItems="center" gap={2} key={index}>
                <FaCircleCheck
                  size={15}
                  color={value ? "#4db16f" : "#D1FADF"}
                />
                <Text
                  fontSize="12px"
                  fontWeight="bold"
                  color={value ? "black" : "#8080808a"}
                >
                  {formatKey(key)}{" "}
                  {typeof value === "number" && value.toLocaleString()}
                </Text>
              </Box>
            ))}
        </SimpleGrid>
      </Box>
      {!active ? (
        <Tooltip
          label={
            isDisable && isTrial
              ? "You are on a trial period. Plan will automatically be downgraded on its completion."
              : ""
          }
          aria-label={
            isDisable && isTrial
              ? "You are on a trial period. Plan will automatically be downgraded on its completion."
              : ""
          }
        >
          <Button
            onClick={() => {
              submit(lookup_key, label);
            }}
            colorScheme={buttonColorScheme}
            m={3}
            alignSelf="center"
            textTransform={"capitalize"}
            width="90%"
            isDisabled={isDisable}
          >
            {loading ? <Spinner size="xs" /> : label}{" "}
          </Button>
        </Tooltip>
      ) : (
        <Button
          colorScheme="blue"
          alignSelf="center"
          width="90%"
          m={3}
          disabled
        >
          Activated
        </Button>
      )}
    </Box>
  );
};

export default BillingCard;
