import {
  Box,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import { fetchController } from "../../utils/FetchController/fetchController";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../utils/Loader/Paginator";
import { showToast } from "../../utils/Toast/Toast";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const AssignAssistant = ({ isOpen, onOpen, onClose, selectedItem }) => {
  const baseUrl = getHostDomain();
  const finalRef = useRef(null);
  const [heads, setHeads] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;
  const endpoint = `/api/v1/datasource/${selectedItem?.id}/assistants?page=${page}&size=${paginationSize}`;
  const urlHit = baseUrl + endpoint;
  const hitFetch = async () => {
    try {
      setIsLoading(true);

      const getData = await fetchController(urlHit, "GET");
      setPages(getData?.data?.pages);
      if (getData?.data) {
        const items = getData.data.items || [];
        const heads = items.length > 0 ? Object.keys(items[0]) : [];
        setHeads(heads);
        setTableData(items);
      } else {
        setHeads([]);
        setTableData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      hitFetch();
    }
  }, [isOpen, page, paginationSize]);
  const handleUnlink = async (item) => {
    const endpoint = `/api/v1/datasource/${selectedItem?.id}/${item.id}/LinkedAssistantDataSource`;
    const urlHit = baseUrl + endpoint;
    const getData = await fetchController(urlHit, "DELETE");
    if (getData.detail) {
      hitFetch();
      showToast(getData.detail, "success");
    } else {
      showToast("Oops! Something went wrong.", "error");
    }
  };
  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          setHeads([]);
          setTableData([]);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent height="70vh" width="95%" maxWidth="600px">
          {isLoading && (
            <Center
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              background="rgba(255, 255, 255, 0.8)"
              zIndex="999"
            >
              <Spinner />
            </Center>
          )}
          <ModalHeader>Linked Assistants</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table size="sm">
                {tableData.length > 0 && (
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Description</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                )}
                <Tbody>
                  {tableData.length > 0 ? (
                    tableData.map((item) => {
                      return (
                        <Tr key={item.id}>
                          <Td>{item.name}</Td>
                          <Td>{item.description}</Td>
                          <Td
                            onClick={() => {
                              handleUnlink(item);
                            }}
                            cursor="pointer"
                          >
                            <MdDelete color="red" />
                          </Td>
                        </Tr>
                      );
                    })
                  ) : (
                    <Box>
                      <Center>No Assistant</Center>
                    </Box>
                  )}
                </Tbody>
              </Table>
              <Flex
                justifyContent={"flex-end"}
                alignItems={"center"}
                marginBlock={2}
                gap={2}
              >
                <PaginationSizeSelect
                  paginationSize={paginationSize}
                  setPaginationSize={setPaginationSize}
                  tableLength={tableData.length}
                />
                <Paginator
                  colorScheme={buttonColorScheme}
                  onChangePage={setPage}
                  page={page}
                  pages={pages}
                />
              </Flex>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssignAssistant;
