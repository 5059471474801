import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { MdDeleteOutline } from "react-icons/md";

export default function ToolFunctionDataExtraction({
  nodeForm,
  onSubmit,
  onBack,
  hasFinished,
}) {
  const { watch, setValue, handleSubmit } = nodeForm.form;
  const { data } = watch();
  const dataForm = useForm({
    defaultValues: {
      name: "",
      json_path: "",
    },
  });
  const dataExtractions = data?.data_extractions || [];

  return (
    <>
      <ModalBody>
        <Stack spacing={2}>
          <form
            onSubmit={dataForm.handleSubmit((values) => {
              const dataExtractionFields = [
                ...dataExtractions,
                { ...values, id: `${Date.now()}` },
              ];
              setValue("data.data_extractions", dataExtractionFields);
              localStorage.setItem(
                "dataExtractionFields",
                JSON.stringify(dataExtractionFields)
              );
              dataForm.reset();
            })}
          >
            <Stack spacing={2}>
              <Alert status="info">
                <AlertIcon />
                You can parse the Tool’s response and assign them to your
                Conversation Flow variables using JSONPath expression.
              </Alert>
              <FormControl>
                <FormLabel>Name Your Extracted Variable</FormLabel>
                <Input {...dataForm.register("name")} />
              </FormControl>
              <FormControl>
                <FormLabel>JSONPath for Extraction</FormLabel>
                <Input {...dataForm.register("json_path")} />
              </FormControl>
              <Button
                type="submit"
                isDisabled={dataForm.watch("name").length < 1}
              >
                Add
              </Button>
            </Stack>
          </form>

          {dataExtractions?.length && (
            <Text fontWeight={"semi-bold"}>Data extraction fields</Text>
          )}
          <Flex gap={2} justifyContent={"flex-start"} alignItems={"center"}>
            {dataExtractions.map((dataExtraction) => (
              <Tag
                key={dataExtraction.id}
                variant="subtle"
                size={"lg"}
                colorScheme="blue"
              >
                <TagLeftIcon
                  cursor={"pointer"}
                  onClick={() => {
                    setValue(
                      "data.data_extractions",
                      dataExtractions.filter(
                        (data) => data.id !== dataExtraction.id
                      )
                    );
                  }}
                  boxSize="12px"
                  as={MdDeleteOutline}
                />
                <TagLabel>{dataExtraction.name}</TagLabel>
              </Tag>
            ))}
          </Flex>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          colorScheme={hasFinished ? "blue" : "yellow"}
        >
          {hasFinished ? "Finish" : "Next"}
        </Button>
      </ModalFooter>
    </>
  );
}
