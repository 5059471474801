import {
  Box,
  Center,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import { getHostDomain } from "../../utils/utils";
import ExternalModal from "./ExternalModal";

const AssignIntent = ({
  isOpen,
  onOpen,
  onClose,
  loadIntent,
  selectedItem,
  tableData,
  searchTerm,
  setSearchTerm,
  isLoading,
  setIsLoading,
}) => {
  const baseUrl = getHostDomain();
  const finalRef = useRef(null);
  const [externalModalOpen, setExternalModalOpen] = useState(false);

  const [id, setId] = useState("");
  const handleCheckboxChange = async (item) => {
    try {
      setIsLoading(true);
      if (item.linked) {
        const response = await fetchController(
          `${baseUrl}/api/v1/assistant/${selectedItem.id}/${item.id}/LinkedAssistantIntent`,
          "DELETE"
        );
        showToast(response.detail, "success");
      } else {
        setId(item.id);
        setExternalModalOpen(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      loadIntent();
    }
  };

  const handleAddIntent = async ({ name, email }) => {
    const endpoint = `/api/v1/assistant/${selectedItem?.id}/add_intent/${id}`;
    const urlHit = baseUrl + endpoint;

    try {
      setIsLoading(true);

      const body = {
        actions: [
          {
            type: "email",
            email: email,
            emailName: name,
          },
        ],
      };
      const getData = await fetchController(urlHit, "POST", body);
      if (getData?.data) {
        toast.success("Intent Linked");
        loadIntent();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setExternalModalOpen(false);
    }
  };

  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent height="70vh" width="95%" maxWidth="600px">
          {isLoading && (
            <Center
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              background="rgba(255, 255, 255, 0.8)"
              zIndex="999"
            >
              <Spinner />
            </Center>
          )}
          <ModalHeader>Linked Data Sources</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb="4">
              <Input
                placeholder="Search by name or description"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
            <TableContainer height="50vh" overflowY="Scroll">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Assign</Th>
                    <Th>Name</Th>
                    <Th>Description</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {tableData &&
                    tableData.map((item) => {
                      return (
                        <Tr key={item.id}>
                          <Td>
                            <Checkbox
                              mt="10px"
                              ml="15px"
                              isChecked={item.linked}
                              onChange={() => handleCheckboxChange(item)}
                            />
                          </Td>
                          <Td>{item.name}</Td>
                          <Td>{item.description}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ExternalModal
        isOpen={externalModalOpen}
        onClose={() => setExternalModalOpen(false)}
        onAddIntent={handleAddIntent}
      />
    </>
  );
};

export default AssignIntent;
