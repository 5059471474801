import { Button, Flex, Heading, IconButton, useColorModeValue } from "@chakra-ui/react";
import "@xyflow/react/dist/style.css";
import { MdAdd, MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
export default function OperationalHeader(props) {
  const navigate = useNavigate();
  const bg = useColorModeValue("gray.50", "gray.700");

  const handleGoBack = () => {
    navigate("/conversation-flow")
  }

  return (
    <Flex
      p={3}
      bg={bg}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={3}
    >
      <Flex gap={4} alignItems={"center"}>
        <IconButton
          variant="outline"
          colorScheme="blue"
          aria-label="back"
          icon={<MdArrowBack />}
          onClick={handleGoBack}
          size={"sm"}
        />
        <Heading as="h4" size="md">
          {props.flowName}
        </Heading>
      </Flex>
      <Button
        onClick={props.onOpen}
        colorScheme="blue"
        size={"sm"}
        leftIcon={<MdAdd />}
      >
        Step
      </Button>
    </Flex>
  );
}
