/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState, useMemo } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import Table from "../Tables/Table";
import { Box } from "@chakra-ui/react";
import { getHostDomain } from "../../utils/utils";

const Sources = ({ assistantData }) => {
  const baseUrl = getHostDomain();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchSources = async () => {
      if (!assistantData?.data?.id) return;

      try {
        const endpoint = `/api/v1/assistant/${assistantData.data.id}/list_data_sources`;
        const url = `${baseUrl}${endpoint}`;
        const response = await fetchController(url, "GET");

        if (response?.data) {
          setTableData(response.data.items || []);
        }
      } catch (error) {
        console.error("Error fetching data sources:", error);
      }
    };

    fetchSources();
  }, [assistantData, baseUrl]);

  const columns = useMemo(
    () => [
      { Header: "Name", accessor: "name" },
      { Header: "Description", accessor: "description" },
    ],
    []
  );

  return (
    <Box width="50%">
      <Table columns={columns} data={tableData} />
    </Box>
  );
};

export default Sources;
