import {
  AbsoluteCenter,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Stack,
} from "@chakra-ui/react";
import { createIcon } from "@chakra-ui/icons";
import { useContext, useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { FcGoogle } from "react-icons/fc";
import { MdLogin } from "react-icons/md";
import PasswordInput from "../Forms/PasswordInput";
import AuthContext from "./AuthContext";
import { fetchController } from "../../utils/FetchController/fetchController";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import useRecaptcha from "./useRecaptcha";
import {
  getCookieValue,
  getHostDomain,
  isValidDomain,
  validateRegex,
} from "../../utils/utils";
import useTitle from "../../utils/Hook/useTitle";
import { Link, useSearchParams } from "react-router-dom";
import AgencyLogo from "./AgencyLogo";
import axios from "axios";
import Loader from "../../utils/Loader/Loader";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import useHubSpot from "./useHubspot";

const MicrosoftIcon = createIcon({
  displayName: "MicrosoftIcon",
  viewBox: "0 0 129 129",
  path: (
    <>
      <path fill="#F25022" d="M0,0h61.3v61.3H0V0z" />
      <path fill="#7FBA00" d="M67.7,0H129v61.3H67.7V0z" />
      <path fill="#00A4EF" d="M0,67.7h61.3V129H0V67.7z" />
      <path fill="#FFB900" d="M67.7,67.7H129V129H67.7V67.7z" />
    </>
  ),
});

function LoginForm() {
  const { login } = useContext(AuthContext);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$";
  const [searchParams] = useSearchParams();
  const refresh_token = searchParams.get("token");
  const getRecaptchaToken = useRecaptcha();
  const { submitForm } = useHubSpot();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleHubSpotLogin = (data) => {
    const fields = [
      {
        objectTypeId: "0-1",
        name: "email",
        value: data.email,
      },
    ];
    const hubspotutk = getCookieValue("hubspotutk");
    const pageUri = `${window.origin}/login`;
    const pageName = "login";

    submitForm(fields, hubspotutk, pageUri, pageName);
  };

  const onSubmit = async (e) => {
    e.preventDefault();    
    setIsValidate(true);
    if (
      !formData.email ||
      !formData.password ||
      !validateRegex(emailPattern, formData.email)
    )
      return;

    try {
      setLoading(true);
      const data = { ...formData };
      const reCaptchaToken = await getRecaptchaToken("login");
      data.captcha_response = reCaptchaToken;

      await login(data);
      if (isValidDomain(window.location.origin)) {
        handleHubSpotLogin(formData);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const [status, setStatus] = useState("loading");
  const onOAuthLogin = async (loginType, provider) => {
    setStatus(loginType + "-loading");
    const response = await fetchController(
      process.env.REACT_APP_SERVER_URL + `/api/v1/login/${loginType}`
    );
    const providerKey = provider ? `?provider=${provider}` : "";
    const redirectUri = `${response.url}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}${providerKey}`;
    window.open(redirectUri, "_self");
    setStatus("idle");
  };

  const agency = useContext(AgencyContext);
  const {
    textColor,
    buttonColorScheme,
    agencyData,
    myDetails,
    titleAgencyName,
  } = agency;
  const agency_id = myDetails ? myDetails?.agency_id : null;
  useTitle(titleAgencyName);
  const isTokenValid = (token) => {
    try {
      jwtDecode(token);
      return true;
    } catch (error) {
      toast.error("Login failed");
      return false;
    }
  };
  useEffect(() => {
    (async function () {
      if (refresh_token && isTokenValid(refresh_token)) {
        setStatus("loading");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        try {
          const resfreshTokenResponse = await axios.post(
            `${getHostDomain()}/api/v1/login/refresh_token`,
            { refresh_token }
          );
          localStorage.setItem(
            "token",
            resfreshTokenResponse.data.data.access_token
          );
          localStorage.setItem(
            "refreshToken",
            resfreshTokenResponse.data.data.refresh_token
          );
        } catch (error) {
          toast.error("Some error occurred");
          setStatus("failed");
        } finally {
          window.location.reload();
          setStatus("idle");
        }
      } else {
        setStatus("idle");
      }
    })();
  }, [refresh_token]);
  const isAgencyLoaded = !agency.loading;
  return (
    <Box height={"100vh"} bgColor={"#F0F1F8"} p={1}>
      {status === "loading" ? (
        <Loader />
      ) : (
        <Flex height={"100%"}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            width={"50%"}
            spacing={4}
            py={10}
          >
            <Box>
              <AgencyLogo />
            </Box>
            {isAgencyLoaded ? (
              <>
                {!agency_id && (
                  <Heading size="xl" color={textColor}>
                    Welcome
                  </Heading>
                )}
                <Heading size="md" color={textColor}>
                  {!agency_id
                    ? "Log in to Insighto.ai to continue to App."
                    : "Log in to continue to App"}
                </Heading>
              </>
            ) : null}
          </Stack>

          <Stack
            width={"50%"}
            px={10}
            py={20}
            spacing={6}
            justifyContent="center"
            alignItems={"center"}
            borderRadius={20}
            bgColor={"#C7C7C7"}
          >
            <Flex
              gap={4}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              mb={10}
            >
              <MdLogin fontSize={"40px"} />
              <Heading size="xl">Login</Heading>
            </Flex>
            <form onSubmit={onSubmit} style={{ width: "80%" }}>
              <Flex direction={"column"} gap={6}>
                <FormControl
                  isInvalid={
                    (isValidate && !formData.email) ||
                    (isValidate && !validateRegex(emailPattern, formData.email))
                  }
                >
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    placeholder={"Enter your email"}
                    type="email"
                    bgColor={"white"}
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {isValidate &&
                    (!formData.email ? (
                      <FormErrorMessage>{"Email is required"}</FormErrorMessage>
                    ) : (
                      !validateRegex(emailPattern, formData.email) && (
                        <FormErrorMessage>
                          {"Invalid email address"}
                        </FormErrorMessage>
                      )
                    ))}
                </FormControl>

                <FormControl isInvalid={isValidate && !formData.password}>
                  <FormLabel>Password</FormLabel>
                  <PasswordInput
                    placeholder={"Enter password"}
                    value={formData.password}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                  {isValidate && !formData.password && (
                    <FormErrorMessage>
                      {"Password is required"}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <Link
                  style={{
                    fontWeight: "bold",
                    textAlign: "end",
                    color: textColor,
                  }}
                  to="/forgot-password"
                >
                  {"Forgot password?"}
                </Link>

                <Button
                  type="submit"
                  colorScheme={buttonColorScheme}
                  isLoading={loading}
                  w={"100%"}
                >
                  {"Login"}
                </Button>
              </Flex>
            </form>
            {!agency.loading
              ? !agency_id && (
                  <>
                    <Box width={"80%"}>
                      <Box position="relative">
                        <Divider borderColor={textColor} />
                        <AbsoluteCenter
                          bg={"#C7C7C7"}
                          px="4"
                          fontWeight={"medium"}
                        >
                          or continue with
                        </AbsoluteCenter>
                      </Box>
                    </Box>

                    <ButtonGroup
                      colorScheme={buttonColorScheme}
                      variant="outline"
                      spacing="6"
                    >
                      <IconButton
                        isLoading={status === "google-login-loading"}
                        onClick={() => {
                          onOAuthLogin("google-login", "google");
                        }}
                        icon={<FcGoogle />}
                      />
                      <IconButton
                        isLoading={status === "microsoft-login-loading"}
                        icon={<MicrosoftIcon />}
                        onClick={() => {
                          onOAuthLogin("microsoft-login", "");
                        }}
                      />
                    </ButtonGroup>
                  </>
                )
              : null}
            <Box fontWeight={"medium"} textAlign={"center"} mt={5}>
              Don't have an Account?{" "}
              <Link
                style={{ fontWeight: "bold", color: textColor }}
                to="/register"
              >
                {"Sign up"}
              </Link>
            </Box>
          </Stack>
        </Flex>
      )}
    </Box>
  );
}

export default function Login() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <LoginForm />
    </GoogleReCaptchaProvider>
  );
}
